import React from "react";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import MatBorderedPanel from "../../components/common/Mat/MatBorderedPanel";
import MatBox from "../../components/common/Mat/MatBox";
import MatButton from "../../components/common/Mat/MatButton";
import MatGrid from "../../components/common/Mat/MatGrid";
import MatHintText from "../../components/common/Mat/MatHintText";
import MatInput from "../../components/common/Mat/MatInput";
import MatLabel from "../../components/common/Mat/MatLabel";
import MatRadio from "../../components/common/Mat/MatRadio";
import StyledIcon from "../../components/common/StyledIcon";
import { updateFilters } from "../actions/filtersActions";

const FilterBox = styled(MatBox)`
    margin-top: 30px;
`;

const KeywordSearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -3px;
    margin-left: 7px;
    @media (max-width: 999px) {
        margin-left: 0;
    }
    ${MatLabel} {
        margin-top: 15px;
        margin-right: 10px;
    }
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
    justify-content: space-around;
    @media (max-width: 999px) {
        flex-wrap: wrap;
        justify-content: left;
    }
    label {
        margin-top: 10px;
    }
`;

@connect(
    state => ({
        ...state.companiesList.filters
    }),
    null,
    null,
    {
        pure: true
    }
)
export default class FiltersForm extends React.Component {
    searchContentChanged = ev => {
        this.props.dispatch(updateFilters({ searchContent: ev.target.value }));
    };

    render() {
        return (
            <FilterBox>
                <MatGrid.Row wrapMobile>
                    <MatGrid.Col width="100%" mobileWidth="100%">
                        {/* start header  */}
                        <MatGrid.Row alignCenter>
                            <MatGrid.Col>
                                <MatHintText>Quick companies searching</MatHintText>
                            </MatGrid.Col>
                            <MatGrid.Spacer />
                            <MatGrid.Col>
                                <LinkContainer to="/dashboard/companies/new">
                                    <MatButton success withIcon>
                                        Add new company <StyledIcon glyph="plus" />
                                    </MatButton>
                                </LinkContainer>
                            </MatGrid.Col>
                        </MatGrid.Row>
                        {/* end header  */}

                        <KeywordSearchWrapper>
                            <MatLabel>keyword</MatLabel>
                            <MatInput
                                noBottomMargin
                                label={"search"}
                                type="text"
                                required
                                value={this.props.searchContent}
                                onChange={this.searchContentChanged}
                            />
                        </KeywordSearchWrapper>
                    </MatGrid.Col>
                  
                </MatGrid.Row>
            </FilterBox>
        );
    }
}
