import { apiFetch } from "../../actions/apiActions";
import { toast } from 'react-toastify';

export const PASSWORD_CHANGED = "CHANGE_PASSWORD.PASSWORD_CHANGED";

export default function getActions (dispatch) {
  return {
    setNewPassword: (data) => {
      dispatch(setNewPassword(data));
    }
  };
}

export function statusChanged (status) {
  return {
    type: PASSWORD_CHANGED,
    status
  };
}

export function setNewPassword(data) {
  return async (dispatch) => {
    try {
      let changePassword = await dispatch(
        apiFetch("/api/set-new-password", {
          method: "POST",
          body: JSON.stringify(data)
        })
      );

      let changePasswordData = await changePassword.json();

      if (changePassword.status === 200 || changePassword.status === 400 || changePassword.status === 401 || changePassword.status === 403) {
        dispatch(statusChanged(changePasswordData));
        if(changePasswordData.success) {
          toast("Password updated!", {
            type: "success"
          });
        }
      } else {
        throw new Error("An error has occured: " + (changePasswordData.messages || "Unknown error"));
      }

      if (!changePasswordData || typeof changePasswordData !== "object") {
        throw new Error("Data returned by API is not an object!");
      }

    } catch (e) {
      dispatch(statusChanged('Problem with server response. Please try again later.'))
    }
  };
}

