import styled from "styled-components";

const TopControlsRow = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
        a,
        button,
        p {
            margin-left: 0 !important;
            margin-top: 5px !important;
            margin-bottom: 5px !important;
        }
    }
    p {
        margin: 0;
        margin-left: 15px;
        margin-top: 7px;
    }
    a,
    button {
        margin-top: 0;
        margin-left: 10px;
        max-height: 34px;
    }
`;

export default TopControlsRow;
