import { encodeParams, makeActionCreator, selectObjectKeys } from "../../utils";
import { companiesFiltersSelector } from "./filtersActions";
import { stringify as stringifyQueryString } from "querystring";
import { toast } from "react-toastify";
import { push } from "react-router-redux";
import { apiFetch } from "../../actions/apiActions";

export const DATA_LOADED = "COMPANIES_LIST.RESULTS.DATA_LOADED";
export const IS_LOADING_CHANGED = "COMPANIES_LIST.RESULTS.IS_LOADING_CHANGED";
export const LAST_FILTERS_UPDATE_CHANGED = "COMPANIES_LIST.RESULTS.LAST_FILTERS_UPDATE_CHANGED";

export const dataLoaded = makeActionCreator(DATA_LOADED, "totalRows", "payload");
export const isLoadingChanged = makeActionCreator(IS_LOADING_CHANGED, "payload");
export const lastFiltersUpdateChanged = makeActionCreator(LAST_FILTERS_UPDATE_CHANGED, "payload");

export let requestCache = {}; // this global variable holds the request cache for prefetching and faster response times

export function clearCompaniesListCache() {
    requestCache = {};
}

export function loadRowsDebounced() {
    return async (dispatch, getState) => {
        const old = new Date().toString();
        dispatch(lastFiltersUpdateChanged(old));
        setTimeout(() => {
            if (getState().companiesList.results.lastFiltersUpdate === old) {
                dispatch(loadRows());
            }
        }, 400);
    };
}

export function loadRows() {
    return async (dispatch, getState) => {
        const filters = companiesFiltersSelector(getState());
        dispatch(isLoadingChanged(true));
        try {
            const data = await dispatch(fetchPage(filters.page));
            if (!data) return;
            // we can prefetch the next page
            dispatch(fetchPage(filters.page + 1));

            dispatch(dataLoaded(0, data));
        } catch (e) {
            console.error(e);
            toast("Failed to load companies: " + e.message, { type: "error" });
        }
    };
}

export function fetchPage(page) {
    return async (dispatch, getState) => {
        const filters = companiesFiltersSelector(getState());
        const requestUrl =
            `/api/companies/search?` +
            stringifyQueryString({
                // page,
                // rowsPerPage: filters.rowsPerPage,
                sortBy: filters.sortBy,
                query: filters.searchContent
            });
        if (!requestCache[requestUrl]) {
            requestCache[requestUrl] = (async () => {
                const resp = await dispatch(apiFetch(requestUrl, {}));
                const data = await resp.json();
                if (!resp.ok) {
                    throw new Error(data.message);
                }
                return data;
            })().catch(e => {
                console.error("failed to prefetch next page:", e);
                toast("Failed to fetch page: " + e.message, { type: "error" });
                delete requestCache[requestUrl];
            });
        }
        return requestCache[requestUrl];
    };
}
