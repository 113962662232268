import classnames from "classnames";
import React from "react";
import { ControlLabel, FormControl, FormGroup, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import { PERMISSION_DELETE_EMPLOYEES } from "../../../../common/src/permissions";
import ConfirmableButton from "../../components/common/ConfirmableButton";
import StyledButton from "../../components/common/StyledButton";
import StyledIcon from "../../components/common/StyledIcon";
import validateDateOfBirth from "../../ReportsPage/selectors/validateDateOfBirth";
import { userHasPermissionSelector } from "../../selectors/authSelectors";
import { debounce } from "../../utils";
import {
    addTemplateAndLoadData,
    checkDuplicateStatus,
    dateOfBirthChanged,
    DUPLICATE_STATUS_DUPLICATE,
    DUPLICATE_STATUS_LOADING,
    DUPLICATE_STATUS_OK,
    loadEmployee,
    nameChanged,
    saveEmployee,
    STATE_LOADED,
    STATE_LOADING,
    deleteEmployee
} from "../actions/editEmployeeActions";
import TemplatesSelect from "./TemplatesSelect";
import EmployeeTemplatesList from "./EmployeeTemplatesList";
import AlignedFlex from "../../components/common/AlignedFlex";
import { Link } from "react-router-dom";

const TopFieldsRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    @media (max-width: 1300px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        > div {
            margin-top: 10px;
        }
    }
    .form-group {
        margin-bottom: 0;
    }
`;

const TemplatesSelectCell = styled.div`
    width: 350px;
`;

@connect(
    state => ({
        ...state.hr.editEmployee,
        deleteEmployeeEnabled: userHasPermissionSelector(state, PERMISSION_DELETE_EMPLOYEES)
    }),
    dispatch => ({
        loadEmployee: id => dispatch(loadEmployee(id)),
        nameChanged: ev => dispatch(nameChanged(ev.target.value)),
        dateOfBirthChanged: ev => dispatch(dateOfBirthChanged(ev.target.value)),
        saveEmployee: () => dispatch(saveEmployee()),
        checkDuplicateStatus: () => dispatch(checkDuplicateStatus()),
        deleteEmployee: id => dispatch(deleteEmployee(id)),
        addTemplateAndLoadData: id => dispatch(addTemplateAndLoadData(id))
    })
)
export default class EditEmployeePage extends React.Component {
    componentDidMount() {
        this.props.loadEmployee(this.props.match.params.id);
    }
    componentWillReceiveProps(newProps) {
        if (this.props.match.params.id !== newProps.match.params.id) {
            this.props.loadEmployee(newProps.match.params.id);
        }
    }
    checkDuplicateStatus = debounce(() => this.props.checkDuplicateStatus(), 500);
    nameChanged = ev => {
        this.props.nameChanged(ev);
        this.checkDuplicateStatus();
    };
    dateOfBirthChanged = ev => {
        this.props.dateOfBirthChanged(ev);
        this.checkDuplicateStatus();
    };
    render() {
        let content = null;
        if (this.props.state === STATE_LOADING) {
            content = (
                <div>
                    <StyledIcon spin center size="big" />
                </div>
            );
        } else if (this.props.state === STATE_LOADED) {
            const nameInvalid = this.props.name.trim().length < 2;
            const dateOfBirthInvalid = !validateDateOfBirth({ employeeData: this.props });
            let duplicateIndicator = null;
            if (this.props.duplicateStatus === DUPLICATE_STATUS_LOADING) {
                duplicateIndicator = <StyledIcon glyph="refresh" spin />;
            } else if (this.props.duplicateStatus === DUPLICATE_STATUS_OK) {
                duplicateIndicator = (
                    <StyledIcon
                        glyph="ok"
                        title="No duplicate employee"
                        bsClass="glyphicon glyphicon-ok text-success"
                    />
                );
            } else if (this.props.duplicateStatus === DUPLICATE_STATUS_DUPLICATE) {
                duplicateIndicator = (
                    <StyledIcon
                        glyph="remove"
                        title="Duplicate exmployee"
                        bsClass="glyphicon glyphicon-remove text-danger"
                    />
                );
            }
            content = (
                <div>
                    <TopFieldsRow>
                        <FormGroup>
                            <ControlLabel>Name</ControlLabel>
                            <InputGroup>
                                <FormControl
                                    placeholder="Name"
                                    onChange={this.nameChanged}
                                    value={this.props.name}
                                    className={classnames({ "error-on-blur": nameInvalid, "input-contact-name": true })}
                                />
                                <InputGroup.Addon>{duplicateIndicator}</InputGroup.Addon>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Date of birth</ControlLabel>
                            <InputGroup>
                                <FormControl
                                    placeholder="Date of birth"
                                    type="email"
                                    onChange={this.dateOfBirthChanged}
                                    value={this.props.dateOfBirth}
                                    className={classnames({
                                        "error-on-blur": dateOfBirthInvalid,
                                        "input-small": true
                                    })}
                                />
                                <InputGroup.Addon>{duplicateIndicator}</InputGroup.Addon>
                            </InputGroup>
                        </FormGroup>
                        <div>
                            {this.props.deleteEmployeeEnabled && (
                                <ConfirmableButton
                                    onConfirm={_ => this.props.deleteEmployee(this.props._id)}
                                    message={`Do you really want to delete employee ${this.props.name}?`}
                                    key="delete"
                                />
                            )}
                        </div>
                        <div>
                            <StyledButton
                                wide
                                bsStyle="primary"
                                onClick={this.props.saveEmployee}
                                disabled={
                                    nameInvalid ||
                                    dateOfBirthInvalid ||
                                    this.props.duplicateStatus !== DUPLICATE_STATUS_OK
                                }
                            >
                                Save
                            </StyledButton>
                        </div>
                        <TemplatesSelectCell>
                            <TemplatesSelect
                                onChange={async ev => {
                                    if (ev) {
                                        await this.props.addTemplateAndLoadData(ev.value);
                                        window.scrollTo(0, document.body.scrollHeight);
                                    }
                                }}
                            />
                        </TemplatesSelectCell>
                    </TopFieldsRow>
                    <hr />
                    <EmployeeTemplatesList />
                    <StyledButton
                        wide
                        bsStyle="primary"
                        onClick={this.props.saveEmployee}
                        className="pull-right"
                        disabled={
                            nameInvalid || dateOfBirthInvalid || this.props.duplicateStatus !== DUPLICATE_STATUS_OK
                        }
                    >
                        Save
                    </StyledButton>
                    <div className="clearfix" />
                </div>
            );
        }
        return (
            <div>
                <h3>
                    <Link to="/dashboard/hr" exact>
                        <StyledIcon glyph="arrow-left" />
                    </Link>
                    &nbsp; Edit employee
                </h3>
                {content}
            </div>
        );
    }
}
