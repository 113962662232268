import React from "react";
import ReportContainer from "./ReportContainer";

export default function DayContainer(props) {
    let day = props.day;
   
    return (
        <>
            {day.reports.map(report => {
                return <ReportContainer report={report} key={report._id} />;
            })}
        </>
    );
}
