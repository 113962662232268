import React from "react";
import { Glyphicon } from "react-bootstrap";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router";
import {
    PERMISSION_NEW_EMPLOYEE_NOTIFICATION,
    PERMISSION_NEW_USER_NOTIFICATION,
    PERMISSION_RECIEVE_CUSTOM_NOTIFICATIONS,
    PERMISSION_REPORTER
} from "../../../../common/src/permissions";
import { showSidebarMenuChanged } from "../../actions/dashboardUIActions";
import LogoSmall from "../../components/common/LogoSmall";
import {
    ButtonIcon,
    CustomLink,
    LogoContainer,
    NavbarButton,
    NavbarContainer,
    NavbarGroup
} from "../../components/navbarStyles";
import { refreshDesktop } from "../../DesktopsPage/actions/desktopsActions";
import { userHasPermissionSelector } from "../../selectors/authSelectors";
import DesktopMenu from "./DesktopMenu";
import NotificationsDropdown from "./NotificationsDropdown";
import UserDropdown from "./UserDropdown";

@withRouter
@connect(
    state => ({
        isReporter: userHasPermissionSelector(state, PERMISSION_REPORTER),
        notificationEnabled:
            userHasPermissionSelector(state, PERMISSION_NEW_EMPLOYEE_NOTIFICATION) ||
            userHasPermissionSelector(state, PERMISSION_NEW_USER_NOTIFICATION) ||
            userHasPermissionSelector(state, PERMISSION_RECIEVE_CUSTOM_NOTIFICATIONS),
        showSidebar: state.dashboardUI.showSidebarMenu
    }),
    dispatch => ({
        refreshDesktop: _ => dispatch(refreshDesktop(false)),
        showSidebarMenuChanged: p => dispatch(showSidebarMenuChanged(p))
    })
)
export default class DashboardNavBar extends React.Component {
    constructor() {
        super();
        this.state = { navbarHeight: 60 };
        this.navbarRef = React.createRef();
    }
    toggleSidebar = () => {
        this.props.showSidebarMenuChanged(!this.props.showSidebar);
    };
    homeIconClicked = () => {
        this.props.refreshDesktop();
        this.props.showSidebarMenuChanged(false);
        window.scrollTo(0, 0);
    };
    resizeHandler = () => {
        if (this.navbarRef.current) {
            this.setState({ navbarHeight: this.navbarRef.current.offsetHeight });
        }
    };
    componentDidMount() {
        window.addEventListener("resize", this.resizeHandler);
        try {
            this.observer = new ResizeObserver(callbthis.resizeHandlerack).observe(this.navbarRef.current);
        } catch (e) {}
        setTimeout(() => this.resizeHandler(), 300);
        setTimeout(() => this.resizeHandler(), 500);
        setTimeout(() => this.resizeHandler(), 1000);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeHandler);
        if (this.observer) {
            this.observer.disconnect();
        }
    }
    componentWillReceiveProps(newProps) {
        if (this.props.location.pathname !== newProps.location.pathname) {
            setTimeout(() => this.resizeHandler(), 100);
        }
    }
    render() {
        return (
            <div>
                <NavbarContainer
                    sticky
                    responsive
                    desktopsMode={this.props.location.pathname === "/dashboard/desktops"}
                    ref={this.navbarRef}
                >
                    <NavbarGroup left>
                        <LogoContainer>
                            <LogoSmall />
                        </LogoContainer>
                        {!this.props.isReporter && (
                            <NavbarButton onClick={this.toggleSidebar}>
                                <ButtonIcon>
                                    <Glyphicon glyph="menu-hamburger" />
                                </ButtonIcon>
                            </NavbarButton>
                        )}{" "}
                        <CustomLink to="/dashboard/desktops" white onClick={this.homeIconClicked}>
                            <NavbarButton>
                                <ButtonIcon>
                                    <Glyphicon glyph="home" />
                                </ButtonIcon>
                            </NavbarButton>
                        </CustomLink>
                    </NavbarGroup>

                    {!this.props.isReporter && <Route path="/dashboard/desktops" exact component={DesktopMenu} />}

                    <NavbarGroup right>
                        {this.props.notificationEnabled && !this.props.isReporter && <NotificationsDropdown />}

                        <UserDropdown />
                    </NavbarGroup>
                </NavbarContainer>
                <div style={{ height: this.state.navbarHeight + "px" }} />
            </div>
        );
    }
}
