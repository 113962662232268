export default function totalsSelector({ externals }) {
    return externals.external.productQA.reduce(
        (p, c) => ({
            totalChecked: p.totalChecked + c.totalChecked,
            fromThisOk: p.fromThisOk + c.fromThisOk,
            reworked: p.reworked + c.reworked,
            nok: p.nok + c.nok,
            totalOk: p.totalOk + c.totalOk
        }),
        {
            totalChecked: 0,
            fromThisOk: 0,
            reworked: 0,
            nok: 0,
            totalOk: 0
        }
    );
}
