import styled, { css } from "styled-components";

const MatGrid = {
    Row: styled.div`
        display: flex;
        flex-direction: row;
        ${props =>
            props.alignCenter &&
            css`
                align-items: center;
            `}
        ${props =>
            props.justifyCenter &&
            css`
                justify-content: center;
            `}
        ${props =>
            props.wrapMobile &&
            css`
                @media (max-width: 999px) {
                    flex-wrap: wrap;
                }
            `}
    `,
    Col: styled.div`
    box-sizing: border-box;
        ${props =>
            props.width &&
            css`
                width: ${props.width};
            `}

        ${props =>
            props.marginRight &&
            css`
                margin-right: 20px;
                @media (max-width: 999px) {
                    margin-right: 0;
                }
            `}
        ${props =>
            props.mobileWidth &&
            css`
                @media (max-width: 999px) {
                    width: ${props.mobileWidth};
                }
            `}
        ${props =>
            props.paddingTop &&
            css`
                padding-top: 20px;
            `}
            ${props =>
            props.paddingBottom &&
            css`
                padding-bottom: 20px;
            `}
        ${props =>
            props.borderRight &&
            css`
                border-right: 1px solid rgb(233, 233, 233);
                @media (max-width: 999px) {
                    border-right: none;
                    padding-bottom: 10px;
                    border-bottom: 1px solid rgb(233, 233, 233);
                }
            `},
    `,
    Spacer: styled.div`
        flex-grow: 999;
    `
};

export default MatGrid;

MatGrid.Row.displayName = "MyTag.Row";
MatGrid.Col.displayName = "MyTag.Col";