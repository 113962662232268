import { STATE_LOGGED_IN } from "../actions/authActions";

/**
 * Returns true when the user is logged in, the data about him is available and he has the specified permission.
 * @param {*} state
 * @param  {...any} permissions
 */
export function userHasPermissionSelector(state, permission) {
    if (state.auth.authState !== STATE_LOGGED_IN) {
        return false;
    }
    let override = state.auth.user.permissionOverrides.find(o => o.permission === permission);
    if (override) {
        return override.allow;
    }
    return state.auth.user.group.permissions.some(p => p === permission);
}
