import React from "react";
import { connect } from "react-redux";
import FieldEditRow from "./FieldEditRow";
import styled from "styled-components";

const FieldsTable = styled.table`
    width: 100%;
    padding: 15px;
`;
@connect(state => state.hr.editTemplate)
export default class FieldsList extends React.Component {
    render() {
        return (
            <FieldsTable>
                <tbody>
                    {this.props.fields.map((f, i) => (
                        <FieldEditRow key={f._id || i} field={f} fieldIndex={i} />
                    ))}
                </tbody>
            </FieldsTable>
        );
    }
}
