export default function validateQAReworkedAndNOK(r) {
    let valid = true;
    const nok = parseInt(r.nok);
    const totalChecked = parseInt(r.totalChecked);
    const reworked = parseInt(r.reworked);
    if (!isNaN(nok) && !isNaN(totalChecked) && !isNaN(reworked)) {
        valid = nok + reworked <= totalChecked;
    }
    return valid;
}
