import React from "react";
import styled, { css } from "styled-components";

const statusColors = {
    open: { color: "#99d9ea", noBackground: true, title: "Open" },
    inProgress: { color: "#99d9ea", title: "In progress" },
    approved: { color: "#3f48cc", title: "Approved" },
    hidden: { color: "#bfb5b6", title: "Hidden" },
    visibleToCustomer: { color: "#4a4142", title: "Visible to customer" },
    deleted: { color: "#C93B37", title: "Deleted" }
};

export default styled.div.attrs(props => ({ title: statusColors[props.status].title }))`
    height: 18px;
    width: ${p => (p.superWide ? "85px" : p.wide ? "64px" : p.narrow ? "16px": "32px")};
    border: solid 1px ${p => statusColors[p.status].color};
    background: ${p => (statusColors[p.status].noBackground ? "none" : statusColors[p.status].color)};
    margin-left: 4px;
    border-radius: 3px;
    position: relative;
    ${props =>
        props.partial &&
        css`
            margin-left: 0;
            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        `}
    ${props =>
        props.marginFix &&
        css`
            margin-top: -23px;
        `}
    ${props =>
        props.clickable &&
        css`
            cursor: pointer;
        `}
    ${props =>
        props.checked &&
        css`
            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 1px;
                left: 6px;
                width: 10px;
                height: 5px;
                border: solid 2px ${p => (statusColors[p.status].noBackground ? statusColors[p.status].color : "white")};
                border-right: none;
                border-top: none;
                transform: translate(3px, 4px) rotate(-45deg);
            }
        `}
`;
