import { Glyphicon } from "react-bootstrap";
import { transit } from "react-css-transition/lib/index";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import theme from "../theme";
import React from "react";

export const NavbarContainer = styled.div`
    height: 60px;
    background-color: ${theme.customerPrimaryColor};
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: row;
    color: #fff;
    ${props =>
        props.sticky &&
        css`
            position: fixed;
            z-index: 1030;
            top: 0;
            left: 0;
            right: 0;
          
        `}
    ${props =>
        props.responsive &&
        css`
            @media (max-width: ${props => (props.desktopsMode ? "1370px" : "480px")}) {
                flex-wrap: wrap;

                height: auto;
            }
        `}
`;

export const NavbarGroup = styled.div`
    display: flex;
    flex-direction: row;
    ${props =>
        props.right &&
        css`
            @media (min-width: 480px) {
                margin-left: auto;
            }
        `}
    ${props =>
        props.left &&
        css`
            margin-right: auto;
        `}

    ${props =>
        props.center &&
        css`
            margin-left: auto;
            margin-right: auto;
        `}
`;

export const LogoTextContainer = styled.div`
    float: left;
    font-size: 18px;
    margin-top: 20px;
    margin-left: 20px;
    display: none;
    @media (min-width: 800px) {
        ${props =>
            props.expanded &&
            css`
                display: block;
            `}
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    width: 80px;
    margin: 0 0 0 10px;
    transition: 500ms;
    @media (min-width: 800px) {
        ${props =>
            props.expanded &&
            css`
                width: 240px;
                margin: 0 0 0 15px;
            `}
    }
`;

export const MenuContainer = styled.div`
    display: flex;
`;

export const LeftMenuContainer = styled.div`
    display: flex;
`;

export const NavbarItemsRight = styled.div`
    display: flex;
    margin-left: auto;
    margin-right: 30px;
`;

export const UserNameContainer = styled.div`
    margin-left: 15px;
    z-index: 0;
    margin-top: -3px;
    @media (max-width: 1080px) {
        ${props =>
            props.hideOnMobile &&
            css`
                display: none;
            `}
    }
`;

export const NavbarDropdownContent = styled.div`
    color: black;
    position: absolute;
    margin-right: 25px;
    padding-right: 10px;
    background-color: #fff;
    z-index: 5000;
    border-radius: 4px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    ${props =>
        props.withPadding &&
        css`
            padding: 10px;
        `}
    ${props =>
        props.wide &&
        css`
            width: 400px;

            @media (max-width: 1080px) {
                margin-left: -100px;
            }
            @media (max-width: 400px) {
                width: 100vw;
                margin-left: 0;
            }
        `}
    ${props =>
        props.withOverflow &&
        css`
            max-height: 80vh;
            overflow-y: scroll;
        `}
        ${props =>
            props.responsiveOffset &&
            css`
                /* moves ther dropdown to the left when the trigger icon is to close to the edge of the screen */
                @media (max-width: 1080px) {
                    margin-left: -50px;
                }
            `}
`;

export const UserMenuListItem = styled.li`
    cursor: pointer;
    color: rgba(0, 0, 0, 0.86);
    list-style-type: none;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: -10px;
    padding-right: 20px;
    white-space: nowrap;
    transition: background-color 300ms ease-in-out;
    &:hover {
        background-color: #e1e1e1;
    }
`;

export const UserMenuListItemIcon = styled(Glyphicon)`
    padding-right: 10px;
    margin-left: 20px;
`;

export const LineSeperator = styled.div`
    border-top: solid 1px ${props => (props.black ? "rgba(0,0,0,.85)" : "#fff")};
    margin: 4px -6px 4px 4px;
`;
export const NavbarButton = styled.div`
    cursor: pointer;
    padding-left: 21px;
    padding-right: 20px;
    height: 60px;
    display: flex;
    justify-content: center;
    transition: background-color 400ms ease-in-out;
    &:hover {
        background-color: #0e2444;
    }
    @media (max-width: 800px) {
        ${props =>
            props.halfHeightWhenResponsive &&
            css`
                height: 30px;
            `}
    }
    @media (max-width: 1080px) {
        ${props =>
            props.hideOnMobile &&
            css`
                display: none;
            `}
    }
`;

export const ButtonIcon = styled.div`
    align-self: center;
    font-size: 1.9rem;
    display: flex;
`;

export const ButtonText = styled.div`
    align-self: center;
    display: flex;
`;

export const CustomLink = styled(({ white, ...rest }) => <Link {...rest} />)`
    text-decoration: none !important;
    color: ${props => (props.white ? "#fff" : "#000")} !important;
`;

export const transitionMainMenuSizeStyles = {
    defaultStyle: {
        width: "80px",
        margin: "0 0 0 10px"
    },
    enterStyle: {
        margin: transit("0 0 0 15px", 500, "ease-in-out"),
        width: transit("240px", 500, "ease-in-out")
    },
    leaveStyle: {
        margin: transit("0 0 0 10px", 500, "ease-in-out"),
        width: transit("80px", 500, "ease-in-out")
    },
    activeStyle: {
        width: "240px",
        margin: "0 0 0 15px"
    }
};
