import React from "react";
import { connect } from "react-redux";

const SimpleTdComponent = ({ children }) => <td>{children}</td>;

@connect(
    null,
    null,
    null,
    { pure: true }
)
export default class AutoCell extends React.PureComponent {
    dispatchChangeEvent(creator, ev) {
        // TODO: find a better way to check if this is a SyntheticEvent
        if (!ev) {
            this.props.dispatch(creator(this.props.rowIndex, null));
            return;
        }

        this.props.dispatch(creator(this.props.rowIndex, ev.nativeEvent ? ev.target.value : ev));
    }
    handleKeyPress = ev => {
        if (this.props.model.enterToJumpToNextRow && ev.nativeEvent && ev.key === "Enter") {
            if (this.props.rowIndex === this.props.rowsLength - 1) {
                this.props.dispatch(this.props.model.addRowActionCreator());
                this.props.focusLastRowOnNextUpdate && this.props.focusLastRowOnNextUpdate();
            } else {
                this.props.focusNextRow && this.props.focusNextRow();
            }
        }
    };
    handleChangeWithActionCreator = ev => {
        if (this.props.column.onChangeValidator) {
            if (!this.props.column.onChangeValidator(ev)) {
                return;
            }
        }
        if (Array.isArray(this.props.column.changeActionCreator)) {
            Array.from(this.props.column.changeActionCreator).forEach(c => this.dispatchChangeEvent(c, ev));
        } else {
            this.dispatchChangeEvent(this.props.column.changeActionCreator, ev);
        }
    };
    render() {
        let { column } = this.props;
        let commonProps = {
            rowIndex: this.props.rowIndex,
            row: this.props.row,
            columnIndex: this.props.columnIndex,
            column: this.props.column,
            disabled: this.props.disabled,
            onKeyPress: this.handleKeyPress,
            innerRef: this.props.innerRef,
            dispatch: this.props.dispatch
        };
        let TdComponent = this.props.column.tdComponent || this.props.model.tdComponent || SimpleTdComponent;
        if (column.valueSelector) {
            let value = column.valueSelector(this.props.row, this.props.rowIndex);
            // cell with value
            if (column.changeActionCreator) {
                // cell with value going straight to redux
                return (
                    <TdComponent>
                        <this.props.column.cell
                            value={value}
                            onChange={this.handleChangeWithActionCreator}
                            {...commonProps}
                        />
                    </TdComponent>
                );
            } else if (column.changeHandler) {
                // cell with value with custom handler
                return (
                    <TdComponent>
                        <this.props.column.cell value={value} onChange={column.changeHandler} {...commonProps} />
                    </TdComponent>
                );
            } else {
                return (
                    <TdComponent>
                        <this.props.column.cell value={value} {...commonProps} />
                    </TdComponent>
                );
            }
        } else {
            return (
                <TdComponent>
                    <this.props.column.cell {...commonProps} />
                </TdComponent>
            );
            // dumb cell
        }
    }
}
