export function decrementMonth({ month, year }) {
    if (month == 0) {
        return {
            year: year - 1,
            month: 11
        };
    }
    return {
        year: year,
        month: month - 1
    };
}

export function incrementMonth({ month, year }) {
    if (month == 11) {
        return {
            year: year + 1,
            month: 0
        };
    }
    return {
        year: year,
        month: month + 1
    };
}
