import React from "react";
import { connect } from "react-redux";
import { FormGroup, ControlLabel, FormControl, InputGroup } from "react-bootstrap";
import totalsSelector from "../selectors/totalsSelector";
import styled from "styled-components";
import { parseFloatWithZeroFallback } from "../../utils";

export const ShortInputGroup = styled(InputGroup)`
    width: 180px;
`;
@connect(({ externals }) => ({
    ...totalsSelector({ externals }),
    ...externals.external
}))
export default class CurrentReportSortRate extends React.Component {
    render() {
        let p = this.props;
        let workingHours = p.flatRate
            ? p.workingHours
            : parseFloatWithZeroFallback(p.normalHours) +
              parseFloatWithZeroFallback(p.nightHours) +
              parseFloatWithZeroFallback(p.saturdayHours) +
              parseFloatWithZeroFallback(p.sundayHours);
        return (
            <FormGroup className="form-inline">
                <ControlLabel>Sort rate</ControlLabel>
                <ShortInputGroup>
                    <FormControl disabled value={(p.totalChecked / workingHours).toFixed(2)} />
                    <InputGroup.Addon>pcs / h</InputGroup.Addon>
                </ShortInputGroup>
            </FormGroup>
        );
    }
}
