import { makeActionCreator, encodeParams } from "../../utils";
import { toast } from "react-toastify";
import { apiFetch } from "../../actions/apiActions";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_ERROR = "STATE_ERROR";

export const STATE_CHANGED = "HR.TEMPLATES_LIST.STATE_CHANGED";
export const TEMPLATES_CHANGED = "HR.TEMPLATES_LIST.TEMPLATES_CHANGED";
export const TEMPLATE_DELETED = "HR.TEMPLATES_LIST.TEMPLATE_DELETED";
export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const templatesChanged = makeActionCreator(TEMPLATES_CHANGED, "payload");
export const templateDeleted = makeActionCreator(TEMPLATE_DELETED, "templateId");

export function loadTemplates(query) {
    return async (dispatch, getState) => {
        try {
            dispatch(stateChanged(STATE_LOADING));

            let resp = await dispatch(apiFetch(encodeParams`/api/templates/search`, {}, query));
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            let data = await resp.json();
            dispatch(templatesChanged(data));
            dispatch(stateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(stateChanged(STATE_ERROR));
            toast(e.message, {
                type: "error"
            });
        }
    };
}

export function templatesSearched(filter) {
    return async dispatch => {
        try {
            let u = encodeParams`/api/templates/search?filter=${filter}`;
            let resp = await dispatch(apiFetch(u));
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message || "Unknown error");
            }
            return data;
        } catch (e) {
            console.error("Error while searching templates", e);
            toast("Failed to load templates search: " + e.message, { type: "error" });
            return [];
        }
    };
}

export function deleteTemplate(id) {
    return async dispatch => {
        try {
            let resp = await dispatch(apiFetch(encodeParams`/api/templates/${id}`, { method: "DELETE" }));
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            dispatch(templateDeleted(id));
            toast("Template deleted!", {
                type: "success"
            });
        } catch (e) {
            dispatch(stateChanged(STATE_ERROR));
            toast("Error while deleting template:" + e.message, {
                type: "error"
            });
        }
    };
}
