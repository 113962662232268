import React from "react";
import { clockTimeToMinutes, formatTimeWithoutZeros } from "../../../../../utils";
import styled from "styled-components";
import StyledIcon from "../../../../../components/common/StyledIcon";
import JSpdf from "jspdf";
import html2canvas from "html2canvas";
import { connect } from "react-redux";
import getMainOrderNumber from "../../../../../selectors/getMainOrderNumber";

const BalloonContainer = styled.div`
    background-color: #fff;
    border: solid 1px black;
    border-radius: 3px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    width: auto;
    min-width: 490px;
    max-width: 900px;
    font-size: 12px;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    display: block;
    margin-left: ${props => (props.marginLeft !== "0px" ? props.marginLeft : -props.size + "px")};
    color: #000;
`;

const DateContainer = styled.div`
    display: inline-block;
    position: absolute;
    left: 10px;
    font-weight: bold;
`;

const TimeContainer = styled.div`
    display: inline-block;
    position: absolute;
    left: 130px;
`;

const TotalHoursContainer = styled.div`
    display: inline-block;
    position: absolute;
    left: 260px;
`;

const FormularNumberContainer = styled.div`
    display: inline-block;
    position: absolute;
    left: 395px;
`;
const SortRateContainer = styled.div`
    display: inline-block;
    position: absolute;
    left: 520px;
`;

const EmployeesContainer = styled.div`
    display: block;
    padding-top: 20px;
    padding-left: 5px;
`;

const ProductsContainer = styled.div`
    display: block;
    padding-top: 5px;
`;

const TotalContainer = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
`;

const SinglePartContainer = styled.div`
    display: flex;
`;

const PartNameContainer = styled.div`
    width: 100px;
    text-align: center;
    display: inline-block;
    margin-left: 10px;
`;

const PartValueContainer = styled.div`
    width: 35px;
    text-align: center;
    display: inline-block;
    margin-left: 15px;
    color: ${props => (props.color ? props.color : "black")};
`;

const PartRemarksContainer = styled.div`
    width: 80px;
    text-align: center;
    display: inline-block;
    margin-right: 10px;
`;

const DescriptionContainer = styled.div`
    width: 75px;
    display: inline-block;
    font-weight: ${props => (props.normal ? "normal" : "bold")};
    color: ${props => (props.color ? props.color : "black")};
`;

const ValueContainer = styled.div`
    display: inline-block;
    font-weight: ${props => (props.normal ? "normal" : "bold")};
    color: ${props => (props.color ? props.color : "black")};
    margin-left: ${props => (props.marginLeft ? props.marginLeft : "0px")};
`;

const DataContainer = styled.div`
    margin-left: 5px;
`;

const PrintButtonContainer = styled.div`
    font-size: 14px;
    margin: 3px;
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
`;

const Remarks = styled.p`
    text-align: left;
`;
@connect(state => ({
    companies: state.desktops.desktop.companies
}))
export default class Balloon extends React.Component {
    state = {printButton: true};
    componentDidMount() {
        this.props.printButton === false ? this.setState({printButton: false}): null;
    }
    render() {
        let oneDayCommunicat = "";
        if (
            (this.props.data.startWork &&
                this.props.data.endWork &&
                clockTimeToMinutes(this.props.data.endWork) > 0 &&
                clockTimeToMinutes(this.props.data.endWork) < clockTimeToMinutes(this.props.data.startWork)) ||
            clockTimeToMinutes(this.props.data.startWork) < 6 * 60 ||
            this.props.data.oneDayCommunicat
        ) {
            oneDayCommunicat = " +1d";
        }

        let products;
        this.props.data.products
            ? (products = this.props.data.products.map(p => {
                  p.letterColumnsContent && p.letterColumnsContent.length > 10
                      ? (p.letterColumnsContent = p.letterColumnsContent.slice(0, 10))
                      : "";
                  return (
                      <SinglePartContainer>
                          <PartNameContainer>{p.partNumber ? p.partNumber : "-"}</PartNameContainer>
                          <PartValueContainer color={"#3f48cc"}>
                              {p.totalChecked ? p.totalChecked : "-"}
                          </PartValueContainer>
                          <PartValueContainer color={"#42f468"}>
                              {p.totalChecked - p.nok - p.reworked}
                          </PartValueContainer>
                          <PartValueContainer color={"#f4b841"}>{p.reworked ? p.reworked : "-"}</PartValueContainer>
                          <PartValueContainer color={"#fc3411"}>{p.nok ? p.nok : "-"}</PartValueContainer>
                          {p.letterColumnsContent
                              ? p.letterColumnsContent.map(l => {
                                    return <PartValueContainer color={"#fc3411"}>{l}</PartValueContainer>;
                                })
                              : ""}
                          <PartValueContainer color={"#2d9e19"}>{p.totalChecked - p.nok}</PartValueContainer>
                          {this.props.data.status !== "external" && (
                              <PartRemarksContainer>
                                  {p.remarks &&
                                      p.remarks.map((r, i) => (
                                          <Remarks>
                                              {p.remarks.length > 1 ? i + 1 + ". " : ""}
                                              {r.remarks}
                                          </Remarks>
                                      ))}
                              </PartRemarksContainer>
                          )}
                          {this.props.data.status === "external" && (
                              <PartRemarksContainer>
                                  {p.remarks &&
                                      p.remarks.map((r, i) => (
                                          <Remarks>
                                              {p.remarks.length > 1 ? i + 1 + ". " : ""}
                                              {r.showRemarks ? r.remarks : r.remarks ? `(${r.remarks})` : ""}
                                          </Remarks>
                                      ))}
                              </PartRemarksContainer>
                          )}
                      </SinglePartContainer>
                  );
              }))
            : null;
        let employees;
        this.props.data.employees
            ? (employees = this.props.data.employees.map((e, i) => {
                  let result = getShortName(e.name);
                  i !== this.props.data.employees.length - 1 ? (result += ", ") : "";
                  return result;
              }))
            : "";

        //creating history of changes
        let last = getShortName(this.props.data.lastEdited[0].displayName);
        let nameOfEditors = [getShortName(this.props.data.firstVersion[0].displayName)];
        this.props.data.threeFromTheEndEdited
            ? nameOfEditors.push(getShortName(this.props.data.threeFromTheEndEdited[0].displayName))
            : "";
        this.props.data.twoFromTheEndEdited
            ? nameOfEditors.push(getShortName(this.props.data.twoFromTheEndEdited[0].displayName))
            : "";
        if (
            this.props.data.versionMetadata &&
            (this.props.data.versionMetadata.message !== "Approved" &&
                this.props.data.versionMetadata.message !== "Cancelled approval")
        ) {
            nameOfEditors.push(getShortName(this.props.data.lastEdited[0].displayName));
            //if tmp length is equal 4 delete second object
            nameOfEditors.length === 4 ? nameOfEditors.splice(1, 1) : "";
        }
        let edited = nameOfEditors.map((n, i) => {
            let result;
            if (i === 0 && nameOfEditors.length > 1) {
                result = n + "... ";
            } else if (i === 1 && nameOfEditors.length > 2) {
                result = n + ", ";
            } else {
                result = n;
            }
            return result;
        });
        //summary of letterColumnContent
        let summaryOfLetter = [];
        this.props.data.products.forEach(p => {
            if (p.letterColumnsContent) {
                p.letterColumnsContent.forEach((l, index) => {
                    summaryOfLetter[index] ? (summaryOfLetter[index] += l) : (summaryOfLetter[index] = l);
                });
            }
        });

        function genPdf(contentToPrint, companies, companyIndex, orderIndex) {
            //get order name
            let orderNumber = getMainOrderNumber(companies[companyIndex].orders[orderIndex].order);

            html2canvas(contentToPrint).then(function(canvas) {
                let img = canvas.toDataURL("image/jpeg");
                let doc;
                canvas.width > 790 ? (doc = new JSpdf("landscape")) : (doc = new JSpdf());
                doc.setFontSize(10);
                doc.text(10, 10, orderNumber);
                doc.addImage(img, "JPEG", 10, 15);
                doc.save("report.pdf");
            });
        }

        function getShortName(name) {
            let nameParts = name.split(" ");
            let result;
            if (nameParts.length > 1) {
                result = nameParts[1][0] + ". " + nameParts[0];
            } else {
                result = name;
            }
            return result;
        }
        return (
            <BalloonContainer
                marginLeft={this.props.marginLeft}
                size={this.props.data.sortRate ? 640 : 490}
                style={this.props.data.sortRate ? { minWidth: "640px"} :
                  { minWidth: "490px"}}
            >
                <div
                    ref={toPrintDiv => {
                        this.toPrintDiv = toPrintDiv;
                    }}
                >
                    <DateContainer>
                        {new Date(this.props.data.controlDate).toLocaleDateString("en-GB", {})}
                    </DateContainer>
                    <TimeContainer>
                        {this.props.data.startWork !== "NaN:NaN" && formatTimeWithoutZeros(this.props.data.startWork, true)} -{" "}
                        {this.props.data.startWork !== "NaN:NaN" && formatTimeWithoutZeros(this.props.data.endWork, true)}
                        {oneDayCommunicat}
                    </TimeContainer>
                    <TotalHoursContainer>
                        Total hours: <span style={{ fontWeight: "bold" }}> {this.props.data.sumWorkingHours} </span>
                        {this.props.data.externalMetadata &&
                        this.props.data.sumWorkingHoursFromReport != this.props.data.sumWorkingHours
                            ? "(" + this.props.data.sumWorkingHoursFromReport + ")"
                            : ""}
                    </TotalHoursContainer>
                    <FormularNumberContainer>Form numb.: {this.props.data.formularNumber}</FormularNumberContainer>
                    {this.props.data.sortRate && (
                        <SortRateContainer>Sort rate: {this.props.data.sortRate}</SortRateContainer>
                    )}
                    <EmployeesContainer>{employees}</EmployeesContainer>

                    <ProductsContainer>{products}</ProductsContainer>
                    <TotalContainer>
                        <SinglePartContainer>
                            <PartNameContainer>Total:</PartNameContainer>
                            <PartValueContainer color={"#3f48cc"}>{this.props.data.total}</PartValueContainer>
                            <PartValueContainer color={"#42f468"}>
                                {this.props.data.total - this.props.data.nok - this.props.data.reworked}
                            </PartValueContainer>
                            <PartValueContainer color={"#f4b841"}>
                                {this.props.data.reworked ? this.props.data.reworked : "-"}
                            </PartValueContainer>
                            <PartValueContainer color={"#fc3411"}>
                                {this.props.data.nok ? this.props.data.nok : "-"}
                            </PartValueContainer>
                            {summaryOfLetter
                                ? summaryOfLetter.map(l => {
                                      return <PartValueContainer color={"#fc3411"}>{l}</PartValueContainer>;
                                  })
                                : ""}
                            <PartValueContainer color={"#2d9e19"}>
                                {this.props.data.total - this.props.data.nok}
                            </PartValueContainer>
                            <PartRemarksContainer>{this.props.data.remarks}</PartRemarksContainer>
                        </SinglePartContainer>
                    </TotalContainer>
                    {this.props.data.remarksDescription ? (
                        <DataContainer>
                            <DescriptionContainer normal>Remarks: </DescriptionContainer>
                            <ValueContainer>
                                {/*if exist remarks show normal (in open/to appove/approved reports);
                                    if is external and remarks is unchecked in preExternal show remarks in ();
                                     if is externalDontShowToClient show remarks from preExternal;*/}
                                {this.props.data.showRemarks
                                    ? this.props.data.remarksDescription
                                    : this.props.data.status === "external" &&
                                      this.props.data.hiddenFromClient === false
                                        ? `(${this.props.data.remarksDescription})`
                                        : this.props.data.remarksDescription}
                            </ValueContainer>
                        </DataContainer>
                    ) : null}

                    <DataContainer>
                        <DescriptionContainer normal>Edited:</DescriptionContainer>
                        <ValueContainer normal>{edited}</ValueContainer>
                    </DataContainer>
                    {this.props.data.versionMetadata &&
                        this.props.data.versionMetadata.message === "Approved" && (
                            <DataContainer>
                                <DescriptionContainer>Approved:</DescriptionContainer>
                                <ValueContainer>{last}</ValueContainer>
                                <ValueContainer marginLeft={"20px"}>
                                    {new Date(this.props.data.versionMetadata.createdOn).toLocaleDateString(
                                        "en-GB",
                                        {}
                                    )}
                                </ValueContainer>
                                <ValueContainer marginLeft={"20px"}>
                                    {new Date(this.props.data.versionMetadata.createdOn).toLocaleTimeString(
                                        "en-GB",
                                        {}
                                    )}
                                </ValueContainer>
                            </DataContainer>
                        )}

                    {this.props.data.versionMetadata &&
                        this.props.data.versionMetadata.message === "Cancelled approval" && (
                            <DataContainer>
                                <DescriptionContainer color={"#fc3411"}>Cancel app.:</DescriptionContainer>
                                <ValueContainer color={"#fc3411"}>{last}</ValueContainer>
                                <ValueContainer color={"#fc3411"} marginLeft={"20px"}>
                                    {new Date(this.props.data.versionMetadata.createdOn).toLocaleDateString(
                                        "en-GB",
                                        {}
                                    )}
                                </ValueContainer>
                                <ValueContainer color={"#fc3411"} marginLeft={"20px"}>
                                    {new Date(this.props.data.versionMetadata.createdOn).toLocaleTimeString(
                                        "en-GB",
                                        {}
                                    )}
                                </ValueContainer>
                            </DataContainer>
                        )}

                    {this.props.data.externalMetadata && (
                        <DataContainer>
                            <DescriptionContainer>External:</DescriptionContainer>
                            <ValueContainer>{getShortName(this.props.data.externalMetadata[0].displayName)}</ValueContainer>
                            <ValueContainer marginLeft={"20px"}>
                                {new Date(this.props.data.externalCreatedOn).toLocaleDateString(
                                    "en-GB",
                                    {}
                                )}
                            </ValueContainer>
                            <ValueContainer marginLeft={"20px"}>
                                {new Date(this.props.data.externalCreatedOn).toLocaleTimeString(
                                    "en-GB",
                                    {}
                                )}
                            </ValueContainer>
                        </DataContainer>
                    )}
                </div>
                {this.state.printButton && <PrintButtonContainer>
                    <StyledIcon
                        glyph="print"
                        onClick={() =>
                            genPdf(
                                this.toPrintDiv,
                                this.props.companies,
                                this.props.companyIndex,
                                this.props.orderIndex
                            )
                        }
                    />
                </PrintButtonContainer>}
            </BalloonContainer>
        );
    }
}
