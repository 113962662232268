export const VERROR_INTERNAL_ORDER_NUMBER_TAKEN = "INTERNAL_ORDER_NUMBER_TAKEN";
export const VERROR_NO_OFFICIAL_ORDER_NAME = "VERROR_NO_OFFICIAL_ORDER_NAME";
export const VERROR_NO_UNOFFICIAL_ORDER_NAME = "VERROR_NO_UNOFFICIAL_ORDER_NAME";

export const validationErrors = {
    [VERROR_INTERNAL_ORDER_NUMBER_TAKEN]: () => ({
        type: VERROR_INTERNAL_ORDER_NUMBER_TAKEN,
        message: "The internal order number is already taken."
    }),
    [VERROR_NO_OFFICIAL_ORDER_NAME]: () => ({
        type: VERROR_NO_OFFICIAL_ORDER_NAME,
        message: "No official order name."
    }),
    [VERROR_NO_UNOFFICIAL_ORDER_NAME]: () => ({
        type: VERROR_NO_UNOFFICIAL_ORDER_NAME,
        message: "No unofficial order name."
    })
};
