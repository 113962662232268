import styled from "styled-components";

export const FirstShadowOfGroupedContainer = styled.div`
    height: 25px;
    box-shadow:-3px 2px 0 0px #4a4142;
    border-radius: 3px;
    margin-left: 3px;
    margin-right: 10px;
    margin-top: -26px;
`;

export const SecondShadowOfGroupedContainer = styled.div`
    height: 25px;
    box-shadow:-3px 2px 0 0px #4a4142;
    border-radius: 3px;
    margin-left: -1px; 
    margin-right: 16px; 
    margin-top: -21px;
`;

export const GroupedContainer = styled.div`
    margin-left: 4px; 
    margin-right: -4px;
`;

export const ShowAllButtonContainer = styled.div`
    display: block;
    font-size: 12px;
    height: 10px;
    margin-top: 2px;
    text-align: center;
    cursor: pointer;
`;

export const SingleReportContainer = styled.div`
    margin: 4px 8px 3px 8px;    
    font-size: 14px;
    color: #fff;
    display: block;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16);
    z-index: 2;
`;

export const CountOfReportContainerToExternal = styled.div`
    width 25px;
    height: 25px;
    background-color: black;
    display: inline-block;
    border-radius: 0 3px 3px 0;
    float: right;
    margin-top: -1px;
    text-align: center;
    line-height: 25px;
    color: #fff;
    cursor: pointer;
    z-index: 1;
`;
export const CountOfReportContainer = styled.div`
    width 25px;
    height: 25px;
    background-color: black;
    display: inline-block;
    border-radius: 0 3px 3px 0;
    float: right;
    margin: -26px -4px 0 0;
    text-align: center;
    line-height: 25px;
    color: #fff;
    cursor: pointer;
    z-index: 1;
`;

export const SingleExternalReportContainer = styled.div`
    background-color: #4a4142;
    border: 1px solid #4a4142;
    height: 25px;
    border-radius: 3px;
    margin-right: -3px;
`;

export const InformationContainer = styled.div`
    cursor: pointer;
    height: 25px;
    margin-right:25px;    
`;



