export default function getMainOrderNumber(o) {
    let mainNumber;
    if (o.orderNumbers.isStandingOrder) {
        mainNumber = o.orderNumbers.standingOrderNumber;
    } else if (o.orderNumbers.isInternalOrder) {
        mainNumber = o.orderNumbers.internalOrderNumber;
    } else {
        mainNumber = o.orderNumbers.orderNumber;
    }
    return mainNumber;
}
