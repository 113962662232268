import { apiFetch } from "../../actions/apiActions";

export const ORDERS_CHANGED = "REPORTER_DESKTOP.ORDERS_CHANGED";

export default function getActions (dispatch) {
    return {
        getCompanies: () => {
            dispatch(getCompanies());
        }
    };
}

export function companiesChanged (companies) {
    return {
        type: ORDERS_CHANGED,
        companies
    };
}

export function getCompanies() {
    return async (dispatch) => {
        try {
            let companies = await dispatch(
                apiFetch(`/api/reporter-desktop`)
            );

            let ordersData = await companies.json();

            if (companies.status === 200) {
                dispatch(companiesChanged(ordersData));
            } else {
                throw new Error("An error has occured: " + (ordersData.messages || "Unknown error"));
            }

            if (!ordersData || typeof ordersData !== "object") {
                throw new Error("Data returned by API is not an object!");
            }

        } catch (e) {

        }
    };
}

