import { DATA_LOADED, IS_LOADING_CHANGED, LAST_FILTERS_UPDATE_CHANGED } from "../actions/resultsActions";

const makeInitialState = _ => ({
    isLoading: true,
    rows: [],
    totalRows: 0,
    lastFiltersUpdate: new Date(0).toString()
});

export default function results(state = makeInitialState(), action) {
    switch (action.type) {
        case IS_LOADING_CHANGED:
            return {
                ...state,
                isLoading: action.payload
            };
        case DATA_LOADED:
            return {
                ...state,
                isLoading: false,
                totalRows: action.totalRows,
                rows: action.payload
            };
        case LAST_FILTERS_UPDATE_CHANGED:
            return {
                ...state,
                lastFiltersUpdate: action.payload
            };
        default:
            return state;
    }
}
