import React from "react";
import { push } from "react-router-redux";
import { toast } from "react-toastify";
import { PERMISSION_EDIT_EXTERNALS, PERMISSION_REPORTER } from "../../../../common/src/permissions";
import { apiFetch } from "../../actions/apiActions";
import { checkUserPermission } from "../../actions/authActions";
import { showConfirmModal } from "../../actions/modalsActions";
import { desktopsChanged, loadDesktop } from "../../DesktopsPage/actions/desktopsActions";
import { getExternalIdForReport, loadExternalFromReportId } from "../../ExternalsModal/actions/externalActions";
import { encodeParams, makeActionCreator, selectObjectKeys } from "../../utils";
import getValidationErrors from "../selectors/getValidationErrors";
import { checkConflictsForAllRows, createNewEmployeesAndAttachIds } from "./employeeTimeTableActions";
import { loadProblemDescriptionFromOrder, saveProblemDescriptionToConnectedOrder } from "./problemDescriptionActions";
import { cleanUpEmptyRows, loadAutocomplete, loadEmptyColumnsFromPreviousReport } from "./productQAActions";
import { errorsChanged, errorCleared } from "./validationActions";
import { VERROR_NO_CONNECTED_ORDER } from "../validationErrors";
import { userHasPermissionSelector } from "../../selectors/authSelectors";
import { clearCompaniesListCache } from "../../CompaniesListPage/actions/resultsActions";

const versionedFields = ["problemDescription", "employeeTimeTable", "productQA", "remarksDescription"];

export const STATE_NEW = "STATE_NEW";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_OLD_VERSION_LOADED = "STATE_OLD_VERSION_LOADED";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_ERROR = "STATE_ERROR";

export const STATE_CHANGED = "REPORTS.REPORT.STATE_CHANGED";
export const ERROR_CHANGED = "REPORTS.REPORT.ERROR_CHANGED";
export const REPORT_DATA_CHANGED = "REPORTS.REPORT.REPORT_DATA_CHANGED";
export const REPORT_RESET = "REPORTS.REPORT.REPORT_RESET";
export const REPORT_VERSIONS_CHANGED = "REPORTS.REPORT.REPORT_VERSIONS_CHANGED";
export const VIEWED_VERSION_CHANGED = "REPORTS.REPORT.VIEWED_VERSION_CHANGED";
export const SHOW_VERSION_MESSAGE_MODAL_CHANGED = "REPORTS.REPORT.SHOW_VERSION_MESSAGE_MODAL_CHANGED";
export const MODAL_VERSION_MESSAGE_CHANGED = "REPORTS.REPORT.MODAL_VERSION_MESSAGE_CHANGED";
export const CONTROL_DATE_CHANGED = "REPORTS.REPORT.CONTROL_DATE_CHANGED";
export const REPORT_NAME_CHANGED = "REPORTS.REPORT.REPORT_NAME_CHANGED";
export const OFFICIAL_ORDER_NAME_CHANGED = "REPORTS.REPORT.OFFICIAL_ORDER_NAME_CHANGED";
export const FORMULAR_NUMBER_CHANGED = "REPORTS.REPORT.FORMULAR_NUMBER_CHANGED";
export const CONNECTED_ORDER_ID_CHANGED = "REPORTS.REPORT.CONNECTED_ORDER_ID_CHANGED";
export const EXTERNAL_STATUS_CHANGED = "REPORTS.REPORT.EXTERNAL_STATUS_CHANGED";

export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const errorChanged = makeActionCreator(ERROR_CHANGED, "payload");
export const reportDataChanged = makeActionCreator(REPORT_DATA_CHANGED, "payload");
export const reportReset = makeActionCreator(REPORT_RESET);
export const reportVersionsChanged = makeActionCreator(REPORT_VERSIONS_CHANGED, "payload");
export const viewedVersionChanged = makeActionCreator(VIEWED_VERSION_CHANGED, "payload");
export const showVersionMessageModalChanged = makeActionCreator(SHOW_VERSION_MESSAGE_MODAL_CHANGED, "payload");
export const modalVersionMessageChanged = makeActionCreator(MODAL_VERSION_MESSAGE_CHANGED, "payload");
export const controlDateChanged = makeActionCreator(CONTROL_DATE_CHANGED, "payload");
export const reportNameChanged = makeActionCreator(REPORT_NAME_CHANGED, "payload");
export const officialOrderNameChanged = makeActionCreator(OFFICIAL_ORDER_NAME_CHANGED, "payload");
export const formularNumberChanged = makeActionCreator(FORMULAR_NUMBER_CHANGED, "payload");
export const connectedOrderIdChanged = makeActionCreator(CONNECTED_ORDER_ID_CHANGED, "payload");
export const externalStatusChanged = makeActionCreator(EXTERNAL_STATUS_CHANGED, "payload");

export function reportLoadRequested(id) {
    return async (dispatch, getState) => {
        dispatch(stateChanged(STATE_LOADING));
        try {
            let resp = await dispatch(apiFetch(`/api/reports/${encodeURIComponent(id)}`));
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message || "Unknown error");
            }
            await dispatch(loadExternalStatus(id));
            dispatch(reportDataChanged(data));
            if (!userHasPermissionSelector(getState(), PERMISSION_REPORTER)) {
                dispatch(
                    reportVersionsChanged(
                        await dispatch(interlaceReportVersionsWithExternalVersions(id, data.versions))
                    )
                );
            }
            dispatch(stateChanged(STATE_LOADED));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

export function reportVersionLoadRequested(id, versionId) {
    return async (dispatch, getState) => {
        dispatch(stateChanged(STATE_LOADING));
        try {
            let resp = await dispatch(
                apiFetch(`/api/reports/${encodeURIComponent(id)}/version/${encodeURIComponent(versionId)}`)
            );
            let data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message || "Unknown error");
            }
            await dispatch(loadExternalStatus(id));
            dispatch(reportDataChanged(data));
            if (!userHasPermissionSelector(getState(), PERMISSION_REPORTER)) {
                dispatch(
                    reportVersionsChanged(
                        await dispatch(interlaceReportVersionsWithExternalVersions(id, data.versions))
                    )
                );
            }
            dispatch(stateChanged(STATE_OLD_VERSION_LOADED));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

export function interlaceReportVersionsWithExternalVersions(reportId, existingVersions) {
    return async dispatch => {
        const resp = await dispatch(apiFetch(encodeParams`/api/externals/version-summary/${reportId}`));
        let data = await resp.json();
        if (!resp.ok) {
            if (resp.status == 404) {
                return existingVersions;
            }
            throw new Error(data.message || "Unknown error");
        }
        let arr = [
            ...existingVersions,
            ...data.versions.map(v => ({
                ...v,
                isExternal: true
            }))
        ];

        arr.sort((a, b) => new Date(a.versionMetadata.createdOn) - new Date(b.versionMetadata.createdOn));

        return arr;
    };
}

export function loadExternalStatus(id) {
    return async dispatch => {
        let resp = await dispatch(apiFetch(encodeParams`/api/reports/${id}/external-status`));
        let data = await resp.json();
        if (resp.status !== 200) {
            throw new Error(data.message || "Unknown error");
        }
        dispatch(externalStatusChanged(data));
    };
}

export function reportSaved() {
    return async (dispatch, getState) => {
        // check for conflicts before saving and validate the report
        await dispatch(checkConflictsForAllRows());
        const errors = getValidationErrors(getState());
        dispatch(errorsChanged(errors));
        if (errors.length > 0) {
            toast(
                <div>
                    This report contains the following errors:
                    <ul>
                        {errors.map((e, i) => (
                            <li key={i}>{e.message}</li>
                        ))}
                    </ul>
                </div>,
                {
                    type: "error",
                    autoClose: errors.length * 5000
                }
            );
            return;
        }
        try {
            dispatch(cleanUpEmptyRows());
            let reports = getState().reports;
            if (reports.report.state === STATE_NEW) {
                dispatch(stateChanged(STATE_LOADING));
                let resp = await dispatch(
                    apiFetch("/api/reports", {
                        method: "POST",
                        body: JSON.stringify({
                            reportName: reports.report.reportName,
                            officialOrderName: reports.report.officialOrderName,
                            controlDate: reports.report.controlDate,
                            connectedOrder: reports.report.connectedOrderId,
                            ...selectObjectKeys(reports, versionedFields),
                            employeeTimeTable: await dispatch(createNewEmployeesAndAttachIds())
                        })
                    })
                );
                let data = await resp.json();
                if (resp.status !== 200) {
                    throw new Error(data.message || "Unknown error");
                }
                await dispatch(saveProblemDescriptionToConnectedOrder());
                dispatch(stateChanged(STATE_LOADED));
                //dispatch(push(`/dashboard/reports/${encodeURIComponent(data._id)}`)); // move to the report url
                if (userHasPermissionSelector(getState(), PERMISSION_REPORTER)) {
                    dispatch(push("/dashboard/reporter-desktops"));
                } else {
                    dispatch(push(encodeParams`/dashboard/reports/${data._id}`));
                }
                window.scrollTo(0, 0);
                toast(`Report successfully created! Formular number: ${data.formularNumber}`, {
                    type: "success"
                });
            } else if (reports.report.state === STATE_LOADED) {
                dispatch(modalVersionMessageChanged(""));
                if (reports.report.approved) {
                    dispatch(showVersionMessageModalChanged(true));
                } else {
                    dispatch(modalVersionMessageChanged("Modified"));
                    dispatch(newReportVersionCreated());
                }
            } else {
                throw new Error("Not implemented!");
            }
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(stateChanged(STATE_LOADED));
        } finally {
            clearCompaniesListCache(); // clear the companies list request cache, since the sorting order may depend on the date of the order
        }
    };
}

/**
 * Runs when user enters the version message or approves the report. Act
 */
export function newReportVersionCreated() {
    return async (dispatch, getState) => {
        try {
            let reports = getState().reports;
            let reportId = reports.report.reportId;
            dispatch(stateChanged(STATE_LOADING));
            dispatch(showVersionMessageModalChanged(false));
            let resp = await dispatch(
                apiFetch(`/api/reports/${encodeURIComponent(reportId)}`, {
                    method: "PUT",
                    body: JSON.stringify({
                        versionMetadata: {
                            message: reports.report.modalVersionMessage
                        },
                        ...selectObjectKeys(reports, versionedFields),
                        employeeTimeTable: await dispatch(createNewEmployeesAndAttachIds()),
                        controlDate: reports.report.controlDate
                    })
                })
            );
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message || "Unknown error");
            }
            await dispatch(reportLoadRequested(encodeURIComponent(reports.report.reportId)));
            dispatch(stateChanged(STATE_LOADED));
            toast("Report successfully saved!", {
                type: "success"
            });

            // external editing
            if (
                (await dispatch(checkUserPermission(PERMISSION_EDIT_EXTERNALS))) &&
                (await dispatch(getExternalIdForReport(reportId)))
            ) {
                if (
                    await dispatch(
                        showConfirmModal({
                            cancelButtonText: "Close",
                            okButtonText: "Edit external",
                            title: "Warning",
                            message:
                                "An exernal exists for this report in the system. Would you like to edit that external now?"
                        })
                    )
                ) {
                    await dispatch(loadExternalFromReportId(reportId, reports.report.controlDate));
                }
            }
            if (userHasPermissionSelector(getState(), PERMISSION_REPORTER)) {
                dispatch(push("/dashboard/reporter-desktops"));
            }
            window.scrollTo(0, 0);
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(stateChanged(STATE_LOADED));
        }
    };
}

export function reportApprovalChanged(approval, desktop = false) {
    return async (dispatch, getState) => {
        try {
            dispatch(stateChanged(STATE_LOADING));
            let reports = getState().reports;
            //ispatch(modalVersionMessageChanged());
            //await dispatch(newReportVersionCreated()); // save the report before approving
            let resp = await dispatch(
                apiFetch(`/api/reports/${encodeURIComponent(reports.report.reportId)}/approval`, {
                    method: "PATCH",
                    body: JSON.stringify({
                        approval,
                        versionMetadata: {
                            message: approval ? "Approved" : "Cancelled approval"
                        },
                        ...selectObjectKeys(reports, versionedFields),
                        employeeTimeTable: await dispatch(createNewEmployeesAndAttachIds()),
                        controlDate: reports.report.controlDate
                    })
                })
            );
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message || "Unknown error");
            }
            if (!approval) {
                await dispatch(reportLoadRequested(encodeURIComponent(reports.report.reportId)));
            } else {
                if (userHasPermissionSelector(getState(), PERMISSION_REPORTER)) {
                    dispatch(push("/dashboard/reporter-desktops"));
                } else {
                    if(desktop) {
                        dispatch(push("/dashboard/desktops"));
                    } else {
                        await dispatch(reportLoadRequested(encodeURIComponent(reports.report.reportId)));
                        dispatch(stateChanged(STATE_LOADED));
                        dispatch(push(`/dashboard/reports/${reports.report.reportId}`));
                    }

                }
            }
            toast("Report approval change successfull!", {
                type: "success"
            });
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

export function approveReportFromDesktop(reportId, properties) {
    return async (dispatch, getState) => {
        try {
            let activeDesktop = getState().desktops.desktop.activeDesktop;
            let companies = getState().desktops.desktop.companies;
            if (properties && "companyIndex" in properties && "orderIndex" in properties) {
                companies[properties.companyIndex].orders[properties.orderIndex] = {
                    ...companies[properties.companyIndex].orders[properties.orderIndex],
                    status: "Updating"
                };
                dispatch(desktopsChanged(companies));
            }
            await dispatch(reportLoadRequested(reportId));
            await dispatch(reportApprovalChanged(true, true));
            dispatch(loadDesktop(activeDesktop, false));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(errorChanged(e.message));
        }
    };
}

export function loadUnofficialNameFromOrder(orderId) {
    return async dispatch => {
        try {
            let resp = await dispatch(apiFetch(encodeParams`/api/orders/${orderId}/names`));
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message || "Unknown error");
            }
            dispatch(reportNameChanged(data.unofficialName.unofficialName));
            dispatch(officialOrderNameChanged(data.officialName.officialName));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

/**
 * Change the connected order id and load some data connected with the order.
 * @param {*} orderId
 */
export function connectedOrderIdChangedAndShouldLoad(orderId) {
    return async (dispatch, getState) => {
        dispatch(connectedOrderIdChanged(orderId));
        if (orderId) {
            dispatch(errorCleared({ type: VERROR_NO_CONNECTED_ORDER }));
            dispatch(loadProblemDescriptionFromOrder(orderId));
            dispatch(loadUnofficialNameFromOrder(orderId));
            dispatch(loadEmptyColumnsFromPreviousReport());
            getState().reports.productQA.rows.forEach((_, i) => dispatch(loadAutocomplete(i)));
        }
    };
}

export function reportDeleteChanged() {
    return async (dispatch, getState) => {
        try {
            dispatch(stateChanged(STATE_LOADING));
            let reports = getState().reports;
            let resp = await dispatch(
                apiFetch(encodeParams`/api/reports/${reports.report.reportId}`, {
                    method: "DELETE",
                    body: JSON.stringify({})
                })
            );
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message || "Unknown error");
            }
            let respExternal = await dispatch(
                apiFetch("/api/externals", {
                    method: "POST",
                    body: JSON.stringify({
                        id: null,
                        externalModalOpen: false,
                        flatRate: false,
                        error: null,
                        controlDate: new Date(data.controlDate),
                        orderNumber: null,
                        connectedOrder: reports.report.connectedOrderId,
                        connectedReport: reports.report.reportId,
                        remarks: "",
                        formularNumber: reports.report.formularNumber,
                        hiddenFromClient: true,
                        showRemarks: false,
                        workingHours: 0,
                        normalHours: 0,
                        nightHours: 0,
                        saturdayHours: 0,
                        sundayHours: 0,
                        hideReason: "Deleted",
                        productQA: [
                            {
                                emptyColumnsContent: [],
                                letterColumnsContent: [],
                                _id: null,
                                partNumber: null,
                                reworked: null,
                                nok: null,
                                totalChecked: null,
                                remarks: "",
                                fromThisOk: 0,
                                totalOk: 0,
                                showRemarks: false
                            }
                        ],
                        fromTime: "n/a",
                        toTime: "n/a",
                        deleted: true
                    })
                })
            );
            if (respExternal.status !== 200) {
                throw new Error(data.message || "Unknown error");
            }
            if (userHasPermissionSelector(getState(), PERMISSION_REPORTER)) {
                dispatch(push("/dashboard/reporter-desktops"));
            }
            await dispatch(reportLoadRequested(encodeURIComponent(reports.report.reportId)));
            toast("Report deleted!", {
                type: "success"
            });
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}
