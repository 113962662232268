import React from "react";
import StyledIcon from "../../components/common/StyledIcon";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import { editMode, editGroupedReport } from "../../DesktopsPage/actions/desktopsActions";
import Desktops from "./Desktops";
import DaysFilter from "./DaysFilter";
import { Nav, NavItem } from "react-bootstrap";
import {
    PERMISSION_VIEW_EXTERNALS_LOCAL,
    PERMISSION_VIEW_AND_EDIT_OPEN_REPORTS,
    PERMISSION_VIEW_AND_EDIT_REPORTS_TO_BE_APPROVED,
    PERMISSION_VIEW_APPROVED_REPORTS,
    PERMISSION_VIEW_EXTERNALS
} from "../../../../common/src/permissions";
import { userHasPermissionSelector } from "../../selectors/authSelectors";
import { NavbarButton, ButtonText, NavbarGroup } from "../../components/navbarStyles";

const StyledButton = styled.div`
    height: 18px;
    width: 32px;
    border: solid 1px ${p => p.color};
    background: ${p => (p.noBackground ? "none" : p.color)};
    margin-left: 4px;
    cursor: pointer;
    border-radius: 3px;
`;
const MenuElement = styled.div`
    align-self: center;
    padding-left: 20px;
    padding-right: 20px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 1370px) {
        height: 30px;
    }
`;
const DayFilter = styled.div`
    padding: 14px;
    float: left;
`;

@connect(
    state => ({
        edit: state.desktops.desktop.edit,
        canShowAndEditOpen: userHasPermissionSelector(state, PERMISSION_VIEW_AND_EDIT_OPEN_REPORTS),
        canShowAndEditToApprove: userHasPermissionSelector(state, PERMISSION_VIEW_AND_EDIT_REPORTS_TO_BE_APPROVED),
        canShowApproved: userHasPermissionSelector(state, PERMISSION_VIEW_APPROVED_REPORTS),
        canShowPreExternalsLocal: userHasPermissionSelector(state, PERMISSION_VIEW_EXTERNALS_LOCAL),
        canShowPreExternals: userHasPermissionSelector(state, PERMISSION_VIEW_EXTERNALS)
    }),
    dispatch => ({
        edit: _ => dispatch(editMode()),
        editGroupedExternal: _ => dispatch(editGroupedReport("external")),
        editGroupedExternalDontShow: _ => dispatch(editGroupedReport("externalDontShow")),
        editGroupedApproved: _ => dispatch(editGroupedReport("approved")),
        editGroupedToApprove: _ => dispatch(editGroupedReport("toApprove")),
        editGroupedOpen: _ => dispatch(editGroupedReport("open"))
    })
)
export default class DesktopMenu extends React.Component {
    render() {
        return [
            <NavbarGroup right key={1}>
                <MenuElement>
                    &nbsp;
                    <DaysFilter />
                </MenuElement>
            </NavbarGroup>,
            <NavbarGroup key={2}>
                <MenuElement flex title="Group types of reports" key={2}>
                    <StyledIcon glyph="menu-hamburger" />
                    Groups:
                    {this.props.canShowAndEditOpen && (
                        <StyledButton onClick={this.props.editGroupedOpen} color="#99d9ea" noBackground title="Open" />
                    )}
                    {this.props.canShowAndEditToApprove && (
                        <StyledButton onClick={this.props.editGroupedToApprove} color="#99d9ea" title="In progress" />
                    )}
                    {this.props.canShowApproved && (
                        <StyledButton onClick={this.props.editGroupedApproved} color="#3f48cc" title="Approved" />
                    )}
                    {this.props.canShowPreExternalsLocal && (
                        <StyledButton onClick={this.props.editGroupedExternalDontShow} color="#bfb5b6" title="Hidden" />
                    )}
                    {this.props.canShowPreExternals && (
                        <StyledButton
                            onClick={this.props.editGroupedExternal}
                            color="#4a4142"
                            title="Visible to customer"
                        />
                    )}
                </MenuElement>
            </NavbarGroup>,
            <NavbarGroup left key={3}>
                <MenuElement title="Select your desktop" key={3}>
                    <Desktops />
                </MenuElement>
                <NavbarButton onClick={this.props.edit} key={4} halfHeightWhenResponsive hideOnMobile>
                    <ButtonText>Edit desktops</ButtonText>
                </NavbarButton>
            </NavbarGroup>
        ];
    }
}
