import { makeActionCreator, encodeParams } from "../../utils";
import { toast } from "react-toastify";
import { apiFetch } from "../../actions/apiActions";
import { HR_FIELD_TYPE_TIME_FROM, HR_FIELD_TYPE_TIME_TO } from "../../../../common/src/hrTemplates";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_LOADED = "STATE_LOADED";

export const DR_MODE_CONTAINS = "CONTAINS";
export const DR_MODE_OVERLAPS = "OVERLAPS";
// for single dates
export const DR_MODE_EARLIER_THAN = "EARLIER_THAN";
export const DR_MODE_LATER_THAN = "LATER_THAN";

export const SHOW_MODAL_CHANGED = "HR.TEMPLATE_FILTERS_MODAL.SHOW_MODAL_CHANGED";
export const TEMPLATE_ID_CHANGED = "HR.TEMPLATE_FILTERS_MODAL.TEMPLATE_ID_CHANGED";
export const TEMPLATE_DATA_CHANGED = "HR.TEMPLATE_FILTERS_MODAL.TEMPLATE_DATA_CHANGED";
export const TEMPLATE_DATA_STATE_CHANGED = "HR.TEMPLATE_FILTERS_MODAL.TEMPLATE_DATA_STATE_CHANGED";
export const SET_FIELD_FILTERS = "HR.TEMPLATE_FILTERS_MODAL.CLEAR_FIELD_FILTERS";
export const FIELD_FILTER_CHANGED = "HR.TEMPLATE_FILTERS_MODAL.FIELD_FILTER_CHANGED";
export const DATE_RANGE_FILTER_MODE_CHANGED = "HR.TEMPLATE_FILTERS_MODAL.DATE_RANGE_FILTER_MODE_CHANGED";

export const showModalChanged = makeActionCreator(SHOW_MODAL_CHANGED, "payload", "query");
export const templateDataChanged = makeActionCreator(TEMPLATE_DATA_CHANGED, "payload");
export const templateIdChanged = makeActionCreator(TEMPLATE_ID_CHANGED, "payload");
export const templateDataStateChanged = makeActionCreator(TEMPLATE_DATA_STATE_CHANGED, "payload");
export const setFieldFilters = makeActionCreator(SET_FIELD_FILTERS, "payload");
export const fieldFilterChanged = makeActionCreator(FIELD_FILTER_CHANGED, "fieldId", "payload");
export const dateRangeFilterModeChanged = makeActionCreator(DATE_RANGE_FILTER_MODE_CHANGED, "payload");

export function showTemplateFiltersModal(query) {
    return async (dispatch, getState) => {
        if (query["filter.template"]) {
            dispatch(loadAndSetTemplateById(query["filter.template"]));
            let fieldValuesFilters = {};
            Object.keys(query)
                .filter(k => k.startsWith("filter.template.field."))
                .forEach(k => {
                    try {
                        fieldValuesFilters[k.replace(/^filter\.template\.field\./, "")] = JSON.parse(query[k]);
                    } catch (e) {}
                });
            dispatch(setFieldFilters(fieldValuesFilters));
            dispatch(dateRangeFilterModeChanged(query["filter.dateRangeFilterMode"]));
        } else {
            dispatch(loadAndSetTemplateById(null));
            dispatch(setFieldFilters({}));
        }
        dispatch(showModalChanged(true));
    };
}

export function loadAndSetTemplateById(id) {
    return async (dispatch, getState) => {
        dispatch(templateIdChanged(id));
        if (!id) {
            dispatch(templateDataStateChanged(STATE_DEFAULT));
            dispatch(templateDataChanged(null));
            return;
        }
        dispatch(templateDataStateChanged(STATE_LOADING));
        try {
            const resp = await dispatch(apiFetch(encodeParams`/api/templates/${id}`));
            const data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            dispatch(templateDataChanged(data));
            let hasTimeFrom = data.fields.some(f => f.type === HR_FIELD_TYPE_TIME_FROM);
            let hasTimeTo = data.fields.some(f => f.type === HR_FIELD_TYPE_TIME_TO);
            let drMode = getState().hr.templateFiltersModal.dateRangeFilterMode;
            // set default date range mode
            if ((hasTimeFrom || hasTimeTo) && !(hasTimeFrom && hasTimeTo)) {
                // only one date field
                if (drMode !== DR_MODE_EARLIER_THAN && drMode !== DR_MODE_LATER_THAN) {
                    dispatch(dateRangeFilterModeChanged(DR_MODE_EARLIER_THAN));
                }
            } else {
                if (drMode !== DR_MODE_OVERLAPS && drMode !== DR_MODE_CONTAINS) {
                    dispatch(dateRangeFilterModeChanged(DR_MODE_OVERLAPS));
                }
            }
            dispatch(templateDataStateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(templateIdChanged(null));
            dispatch(templateDataStateChanged(STATE_DEFAULT));
            dispatch(templateDataChanged(null));
            toast("Failed to load template fields for filtering. " + e.message, { type: "error" });
        }
    };
}

export function loadFieldOptions(templateId, fieldId, query) {
    return async (dispatch, getState) => {
        try {
            const resp = await dispatch(
                apiFetch(
                    encodeParams`/api/employees-management/template-field-autocomplete?templateId=${templateId}&fieldId=${fieldId}&query=${query}`
                )
            );
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            const data = await resp.json();
            return data;
        } catch (e) {
            toast("An error has occured while loading field values. " + e.message, { type: "error" });
        }
    };
}
