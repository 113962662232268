import React from "react";
import { DumbExternalReportHeader, LinearContainer } from "./ExternalReportHeader";
import { Row, Col, ControlLabel, FormControl, InputGroup, FormGroup, Table } from "react-bootstrap";
import { ShortInputGroup } from "./CurrentReportSortRate";
import { DumbExternalQATableTotalsRow } from "./ExternalQATableTotalsRow";
import styled from "styled-components";
import theme from "../../theme";

const TableWithNoBorder = styled(Table)`
    width: auto;
    border: none !important;
`;

const BottomDivider = styled.div`
    border-bottom: 2px solid ${theme.secondaryColor};
    padding-bottom: 10px;
    margin-bottom: 10px;
`;

const TextareaLabel = styled(ControlLabel)`
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 8px;
    translate: none;
`;

const RemarksContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;

export default class OldExternal extends React.Component {
    render() {
        let e = this.props.external;

        return (
            <BottomDivider>
                <DumbExternalReportHeader {...e} disabled />
                <TableWithNoBorder responsive={true}>
                    <tbody>
                        <DumbExternalQATableTotalsRow {...e.total} />
                    </tbody>
                </TableWithNoBorder>
                <LinearContainer>
                    <FormGroup className="form-inline">
                        <ControlLabel>Sort rate</ControlLabel>
                        <ShortInputGroup>
                            <FormControl disabled value={e.sortRate} />
                            <InputGroup.Addon>pcs / h</InputGroup.Addon>
                        </ShortInputGroup>
                    </FormGroup>

                    <FormGroup className="form-inline">
                        <ControlLabel>Total working hours confirmed</ControlLabel>
                        <FormControl disabled value={e.totalWorkingHours} className="input-very-small" />
                    </FormGroup>
                    <RemarksContainer>
                        <TextareaLabel>Remarks</TextareaLabel>
                        <div className="form-inline">
                            <FormControl componentClass="textarea" disabled value={e.remarks} />
                        </div>
                    </RemarksContainer>
                </LinearContainer>
            </BottomDivider>
        );
    }
}
