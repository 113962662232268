import React from "react";
import External from "./External";

export default class MainPage extends React.Component {
    render() {
        return (
            <External />
        );
    }
}
