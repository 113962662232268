import {
    EMAIL_CHANGED,
    ERROR_MESSAGE_CHANGED,
    PASSWORD_RESET_STATE_CHANGED,
    STATE_DEFAULT,
    SHOW_SUCCESS_MODAL_CHANGED
} from "../actions/passwordResetActions";

const initialState = {
    email: "",
    errorMessage: "",
    passwordResetState: STATE_DEFAULT,
    showSuccessModal: false
};

export default function passwordReset(state = initialState, action) {
    switch (action.type) {
        case EMAIL_CHANGED:
            return {
                ...state,
                email: action.email
            };
        case ERROR_MESSAGE_CHANGED:
            return {
                ...state,
                errorMessage: action.errorMessage
            };
        case PASSWORD_RESET_STATE_CHANGED:
            return {
                ...state,
                passwordResetState: action.passwordResetState
            };
        case SHOW_SUCCESS_MODAL_CHANGED:
            return {
                ...state,
                showSuccessModal: action.showSuccessModal
            };
        default:
            return state;
    }
}
