import React from "react";
import LoaderGuard from "../../components/common/LoaderGuard";
import {
    STATE_LOADING,
    STATE_LOADED,
    templateRemoved,
    templateFieldValueChanged,
    loadAutocompleteData
} from "../actions/editEmployeeActions";
import { connect } from "react-redux";
import styled from "styled-components";
import theme from "../../theme";
import ConfirmableButton from "../../components/common/ConfirmableButton";
import TemplateForm from "./templates/form/TemplateForm";
import { HR_FIELD_TYPE_TEXT } from "../../../../common/src/hrTemplates";

const TemplateInstanceContainer = styled.div`
    border: solid 2px ${theme.secondaryColor};
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 15px;
    width: calc(50% - 30px);
    @media (max-width: 1300px) {
        width: calc(100% - 30px);
        margin: 7px;
    }
    hr {
        border: solid 1px ${theme.secondaryColor};
    }
`;

const SectionWrapper = styled.div`
    padding-right: 15px;
    padding-left: 15px;
`;

@connect(
    state => state.hr.editEmployee,
    (dispatch, ownProps) => ({
        templateRemoved: _ => dispatch(templateRemoved(ownProps.templateIndex)),
        templateFieldValueChanged: (fieldId, payload) =>
            dispatch(templateFieldValueChanged(ownProps.templateIndex, fieldId, payload)),
        loadAutocompleteData: (field, query) => dispatch(loadAutocompleteData(ownProps.templateIndex, field, query))
    })
)
export default class TemplateInstance extends React.Component {
    render() {
        return (
            <TemplateInstanceContainer>
                <LoaderGuard
                    isLoading={this.props.template.state === STATE_LOADING}
                    showContents={this.props.template.state === STATE_LOADED}
                >
                    <SectionWrapper>
                        {this.props.template.state === STATE_LOADED && (
                            <strong>{this.props.template.templateData.name}</strong>
                        )}
                        <ConfirmableButton className="pull-right" onConfirm={this.props.templateRemoved} />
                        <div className="clearfix" />
                    </SectionWrapper>
                    <hr />
                    <SectionWrapper>
                        <TemplateForm
                            template={this.props.template.templateData}
                            valueSelector={field => {
                                return this.props.template.fieldValues[field._id];
                            }}
                            onChangeSelector={field => ev => {
                                this.props.templateFieldValueChanged(field._id, ev);
                                if (field.type === HR_FIELD_TYPE_TEXT) {
                                    this.props.loadAutocompleteData(field, ev);
                                }
                            }}
                            autocompleteDataSelector={field => this.props.template.autocompleteData[field._id]}
                        />
                    </SectionWrapper>
                </LoaderGuard>
            </TemplateInstanceContainer>
        );
    }
}
