import { makeActionCreator } from "../../utils";
import { makeApiUrl } from "../../api";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_ERROR = "STATE_ERROR";
export const STATE_LOADING = "STATE_LOADING";

export const STATE_CHANGED = "PASSWORD_RESET.FINISH_STATE_CHANGED";
export const MESSAGE_CHANGED = "PASSWORD_RESET.FINISH_MESSAGE_CHANGED";
export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const messageChanged = makeActionCreator(MESSAGE_CHANGED, "payload");

export function pageEntered(userId, token) {
    return async dispatch => {
        dispatch(stateChanged(STATE_LOADING));
        try {
            let resp = await fetch(makeApiUrl("/api/auth/reset-password-finish"), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userId,
                    token
                })
            });

            let data = await resp.json();
            if(resp.status !== 200) {
                dispatch(stateChanged(STATE_ERROR));
                dispatch(messageChanged("Error: " + data.message));
                return;
            }
            dispatch(messageChanged("Your password has been reset, check your email for further instructions."));
        } catch (e) {
            dispatch(stateChanged(STATE_ERROR));
            dispatch(messageChanged("Error:" + e.message));
            return;
        }
        dispatch(stateChanged(STATE_DEFAULT));
    };
}


