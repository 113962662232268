import {
    STATE_CHANGED,
    ERROR_CHANGED,
    QUESTIONNAIRE_F2F_CONTACT_CHANGED,
    QUESTIONNAIRE_VIA_PHONE_CHANGED,
    QUESTIONNAIRE_BY_EMAIL_CHANGED,
    QUESTIONNAIRE_NOT_SETTLED_CHANGED,
    QUESTIONNAIRE_CLEARED_CHANGED
} from "../actions/questionnaireAction";

export const initialState = {
    f2fContact: false,
    viaPhone: false,
    byEmail: false,
    notSettled: false
};

export default function questionnaire(state = initialState, action) {
    switch (action.type) {
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case ERROR_CHANGED:
            return {
                ...state,
                error: action.payload
            };
        case QUESTIONNAIRE_F2F_CONTACT_CHANGED:
            return {
                ...state,
                f2fContact: action.payload,
                viaPhone: false,
                byEmail: false,
                notSettled: false
            };
        case QUESTIONNAIRE_VIA_PHONE_CHANGED:
            return {
                ...state,
                viaPhone: action.payload,
                f2fContact: false,
                byEmail: false,
                notSettled: false
            };
        case QUESTIONNAIRE_BY_EMAIL_CHANGED:
            return {
                ...state,
                byEmail: action.payload,
                f2fContact: false,
                viaPhone: false,
                notSettled: false
            };
        case QUESTIONNAIRE_NOT_SETTLED_CHANGED:
            return {
                ...state,
                notSettled: action.payload,
                f2fContact: false,
                viaPhone: false,
                byEmail: false
            };
        case QUESTIONNAIRE_CLEARED_CHANGED:
            return {
                ...state,
                f2fContact: false,
                viaPhone: false,
                byEmail: false,
                notSettled: false
            };
        default:
            return state;
    }
}
