export const HR_FIELD_TYPE_TEXT = "TEXT";
export const HR_FIELD_TYPE_CHECKBOX = "CHECKBOX";
export const HR_FIELD_TYPE_TIME_FROM = "TIME_FROM";
export const HR_FIELD_TYPE_TIME_TO = "TIME_TO";
export const HR_FIELD_TYPE_TRAY_NOTIFICATION = "TRAY_NOTIFICATION";
export const HR_FIELD_TYPE_EMAIL_NOTIFICATION = "EMAIL_NOTIFICATION";

export const PARAM_STRING = "STRING";
export const PARAM_LONG_STRING = "LONG_STRING";
export const PARAM_NUMBER = "NUMBER";
export const PARAM_BOOL = "BOOL";
export const PARAM_STATIC_TEXT = "STATIC_TEXT";

export const HR_RULE_NOT_EMPTY = "NOT_EMPTY";
export const HR_RULE_FUTURE = "FUTURE";
export const HR_RULE_NOTIFY_APPROACHING = "NOTIFY_APPROACHING";

export const hrFieldTypesInfo = {
    [HR_FIELD_TYPE_TEXT]: {
        name: "Text area"
    },
    [HR_FIELD_TYPE_CHECKBOX]: {
        name: "Checkbox"
    },
    [HR_FIELD_TYPE_TIME_FROM]: {
        name: "Date from"
    },
    [HR_FIELD_TYPE_TIME_TO]: {
        name: "Date to"
    },
    [HR_FIELD_TYPE_TRAY_NOTIFICATION]: {
        name: "Tray notification"
    },
    [HR_FIELD_TYPE_EMAIL_NOTIFICATION]: {
        name: "Email notification"
    }
};

export function populateTemplateDefaults(template) {
    let newFieldValues = {};
    for (let f of template.fields) {
        let value = null;
        switch (f.type) {
            case HR_FIELD_TYPE_CHECKBOX:
                value = false;
                break;
            case HR_FIELD_TYPE_TEXT:
                value = "";
                break;
            case HR_FIELD_TYPE_TRAY_NOTIFICATION:
            case HR_FIELD_TYPE_EMAIL_NOTIFICATION:
                value = {
                    comments: f.comments,
                    daysEarlier: f.daysEarlier
                };
                break;
        }
        newFieldValues[f._id] = value;
    }
    return newFieldValues;
}

export const FIELD_SORTING_PRIORITY = [
    HR_FIELD_TYPE_TIME_TO,
    HR_FIELD_TYPE_TIME_FROM,
    HR_FIELD_TYPE_EMAIL_NOTIFICATION,
    HR_FIELD_TYPE_TRAY_NOTIFICATION,
    HR_FIELD_TYPE_TEXT,
    HR_FIELD_TYPE_CHECKBOX
];