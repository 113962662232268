import {
    FILE_ADDED,
    PROGRESS_CHANGED,
    FILE_ERRORED,
    REPLACE_FILE_AFTER_UPLOAD,
    FILE_DELETED
} from "../actions/filesActions";
import { ORDER_DATA_CHANGED, ORDER_RESET } from "../actions/orderActions";

export const initialState = {
    files: []
};
export default function files(state = initialState, action) {
    switch (action.type) {
        case ORDER_RESET:
            return initialState;
        case ORDER_DATA_CHANGED:
            return {
                ...state,
                ...action.payload.files
            };
        case FILE_ADDED:
            return {
                ...state,
                files: [...state.files, action.payload]
            };
        case PROGRESS_CHANGED:
            return {
                ...state,
                files: state.files.map(f => {
                    if (f._id !== action.fileId) return f;
                    return {
                        ...f,
                        progress: action.payload
                    };
                })
            };
        case FILE_ERRORED:
            return {
                ...state,
                files: state.files.map(f => {
                    if (f._id !== action.fileId) return f;
                    return {
                        ...f,
                        error: action.payload
                    };
                })
            };
        case REPLACE_FILE_AFTER_UPLOAD:
            return {
                ...state,
                files: state.files.map(f => {
                    if (f._id !== action.fileId) return f;
                    return action.payload;
                })
            };
        case FILE_DELETED:
            return {
                ...state,
                files: state.files.filter(f => f._id !== action.fileId)
            };
        default:
            return state;
    }
}
