import { makeActionCreator, encodeParams } from "../utils";
import { apiFetch } from "./apiActions";
import { toast } from "react-toastify";
import moment from "moment";
import { STATE_LOGGED_IN } from "./authActions";

export const NOTIFICATIONS_LOADED = "NOTIFIACTIONS.NOTIFICATIONS_LOADED";
export const NOTIFICATIONS_ADDED = "NOTIFIACTIONS.NOTIFICATIONS_ADDED";
export const LAST_REFRESH_SET = "NOTIFIACTIONS.LAST_REFRESH_SET";
export const REFRESH_INTERVAL_STARTED_SET = "NOTIFIACTIONS.REFRESH_INTERVAL_STARTED_SET";
export const NOTIFICATION_SET_READ = "NOTIFIACTIONS.NOTIFICATION_SET_READ";

export const notifiactionsLoaded = makeActionCreator(NOTIFICATIONS_LOADED, "payload");
export const notificationAdded = makeActionCreator(NOTIFICATIONS_ADDED, "payload");
export const lastRefreshSet = makeActionCreator(LAST_REFRESH_SET, "payload");
export const refreshIntervalStartedSet = makeActionCreator(REFRESH_INTERVAL_STARTED_SET, "payload");
export const notificationSetRead = makeActionCreator(NOTIFICATION_SET_READ, "notificationId");

const REFRESH_INTERVAL = 60 * 1000; // 1 minute

export function loadNotifications() {
    return async (dispatch, getState) => {
        if (!getState().notifications.refreshIntervalStarted) {
            dispatch(refreshIntervalStartedSet(true));
            setTimeout(_ => dispatch(refreshNotifications()), REFRESH_INTERVAL);
        }
        try {
            let resp = await dispatch(apiFetch("/api/notifications"));
            let notifications = await resp.json();
            if (notifications.some(n => !n.read)) {
                toast("You've got new notifications (Scroll to the top of the page to see them).", { type: "info" });
            }
            dispatch(lastRefreshSet(new Date()));
            dispatch(notifiactionsLoaded(notifications));
        } catch (e) {
            console.error(e);
        }
    };
}

export function refreshNotifications() {
    return async (dispatch, getState) => {
        try {
           
            setTimeout(_ => dispatch(refreshNotifications()), REFRESH_INTERVAL);
            if (getState().auth.authState !== STATE_LOGGED_IN) {
                return;
            }
            let resp = await dispatch(
                apiFetch(
                    encodeParams`/api/notifications?since=${moment(getState().notifications.lastRefresh)
                        .subtract(15, "seconds")
                        .toJSON()}`
                )
            );
            let notifications = await resp.json();
            dispatch(lastRefreshSet(new Date()));
            let filtered = notifications.filter(n =>
                getState().notifications.notifications.every(n2 => n2._id !== n._id)
            );
            if (filtered.length > 0) {
                dispatch(notificationAdded(filtered));
                toast("You've got new notifications (Scroll to the top of the page to see them).", { type: "info" });
            }
        } catch (e) {
            console.error(e);
        }
    };
}

export function markNotificationAsRead(id) {
    return async (dispatch, getState) => {
        try {
            let resp = await dispatch(
                apiFetch(encodeParams`/api/notifications/${id}/read`, {
                    method: "POST",
                    body: JSON.stringify(null)
                })
            );
            if (!resp.ok) {
                throw new Error("Error while marking notification as read.");
            }
            dispatch(notificationSetRead(id));
        } catch (e) {
            console.error(e);
        }
    };
}
