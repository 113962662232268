import subtotalsSelector from "./subtotalsSelector";

/**
 * Checks whether the remarks field has been filled when the total is zero.
 * @param {*} state
 */
export default function validateQATotalRemarks(state) {
    const timeRegex = /^[0-9]{2}:[0-9]{2}$/;
    // no error when time table is not yet fully filled
    if (
        !state.reports.employeeTimeTable.rows.every(row => timeRegex.test(row.fromTime) && timeRegex.test(row.toTime))
    ) {
        return true;
    }
    if (!state.reports.remarksDescription.remarksDescriptionContent.trim()) {
        if (subtotalsSelector(state.reports.productQA.rows).totalChecked <= 0) {
            return false; // remarks must be filled when totalChecked is zero
        }
    }
    return true; // remarks are okay
}
