import { encodeParams, makeActionCreator } from "../../../utils";
import { apiFetch } from "../../../actions/apiActions";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_ERROR = "STATE_ERROR";
export const STATE_LOADING = "STATE_LOADING";

export const OLD_PASSWORD_CHANGED = "CLIENT_PANEL.ACCOUNT.OLD_PASSWORD_CHANGED";
export const NEW_PASSWORD_CHANGED = "CLIENT_PANEL.ACCOUNT.NEW_PASSWORD_CHANGED";
export const NEW_PASSWORD2_CHANGED = "CLIENT_PANEL.ACCOUNT.NEW_PASSWORD2_CHANGED";
export const PASSWORD_ERROR_CHANGED = "CLIENT_PANEL.ACCOUNT.PASSWORD_ERROR_CHANGED";
export const SHOW_COMMUNIQUE_CHANGED = "CLIENT_PANEL.ACCOUNT.SHOW_COMMUNIQUE_CHANGED";

export const oldPasswordChanged = makeActionCreator(OLD_PASSWORD_CHANGED, "payload");
export const newPasswordChanged = makeActionCreator(NEW_PASSWORD_CHANGED, "payload");
export const newPassword2Changed = makeActionCreator(NEW_PASSWORD2_CHANGED, "payload");
export const passwordErrorChanged = makeActionCreator(PASSWORD_ERROR_CHANGED, "payload");
export const showCommuniqueChanged = makeActionCreator(SHOW_COMMUNIQUE_CHANGED, "payload");

export function changePassword() {
    return async (dispatch, getState) => {
        try {
            let resp = await dispatch(
                apiFetch(encodeParams`/api/auth/change-password`, {
                    method: "PATCH",
                    body: JSON.stringify({
                        userId: getState().auth.user._id,
                        password: getState().clientPanel.accounts.newPassword,
                        oldPassword: getState().clientPanel.accounts.oldPassword
                    })
                })
            );
            let data = await resp.json();
            if (resp.status !== 200) {
                dispatch(passwordErrorChanged(data.message));
            } else {
                dispatch(showCommuniqueChanged(true));
                dispatch(oldPasswordChanged(""));
                dispatch(newPasswordChanged(""));
                dispatch(newPassword2Changed(""));
                dispatch(passwordErrorChanged(""));
            }
            return data;
        } catch (e) {
            console.error(e);
        }
    };
}
