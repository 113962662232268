import React from "react";
import CompanyContainer from "./CompanyContainer";

export default function ReporterDesktopPage() {
    return (
        <div>
            <h3>
                Reporter Desktop Page
            </h3>
            <CompanyContainer />
        </div>
    );
}