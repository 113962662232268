export default {
    data: [
        {
            title: "EN",
            header: "Privacy policy",
            paragraphs: [
                `This document describes the principles of processing the personal data of users of the RES Online Report 
                System platform (hereinafter referred to as Users), the administrator of which is RES Polska Sp. z o.o. 
                based in Gliwice.`,
                `1. The Controller, in the meaning of the Regulation of the European Parliament and of the Council (EU) 
                2016/679 of 27 April 2016 on the protection of individuals with regard to the processing of personal data 
                and on the free movement of such data and repealing Directive 95/46/EC (hereinafter referred to as GDPR), 
                of the personal data of users of the RES Online Report System (ORS) is RES Polska Sp. z o.o. Based in 
                Gliwice at ul. Łabędzka 47, 44-121 Gliwice, entered in the company register of the National Court Register 
                by the District Court in Gliwice, 10th Economic Division under KRS number: 302798, NIP: 9691525536, 
                REGON: 240943365.`,
                `2. The personal data of Users is processed for the purpose of enabling Users to access and use the ORS, 
                their identification and possible contact with the User regarding the administration of the User's account. 
                The processing of personal data takes place on the basis of a consent given by the User by clicking 
                the link sent in the User's account activation e-mail, i.e. pursuant to Article 6(1)(a) of the GDPR.`,
                `3. The Controller shall process the following personal data: first and last name, e-mail address, 
                telephone number of the User. Provision of personal data is voluntary, but necessary to enable the User 
                to access the ORS. The User has the right to withdraw their consent to the processing of personal data 
                at any time, but withdrawal of consent will be tantamount to deleting the User's ORS account.`,
                `4. Personal data shall be processed as long as the User's ORS account is active. If User’s account 
                is deleted, their personal data will be deleted within 7 days. If the activation of the account does 
                not take place within 72 hours of sending the activation e-mail, the personal data provided for activation 
                of the User's account shall be deleted upon expiry of this deadline.`,
                `5. The personal data of Users will not be transferred to any third parties without the User's prior 
                consent, with the reservation that User's data may be disclosed and transmitted only at the request of 
                authorised state authorities in cases explicitly indicated by the law, e.g. to law enforcement authorities 
                or controllers of the Inspector General for the protection of personal data.`,
                `6. The User has the right to request from the Controller access to their personal data, the right to 
                have their personal data rectified, the right to stop or limit the processing, the right to object to 
                data processing, and the right to have their personal data transferred. Any changes to personal data 
                may be made at any time through the User's account after prior registration and logging in. In any case, 
                the User may obtain all information regarding access, modification, rectification and deletion of their 
                personal data by contacting the Controller by email: it@resglobalgroup.com or by phone: +48 32 231 08 56.`,
                `7. Personal data will not be subject to automated decision making.`,
                `8. The User has the right to lodge a complaint with the competent supervisory body for the protection 
                of personal data.`,
                `9. In connection with the processing of personal data, the Customer has the rights indicated in 
                Article 7(3), Articles 15-22, and Article 77 of the GDPR.`
            ]
        },
        {
            title: "PL",
            header: "Polityka prywatności",
            paragraphs: [
                `Niniejszy dokument opisuje zasady przetwarzania danych osobowych użytkowników platformy RES Online Report 
            System (dalej Użytkownicy), której administratorem jest RES Polska Sp. z o. o. z siedzibą w Gliwicach.`,
                `1. Administratorem danych osobowych, w rozumieniu Rozporządzenia Parlamentu Europejskiego i Rady (UE) 
            2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych 
            osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej RODO), 
            Użytkowników RES Online Report System (ORS) jest RES Polska Sp. z o. o. z siedzibą Gliwicach przy 
            ul. Łabędzkiej 47, 44 - 121 Gliwice, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego przez 
            Sąd Rejonowy w Gliwicach, Wydział X Gospodarczy pod numerem KRS: 302798, NIP: 9691525536, REGON: 240943365.`,
                `2. Dane osobowe Użytkowników są przetwarzane w celu umożliwienia Użytkownikom dostępu i korzystania z ORS, 
            ich identyfikacji oraz ewentualnej konieczności kontaktu z Użytkownikiem w zakresie administracji kontem 
            Użytkownika. Przetwarzanie danych osobowych następuje na podstawie wyrażonej przez Użytkownika zgody poprzez 
            kliknięcie linku w mailu aktywacyjnym konta Użytkownika, tj. na podstawie art. 6 ust. 1 pkt. a) RODO.`,
                `3. Administrator przetwarza następujące dane osobowe: imię i nazwisko, adres e-mail, numer telefonu 
            Użytkownika. Podanie danych osobowych jest dobrowolne, ale konieczne dla umożliwienia Użytkownikowi dostępu 
            do ORS. Użytkownik ma prawo w każdej chwili cofnąć zgodę na przetwarzanie danych osobowych, jednak cofnięcie 
            zgody będzie równoznaczne z usunięciem konta Użytkownika w ORS.`,
                `4. Dane osobowe są przetwarzane przez okres aktywności konta Użytkownika na platformie ORS. W przypadku 
            usunięcia konta Użytkownika dane osobowe zostaną usunięte w terminie 7 dni. Dane osobowe podane do aktywacji 
            konta Użytkownika, w przypadku gdy aktywacja konta nie nastąpi w terminie 72 godzin od wysłania maila 
            aktywacyjnego, zostają usunięte z upływem tego terminu.`,
                `5. Dane osobowe Użytkowników nie są przekazywane żadnym osobom trzecim bez uprzedniej zgody Użytkownika, 
            z zastrzeżeniem, że dane Użytkownika mogą zostać udostępnione i przekazane wyłącznie na żądanie uprawnionych 
            organów państwowych w przypadkach wyraźnie wskazanych przepisami prawa, np. organom ścigania czy kontrolerom 
            Generalnego Inspektora Danych Osobowych.`,
                `6. Użytkownik ma prawo do żądania od Administratora dostępu do danych osobowych, sprostowania danych osobowych, 
            usunięcia lub ograniczenia przetwarzania, wniesienia sprzeciwu wobec przetwarzania danych, a także przenoszenia 
            danych. Wszelkich zmian danych osobowych Użytkownik może dokonać w dowolnym czasie poprzez konto Użytkownika 
            po uprzedniej rejestracji i zalogowaniu. W każdym jednak przypadku wszelkie informacje odnośnie dostępu, 
            zmiany, poprawiania oraz usuwanie swoich danych osobowych Użytkownik może uzyskać kontaktując się 
            z Administratorem poprzez email: it@resglobalgroup.com lub telefonicznie: +48 32 231 08 56.`,
                `7. Dane osobowe nie będą podlegać zautomatyzowanemu podejmowaniu decyzji.`,
                `8. Użytkownikowi przysługuje prawo do wniesienia skargi do właściwego organu nadzorczego dla ochrony danych 
            osobowych.`,
                `9. W związku z przetwarzaniem danych osobowych kontrahentowi przysługują prawa wskazane w art. 7 ust. 3, 
            art. 15 – 22, art. 77 RODO.`
            ]
        }
    ]
};