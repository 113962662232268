import React from "react";
import { connect } from "react-redux";
import AutoCell from "./AutoCell";

export default class AutoRow extends React.PureComponent {
    render() {
        let cells = this.props.processedColumns.map((col, i) => {
            let extraProps = {};
            if (i === (this.props.model.focusCellIndex || 0)) {
                extraProps["innerRef"] = this.props.firstCellRef;
            }
            return (
                <AutoCell
                    key={i}
                    model={this.props.model}
                    row={this.props.row}
                    rowIndex={this.props.rowIndex}
                    column={col}
                    columnIndex={i}
                    disabled={this.props.disabled}
                    rowsLength={this.props.rowsLength}
                    focusNextRow={this.props.focusNextRow}
                    focusLastRowOnNextUpdate={this.props.focusLastRowOnNextUpdate}
                    {...extraProps}
                />
            );
        });
        if (this.props.model.customRow) {
            return (
                <this.props.model.customRow
                    model={this.props.model}
                    row={this.props.row}
                    rowIndex={this.props.rowIndex}
                    disabled={this.props.disabled}
                    allRows={this.props.allRows}
                    firstCellRef={this.props.firstCellRef}
                    rowsLength={this.props.rowsLength}
                    focusNextRow={this.props.focusNextRow}
                    focusLastRowOnNextUpdate={this.props.focusLastRowOnNextUpdate}
                    processedColumns={this.props.processedColumns}
                    dispatch={this.props.dispatch}
                >
                    {cells}
                </this.props.model.customRow>
            );
        }
        return <tr>{cells}</tr>;
    }
}
