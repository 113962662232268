/**
 * This file contains all the permissions available in the system.
 * The permissions are divided into permission groups. A permission has to have a group to be rendered in the "Groups tab".
 * Additionaly permissions can declare their requirements so that constraints can be enforced.
 */

export const PERMISSION_VIEW_COMPANIES = "VIEW_COMPANIES";
export const PERMISSION_CREATE_COMPANIES = "CREATE_COMPANIES";
export const PERMISSION_EDIT_COMPANIES = "EDIT_COMPANIES";
export const PERMISSION_INVITE_CUSTOMERS = "INVITE_CUSTOMERS";

export const PERMISSION_VIEW_ORDERS = "VIEW_ORDERS";
export const PERMISSION_QUICK_ORDERS_SEARCHING = "QUICK_ORDERS_SEARCHING";
export const PERMISSION_CHECK_HOURS_DATA = "CHECK_HOURS_DATA";
export const PERMISSION_CREATE_ORDERS = "CREATE_ORDERS";
export const PERMISSION_EDIT_ORDERS = "EDIT_ORDERS";
export const PERMISSION_EDIT_ORDERS_CONTACT_PERSON = "EDIT_ORDERS_CONTACT_PERSON";
export const PERMISSION_FINISH_ORDERS = "FINISH_ORDERS";
export const PERMISSION_REOPEN_ORDERS = "REOPEN_ORDERS";
export const PERMISSION_FINISHED_ORDER_NOTIFICATION = "FINISHED_ORDER_NOTIFICATION";
export const PERMISSION_VIEW_ORDERS_VERSION_HISTORY = "VIEW_ORDERS_VERSION_HISTORY";

export const PERMISSION_VIEW_REPORTS = "VIEW_REPORTS";
export const PERMISSION_VIEW_REPORTS_VERSION_HISTORY = "PERMISSION_VIEW_REPORTS_VERSION_HISTORY";
export const PERMISSION_CREATE_REPORTS = "CREATE_REPORTS";
export const PERMISSION_EDIT_REPORTS = "EDIT_REPORTS";
export const PERMISSION_APPROVE_REPORTS = "APPROVE_REPORTS";
export const PERMISSION_EDIT_APPROVED_REPORTS = "EDIT_APPROVED_REPORTS";
export const PERMISSION_EDIT_REPORTS_WITH_PREEXTERNAL = "PERMISSION_EDIT_REPORTS_WITH_PREEXTERNAL";
export const PERMISSION_DELETE_REPORTS = "PERMISSION_DELETE_REPORTS";
export const PERMISSION_QUICK_REPORTS_SEARCHING = "QUICK_REPORTS_SEARCHING";
export const PERMISSION_MASS_REPORTS_APPROVAL = "MASS_REPORTS_APPROVAL";

export const PERMISSION_VIEW_REPORTS_LIST = "VIEW_REPORTS_LIST";

export const PERMISSION_VIEW_EXTERNALS = "VIEW_EXTERNALS";
export const PERMISSION_VIEW_EXTERNALS_LOCAL = "VIEW_EXTERNALS_LOCAL";
export const PERMISSION_CREATE_EXTERNALS = "CREATE_EXTERNALS";
export const PERMISSION_SIMULATE_EXTERNALS = "SIMULATE_EXTERNALS";
export const PERMISSION_EDIT_EXTERNALS = "EDIT_EXTERNALS";
export const PERMISSION_VIEW_APPROVED_REPORTS = "VIEW_APPROVED_REPORTS";
export const PERMISSION_VIEW_AND_EDIT_REPORTS_TO_BE_APPROVED = "VIEW_AND_EDIT_REPORTS_TO_BE_APPROVED";
export const PERMISSION_VIEW_AND_EDIT_OPEN_REPORTS = "VIEW_AND_EDIT_OPEN_REPORTS";

export const PERMISSION_GENERATE_EXCEL_REPORTS = "GENERATE_EXCEL_REPORTS";

export const PERMISSION_NEW_EMPLOYEE_NOTIFICATION = "NEW_EMPLOYEE_NOTIFICATION";
export const PERMISSION_NEW_USER_NOTIFICATION = "NEW_USER_NOTIFICATION";
export const PERMISSION_VIEW_USERS = "VIEW_USERS";
export const PERMISSION_CREATE_USERS = "CREATE_USERS";
export const PERMISSION_EDIT_USERS = "EDIT_USERS";
export const PERMISSION_CHANGE_USER = "CHANGE_USER";

export const PERMISSION_CLIENT_PANEL = "CLIENT_PANEL";
export const PERMISSION_REPORTER = "REPORTER";

export const PERMISSION_MODIFY_GROUPS = "MODIFY_GROUPS";
export const PERMISSION_APPLY_PERMISSION_OVERRIDES = "APPLY_PERMISSION_OVERRIDES";

export const PERMISSION_VIEW_EMPLOYEES = "VIEW_EMPLOYEES";
export const PERMISSION_EDIT_EMPLOYEES = "EDIT_EMPLOYEES";
export const PERMISSION_DELETE_EMPLOYEES = "DELETE_EMPLOYEES";
export const PERMISSION_CREATE_TEMPLATES = "CREATE_TEMPLATES";
export const PERMISSION_RECIEVE_CUSTOM_NOTIFICATIONS = "RECIEVE_CUSTOM_NOTIFICATIONS";

export const PCATEGORY_COMPANIES = "COMPANIES";
export const PCATEGORY_ORDERS = "ORDERS";
export const PCATEGORY_REPORTS = "REPORTS";
export const PCATEGORY_OFFICE = "OFFICE";
export const PCATEGORY_USERS = "USERS";
export const PCATEGORY_DESKTOP = "DESKTOP";
export const PCATEGORY_DESKTOP_AND_REPORTS = "DESKTOP_AND_REPORTS";
export const PCATEGORY_HR = "HR";
export const PCATEGORY_CUSTOMER = "CUSTOMER";
export const PCATEGORY_REPORTER = "REPORTER";

export const permissionsInfo = {
    [PERMISSION_VIEW_COMPANIES]: {
        name: "View companies",
        description: 'Search and view companies in the "Companies" tab and on Desktop.',
        category: PCATEGORY_COMPANIES
    },
    [PERMISSION_CREATE_COMPANIES]: {
        name: "Create companies",
        description: 'Create new companies in the "Companies" tab. Active only with "View companies" permission.',
        requirements: [PERMISSION_VIEW_COMPANIES],
        category: PCATEGORY_COMPANIES
    },
    [PERMISSION_EDIT_COMPANIES]: {
        name: "Edit companies",
        description: 'Edit existing companies in the "Companies" tab. Active only with "View companies" permission.',
        requirements: [PERMISSION_VIEW_COMPANIES],
        category: PCATEGORY_COMPANIES
    },
    [PERMISSION_INVITE_CUSTOMERS]: {
        name: "Invite customers",
        description:
            'Invite customers - create new account with access to customer panel. Active only with "View companies" permission.',
        requirements: [PERMISSION_VIEW_COMPANIES],
        category: PCATEGORY_COMPANIES
    },
    [PERMISSION_VIEW_ORDERS]: {
        name: "View orders",
        description: 'View and search existing orders in the "Orders" tab and on Desktop.',
        category: PCATEGORY_ORDERS
    },
    [PERMISSION_QUICK_ORDERS_SEARCHING]: {
        name: "Quick orders searching",
        description: 'Search and filter orders in the orders list tab. Active only with "View orders" permission.',
        requirements: [PERMISSION_VIEW_ORDERS],
        category: PCATEGORY_ORDERS
    },
    [PERMISSION_CHECK_HOURS_DATA]: {
        name: "Check hours data",
        description: "Download hours data as an excel report from the orders list page.",
        requirements: [PERMISSION_VIEW_ORDERS],
        category: PCATEGORY_ORDERS
    },
    [PERMISSION_CREATE_ORDERS]: {
        name: "Create orders",
        description: 'Create new orders in the "Orders" tab. Active only with "View orders" permission.',
        requirements: [PERMISSION_VIEW_ORDERS],
        category: PCATEGORY_ORDERS
    },
    [PERMISSION_VIEW_ORDERS_VERSION_HISTORY]: {
        name: "View orders version history",
        description: "View and list older versions of orders. Active only with “View orders” permission.",
        requirements: [PERMISSION_VIEW_ORDERS],
        category: PCATEGORY_ORDERS
    },
    [PERMISSION_EDIT_ORDERS]: {
        name: "Edit orders",
        description:
            'Edit existing orders in the "Orders" tab. Active only with “View orders” and "View orders version history" permission.',
        requirements: [PERMISSION_VIEW_ORDERS, PERMISSION_VIEW_ORDERS_VERSION_HISTORY],
        category: PCATEGORY_ORDERS
    },
    [PERMISSION_EDIT_ORDERS_CONTACT_PERSON]: {
        name: "Select contact person",
        description:
            "Edit contact person. Active only with “View orders” permission. Identity with choosing the order in Companies --> Add orders",
        requirements: [PERMISSION_VIEW_ORDERS],
        category: PCATEGORY_ORDERS
    },
    [PERMISSION_FINISH_ORDERS]: {
        name: "Finish orders",
        description: `Active only with “View orders” permission.`,
        requirements: [PERMISSION_VIEW_ORDERS],
        category: PCATEGORY_ORDERS
    },
    [PERMISSION_REOPEN_ORDERS]: {
        name: "Reopen orders",
        description: `Active only with “View orders” permission.`,
        requirements: [PERMISSION_VIEW_ORDERS],
        category: PCATEGORY_ORDERS
    },
    [PERMISSION_FINISHED_ORDER_NOTIFICATION]: {
        name: "Finished order notification",
        description: "Recieve tray notifications when somebody finishes an order.",
        category: PCATEGORY_ORDERS
    },
    [PERMISSION_CREATE_REPORTS]: {
        name: "Create reports",
        description: 'Create new reports via the "Reports" tab and on Desktop.',
        category: PCATEGORY_REPORTS
    },
    [PERMISSION_VIEW_REPORTS]: {
        name: "View interior of reports",
        description: "View report contents (employee working hours and product QA).",
        category: PCATEGORY_REPORTS
    },
    [PERMISSION_VIEW_REPORTS_VERSION_HISTORY]: {
        name: "View reports version history",
        description:
            "View and list older versions of reports when viewing an existing report. Active only with “View interior of reports” permission.",
        requirements: [PERMISSION_VIEW_REPORTS],
        category: PCATEGORY_REPORTS
    },
    [PERMISSION_APPROVE_REPORTS]: {
        name: "Approve reports",
        description:
            'Approve and cancel approval of reports in "Reports" and on Desktop. Active only with "View interior of reports" permission.',
        requirements: [PERMISSION_VIEW_REPORTS],
        category: PCATEGORY_REPORTS
    },
    [PERMISSION_EDIT_REPORTS]: {
        name: "Edit reports",
        description: 'Edit existing reports. Active only with "View interior of reports" permission.',
        requirements: [PERMISSION_VIEW_REPORTS],
        category: PCATEGORY_REPORTS
    },
    [PERMISSION_EDIT_APPROVED_REPORTS]: {
        name: "Edit approved reports",
        description: 'Allow editing of approved reports. Active only with "Edit reports" permission.',
        requirements: [PERMISSION_EDIT_REPORTS],
        category: PCATEGORY_REPORTS
    },
    [PERMISSION_EDIT_REPORTS_WITH_PREEXTERNAL]: {
        name: "Edit reports with externals",
        description:
            'Edit internal reports which have an PreExternal sent to Customer or have been hidden. Active only with “Edit reports" permission.',
        requirements: [PERMISSION_EDIT_REPORTS],
        category: PCATEGORY_REPORTS
    },
    [PERMISSION_DELETE_REPORTS]: {
        name: "Delete report",
        description: 'Delete internal reports. Active only with “Edit reports" permission.',
        requirements: [PERMISSION_EDIT_REPORTS],
        category: PCATEGORY_REPORTS
    },
    [PERMISSION_QUICK_REPORTS_SEARCHING]: {
        name: "Quick reports searching",
        description: "Quick reports searching and filtering in the reports list page.",
        requirements: [PERMISSION_CREATE_REPORTS],
        category: PCATEGORY_REPORTS
    },
    [PERMISSION_MASS_REPORTS_APPROVAL]: {
        name: "Mass approval of reports",
        description: "Possibility of approving/cancelling blue reports in bulk and creating/hiding externals in bulk.",
        requirements: [PERMISSION_QUICK_REPORTS_SEARCHING],
        category: PCATEGORY_REPORTS
    },

    [PERMISSION_VIEW_REPORTS_LIST]: {
        name: "View reports list",
        description: 'View the list of unapproved and approved reports in the "Office" tab.',
        category: PCATEGORY_OFFICE
    },
    [PERMISSION_GENERATE_EXCEL_REPORTS]: {
        requirements: [PERMISSION_VIEW_REPORTS_LIST],
        name: "Generate excel reports",
        description:
            'Generate a working hours excel report from the "Office" tab. Active only with "View reports list" permission.',
        category: PCATEGORY_OFFICE
    },
    [PERMISSION_NEW_EMPLOYEE_NOTIFICATION]: {
        name: "New employee notification",
        description:
            "Receive notifications via email and push in system after login when a new employee is created in an internal report.",
        category: PCATEGORY_OFFICE
    },
    [PERMISSION_VIEW_USERS]: {
        name: "View users",
        description: 'List and view users in the "Users" tab.',
        category: PCATEGORY_USERS
    },
    [PERMISSION_CREATE_USERS]: {
        name: "Create users",
        description:
            'Create new users in the "Users" tab. To control from which groups the user is allowed to choose from use the second table.',
        requirements: [PERMISSION_VIEW_USERS],
        category: PCATEGORY_USERS
    },
    [PERMISSION_EDIT_USERS]: {
        name: "Edit users",
        requirements: [PERMISSION_VIEW_USERS],
        description:
            'Edit existing users in the "Users" tab. To control from which groups the user can edit use the second table. Active only with "View users" permission.',
        category: PCATEGORY_USERS
    },
    [PERMISSION_CHANGE_USER]: {
        name: "Change user",
        requirements: [PERMISSION_VIEW_USERS],
        description: 'Log in as another user without knowing their password. Active only with "View users" permission.',
        category: PCATEGORY_USERS
    },
    [PERMISSION_NEW_USER_NOTIFICATION]: {
        name: "New user notification",
        description: "Receive notifications via email and push in system after login when a new user is created.",
        category: PCATEGORY_USERS
    },
    [PERMISSION_APPLY_PERMISSION_OVERRIDES]: {
        name: "Apply permission overrides",
        requirements: [PERMISSION_EDIT_USERS],
        description:
            'Override permissions individually using the right side of the "Edit user" form. Active only with "Edit users" permission.',
        category: PCATEGORY_USERS
    },
    [PERMISSION_MODIFY_GROUPS]: {
        name: "Modify groups",
        description: "Edit groups and adjust their permissions.",
        category: PCATEGORY_USERS
    },
    [PERMISSION_VIEW_AND_EDIT_OPEN_REPORTS]: {
        name: "Show open reports on desktop (white)",
        description: 'Show open reports on desktop (white). You can enter with "View interior of reports" permission.',
        //requirements: [PERMISSION_EDIT_REPORTS],
        category: PCATEGORY_DESKTOP
    },
    [PERMISSION_VIEW_AND_EDIT_REPORTS_TO_BE_APPROVED]: {
        name: "Show reports to be approved on desktop (turquoise)",
        description:
            'Show reports to be approved on desktop (turquoise). You can enter with "View interior of reports" permission.',
        //requirements: [PERMISSION_EDIT_REPORTS],
        category: PCATEGORY_DESKTOP
    },
    [PERMISSION_VIEW_APPROVED_REPORTS]: {
        name: "Show approved reports on desktop (blue)",
        description: "Show approved reports on desktop (blue). You can enter with View interior of reports permission.",
        //requirements: [PERMISSION_EDIT_REPORTS],
        category: PCATEGORY_DESKTOP
    },
    [PERMISSION_VIEW_EXTERNALS]: {
        name: "View externals visible to customer (black)",
        description: "Permission to view externals visible to Customer.",
        category: PCATEGORY_DESKTOP_AND_REPORTS
    },
    [PERMISSION_VIEW_EXTERNALS_LOCAL]: {
        name: "View externals not visible to customer (grey)",
        description: "Permission to view externals not visible to Customer.",
        category: PCATEGORY_DESKTOP_AND_REPORTS
    },
    [PERMISSION_CREATE_EXTERNALS]: {
        name: "Create externals",
        description: "Create externals which may be visible to the Customer or hidden.",
        requirements: [PERMISSION_VIEW_EXTERNALS, PERMISSION_VIEW_EXTERNALS_LOCAL],
        category: PCATEGORY_DESKTOP_AND_REPORTS
    },
    [PERMISSION_SIMULATE_EXTERNALS]: {
        name: "Simulating externals",
        description: "Simulating externals.",
        requirements: [PERMISSION_CREATE_EXTERNALS],
        category: PCATEGORY_DESKTOP_AND_REPORTS
    },
    [PERMISSION_EDIT_EXTERNALS]: {
        name: "Edit externals",
        description: "Edit externals (visibled or hidden for Customer).",
        requirements: [PERMISSION_VIEW_EXTERNALS, PERMISSION_VIEW_EXTERNALS_LOCAL],
        category: PCATEGORY_DESKTOP_AND_REPORTS
    },
    [PERMISSION_VIEW_EMPLOYEES]: {
        name: "View employees",
        description: 'Allows to view employes in the "HR" tab.',
        category: PCATEGORY_HR
    },
    [PERMISSION_EDIT_EMPLOYEES]: {
        name: "Edit employees",
        description: 'Allows to edit employees in the "HR" tab. Requires the "View employees" permission.',
        requirements: [PERMISSION_VIEW_EMPLOYEES],
        category: PCATEGORY_HR
    },
    [PERMISSION_DELETE_EMPLOYEES]: {
        name: "Delete employee",
        description: 'Allows to delte employees in the "HR" tab.',
        requirements: [PERMISSION_EDIT_EMPLOYEES],
        category: PCATEGORY_HR
    },
    [PERMISSION_CREATE_TEMPLATES]: {
        name: "Create and edit templates",
        description: 'Allows to edit and create templates "HR" tab.',
        requirements: [PERMISSION_VIEW_EMPLOYEES],
        category: PCATEGORY_HR
    },
    [PERMISSION_RECIEVE_CUSTOM_NOTIFICATIONS]: {
        name: "Receive custom notifications",
        description: "Allows the user to receive dashboard and email notifciations from custom templates.",
        requirements: [],
        category: PCATEGORY_HR
    },
    [PERMISSION_CLIENT_PANEL]: {
        name: "Client panel",
        solo: true, // cannot be used with any other permission,
        description:
            "Allow access to Customer's panel for only their Orders. No other permissions are allowed when using this permission.",
        category: PCATEGORY_CUSTOMER
    },
    [PERMISSION_REPORTER]: {
        name: "Edit unapproved reports",
        solo: true,
        description: "Employees can only edit unapproved reports - white (open) and turquoise.",
        category: PCATEGORY_REPORTER
    }
};

export const permissionCategoryInfo = {
    [PCATEGORY_COMPANIES]: {
        name: "Companies",
        order: 1 // order in which the categories are sorted
    },
    [PCATEGORY_ORDERS]: {
        name: "Orders",
        order: 2
    },
    [PCATEGORY_REPORTS]: {
        name: "Reports",
        order: 3
    },
    [PCATEGORY_OFFICE]: {
        name: "Office",
        order: 4
    },
    [PCATEGORY_USERS]: {
        name: "Users",
        order: 5
    },
    [PCATEGORY_DESKTOP]: {
        name: "Desktop",
        order: 6
    },
    [PCATEGORY_DESKTOP_AND_REPORTS]: {
        name: "Desktop and reports (externals)",
        order: 7
    },
    [PCATEGORY_HR]: {
        name: "HR",
        order: 8
    },
    [PCATEGORY_CUSTOMER]: {
        name: "Customer",
        order: 9
    },
    [PCATEGORY_REPORTER]: {
        name: "Reporter",
        order: 10
    }
};
