import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { FormGroup, ControlLabel, FormControl, InputGroup } from "react-bootstrap";
import { ShortInputGroup } from "./CurrentReportSortRate";
import styled from "styled-components";

const LinearContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;


@connect(({ externals }) => ({ ...externals.external, ...externals.totals }))
export default class ExternalHeaderBar extends React.Component {
    render() {
        let sortRate = (this.props.totalCheckedParts / this.props.totalWorkingHours).toFixed(2);
        if (isNaN(sortRate)) {
            sortRate = "0";
        }
        return (
            <LinearContainer className="bottom-divider">
                <FormGroup className="form-inline">
                    <ControlLabel>Control date</ControlLabel>
                    <FormControl disabled value={moment(this.props.controlDate).format("YYYY-MM-DD")} className="input-number" />
                </FormGroup>

                <FormGroup className="form-inline">
                    <ControlLabel>Order</ControlLabel>
                    <FormControl disabled value={this.props.orderNumber} className="input-number" />
                </FormGroup>

                <FormGroup className="form-inline">
                    <ControlLabel>Current average sort rate</ControlLabel>
                    <ShortInputGroup>
                        <FormControl disabled value={sortRate} />
                        <InputGroup.Addon>pcs / h</InputGroup.Addon>
                    </ShortInputGroup>
                </FormGroup>
            </LinearContainer>
        );
    }
}
