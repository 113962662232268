import React from "react";
import styled, { css } from "styled-components";
import { Transition } from "react-transition-group";

const DropdownStyle = styled.div`
    position: absolute;

    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    z-index: 1050;
    box-sizing: border-box;
    font-size: 14px;
    font-variant: normal;
    background-color: #fff;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    ${props =>
        props.select &&
        css`
            top: 36px;
            width: 100%;
            min-height: 10px;
        `}
    ${props =>
        props.dropup &&
        css`
            top: initial;
            bottom: calc(100% + 4px);
        `}
    ${props =>
        props.calendar &&
        css`
            margin-top: 15px;
            padding: 25px;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
            border-radius: 3px;
            @media (max-width: 1250px) {
                margin-left: -90px;
            }
            @media (max-width: 1150px) {
                margin-left: -180px;
            }
            @media (max-width: 999px) {
                margin-left: 0;
            }
        `}
    transition: all 0.3s ease-in-out;
    opacity: 0;
    ${props => {
        switch (props.state) {
            case "exited":
                return css`
                    opacity: 0;
                `;
            case "exiting":
                return css`
                    opacity: 0;
                `;
            case "entering":
                return css`
                    opacity: 1;
                `;
            case "entered":
                return css`
                    opacity: 1;
                `;
        }
    }}
`;

export default ({ isOpen, ...props }) => {
    return (
        <Transition
            in={isOpen}
            timeout={300}
            onEnter={node => node.offsetHeight /* access the offset height to reflow the browser */}
            mountOnEnter={true}
            unmountOnExit={true}
        >
            {state => <DropdownStyle {...props} state={state} />}
        </Transition>
    );
};
