import { Tab, Tabs } from "react-bootstrap";
import React from "react";
import policy from "../policy";
import styled from "styled-components";

const Paragraph = styled.div`
    padding-left: 10px;
    padding-right: 10px;
`;

export default class PolicyContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: "EN",
            policy: policy.data
        };
    }

    render() {
        return (
            <Tabs activeKey={this.state.key} onSelect={key => this.setState({ key })}>
                {this.state.policy.map(p => {
                    return (
                        <Tab eventKey={p.title} title={p.title}>
                            <Paragraph>
                                <h3>{p.header}</h3>
                                {p.paragraphs.map(p => {
                                    return (<p>{p}</p>);
                                })}
                            </Paragraph>
                        </Tab>
                    );
                })}
            </Tabs>
        );
    }
}