import { ORDERS_CHANGED } from "../actions/orderActions";

const initialState = {
    companies: []
};

export default function passwordReset(state = initialState, action) {
    switch (action.type) {
        case ORDERS_CHANGED:
            return {
                ...state,
                companies: action.companies
            };
        default:
            return state;
    }
}