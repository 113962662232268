import React from "react";
import { Panel, Button, Clearfix } from "react-bootstrap";
import styled from "styled-components";
import theme from "../../theme";
import { connect } from "react-redux";
import { submitUserError } from "../../actions/diagnosticsActions";

const StyledPanel = styled(Panel)`
    margin-top: 50px;
    color: ${theme.secondaryText};
`;

const StyledBody = styled(Panel.Body)`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
`;

const Spacer = styled.div`
    flex-grow: 999;
`;

@connect(
    null,
    dispatch => ({
        submitUserError: _ => dispatch(submitUserError())
    })
)
export default class DashboardFooter extends React.Component {
    render() {
        return (
            <StyledPanel>
                <StyledBody>
                    <div>{process.env.RES_VERSION}</div>
                    <Spacer />
                    <div>{new Date().getFullYear()} © RES. All rights reserved.</div>
                    <Spacer />
                    <Button onClick={this.props.submitUserError} bsStyle="link">
                        Report an error
                    </Button>
                </StyledBody>
            </StyledPanel>
        );
    }
}
