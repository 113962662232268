import { STATE_UNKNOWN, AUTH_STATE_CHANGED, USER_CHANGED } from "../actions/authActions";

const initialState = {
    authState: STATE_UNKNOWN,
    user: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case AUTH_STATE_CHANGED:
            return {
                ...state,
                authState: action.payload
            };
        case USER_CHANGED:
            return {
                ...state,
                user: action.payload
            };
        default:
            return state;
    }
}
