import styled, { css } from "styled-components";
import { Table } from "react-bootstrap";

export const TableBody = styled(Table)`
    width: auto;
    border: none !important;
    padding: 10px;
`;

export const Container = styled.div`
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.12) 0px 3px 1px -2px;
    display: flex;
    background: #fff;
    ${p =>
        p.alignWithMatComponents &&
        css`
            padding: 20px;
            margin: 5px 20px;
        `}
    ${p =>
        p.noFlex &&
        css`
            display: block;
        `}
`;

export const TableCustom = styled(Table)`
    border: none !important;
    padding: 0;
`;
