import React from "react";
import { Panel, Table } from "react-bootstrap";
import { formatDateTime } from "../../../utils";
import IconButton from "../../../components/common/IconButton";
import { connect } from "react-redux";
import { markNotificationAsRead } from "../../../actions/notificationsActions";
import HeaderWithButton from "./HeaderWithButton";

@connect(
    null,
    (dispatch, { notification }) => ({
        markedAsRead: _ => dispatch(markNotificationAsRead(notification._id))
    })
)
export default class NewUserNotification extends React.Component {
    render() {
        let data = this.props.notification.data;
        return (
            <Panel>
                <HeaderWithButton notification={this.props.notification}>New user created</HeaderWithButton>
                <Table>
                    <tbody>
                        <tr>
                            <th>Display name</th>
                            <td>{data.displayName}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{data.email}</td>
                        </tr>
                        <tr>
                            <th>Added on</th>
                            <td>{formatDateTime(data.creationDate)}</td>
                        </tr>
                        <tr>
                            <th>Added by</th>
                            <td>{data.creatorDisplayName}</td>
                        </tr>
                        <tr>
                            <th>Group</th>
                            <td>{data.groupName}</td>
                        </tr>
                    </tbody>
                </Table>
            </Panel>
        );
    }
}
