import { parse as parseQueryString, stringify as stringifyQueryString } from "querystring";

/**
 * Parses the query string even with a leading ? character.
 */
export function parseRawQueryString(qs) {
    return parseQueryString(qs.replace(/^\?/g, ""));
}

/**
 * Parses a query string, adds custom params and returns a new one (without ?).
 */
export function setQueryParams(qs, params = {}) {
    return stringifyQueryString({
        ...parseRawQueryString(qs),
        ...params
    });
}
