import { createStore, compose, applyMiddleware } from "redux";
import rootReducer from "./reducers/rootReducer";
import history from "./history";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import diagnosticsEnhancer from "./enhancers/diagnosticsEnhancer";

// eslint-disable-next-line no-console
console.log("Initializing store");

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // when redux devtools is available use its composeEnhancers function over the deafult one
const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(routerMiddleware(history), thunk.withExtraArgument({ getStore: _ => store })),
        diagnosticsEnhancer()
    )
);
if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-console
    console.log("Exposing store at window.store");
    window.store = store;
}
export default store;
