import { makeActionCreator } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import { toast } from "react-toastify";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_ERROR = "STATE_ERROR";

export const COMPANY_SEARCH_CONTENT_CHANGED = "COMPANIES.COMPANIES_LIST.COMPANY_SEARCH_CONTENT_CHANGED";
export const SEARCH_STATE_CHANGED = "COMPANIES.COMPANIES_LIST.SEARCH_STATE_CHANGED";
export const COMPANY_HEADERS_CHANGED = "COMPANIES.COMPANIES_LIST.COMPANY_HEADERS_CHANGED"; // list of displayed companies changed
export const ERROR_CHANGED = "COMPANIES.COMPANIES_LIST.ERROR_CHANGED";

export const companySearchContentChanged = makeActionCreator(COMPANY_SEARCH_CONTENT_CHANGED, "payload");
export const searchStateChanged = makeActionCreator(SEARCH_STATE_CHANGED, "payload");
export const companyHeadersChanged = makeActionCreator(COMPANY_HEADERS_CHANGED, "payload");
export const errorChanged = makeActionCreator(ERROR_CHANGED, "payload");

export function companiesSearched(query) {
    return async dispatch => {
        dispatch(companySearchContentChanged(query));
        // TODO: add debouncing
        await dispatch(loadCompaniesList());
    };
}

export function loadCompaniesList() {
    return async (dispatch, getState) => {
        try {
            let query = getState().companies.companiesList.companySearchContent;
            dispatch(searchStateChanged(STATE_LOADING));
            let resp = await dispatch(apiFetch(`/api/companies/search?query=${encodeURIComponent(query)}`));
            let data = await resp.json();
            if (!Array.isArray(data)) {
                throw new Error("Data returned by API is not an object!");
            }
            dispatch(searchStateChanged(STATE_DEFAULT));
            dispatch(companyHeadersChanged(data));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(searchStateChanged(STATE_ERROR));
            dispatch(errorChanged(e.message || "Unknown error"));
        }
    };
}
