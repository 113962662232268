import {
    DATA_LOADED,
    IS_LOADING_CHANGED,
    SELECTED_CHANGED,
    SELECT_DESELECT_ALL,
    STATUS_CHANGED,
    MASS_OPERATION_LOADING_CHANGED
} from "../actions/resultsActions";

const makeInitialState = _ => ({
    isLoading: true,
    rows: [],
    totalRows: 0,
    enabledColumns: [],
    massOperationLoading: false
});

export default function results(state = makeInitialState(), action) {
    switch (action.type) {
        case IS_LOADING_CHANGED:
            return {
                ...state,
                isLoading: action.payload
            };
        case DATA_LOADED:
            return {
                ...state,
                isLoading: false,
                totalRows: action.totalRows,
                enabledColumns: action.enabledColumns,
                rows: action.payload
            };
        case SELECTED_CHANGED:
            return {
                ...state,
                rows: state.rows.map(r => (r._id === action.rowId ? { ...r, selected: action.payload } : r))
            };
        case SELECT_DESELECT_ALL:
            return {
                ...state,
                rows: state.rows.map(r => (r.selected !== action.payload ? { ...r, selected: action.payload } : r))
            };
        case STATUS_CHANGED:
            return {
                ...state,
                rows: state.rows.map(r => (r._id === action.rowId ? { ...r, status: action.payload } : r))
            };
        case MASS_OPERATION_LOADING_CHANGED:
            return {
                ...state,
                massOperationLoading: action.payload
            };
        default:
            return state;
    }
}
