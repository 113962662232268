import React from "react";
import styled, { css } from "styled-components";

const rightArrowSvg = (
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
        <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
    </svg>
);

const leftArrowSvg = (
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
        <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
    </svg>
);

const Button = styled.button`
    position: relative;
    appearance: none;
    background: transparent;
    border: none;
    outline: none;
    width: 24px;
    height: 24px;
    svg {
        width: 24px;
        height: 24px;
        fill: currentColor;
    }
    &:before {
        display: block;
        position: absolute;
        content: " ";
        top: -7px;
        left: -2px;
        width: 40px;
        height: 40px;
        background: black;
        border-radius: 9999px;

        opacity: 0;
        pointer-events: none;
        transition: 200ms;
    }
    &:hover:before {
        opacity: 0.1;
    }
    &:disabled {
        &:hover:before {
            opacity: 0;
        }
        svg {
            opacity: 0.4;
        }
    }
`;

export default ({ left, ...props }) => <Button {...props}>{left ? leftArrowSvg : rightArrowSvg}</Button>;
