import React from "react";
import { Modal, Button } from "react-bootstrap";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { showPleaseLoginModalChanged } from "../actions/apiActions";
import WarningTriangle from "../components/common/WarningTriangle";
import { encodeParams } from "../utils";

@connect(
    ({ api }) => ({
        showPleaseLoginModal: api.showPleaseLoginModal
    }),
    dispatch => ({
        push: p => dispatch(push(p)),
        showPleaseLoginModalChanged: p => dispatch(showPleaseLoginModalChanged(p))
    })
)
export default class PleaseLoginModal extends React.Component {
    goToLoginPage = _ => {
        this.props.push({ pathname: "/login", search: encodeParams`?back=${window.location.pathname + window.location.search + window.location.hash}` });
        this.props.showPleaseLoginModalChanged(false);
    }
    render() {
        return (
            <Modal show={this.props.showPleaseLoginModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <WarningTriangle />
                        You are not logged in!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.goToLoginPage}>Login</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
