import React from "react";
import { FormControl, FormGroup, Panel } from "react-bootstrap";
import Logo from "../../components/common/Logo";
import { connect } from "react-redux";
import { emailChanged, resetPassword, STATE_LOADING, STATE_ERROR } from "../actions/passwordResetActions";
import LoadableButton from "../../components/common/LoadableButton";
import SuccessModal from "./SuccessModal";
import NarrowContainer from "../../components/common/containers/NarrowContainer";

@connect(
    ({ passwordReset }) => {
        return {
            email: passwordReset.email,
            errorMessage: passwordReset.errorMessage,
            isLoading: passwordReset.passwordResetState === STATE_LOADING,
            isError: passwordReset.passwordResetState === STATE_ERROR
        };
    },
    dispatch => {
        return {
            emailChanged: ev => dispatch(emailChanged(ev.target.value)),
            onResetPasswordClicked: _ => dispatch(resetPassword())
        };
    }
)
export default class PasswordResetPage extends React.Component {
    render() {
        let errorPanel;
        if (this.props.isError) {
            errorPanel = (
                <Panel header="Error" bsStyle="danger">
                    {this.props.errorMessage}
                </Panel>
            );
        }
        return (
            <div className="center-vertically">
                <NarrowContainer>
                    <Logo />
                    {errorPanel}
                    <FormGroup>
                        <FormControl
                            type="email"
                            placeholder="Enter email"
                            value={this.props.email}
                            onChange={this.props.emailChanged}
                        />
                    </FormGroup>
                    <LoadableButton
                        block
                        bsStyle="primary"
                        onClick={this.props.onResetPasswordClicked}
                        isLoading={this.props.isLoading}
                    >
                        Reset password
                    </LoadableButton>
                    <SuccessModal />
                </NarrowContainer>
            </div>
        );
    }
}
