import React from "react";
import MatButton from "../../components/common/Mat/MatButton";
import { connect } from "react-redux";
import { finishSelectedOrders } from "../actions/resultsActions";
import styled, { css } from "styled-components";
import StyledTh from "../../components/common/AutoTable/StyledTh";


export default connect(state => state.ordersList.results)(({ rows, dispatch, model }) => {
    const hasSelectedOrders = rows.some(row => !row.finished && row.selected);
    if (hasSelectedOrders) {
        return (
            <StyledTh model={model}>
                <MatButton slim orderFinishColor onClick={() => dispatch(finishSelectedOrders())}>
                    Finish
                </MatButton>
            </StyledTh>
        );
    }
    return <StyledTh model={model}>Finish</StyledTh>;
});
