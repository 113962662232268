import { push } from "react-router-redux";
import UnauthorizedError from "../errors/UnauthorizedError";
import store from "../store";
import { makeActionCreator } from "../utils";
import { apiFetch } from "./apiActions";
import {
    desktopsChanged,
    desktopsCountChanged,
    desktopsDaysRangeChanged
} from "../DesktopsPage/actions/desktopsActions";
import { PERMISSION_CLIENT_PANEL, PERMISSION_REPORTER } from "../../../common/src/permissions";

export const STATE_UNKNOWN = "STATE_UNKNOWN";
export const STATE_ERROR = "STATE_ERROR";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_LOGGED_IN = "STATE_LOGGED_IN";
export const STATE_NOT_LOGGED_IN = "STATE_NOT_LOGGED_IN";

export const AUTH_STATE_CHANGED = "AUTH.AUTH_STATE_CHANGED";
export const USER_CHANGED = "AUTH.USER_CHANGED";

export const authStateChanged = makeActionCreator(AUTH_STATE_CHANGED, "payload");
export const userChanged = makeActionCreator(USER_CHANGED, "payload");

export function authCheckRequested() {
    return async dispatch => {
        dispatch(authStateChanged(STATE_LOADING));
        try {
            let resp = await dispatch(apiFetch("/api/auth/current-user"));
            let data = await resp.json();
            dispatch(userChanged(data));
            dispatch(authStateChanged(STATE_LOGGED_IN));
        } catch (e) {
            if (e instanceof UnauthorizedError) {
                dispatch(authStateChanged(STATE_NOT_LOGGED_IN));
                return;
            }
            dispatch(authStateChanged(STATE_ERROR));
            // eslint-disable-next-line no-console
            console.error("Auth check failed", e);
        }
    };
}
export function detectUserHome() {
    return async (dispatch, getState) => {
        await dispatch(authCheckRequested())
    
        if (await dispatch(checkUserPermission(PERMISSION_CLIENT_PANEL))) {
            dispatch(push("/customer/orders/list"));
        } else if (await dispatch(checkUserPermission(PERMISSION_REPORTER))) {
            dispatch(push("/dashboard/reporter-desktops"));
        } else {
            dispatch(push("/dashboard/desktops"));
        }
    };
}

export function logoutRequested() {
    return async dispatch => {
        dispatch(authStateChanged(STATE_LOADING));
        try {
            let resp = await dispatch(
                apiFetch("/api/auth/logout", {
                    method: "POST"
                })
            );
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message);
            }
            dispatch(userChanged(null));
            dispatch(desktopsCountChanged(null));
            dispatch(desktopsDaysRangeChanged(null));
            dispatch(desktopsChanged([]));
            dispatch(authStateChanged(STATE_NOT_LOGGED_IN));
            dispatch(push("/login"));
        } catch (e) {
            dispatch(authStateChanged(STATE_ERROR));
            // eslint-disable-next-line no-console
            console.error("Logout failed", e);
        }
    };
}

/**
 * Waits for user data to load when necessary and checks if user has permission.
 * @param {*} permissionName
 */
export function checkUserPermission(permissionName) {
    return async _ => {
        let checkLoggedIn = _ => store.getState().auth.authState === STATE_LOGGED_IN;
        let checkPerm = _ => {
            let user = store.getState().auth.user;
            let override = user.permissionOverrides.find(po => po.permission === permissionName);
            if (override) {
                return override.allow;
            }
            return user.group.permissions.indexOf(permissionName) !== -1;
        };
        if (checkLoggedIn()) {
            await new Promise(res => setTimeout(res, 0));
            return checkPerm();
        }
        return await new Promise(res => {
            let unsubscribe = store.subscribe(_ => {
                if (checkLoggedIn()) {
                    unsubscribe();
                    res(checkPerm());
                }
            });
        });
    };
}
