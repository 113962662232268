import React from "react";
import StyledIcon from "./StyledIcon";

export default ({ isLoading, showContents, children }) => {
    if (isLoading) {
        return (
            <div>
                <StyledIcon spin center size="big" />
            </div>
        );
    }
    if (showContents) {
        return children;
    }
    return null;
};
