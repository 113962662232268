/**
 * Checks if date of birth is a valid leading PESEL number.
 * @param {Object} row
 */
export default function validateDateOfBirth (row) {
  let dateOfBirthValid;
  if (!row.employeeData) {
    dateOfBirthValid = true;
  } else {
    if (row.employeeData.dateOfBirth.length !== 6) {
      dateOfBirthValid = false;
    } else {
      // allow string which is no valid date format YYMMDD (can be random numbers)
      const onlyNumbers = /^[0-9]{6}$/.test(row.employeeData.dateOfBirth.toString());
      dateOfBirthValid = row.employeeData.dateOfBirth.toString().length === 6 && onlyNumbers;
    }
  }
  return dateOfBirthValid;
}
