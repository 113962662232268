import {
    STATE_DEFAULT,
    STATE_CHANGED,
    ERROR_CHANGED,
    CONTACT_PERSON_MODAL_OPEN_CHANGED,
    CONTACT_PERSON_MODAL_NAME_CHANGED,
    CONTACT_PERSON_MODAL_EMPLOYEE_ID_CHANGED,
    CONTACT_PERSON_MODAL_ORDERS_CHANGED,
    CONTACT_PERSON_MODAL_ORDERS_EDITED_CHANGED,
    CONTACT_PERSON_MODAL_EMAIL_CHANGED,
    CONTACT_PERSON_MODAL_EDITED_ORDERS_ADD,
    CONTACT_PERSON_MODAL_EDITED_ORDERS_REMOVE,
    CONTACT_PERSON_MODAL_EDITED_ORDERS_REMOVE_ALL
} from "../actions/contactPersonActions";

export const initialState = {
    contactPersonModalOpen: false,
    name: null,
    employeeId: null,
    orders: [],
    email: null,
    editedOrders: [],
    state: STATE_DEFAULT,
    error: null
};

export default function contactPerson(state = initialState, action) {
    switch (action.type) {
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case ERROR_CHANGED:
            return {
                ...state,
                error: action.payload
            };
        case CONTACT_PERSON_MODAL_OPEN_CHANGED:
            return {
                ...state,
                contactPersonModalOpen: action.payload
            };
        case CONTACT_PERSON_MODAL_NAME_CHANGED:
            return {
                ...state,
                name: action.payload
            };
        case CONTACT_PERSON_MODAL_EMPLOYEE_ID_CHANGED:
            return {
                ...state,
                employeeId: action.payload
            };
        case CONTACT_PERSON_MODAL_ORDERS_CHANGED:
            return {
                ...state,
                orders: action.payload
            };
        case CONTACT_PERSON_MODAL_ORDERS_EDITED_CHANGED:
            return {
                ...state,
                orders: state.orders.map((o, oi) => {
                    if(action.payload.index === oi) {
                        o.edited = action.payload.edited;
                    }
                    return o;
                })
            };
        case CONTACT_PERSON_MODAL_EMAIL_CHANGED:
            return {
                ...state,
                email: action.payload
            };
        case CONTACT_PERSON_MODAL_EDITED_ORDERS_ADD:
            return {
                ...state,
                editedOrders: [
                    ...state.editedOrders,
                    {
                        orderId: action.payload.orderId,
                        employeeId: action.payload.employeeId,
                        contactPerson: action.payload.contactPerson,
                        index: action.payload.index
                    }
                ]
            };
        case CONTACT_PERSON_MODAL_EDITED_ORDERS_REMOVE:
            return {
                ...state,
                editedOrders: state.editedOrders.filter((_, oi) => action.payload.index !== oi)
            };
        case CONTACT_PERSON_MODAL_EDITED_ORDERS_REMOVE_ALL:
            return {
                ...state,
                editedOrders: []
            };
        default:
            return state;
    }
}
