import { makeActionCreator } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import { stateChanged, errorChanged, STATE_ERROR } from "./reportActions";

export const EXTRA_FIELD_ADDED = "REPORTS.PROBLEM_DESCRIPTION.EXTRA_FIELD_ADDED";
export const EXTRA_FIELD_CHANGED = "REPORTS.PROBLEM_DESCRIPTION.EXTRA_FIELD_CHANGED";
export const EXTRA_FIELD_REMOVED = "REPORTS.PROBLEM_DESCRIPTION.EXTRA_FIELD_REMOVED";
export const PROBLEM_DESCRIPTION_CONTENT_CHANGED = "REPORTS.PROBLEM_DESCRIPTION.PROBLEM_DESCRIPTION_CONTENT_CHANGED";
export const PROBLEM_DESCRIPTION_CHANGED = "REPORTS.PROBLEM_DESCRIPTION.PROBLEM_DESCRIPTION_CHANGED";
export const IS_LOADING_CHANGED = "REPORTS.PROBLEM_DESCRIPTION.IS_LOADING_CHANGED";

export const extraFieldAdded = makeActionCreator(EXTRA_FIELD_ADDED);
export const extraFieldChanged = makeActionCreator(EXTRA_FIELD_CHANGED, "fieldIndex", "payload");
export const extraFieldRemoved = makeActionCreator(EXTRA_FIELD_REMOVED, "fieldIndex");
export const problemDescriptionChanged = makeActionCreator(PROBLEM_DESCRIPTION_CHANGED, "payload");
export const problemDescriptionContentChanged = makeActionCreator(PROBLEM_DESCRIPTION_CONTENT_CHANGED, "payload");
export const isLoadingChanged = makeActionCreator(IS_LOADING_CHANGED, "payload");

export function loadProblemDescriptionFromOrder(orderId) {
    return async dispatch => {
        try {
            dispatch(isLoadingChanged(true));
            let resp = await dispatch(apiFetch(`/api/orders/${encodeURIComponent(orderId)}/problemDescription`));
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message);
            }
            dispatch(problemDescriptionChanged(data));
        } catch (e) {
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        } finally {
            dispatch(isLoadingChanged(false));
        }
    };
}

export function saveProblemDescriptionToConnectedOrder() {
    return async (dispatch, getState) => {
        let problemDescription = getState().reports.problemDescription;
        if (!problemDescription.modified) return;
        let connectedOrderId = getState().reports.report.connectedOrderId;
        if(!connectedOrderId) return;
        let resp = await dispatch(apiFetch(`/api/orders/${encodeURIComponent(connectedOrderId)}/problemDescription`, {
            method: "PUT",
            body: JSON.stringify(problemDescription)
        }));

        if (resp.status !== 200) {
            throw new Error((await resp.json()).message);
        }
    };
}
