import { combineReducers } from "redux";
import usersList from "./usersList";
import editUser from "./editUser";
import groups from "./groups";

export default combineReducers({
    usersList,
    editUser,
    groups
});
