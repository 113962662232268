import styled, { css } from "styled-components";

const StyledTh = styled.th`
    ${p =>
        p.model &&
        p.model.stickyHeader &&
        css`
            position: sticky;
            top: ${p => p.model.sitckyTopMargin || "60px"};
            background: white;
            z-index: 99;
        `}
`;

export default StyledTh;
