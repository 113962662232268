import React from "react";
import styled, { css } from "styled-components";

const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];
const WEEKDAYS = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

const CalendarHeader = styled.div`
    color: #484848;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
`;

const OffsetTd = styled.td`
    border: none !important;
`;

const DayTd = styled.td`
    ${props =>
        props.highlightedBoundary &&
        css`
            color: rgb(255, 255, 255) !important;
            background: rgb(68, 130, 255) !important;
            border-width: 1px !important;
            border-style: double !important;
            border-color: rgb(68, 130, 255) !important;
        `}
    ${props =>
        props.highlightedRange &&
        !props.highlightedBoundary &&
        css`
            color: rgb(255, 255, 255) !important;
            background: rgb(99, 161, 255) !important;
            border-width: 1px !important;
            border-style: double !important;
            border-color: rgb(99, 161, 255) !important;
        `}
`;

const MonthTable = styled.table`
    border-radius: 0;
    border: none !important;
    border-collapse: collapse !important;
    td,
    th {
        text-align: center;
    }
    ${DayTd}, ${OffsetTd} {
        box-sizing: border-box;
        font-size: 14px;
        width: 39px;
        height: 39px;
        border: 1px solid #e4e7e7;
        color: #484848;
        background: #fff;
        &:not(${OffsetTd}) {
            cursor: pointer;
            &:hover {
                background: #e4e7e7;
                border: 1px solid #e4e7e7;
                color: inherit;
            }
        }
    }
    th {
        font-size: 80%;
        color: rgb(117, 117, 117);
        font-weight: initial;
        padding-bottom: 3px;
    }
`;

const Wrapper = styled.div`
    &:first-child {
        margin-right: 20px;
    }
`;

export default ({ month, year, dayClicked, dateRange, ...rest }) => {
    const startOfMonth = new Date(year, month, 1, 0, 0, 0, 0);
    let startOffset = (startOfMonth.getDay() + 6) % 7; // how many empty days before the month starts
    if (startOffset === 7) {
        startOffset = 0;
    }
    const lengthOfMonth = new Date(year, month + 1, 0, 0, 0, 0, 0).getDate();
    let endOffset = 7 - ((startOffset + lengthOfMonth) % 7);
    if (endOffset === 7) {
        endOffset = 0;
    }
    const rows = [[]];
    let counter = 0;
    for (let i = 0; i < startOffset; i++) {
        rows[rows.length - 1].push(<OffsetTd key={i + "_foffset"} />);
        counter++;
    }
    for (let i = 0; i < lengthOfMonth; i++) {
        if (counter % 7 === 0) {
            rows.push([]);
        }
        counter++;
        const dayDate = new Date(year, month, i + 1, 0, 0, 0, 0);

        const highlightedBoundary =
            (dateRange.start && dateRange.start.getTime() === dayDate.getTime()) ||
            (dateRange.end && dateRange.end.getTime() === dayDate.getTime());
        const highlightedRange =
            dateRange.start &&
            dateRange.end &&
            dateRange.start.getTime() < dayDate.getTime() &&
            dateRange.end.getTime() > dayDate.getTime();
        rows[rows.length - 1].push(
            <DayTd
                key={i + "_m"}
                onClick={() => dayClicked(dayDate)}
                highlightedBoundary={highlightedBoundary}
                highlightedRange={highlightedRange}
            >
                {i + 1}
            </DayTd>
        );
    }
    for (let i = 0; i < endOffset; i++) {
        rows[rows.length - 1].push(<OffsetTd key={i + "_eoffset"} />);
        counter++;
    }
    return (
        <Wrapper {...rest}>
            <CalendarHeader>
                {MONTHS[month]} {year}
            </CalendarHeader>
            {false && (
                /* debugging helper */
                <div>
                    lengthOfMonth = {lengthOfMonth}
                    <br />
                    endOffset = {endOffset}
                    <br />
                    startOffset = {startOffset}
                </div>
            )}
            <MonthTable>
                <thead>
                    <tr>
                        {WEEKDAYS.map(wd => (
                            <th key={wd}>{wd}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((r, i1) => (
                        <tr key={i1}>{r}</tr>
                    ))}
                </tbody>
            </MonthTable>
        </Wrapper>
    );
};
