import React from "react";
import {
    HR_FIELD_TYPE_TEXT,
    HR_FIELD_TYPE_CHECKBOX,
    HR_FIELD_TYPE_TIME_FROM,
    HR_FIELD_TYPE_TIME_TO,
    HR_FIELD_TYPE_EMAIL_NOTIFICATION,
    HR_FIELD_TYPE_TRAY_NOTIFICATION
} from "../../../../../common/src/hrTemplates";
import { FormControl, FormGroup } from "react-bootstrap";
import styled from "styled-components";
import { connect } from "react-redux";
import { fieldCommentsChanged, fieldDaysEarlierChanged } from "../../actions/editTemplateActions";

const DaysEarlierCell = styled.td`
    width: 120px;
    padding-left: 15px;
`;

@connect(
    null,
    (dispatch, props) => ({
        fieldCommentsChanged: ev => dispatch(fieldCommentsChanged(props.fieldIndex, ev.target.value)),
        fieldDaysEarlierChanged: ev => dispatch(fieldDaysEarlierChanged(props.fieldIndex, ev.target.value))
    })
)
export default class FieldParamCells extends React.Component {
    render() {
        switch (this.props.field.type) {
            case HR_FIELD_TYPE_TEXT:
                return [
                    <td key={1}>
                        <FormControl
                            placeholder="Comments"
                            value={this.props.field.comments}
                            onChange={this.props.fieldCommentsChanged}
                        />
                    </td>,
                    <td key={2} />
                ];
            case HR_FIELD_TYPE_CHECKBOX:
                return [
                    <td key={1}>
                        <FormControl
                            placeholder="Comments to checkbox"
                            value={this.props.field.comments}
                            onChange={this.props.fieldCommentsChanged}
                        />
                    </td>,
                    <td key={2} />
                ];
            case HR_FIELD_TYPE_TIME_FROM:
            case HR_FIELD_TYPE_TIME_TO:
                return [<td key={1}>Show calendar to set</td>, <td key={2} />];
            case HR_FIELD_TYPE_EMAIL_NOTIFICATION:
            case HR_FIELD_TYPE_TRAY_NOTIFICATION:
                return [
                    <td key={1}>
                        <FormControl
                            placeholder="What to notify?"
                            value={this.props.field.comments}
                            onChange={this.props.fieldCommentsChanged}
                        />
                    </td>,
                    <DaysEarlierCell key={2}>
                        <FormControl
                            placeholder="Days earlier"
                            type="number"
                            value={this.props.field.daysEarlier}
                            onChange={this.props.fieldDaysEarlierChanged}
                        />
                    </DaysEarlierCell>
                ];
            default:
                return [<td key={1} />, <td key={2} />];
        }
    }
}
