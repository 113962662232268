import React from "react";
import FormFieldsList from "./FormFieldsList";

export default class TemplateForm extends React.Component {
    render() {
        return (
            <div>
                <FormFieldsList
                    fields={this.props.template.fields}
                    valueSelector={this.props.valueSelector}
                    onChangeSelector={this.props.onChangeSelector}
                    autocompleteDataSelector={this.props.autocompleteDataSelector}
                />
            </div>
        );
    }
}
