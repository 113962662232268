import React from "react";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";

export default ({ field, value, onChange }) => (
    <FormGroup>
        <FormGroup>
            <div className="flex-row">
                <input
                    type="checkbox"
                    checked={value}
                    onChange={ev => onChange(ev.target.checked)}
                    className="checkbox-big"
                />
                <span className="inline-label">{field.comments}</span>
            </div>
        </FormGroup>
    </FormGroup>
);
