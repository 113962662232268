import {
    NOTIFICATIONS_LOADED,
    NOTIFICATIONS_ADDED,
    LAST_REFRESH_SET,
    REFRESH_INTERVAL_STARTED_SET,
    NOTIFICATION_SET_READ
} from "../actions/notificationsActions";
import { updateRow } from "../utils";

export const initialState = {
    notifications: [],
    lastRefresh: null,
    refreshIntervalStarted: false
};

export default function notifications(state = initialState, action) {
    switch (action.type) {
        case NOTIFICATIONS_LOADED:
            return {
                ...state,
                notifications: action.payload
            };
        case NOTIFICATIONS_ADDED:
            return {
                ...state,
                notifications: [...state.notifications, ...action.payload]
            };
        case LAST_REFRESH_SET:
            return {
                ...state,
                lastRefresh: action.payload
            };
        case REFRESH_INTERVAL_STARTED_SET:
            return {
                ...state,
                refreshIntervalStarted: action.payload
            };
        case NOTIFICATION_SET_READ:
            return {
                ...state,
                notifications: state.notifications.map(n => (n._id === action.notificationId) ? { ...n, read: true } : n)
            };
        default:
            return state;
    }
}
