import {
    COMPANY_ERROR_CHANGED,
    COMPANY_STATE_CHANGED,
    COMPANY_ID_CHANGED,
    COMPANY_NAME_CHANGED,
    COMPANY_ADDRESS1_CHANGED,
    COMPANY_ADDRESS2_CHANGED,
    COMPANY_EMPLOYEE_ADDED,
    COMPANY_EMPLOYEE_REMOVED,
    COMPANY_EMPLOYEE_NAME_CHANGED,
    COMPANY_EMPLOYEE_EMAIL_CHANGED,
    COMPANY_EMPLOYEE_PHONE_NUMBER_CHANGED,
    COMPANY_EMPLOYEE_ACCESS_TO_PANEL_CHANGED,
    COMPANY_EMPLOYEE_UNIQUE_CHANGED,
    COMPANY_EMPLOYEE_EXIST_IN_EMPLOYEES_ID_CHANGED,
    STATE_NO_COMPANY,
    COMPANY_EMPLOYEES_CHANGED,
    COMPANY_TO_DELETE_ACCESS_ADDED,
    COMPANY_TO_DELETE_ACCESS_REMOVED,
    COMPANY_EMPLOYEE_ACCOUNT_STATUS_CHANGED,
    COMPANY_RESET,
    COMPANY_UNOFFICIAL_NAME_CHANGED,
    COMPANY_COUNTRY_CHANGED,
    COMPANY_VAT_TAX_NUMBER_CHANGED
} from "../actions/companyActions";
import { updateRow } from "../../utils";

const initialState = {
    state: STATE_NO_COMPANY,
    error: null,
    id: 0,
    name: "",
    address1: "",
    address2: "",
    country: "",
    vatTaxNumber: "",
    unofficialName: "",
    employees: [],
    toDeleteAccess: []
};

export default function company(state = initialState, action) {
    switch (action.type) {
        case COMPANY_RESET:
            return initialState;
        case COMPANY_ID_CHANGED:
            return {
                ...state,
                id: action.payload
            };
        case COMPANY_NAME_CHANGED:
            return {
                ...state,
                name: action.payload
            };
        case COMPANY_ADDRESS1_CHANGED:
            return {
                ...state,
                address1: action.payload
            };
        case COMPANY_ADDRESS2_CHANGED:
            return {
                ...state,
                address2: action.payload
            };
        case COMPANY_COUNTRY_CHANGED:
            return {
                ...state,
                country: action.payload
            };
        case COMPANY_VAT_TAX_NUMBER_CHANGED:
            return {
                ...state,
                vatTaxNumber: action.payload
            };
        case COMPANY_UNOFFICIAL_NAME_CHANGED:
            return {
                ...state,
                unofficialName: action.payload
            };
        case COMPANY_ERROR_CHANGED:
            return {
                ...state,
                error: action.payload
            };
        case COMPANY_STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case COMPANY_EMPLOYEES_CHANGED:
            return {
                ...state,
                employees: action.payload
            };
        case COMPANY_EMPLOYEE_ADDED:
            return {
                ...state,
                employees: [
                    ...state.employees,
                    {
                        name: "",
                        email: "",
                        phoneNumber: "",
                        accessToPanel: false,
                        unique: null,
                        exsistInEmployeesId: null,
                        accountActive: null
                    }
                ]
            };
        case COMPANY_EMPLOYEE_REMOVED:
            return {
                ...state,
                employees: state.employees.filter((_, ei) => action.employeeIndex !== ei)
            };
        case COMPANY_EMPLOYEE_NAME_CHANGED:
            return {
                ...state,
                employees: updateRow(state.employees, "name", action, "employeeIndex", true)
            };
        case COMPANY_EMPLOYEE_EMAIL_CHANGED:
            return {
                ...state,
                employees: updateRow(state.employees, "email", action, "employeeIndex", true)
            };
        case COMPANY_EMPLOYEE_PHONE_NUMBER_CHANGED:
            return {
                ...state,
                employees: updateRow(state.employees, "phoneNumber", action, "employeeIndex", true)
            };
        case COMPANY_EMPLOYEE_ACCESS_TO_PANEL_CHANGED:
            return {
                ...state,
                employees: updateRow(state.employees, "accessToPanel", action, "employeeIndex", true)
            };
        case COMPANY_EMPLOYEE_UNIQUE_CHANGED:
            return {
                ...state,
                employees: updateRow(state.employees, "unique", action, "employeeIndex", true)
            };
        case COMPANY_EMPLOYEE_EXIST_IN_EMPLOYEES_ID_CHANGED:
            return {
                ...state,
                employees: updateRow(state.employees, "exsistInEmployeesId", action, "employeeIndex", true)
            };
        case COMPANY_EMPLOYEE_ACCOUNT_STATUS_CHANGED:
            return {
                ...state,
                employees: updateRow(state.employees, "accountActive", action, "employeeIndex", true)
            };
        case COMPANY_TO_DELETE_ACCESS_ADDED:
            return {
                ...state,
                toDeleteAccess: [
                    ...state.toDeleteAccess,
                    {
                        employeeId: action.payload.employeeId
                    }
                ]
            };
        case COMPANY_TO_DELETE_ACCESS_REMOVED:
            return {
                ...state,
                toDeleteAccess: []
            };
        default:
            return state;
    }
}
