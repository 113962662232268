import {
    ORDER_PART_ADDED,
    ORDER_PART_REMOVED,
    ORDER_PART_NAME_CHANGED,
    ORDER_PART_NUMBER_ADDED,
    ORDER_PART_NUMBER_REMOVED,
    ORDER_PART_NUMBER_CHANGED
} from "../actions/orderPartsActions";
import { ORDER_DATA_CHANGED, ORDER_RESET } from "../actions/orderActions";

import { updateRow } from "../../utils";

const makeInitialState = _ => ({
    parts: [{
        name: "",
        partNumbers: [""]
    }]
});

export default function orderParts(state = makeInitialState(), action) {
    switch (action.type) {
        case ORDER_DATA_CHANGED:
            return action.payload.orderParts;
        case ORDER_RESET:
            return makeInitialState();
        case ORDER_PART_ADDED:
            return {
                ...state,
                parts: [
                    ...state.parts,
                    {
                        name: "",
                        partNumbers: [""]
                    }
                ]
            };
        case ORDER_PART_REMOVED:
            return {
                ...state,
                parts: state.parts.filter((_, i) => i !== action.partIndex)
            };
        case ORDER_PART_NAME_CHANGED:
            return {
                ...state,
                parts: updateRow(state.parts, "name", action, "partIndex")
            };
        case ORDER_PART_NUMBER_ADDED:
            return {
                ...state,
                parts: state.parts.map((part, partIndex) => {
                    if (partIndex !== action.partIndex) return part;
                    return {
                        ...part,
                        partNumbers: [
                            ...part.partNumbers,
                            ""
                        ]
                    };
                })
            };
        case ORDER_PART_NUMBER_CHANGED:
            return {
                ...state,
                parts: state.parts.map((part, partIndex) => {
                    if (partIndex !== action.partIndex) return part;
                    return {
                        ...part,
                        partNumbers: part.partNumbers.map((pn, i) => {
                            if (i !== action.partNumberIndex) return pn;
                            return action.payload;
                        })
                    };
                })
            };
        case ORDER_PART_NUMBER_REMOVED:
            return {
                ...state,
                parts: state.parts.map((part, partIndex) => {
                    if (partIndex !== action.partIndex) return part;
                    return {
                        ...part,
                        partNumbers: part.partNumbers.filter((pn, i) => i !== action.partNumberIndex)
                    };
                })
            };
        default:
            return state;
    }
}
