import styled, { css } from "styled-components";
import StyledIcon from "../StyledIcon";
import theme from "../../../theme";

export default styled.button`
    display: inline-block;
    margin-bottom: 0px;
    font-weight: 500;
    text-align: center;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    line-height: 1.5;
    font-size: 14px;
    height: 36px;
    user-select: none;
    position: relative;
    color: rgb(89, 89, 89);
    border-width: 1px;
    border-style: solid;
    border-image: initial; 
    padding: 0px 25px;
    border-radius: 4px;
    border-color: rgb(233, 233, 233);
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    position: relative;
    outline: none;
    ${props =>
        props.primary &&
        css`
            color: white;
            background-color: rgb(68, 130, 255);
            border-color: rgb(68, 130, 255);
        `}

    ${props =>
        props.orderFinishColor &&
        css`
            background-color: ${theme.finishOrderBrown};
            border-color: ${theme.finishOrderBrown};
            color: white;
        `}
    ${props =>
        props.slim &&
        css`
            line-height: 1;
            height: 20px;
            padding: 0 5px;
        `}
    ${props =>
        props.success &&
        css`
            color: #ffffffee;
            background-color: #52c41a;
            border-color: #52c41a;
            &:active {
                background-color: #32a40a;
                border-color: #32a40a;
            }
        `}

        ${props =>
        props.danger &&
        css`
            color: #ffffffee;
            background-color: #D2322D;
            border-color: #D2322D;
            &:active {
                background-color: #D2322D;
                border-color: #D2322D;
            }
        `}
    ${props =>
        props.withIcon &&
        css`
            padding-right: 0;
            &:after {
                display: block;
                content: " ";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 30px;
                background: #00000011;
                ${props =>
                    props.slim &&
                    css`
                        width: 20px;
                    `}
            }
        `}
    ${StyledIcon} {
      
        margin-top: -4px;
        margin-left: 25px;
        margin-right: 8px;
        ${props =>
            props.slim &&
            css`
                margin-left: 10px;
                margin-right: 2px;
            `}
      
    }
`;
