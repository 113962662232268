import { TOTALS_DATA_CHANGED } from "../actions/totalsActions";

export const initialState = {
    totalWorkingHours: null,
    totalCheckedParts: null
};

export default function external(state = initialState, action) {
    switch (action.type) {
        case TOTALS_DATA_CHANGED: {
            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state;
    }
}
