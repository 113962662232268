import { makeActionCreator, encodeParams } from "../../utils";
import { apiFetch } from "../../actions/apiActions";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_ERROR = "STATE_ERROR";

export const MAIN_MENU_CHANGED = "CLIENT_PANEL.CLIENT_PANELS.MAIN_MENU_CHANGED";
export const MAIN_MENU_STATE_CHANGED = "CLIENT_PANEL.CLIENT_PANELS.MAIN_MENU_STATE_CHANGED";
export const MAIN_MENU_ERROR_CHANGED = "CLIENT_PANEL.CLIENT_PANELS.MAIN_MENU_ERROR_CHANGED";
export const STATE_CHANGED = "CLIENT_PANEL.CLIENT_PANELS.STATE_CHANGED";
export const REGULATIONS_ACCEPTED_CHANGED = "CLIENT_PANEL.CLIENT_PANELS.REGULATIONS_ACCEPTED_CHANGED";

export const mainMenuStateChanged = makeActionCreator(MAIN_MENU_STATE_CHANGED, "payload");
export const mainMenuChanged = makeActionCreator(MAIN_MENU_CHANGED, "payload");
export const mainMenuErrorChanged = makeActionCreator(MAIN_MENU_ERROR_CHANGED, "payload");
export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const regulationsAcceptedChanged = makeActionCreator(REGULATIONS_ACCEPTED_CHANGED, "payload");

export function editMainMenu() {
    return async (dispatch, getState) => {
        try {
            let edit = getState().clientPanel.clientPanels.showMainMenu;
            dispatch(mainMenuChanged(edit ? !edit : true));
        } catch (e) {
            dispatch(mainMenuStateChanged(STATE_ERROR));
            dispatch(mainMenuErrorChanged(e.message || "Unknown error"));
        }
    };
}

export function checkStatusOfRegulationsAcceptance() {
    return async dispatch => {
        try {
            let resp = await dispatch(
                apiFetch(
                    encodeParams`/api/users/accept-regulations`
                )
            );

            let data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            dispatch(regulationsAcceptedChanged(data.accepted));
        } catch (e) {
            dispatch(stateChanged(STATE_ERROR));
            console.error(e);
        }
    };
}

export function acceptRegulations() {
    return async dispatch => {
        try {
            let resp = await dispatch(
                apiFetch("/api/users/accept-regulations", {
                    method: "PATCH"
                })
            );

            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message);
            }
            dispatch(regulationsAcceptedChanged(true));
        } catch (e) {
            dispatch(stateChanged(STATE_ERROR));
            console.error(e);
        }
    };
}
