import React from "react";
import { connect } from "react-redux";
import { loadDesktop } from "../../DesktopsPage/actions/desktopsActions";
import styled from "styled-components";

const Field = styled.span`
    cursor: pointer;
`;
const activeStyle = {
    color: "black",
    fontWeight: "bold"
};

@connect(
    ({ desktops }) => ({
        count: desktops.desktop.countOfDesktops,
        currentActiveDesktop: desktops.desktop.activeDesktop
    }),
    dispatch => ({
        loadDesktop: number => dispatch(loadDesktop(number))
    })
)
export default class Desktops extends React.Component {
    render() {
        let listOfDesktops, titleOfDesktop;
        if (this.props.count > 1) {
            titleOfDesktop = "Desktops: ";
            listOfDesktops = [...Array(this.props.count)].map((_, i) => (
                <span
                    style={this.props.currentActiveDesktop === i + 1 ? activeStyle : {}}
                    onClick={() => this.props.loadDesktop(i + 1)}
                    key={i}
                >
                    {" "}
                    {i + 1} {i + 1 !== this.props.count ? "| " : ""}{" "}
                </span>
            ));
        }
        return (
            <div>
                {titleOfDesktop}
                <Field>{listOfDesktops}</Field>
            </div>
        );
    }
}
