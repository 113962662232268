import { STATE_DEFAULT } from "../../actions/companiesListActions";

export const makeInitialState = _ => ({
    companySearchContent: "",
    searchState: STATE_DEFAULT,
    error: null,
    companyHeaders: [] // list of displayed companies changed
});

export default function problemDescriptionHOR({
    COMPANY_SEARCH_CONTENT_CHANGED,
    SEARCH_STATE_CHANGED,
    COMPANY_HEADERS_CHANGED,
    ERROR_CHANGED }) {
    return (state = makeInitialState(), action) => {
        switch (action.type) {
            case COMPANY_SEARCH_CONTENT_CHANGED: {
                return {
                    ...state,
                    companySearchContent: action.payload
                };
            }
            case SEARCH_STATE_CHANGED:
                return {
                    ...state,
                    searchState: action.payload
                };

            case COMPANY_HEADERS_CHANGED: {
                return {
                    ...state,
                    companyHeaders: action.payload
                };
            }
            case ERROR_CHANGED:
                return {
                    ...state,
                    error: action.payload
                };
            default:
                return state;
        }
    };
}
