import React from "react";
import { Button, Glyphicon, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

export default class ConfirmableButton extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            modalShown: false
        };
    }

    buttonClicked = ev => {
        this.setState({
            modalShown: true
        });
    };

    hideModal = _ => {
        this.setState({
            modalShown: false
        });
    };

    confirm = _ => {
        this.hideModal();
        if (typeof this.props.onConfirm === "function") {
            this.props.onConfirm();
        }
    };

    propagationWall = ev => {
        if (this.props.stopPropagation) {
            ev.stopPropagation();
        }
    };

    render() {
        const tooltip = <Tooltip id="tooltip">{this.props.tooltip}</Tooltip>;
        const button = (
            <Button
                bsStyle={this.props.bsStyle || "danger"}
                onClick={this.buttonClicked}
                className={this.props.className || ""}
                disabled={this.props.disabled}
            >
                {this.props.children || <Glyphicon glyph="trash" />}
            </Button>
        );
        return (
            <span onClick={this.propagationWall}>
                {/*if exist props.tooltip add tooltip to button*/}
                {this.props.tooltip && (
                    <OverlayTrigger placement="left" overlay={tooltip}>
                        {button}
                    </OverlayTrigger>
                )}
                {/*if not exist props.tooltip display the button*/}
                {!this.props.tooltip && button}
                {this.state.modalShown && (
                    <Modal show={true} onHide={this.hideModal} dialogClassName="modal-centered">
                        <Modal.Header closeButton>
                            <Modal.Title>Are you sure?</Modal.Title>
                        </Modal.Header>
                        {this.props.message && (
                            <Modal.Body>
                                <p>{this.props.message}</p>
                            </Modal.Body>
                        )}
                        <Modal.Footer>
                            <Button onClick={this.hideModal}>Cancel</Button>
                            <Button onClick={this.confirm} bsStyle="danger">
                                OK
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </span>
        );
    }
}
