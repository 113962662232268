import React from "react";
import { connect } from "react-redux";
import AlertModal from "./AlertModal";
import {
    MODAL_TYPE_ALERT,
    MODAL_TYPE_CONFIRM,
    MODAL_STATE_OPENED,
    MODAL_TYPE_LINK_LIST,
    MODAL_TYPE_PROMPT
} from "../../actions/modalsActions";
import ConfirmModal from "./ConfirmModal";
import LinkListModal from "./LinkListModal";
import PromptModal from "./PromptModal";

@connect(({ modals }) => ({
    modals: modals.modals
}))
export default class Modals extends React.Component {
    render() {
        let modal = this.props.modals.find(m => m.state === MODAL_STATE_OPENED);
        if (!modal) return null;
        switch (modal.data.type) {
            case MODAL_TYPE_ALERT:
                return <AlertModal modal={modal} />;
            case MODAL_TYPE_CONFIRM:
                return <ConfirmModal modal={modal} />;
            case MODAL_TYPE_LINK_LIST:
                return <LinkListModal modal={modal} />;
            case MODAL_TYPE_PROMPT:
                return <PromptModal modal={modal} />;
            default:
                throw new Error(`Unrecognized modal type ${modal.data.type} (typeof ${typeof modal.data.type}).`);
        }
    }
}
