import { STATE_DEFAULT, MESSAGE_CHANGED, STATE_CHANGED } from "../actions/passwordResetFinishActions";

export const initialState = {
    state: STATE_DEFAULT,
    message: null
};

export default function passwordResetFinish(state = initialState, action) {
    switch (action.type) {
        case MESSAGE_CHANGED:
            return {
                ...state,
                message: action.payload
            };
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        default:
            return state;
    }
}


