import {
    SHOW_MODAL_CHANGED,
    STATE_DEFAULT,
    TEMPLATE_DATA_CHANGED,
    TEMPLATE_DATA_STATE_CHANGED,
    TEMPLATE_ID_CHANGED,
    SET_FIELD_FILTERS,
    FIELD_FILTER_CHANGED,
    DR_MODE_CONTAINS,
    DATE_RANGE_FILTER_MODE_CHANGED
} from "../actions/templateFiltersModalActions";

export const initialState = {
    showModal: false,
    templateId: null,
    templateData: null,
    templateDataState: STATE_DEFAULT,
    fieldFilters: {},
    dateRangeFilterMode: DR_MODE_CONTAINS
};

export default function templateFiltersModal(state = initialState, action) {
    switch (action.type) {
        case SHOW_MODAL_CHANGED:
            return {
                ...state,
                showModal: action.payload
            };
        case TEMPLATE_ID_CHANGED:
            return {
                ...state,
                templateId: action.payload
            };
        case TEMPLATE_DATA_CHANGED:
            return {
                ...state,
                templateData: action.payload
            };
        case TEMPLATE_DATA_STATE_CHANGED:
            return {
                ...state,
                templateDataState: action.payload
            };
        case SET_FIELD_FILTERS:
            return {
                ...state,
                fieldFilters: action.payload
            };
        case FIELD_FILTER_CHANGED:
            if (!action.payload || action.payload.length === 0) {
                let fieldFilters = {};
                Object.keys(state.fieldFilters)
                    .filter(k => k !== action.fieldId)
                    .forEach(k => (fieldFilters[k] = state.fieldFilters[k]));
                return {
                    ...state,
                    fieldFilters
                };
            }
            return {
                ...state,
                fieldFilters: {
                    ...state.fieldFilters,
                    [action.fieldId]: action.payload
                }
            };
        case DATE_RANGE_FILTER_MODE_CHANGED:
            return {
                ...state,
                dateRangeFilterMode: action.payload
            };
        default:
            return state;
    }
}
