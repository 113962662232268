export const VERROR_NO_COMPANY_NAME = "NO_COMPANY_NAME";
export const VERROR_NO_ADDRESS1 = "NO_ADDRESS1";
export const VERROR_NO_ADDRESS2 = "NO_ADDRESS2";
export const VERROR_NO_CONTACT_NAME = "NO_CONTACT_NAME";
export const VERROR_INVALID_CONTACT_EMAIL = "INVALID_CONTACT_EMAIL";

export const validationErrors = {
    [VERROR_NO_COMPANY_NAME]: () => ({
        type: VERROR_NO_COMPANY_NAME,
        message: "Missing company name."
    }),
    [VERROR_NO_ADDRESS1]: () => ({
        type: VERROR_NO_ADDRESS1,
        message: "Missing company city."
    }),
    [VERROR_NO_ADDRESS2]: () => ({
        type: VERROR_NO_ADDRESS2,
        message: "Missing company street."
    }),
    [VERROR_NO_CONTACT_NAME]: rowIndex => ({
        type: VERROR_NO_CONTACT_NAME,
        message: "Missing company contact name.",
        rowIndex
    }),
    [VERROR_INVALID_CONTACT_EMAIL]: rowIndex => ({
        type: VERROR_INVALID_CONTACT_EMAIL,
        message: "Invalid company contact email.",
        rowIndex
    })
};
