import { stringify as stringifyQueryString } from "querystring";
import { toast } from "react-toastify";
import { apiFetch } from "../../actions/apiActions";
import { makeActionCreator, formatDate, encodeParams } from "../../utils";
import { serializeFiltersToQueryObject } from "./filtersActions";
import { finance } from "faker";
import { showConfirmModal } from "../../actions/modalsActions";

export const IS_LOADING_CHANGED = "REPORTS_LIST.RESULTS.IS_LOADING_CHANGED";
export const DATA_LOADED = "REPORTS_LIST.RESULTS.DATA_LOADED";
export const SELECTED_CHANGED = "REPORTS_LIST.RESULTS.SELECTED_CHANGED";
export const SELECT_DESELECT_ALL = "REPORTS_LIST.RESULTS.SELECT_DESELECT_ALL";
export const STATUS_CHANGED = "REPORTS_LIST.RESULTS.STATUS_CHANGED";
export const MASS_OPERATION_LOADING_CHANGED = "REPORTS_LIST.RESULTS.MASS_OPERATION_LOADING_CHANGED";

export const isLoadingChanged = makeActionCreator(IS_LOADING_CHANGED, "payload");
export const dataLoaded = makeActionCreator(DATA_LOADED, "enabledColumns", "totalRows", "payload");
export const selectedChanged = makeActionCreator(SELECTED_CHANGED, "rowId", "payload");
export const selectDeselectAll = makeActionCreator(SELECT_DESELECT_ALL, "payload");
export const statusChanged = makeActionCreator(STATUS_CHANGED, "rowId", "payload");
export const massOperationLoadingChanged = makeActionCreator(MASS_OPERATION_LOADING_CHANGED, "payload");

export var requestCache = {}; // this global variable holds the request cache for prefetching and faster response times

export function loadRows() {
    return async (dispatch, getState) => {
        const requestUrl = `/api/reports-list?` + stringifyQueryString(dispatch(serializeFiltersToQueryObject(true)));
        try {
            const enabledColumns = getState().reportsList.filters.customizerEnabledColumns; // copy the enabled columns at the time of the request
            if (!requestCache[requestUrl]) {
                requestCache[requestUrl] = (async () => {
                    const resp = await dispatch(apiFetch(requestUrl, {}));
                    const data = await resp.json();
                    if (!resp.ok) {
                        throw new Error(data.message);
                    }
                    return data;
                })();
            }

            const data = await requestCache[requestUrl];
            const { rowsPerPage, page } = getState().reportsList.filters;
            if (page < Math.floor(data.totalRows / rowsPerPage)) {
                // we can prefetch the next page
                dispatch(prefetchPage(page + 1));
            }

            dispatch(
                dataLoaded(
                    enabledColumns,
                    data.totalRows,
                    data.rows.map(r => ({
                        ...r,
                        selected: false
                    }))
                )
            );
        } catch (e) {
            delete requestCache[requestUrl];
            console.error(e);
            toast("Failed to load reports: " + e.message, { type: "error" });
        }
    };
}

export function prefetchPage(page) {
    return async (dispatch, getState) => {
        const requestUrl =
            `/api/reports-list?` + stringifyQueryString({ ...dispatch(serializeFiltersToQueryObject(true)), page });
        if (!requestCache[requestUrl]) {
            requestCache[requestUrl] = (async () => {
                const resp = await dispatch(apiFetch(requestUrl, {}));
                const data = await resp.json();
                if (!resp.ok) {
                    throw new Error(data.message);
                }
                return data;
            })().catch(e => {
                console.error("failed to prefetch next page:", e);
                delete requestCache[requestUrl];
            });
        }
    };
}

export function changeReportStatusInReportsList(reportId, newStatus) {
    return async (dispatch, getState) => {
        for (let i in requestCache) {
            delete requestCache[i];
        }
        dispatch(statusChanged(reportId, newStatus));
    };
}

export function approveSelectedReports() {
    return async (dispatch, getState) => {
        const reportIds = getState()
            .reportsList.results.rows.filter(row => row.selected && row.status === "inProgress")
            .map(row => row._id);
        if (
            !(await dispatch(
                showConfirmModal({
                    cancelButtonText: "Cancel",
                    okButtonText: "Approve",
                    title: "Are you sure?",
                    message: "Do you really want to approve all (" + reportIds.length + ") reports?"
                })
            ))
        ) {
            return;
        }
        dispatch(massOperationLoadingChanged(true));
        try {
            const resp = await dispatch(
                apiFetch("/api/reports/mass-approval", {
                    method: "PATCH",
                    body: JSON.stringify(reportIds)
                })
            );
            const data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            for (let i in requestCache) {
                delete requestCache[i];
            }
            reportIds.forEach(id => dispatch(statusChanged(id, "approved")));
        } catch (e) {
            console.error(e);
            toast("Failed to approve reports: " + e.message, { type: "error" });
        } finally {
            dispatch(massOperationLoadingChanged(false));
        }
    };
}

export function deleteSelectedReports() {
    return async (dispatch, getState) => {
        const reportIds = getState()
            .reportsList.results.rows.filter(
                row => row.selected && (row.status === "inProgress" || row.status === "approved")
            )
            .map(row => row._id);
        if (
            !(await dispatch(
                showConfirmModal({
                    cancelButtonText: "Cancel",
                    okButtonText: "Delete",
                    title: "Are you sure?",
                    message: "Do you really want to delete all (" + reportIds.length + ") reports?"
                })
            ))
        ) {
            return;
        }

        dispatch(massOperationLoadingChanged(true));
        try {
            const resp = await dispatch(
                apiFetch("/api/reports/mass-deletion", {
                    method: "DELETE",
                    body: JSON.stringify(reportIds)
                })
            );
            const data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            for (let i in requestCache) {
                delete requestCache[i];
            }
            reportIds.forEach(id => dispatch(statusChanged(id, "deleted")));
        } catch (e) {
            console.error(e);
            toast("Failed to delete reports: " + e.message, { type: "error" });
        } finally {
            dispatch(massOperationLoadingChanged(false));
        }
    };
}

export function getControlDateForReport(connectedOrderId, formularNumber) {
    return async (dispatch, getState) => {
        const resp = await dispatch(
            apiFetch(
                encodeParams`/api/reports/by-formular-number?connectedOrderId=${connectedOrderId}&formularNumber=${formularNumber}`
            )
        );
        const data = await resp.json();
        if (!resp.ok) {
            throw new Error(data.message);
        }
        return data.controlDate;
    };
}

export function createExternalsForSelectedReports(hidden = false) {
    return async (dispatch, getState) => {
        if (
            !(await dispatch(
                showConfirmModal({
                    cancelButtonText: "Cancel",
                    okButtonText: "Create externals",
                    title: "Are you sure?",
                    message:
                        "Do you really want to create" + (hidden ? " hidden" : "") + " externals for selected reports?"
                })
            ))
        ) {
            return;
        }
        dispatch(massOperationLoadingChanged(true));
        try {
            const selectedReports = getState().reportsList.results.rows.filter(
                row => row.selected && row.status === "approved"
            );
            let minReport = selectedReports[0];
            let maxReport = selectedReports[0];
            selectedReports.forEach(r => {
                if (minReport.formularNumber > r.formularNumber) {
                    minReport = r;
                }
                if (maxReport.formularNumber < r.formularNumber) {
                    maxReport = r;
                }
            });
            let overdueResp = await dispatch(
                apiFetch(encodeParams`/api/externals/order/reports-without-externals/${minReport._id}`)
            );
            const overdueExternals = await overdueResp.json();
            if (!overdueResp.ok) {
                throw new Error(overdueExternals.message);
            }

            if (
                (overdueExternals && overdueExternals.length > 0) ||
                maxReport.formularNumber - minReport.formularNumber + 1 !== selectedReports.length
            ) {
                const reportsNotSelected =
                    maxReport.formularNumber - minReport.formularNumber + 1 - selectedReports.length;
                const sortedSelected = [...selectedReports].sort((a, b) => a.formularNumber - b.formularNumber);
                let firstMissingFormularNumber = null;
                let lastMissingFormularNumber = null;
                sortedSelected.forEach((r, i, arr) => {
                    if (
                        !firstMissingFormularNumber &&
                        i < arr.length - 1 &&
                        r.formularNumber + 1 !== arr[i + 1].formularNumber
                    ) {
                        firstMissingFormularNumber = r.formularNumber + 1;
                    }
                    if (i > 0 && r.formularNumber - 1 !== arr[i - 1].formularNumber) {
                        lastMissingFormularNumber = r.formularNumber - 1;
                    }
                });

                if (overdueExternals && overdueExternals.length > 0) {
                    minReport = overdueExternals[0];
                    firstMissingFormularNumber = overdueExternals[0].formularNumber;
                    if (!lastMissingFormularNumber) {
                        // maxReport = overdueExternals[overdueExternals.length - 1];
                        lastMissingFormularNumber = overdueExternals[overdueExternals.length - 1].formularNumber;
                    }
                }

                const firstMissing = await dispatch(
                    getControlDateForReport(selectedReports[0].connectedOrderId, firstMissingFormularNumber)
                );

                const lastMissing = await dispatch(
                    getControlDateForReport(selectedReports[0].connectedOrderId, lastMissingFormularNumber)
                );

                if (
                    !(await dispatch(
                        showConfirmModal({
                            cancelButtonText: "Cancel",
                            okButtonText: "Yes, " + (hidden ? "hide all" : "show all to client"),
                            title: `You didn't select ${reportsNotSelected + overdueExternals.length} reports.`,
                            message: `You didn't select the reports between ${formatDate(
                                firstMissing
                            )} and ${formatDate(lastMissing)}.`
                        })
                    ))
                ) {
                    return;
                }
            }
            const resp = await dispatch(
                apiFetch("/api/externals/create-in-bulk", {
                    method: "POST",
                    body: JSON.stringify({
                        connectedOrderId: selectedReports[0].connectedOrderId,
                        hiddenFromClient: hidden,
                        minFormularNumber: minReport.formularNumber,
                        maxFormularNumber: maxReport.formularNumber
                    })
                })
            );
            const data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            for (let i in requestCache) {
                delete requestCache[i];
            }
            data.forEach(id => dispatch(statusChanged(id, hidden ? "hidden" : "visibleToCustomer")));
            toast(`Created externals for ${data.length} reports.`, { type: "success" });
        } catch (e) {
            console.error(e);
            toast("Failed to create externals: " + e.message, { type: "error" });
        } finally {
            dispatch(massOperationLoadingChanged(false));
        }
    };
}
