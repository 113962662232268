import React from "react";
import { Async as AsyncSelect } from "react-select";
import { connect } from "react-redux";
import { templatesSearched } from "../actions/templatesListActions";
@connect(
    null,
    dispatch => ({
        templatesSearched: q => dispatch(templatesSearched(q))
    })
)
export default class TemplatesSelect extends React.Component {
    loadTemplatesOptions = async query => {
        let templates = await this.props.templatesSearched(query);
        return {
            options: templates.map(template => ({
                label: template.name,
                value: template._id
            }))
        };
    };

    render() {
        return (
            <AsyncSelect
                ignoreAccents={false}
                placeholder="Search templates"
                loadOptions={this.loadTemplatesOptions}
                value={this.props.value}
                onChange={this.props.onChange}
            />
        );
    }
}
