import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { PERMISSION_CLIENT_PANEL, PERMISSION_EDIT_USERS } from "../../../../common/src/permissions";
import { authCheckRequested, logoutRequested } from "../../actions/authActions";
import CustomerNavBar from "./CustomerNavBar";
import CustomerMenu from "./CustomerMenu";
import CustomerPanelPage from "../CustomerPanelPage/components/CustomerPanelPage";
import { Button } from "react-bootstrap";
import { acceptRegulations, checkStatusOfRegulationsAcceptance } from "../actions/customerPanelActions";
import { findDOMNode } from "react-dom";
import Regulations from "./Regulations";
import { Route } from "react-router";
import RegulationsPage from "./RegulationPage";
import AccountsPage from "../AccountsPage/components/AccountsPage";
import DashboardFooter from "../../DashboardPage/components/DashboardFooter";
import CustomerOrdersListPage from "../CustomerOrdersListPage/components/CustomerOrdersListPage";

const CustomerBody = styled.div`
    display: flex;
    width: ${props => (props.menu == true ? "calc(100% - 240px)" : "calc(100% - 80px)")};
    @media (max-width: 800px) {
        width: ${props => (props.menu == true ? "calc(100%-240px)" : "99%")};
    }
    margin-top: 12px;
    max-height: calc(100vh - 90px);
    overflow-y: scroll;
`;

const CustomerSiteContainer = styled.div`
    display: flex;
    background: #f5f5f5;
`;

const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(52, 52, 52, 0.8);
    z-index: 800;
`;

const HeaderContainer = styled.div`
    max-height: 80px;
    margin-left: 10px;
`;

const Content = styled.div`
    position: relative;
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    height: 610px;
    padding-top: 10px;
`;

const FooterContainer = styled.div`
    margin-top: 10px;
    height: 15%;
`;

const RegulationsContainer = styled.div`
    height: 455px;
    margin: auto;
    overflow-y: auto;
    border: solid 1px #dddddd;
    padding: 5px;
`;

const CustomButton = styled(Button)`
    margin-left: 5px;
`;

const FullWidthContainer = styled.div`
    width: 100%;
`;

const FooterWrapper = styled.div`
    padding-left: 15px;
`;

@connect(
    ({ clientPanel, auth }) => ({
        userTabEnabled: auth.user && auth.user.group.permissions.indexOf(PERMISSION_EDIT_USERS) !== -1,
        enableClientPanelPage: auth.user && auth.user.group.permissions.indexOf(PERMISSION_CLIENT_PANEL) !== -1,
        regulationsAccepted: clientPanel.clientPanels.regulationsAccepted,
        statusMainMenu: clientPanel.clientPanels.showMainMenu
    }),
    dispatch => ({
        authCheckRequested: _ => dispatch(authCheckRequested()),
        acceptRegulations: _ => dispatch(acceptRegulations()),
        logoutRequested: _ => dispatch(logoutRequested()),
        checkStatusOfRegulationsAcceptance: _ => dispatch(checkStatusOfRegulationsAcceptance())
    })
)
export default class CustomerPage extends React.Component {
    constructor() {
        super();
        this.state = {
            allRegulationWasShown: false
        };
    }

    componentDidMount() {
        this.props.authCheckRequested();
        this.props.checkStatusOfRegulationsAcceptance();
    }

    componentDidUpdate() {
        this.checkRegulationsContainerSize();
    }

    handleScroll = e => {
        const bottom = e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight;
        if (bottom) {
            this.setState({
                allRegulationWasShown: true
            });
        }
    };

    checkRegulationsContainerSize() {
        let heightOfContentRegulations = null;
        let heightOfWindowRegulations = null;

        if (this.regulationsContainerRef && this.refs.insideDivRef) {
            heightOfContentRegulations = window
                .getComputedStyle(findDOMNode(this.regulationsContainerRef))
                .getPropertyValue("height");
            heightOfWindowRegulations = window
                .getComputedStyle(findDOMNode(this.refs.insideDivRef))
                .getPropertyValue("height");
            if (heightOfWindowRegulations < heightOfContentRegulations && this.state.allRegulationWasShown === false) {
                this.setState({
                    allRegulationWasShown: true
                });
            }
        }
    }
    render() {
        let content = "";
        content = (
            <div>
                {!this.props.regulationsAccepted && this.props.enableClientPanelPage && (
                    <Container>
                        <Content>
                            <HeaderContainer>
                                <h2>Privacy policy of RES Online Report System</h2>
                            </HeaderContainer>
                            <RegulationsContainer
                                onScroll={this.handleScroll}
                                innerRef={div => (this.regulationsContainerRef = div)}
                            >
                                <div ref="insideDivRef">
                                    <Regulations />
                                </div>
                            </RegulationsContainer>
                            <FooterContainer>
                                <CustomButton
                                    className="pull-right"
                                    onClick={this.props.logoutRequested}
                                    bsStyle="danger"
                                >
                                    Don’t accept
                                </CustomButton>
                                <Button
                                    disabled={!this.state.allRegulationWasShown}
                                    className="pull-right"
                                    onClick={this.props.acceptRegulations}
                                    bsStyle="success"
                                >
                                    Accept
                                </Button>
                            </FooterContainer>
                        </Content>
                    </Container>
                )}
            </div>
        );
        return (
            <div>
                {content}
                <CustomerNavBar />
                <CustomerSiteContainer>
                    <CustomerMenu />
                    <CustomerBody menu={this.props.statusMainMenu}>
                        <FullWidthContainer>
                            <Route component={AccountsPage} path={`${this.props.match.url}/account`} />
                            <Route component={RegulationsPage} path={`${this.props.match.url}/regulations`} />
                            <Route component={CustomerOrdersListPage} path={`${this.props.match.url}/orders/list`} />
                            <Route component={CustomerPanelPage} path={`${this.props.match.url}/:id?`} exact />
                            <FooterWrapper>
                                <DashboardFooter />
                            </FooterWrapper>
                        </FullWidthContainer>
                    </CustomerBody>
                </CustomerSiteContainer>
            </div>
        );
    }
}
