import {
    validationErrors,
    VERROR_NO_COMPANY_NAME,
    VERROR_NO_ADDRESS1,
    VERROR_NO_CONTACT_NAME,
    VERROR_INVALID_CONTACT_EMAIL,
    VERROR_NO_ADDRESS2
} from "../validationErrors";
import { validateEmail } from "../../utils";

export default function getValidationErrors(state) {
    const errors = [];
    if (!state.companies.company.name.trim()) {
        errors.push(validationErrors[VERROR_NO_COMPANY_NAME]());
    }
    if (!state.companies.company.address1.trim()) {
        errors.push(validationErrors[VERROR_NO_ADDRESS1]());
    }
    if (!state.companies.company.address2.trim()) {
        errors.push(validationErrors[VERROR_NO_ADDRESS2]());
    }
    state.companies.company.employees.forEach((e, rowIndex) => {
        if (!e.name.trim()) {
            errors.push(validationErrors[VERROR_NO_CONTACT_NAME](rowIndex));
        }
        if (!!e.email && !validateEmail(e.email)) {
            errors.push(validationErrors[VERROR_INVALID_CONTACT_EMAIL](rowIndex));
        }
    });
    return errors;
}
