import { PASSWORD_CHANGED } from "../actions/changePasswordActions";

const initialState = {
  status: null
};

export default function changePasswordReducer(state = initialState, action) {
  switch (action.type) {
    case PASSWORD_CHANGED:
      return {
        ...state,
        status: action.status
      };
    default:
      return state;
  }
}
