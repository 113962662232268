import {
    COMPANY_ID_CHANGED,
    COMPANY_NAME_CHANGED,
    STATE_DEFAULT,
    COMPANY_ADDRESS1_CHANGED,
    COMPANY_ADDRESS2_CHANGED,
    COMPANY_STATE_CHANGED,
    ORDERS_CHANGED,
    DATA_CHANGED,
    EDIT_CHANGED,
    DESKTOPS_CHANGED,
    DESKTOPS_COUNT_CHANGED,
    DESKTOPS_DAYS_RANGE_CHANGED,
    ACTIVE_DESKTOP_CHANGED,
    DESKTOPS_ID_CHANGED,
    REFRESH_DESKTOP_INTERVAL_STARTED_SET,
    GROUP_FLAG_CHANGED,
    MODAL_INFORMATION_CHANGED,
    DESKTOPS_LAST_DATA_LOAD
} from "../actions/desktopsActions";

const initialState = {
    error: null,
    company: {
        id: 0,
        name: "",
        address1: "",
        address2: "",
        state: STATE_DEFAULT
    },
    companies: [],
    orders: [],
    edit: false,
    daysRange: 7,
    activeDesktop: 1,
    countOfDesktops: 0,
    id: null,
    groupFlag: false,
    modalInformation: {
        companyIndex: null,
        orderIndex: null
    },
    lastDataLoad: Date.now()
};

export default function company(state = initialState, action) {
    switch (action.type) {
        case COMPANY_ID_CHANGED:
            return {
                ...state,
                company: {
                    ...state.company,
                    id: action.payload
                }
            };
        case COMPANY_NAME_CHANGED:
            return {
                ...state,
                company: {
                    ...state.company,
                    name: action.payload
                }
            };
        case COMPANY_ADDRESS1_CHANGED:
            return {
                ...state,
                company: {
                    ...state.company,
                    address1: action.payload
                }
            };
        case COMPANY_ADDRESS2_CHANGED:
            return {
                ...state,
                company: {
                    ...state.company,
                    address2: action.payload
                }
            };
        case ORDERS_CHANGED:
            return {
                ...state,
                orders: action.payload
            };
        case COMPANY_STATE_CHANGED:
            return {
                ...state,
                company: {
                    ...state.company,
                    state: action.payload
                }
            };
        case DATA_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case EDIT_CHANGED:
            return {
                ...state,
                edit: action.payload
            };
        case DESKTOPS_CHANGED:
            return {
                ...state,
                companies: action.payload
            };
        case DESKTOPS_COUNT_CHANGED:
            return {
                ...state,
                countOfDesktops: action.payload
            };
        case DESKTOPS_DAYS_RANGE_CHANGED:
            return {
                ...state,
                daysRange: action.payload
            };
        case ACTIVE_DESKTOP_CHANGED:
            return {
                ...state,
                activeDesktop: action.payload
            };
        case DESKTOPS_ID_CHANGED:
            return {
                ...state,
                id: action.payload
            };
        case REFRESH_DESKTOP_INTERVAL_STARTED_SET:
            return {
                ...state,
                refreshDesktopIntervalStartedSet: action.payload
            };
        case GROUP_FLAG_CHANGED:
            return {
                ...state,
                groupFlag: action.payload
            };
        case MODAL_INFORMATION_CHANGED:
            return {
                ...state,
                modalInformation: action.payload
            };
        case DESKTOPS_LAST_DATA_LOAD:
            return {
                ...state,
                lastDataLoad: action.payload
            }
        default:
            return state;
    }
}
