import React from "react";
import { connect } from "react-redux";
import TemplateInstance from "./TemplateInstance";
import styled from "styled-components";

const TemplatesListContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

@connect(state => state.hr.editEmployee)
export default class EmployeeTemplatesList extends React.Component {
    render() {
        return (
            <TemplatesListContainer>
                {this.props.templates.map((t, i) => (
                    <TemplateInstance template={t} key={t._id || t.templateId || i} templateIndex={i} />
                ))}
            </TemplatesListContainer>
        );
    }
}
