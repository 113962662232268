import React from "react";
import { FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import { QARowShowRemarksChanged, QARowRemarksChanged } from "../actions/externalActions";
import { TextareaVerticalResize } from "./CurrentRemarks";
import isExternalDisabled from "../selectors/isExternalDisabled";
import QAField from "./styled/QAField";

@connect(
    state => ({
        disabled: isExternalDisabled(state)
    }),
    (dispatch, { rowIndex }) => ({
        QARowShowRemarksChanged: ev => dispatch(QARowShowRemarksChanged(rowIndex, ev.target.checked)),
        QARowRemarksChanged: ev => dispatch(QARowRemarksChanged(rowIndex, ev.target.value))
    })
)
export default class ExternalQATableRow extends React.Component {
    render() {
        let r = this.props.row;
        return (
            <tr>
                <td>{this.props.rowIndex + 1}</td>
                <td>
                    <QAField type="text" disabled value={r.partNumber} width={180} />
                </td>
                <td>
                    <QAField type="text" disabled value={r.totalChecked} />
                </td>
                <td>
                    <QAField type="text" disabled value={r.fromThisOk} />
                </td>
                <td>
                    <QAField type="text" disabled value={r.reworked} />
                </td>
                <td>
                    <QAField type="text" disabled value={r.nok} />
                </td>
                <td>
                    <QAField type="text" disabled value={r.totalOk} />
                </td>
                <td>
                    <input
                        disabled={this.props.disabled}
                        type="checkbox"
                        className="checkbox-big inline"
                        checked={r.showRemarks}
                        onChange={this.props.QARowShowRemarksChanged}
                    />
                </td>
                <td>
                    <TextareaVerticalResize
                        type="text"
                        componentClass="textarea"
                        value={r.remarks}
                        onChange={this.props.QARowRemarksChanged}
                        disabled={this.props.disabled || !r.showRemarks}
                    />
                </td>
            </tr>
        );
    }
}
