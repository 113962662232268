import { ERRORS_CHANGED, ERROR_CLEARED } from "../actions/validationActions";
import { COMPANY_RESET } from "../actions/companyActions";

export const initialState = {
    errors: []
};

export default function validation(state = initialState, action) {
    switch (action.type) {
        case COMPANY_RESET:
            return initialState;
        case ERRORS_CHANGED:
            return {
                ...state,
                errors: action.payload
            };
        case ERROR_CLEARED:
            return {
                ...state,
                errors: state.errors.filter(e => {
                    return !Object.keys(action.payload).every(k => action.payload[k] === e[k]);
                })
            };
        default:
            return state;
    }
}
