import { transit } from "react-css-transition/lib/index";

export const groupAllStyles = {
    defaultStyle: {
        opacity:1
    },
    enterStyle: {
        opacity:transit(0, 300, "ease-in-out"),
    },
    leaveStyle: {
        opacity: transit(1, 300, "ease-in-out"),
    },
    activeStyle: {
        opacity:0,
    },
};

export const groupSingleStyles = {
    defaultStyle: {
        opacity:0,
        width: "0px",
        display:"flex",
        height: "0px"
    },
    enterStyle: {
        display: "block",
        opacity:transit(1, 300, "ease-in-out"),
        width:transit("100%",300, "ease-in-out")
    },
    leaveStyle: {
        display: "block",
        opacity: transit(0, 300, "ease-in-out"),
        width:transit("0px", 300, "ease-in-out")
    },
    activeStyle: {
        opacity:1,
        width:"100%",
        display: "block"
    }
};