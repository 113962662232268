import React from "react";
import { connect } from "react-redux";
import MatButton from "../../components/common/Mat/MatButton";
import MatSpinner from "../../components/common/Mat/MatSpinner";
import { loadTotalCheckedForOrder } from "../actions/resultsActions";

export default connect()(({ value, dispatch, row }) => {
    if (value.isLoading) {
        return <MatSpinner slim />;
    }
    if (value.data) {
        return (
            <div>
                Ext: {value.data.external} Int: {value.data.internal}
            </div>
        );
    }
    return (
        <div>
            <MatButton slim primary onClick={() => dispatch(loadTotalCheckedForOrder(row._id))}>
                Count
            </MatButton>
        </div>
    );
});
