import { TOKEN_CHANGED, SHOW_PLEASE_LOGIN_MODAL_CHANGED } from "../actions/apiActions";

const initialState = {
    token: null,
    showPleaseLoginModal: false
};

export default function api(state = initialState, action) {
    switch (action.type) {
        case TOKEN_CHANGED:
            return {
                ...state,
                token: action.payload
            };
        case SHOW_PLEASE_LOGIN_MODAL_CHANGED:
            return {
                ...state,
                showPleaseLoginModal: action.payload
            };
        default:
            return state;
    }
}
