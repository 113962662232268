import styled, { css } from "styled-components";

export default styled.div`
    padding: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(233, 233, 233);
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    ${props =>
        props.noPaddingBottom &&
        css`
            padding-bottom: 0;
        `}
    ${props =>
        props.noPaddingTop &&
        css`
            padding-top: 0;
        `}
    ${props =>
        props.noPadding &&
        css`
            padding: 0;
        `}
`;
