import {
    EXTERNALS_CHANGED,
    ORDER_INFORMATION_CHANGED,
    STATE_CHANGED,
    STATE_DEFAULT,
    LAST_FILTERS_UPDATE_CHANGED
} from "../actions/customerPanelExternalsActions";

export const initialState = {
    order: null,
    dateStart: null,
    dateEnd: null,
    state: STATE_DEFAULT,
    externals: null,
    orderInformation: null,
    lastFiltersUpdate: new Date(0).toString()
};

export default function clientPanelExternals(state = initialState, action) {
    switch (action.type) {
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case EXTERNALS_CHANGED:
            return {
                ...state,
                externals: action.payload
            };
        case ORDER_INFORMATION_CHANGED:
            return {
                ...state,
                orderInformation: action.payload
            };
        case LAST_FILTERS_UPDATE_CHANGED:
            return {
                ...state,
                lastFiltersUpdate: action.payload
            };
        default:
            return state;
    }
}
