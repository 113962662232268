import React from "react";
import { Panel, Table } from "react-bootstrap";
import { formatDateTime } from "../../../utils";
import IconButton from "../../../components/common/IconButton";
import { connect } from "react-redux";
import { markNotificationAsRead } from "../../../actions/notificationsActions";
import HeaderWithButton from "./HeaderWithButton";

@connect(
    null,
    (dispatch, { notification }) => ({
        markedAsRead: _ => dispatch(markNotificationAsRead(notification._id))
    })
)
export default class TemplatesCustomNotification extends React.Component {
    render() {
        let data = this.props.notification.data;
        return (
            <Panel>
                <HeaderWithButton notification={this.props.notification}>
                    Template notification: {data.templateName}
                </HeaderWithButton>
                <Table>
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <td>{data.name}</td>
                        </tr>
                        <tr>
                            <th>Date of birth</th>
                            <td>{data.dateOfBirth}</td>
                        </tr>
                        <tr>
                            <th>Template name</th>
                            <td>{data.templateName}</td>
                        </tr>
                        <tr>
                            <th>Comments</th>
                            <td>{data.comments}</td>
                        </tr>
                    </tbody>
                </Table>
            </Panel>
        );
    }
}
