import React from "react";
import styled, { keyframes, css } from "styled-components";
import { Glyphicon } from "react-bootstrap";

const spin = keyframes`
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
`;
// eslint-disable-next-line no-unused-vars
export default styled(({ spin, center, size, color, clickable, ...rest }) => <Glyphicon {...rest} />).attrs(props => ({
    glyph: props.glyph || "refresh"
}))`
    ${props =>
        props.spin &&
        css`
            animation: ${spin} 1s infinite linear;
        `}
    ${props =>
        props.center &&
        css`
            display: block;
            text-align: center;
        `}

    ${props =>
        props.clickable &&
        css`
            cursor: pointer;
        `}
    ${props => 
        props.size === "ultra" &&
        css`
          font-size: 120px;
        `}
    ${props =>
        props.size === "big" &&
        css`
            font-size: 30px;
        `}
    ${props =>
        props.size === "medium" &&
        css`
            font-size: 20px;
        `}
    color: ${props => props.color || "auto"};
`;
