import React from "react";
import { FormControl } from "react-bootstrap";
import styled, { css } from "styled-components";
import errorBorder from "../../styles/errorBorder";

export default styled(({ errorBorder, alignLeft, fatter, ...rest }) => <FormControl {...rest} />)`
    ${props => props.errorBorder && errorBorder}
    ${props =>
        props.alignLeft &&
        css`
            &::placeholder {
                text-align: left;
            }
        `}
        ${props =>
            props.fatter &&
            css`
                height: 42px;
            `}
`;
