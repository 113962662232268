import React from "react";
import MatSortArrowIcon from "../Mat/MatSortArrowIcon";
import StyledTh from "./StyledTh";

export default function MatSortableTableHeader({ model, processedColumns, runtimeProps = {} }) {
    return (
        <thead>
            <tr>
                {processedColumns.map((c, i) => {
                    if (c.customTopHeader) {
                        return <c.customTopHeader key={i} model={model} />;
                    }

                    if (c.sortable) {
                        const isSortedAsc = runtimeProps.sortBy === c.key;
                        const isSortedDesc = runtimeProps.sortBy === "-" + c.key;
                        let nextSortBy = "-" + c.key;
                        if (nextSortBy === runtimeProps.sortBy) {
                            nextSortBy = c.key;
                        }
                        const isOtherSortedDesc = runtimeProps.sortBy.startsWith("-");
                        return (
                            <StyledTh key={i} model={model}>
                                <MatSortArrowIcon
                                    wrapArrow={processedColumns.length > 7}
                                    label={c.title}
                                    isSortedAsc={isSortedAsc}
                                    isSortedDesc={isSortedDesc}
                                    isOtherSortedDesc={isOtherSortedDesc}
                                    onClick={() => runtimeProps.sortByChanged(nextSortBy)}
                                />
                            </StyledTh>
                        );
                    }
                    return (
                        <StyledTh key={i} model={model}>
                            {c.title}
                        </StyledTh>
                    );
                })}
            </tr>
        </thead>
    );
}
