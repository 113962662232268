import React from "react";
import { Panel } from "react-bootstrap";
import { connect } from "react-redux";
import { markNotificationAsRead } from "../../../actions/notificationsActions";
import HeaderWithButton from "./HeaderWithButton";

@connect(
    null,
    (dispatch, { notification }) => ({
        markedAsRead: _ => dispatch(markNotificationAsRead(notification._id))
    })
)
export default class CustomAnnouncementNotification extends React.Component {
    render() {
        let data = this.props.notification.data;

        return (
            <Panel>
                <HeaderWithButton notification={this.props.notification}>
                    {data.title || "Announcement"}
                </HeaderWithButton>
                <p>{data.content}</p>
            </Panel>
        );
    }
}
