import React from "react";
import styled from "styled-components";
import MatLabel from "./MatLabel";

const RadioLabel = styled(MatLabel)`
    position: relative;
`;

const RadioWrapper = styled.span`
    margin: 0;
    line-height: 1;
    vertical-align: sub;
    outline: none;
    box-sizing: border-box;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;

    position: relative;
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    margin-right: 10px;
`;

const RadioHelper = styled.span`
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #d9d9d9;

    border-radius: 100px;
    transition: all 0.3s;
    box-sizing: border-box;
    &:after {
        position: absolute;
        top: 3px;
        left: 3px;
        display: table;
        width: 8px;
        height: 8px;
        background-color: #1890ff;
        border-top: 0;
        border-left: 0;
        border-radius: 8px;
        transform: scale(0);
        opacity: 0;
        transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
        content: " ";
    }
`;

const RadioInput = styled.input`
    box-sizing: border-box;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    &:checked + ${RadioHelper} {
        border-color: rgb(68, 130, 255);
    }
    &:checked + ${RadioHelper}:after {
        transform: scale(1);
        opacity: 1;
    }
`;

export default ({ label, className, ...rest }) => {
    return (
        <RadioLabel className={className}>
            <RadioWrapper>
                <RadioInput type="radio" {...rest} />
                <RadioHelper />
            </RadioWrapper>
            <span>{label}</span>
        </RadioLabel>
    );
};
