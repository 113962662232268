import { MAIN_MENU_CHANGED, REGULATIONS_ACCEPTED_CHANGED, STATE_DEFAULT } from "../actions/customerPanelActions";

const initialState = {
    state: STATE_DEFAULT,
    showMainMenu: false,
    regulationsAccepted: true
};

export default function customerPanels(state = initialState, action) {
    switch (action.type) {
        case MAIN_MENU_CHANGED:
            return {
                ...state,
                showMainMenu: action.payload
            };
        case REGULATIONS_ACCEPTED_CHANGED:
            return {
                ...state,
                regulationsAccepted: action.payload
            };
        default:
            return state;
    }
}