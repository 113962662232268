import { TEMPLATES_CHANGED, STATE_CHANGED, STATE_DEFAULT, TEMPLATE_DELETED } from "../actions/templatesListActions";

const initialState = {
    templates: null,
    state: STATE_DEFAULT
};

export default function templatesList(state = initialState, action) {
    switch (action.type) {
        case TEMPLATES_CHANGED:
            return {
                ...state,
                templates: action.payload
            };
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case TEMPLATE_DELETED:
            return {
                ...state,
                templates: state.templates.filter(t => t._id !== action.templateId)
            };
        default:
            return state;
    }
}
