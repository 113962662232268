/**
 * Root url of the API. No trailing slash!
 */
export const API_URL = process.env.API_URL;

export function makeApiUrl(endpoint, getParams = {}) {
    let encodedParams = "";
    if (Object.keys(getParams).length > 0) {
        encodedParams += "?";
        encodedParams += Object.keys(getParams)
            .filter(k => getParams[k] !== null)
            .map(k => {
                let v = getParams[k];
                return encodeURIComponent(k) + "=" + encodeURIComponent(v);
            })
            .join("&");
    }
    return API_URL + endpoint + encodedParams;
}
