import React from "react";

export default _ => {
    return (
        <thead>
            <tr>
                <th></th>
                <th>Part number</th>
                <th>Total</th>
                <th>OK</th>
                <th>Rework</th>
                <th>NOK</th>
                <th>T. OK</th>
                <th>Show</th>
                <th>Remarks</th>
            </tr>
        </thead>
    );
};
