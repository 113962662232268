import React from "react";
import { connect } from "react-redux";
import { Modal, Button, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { modalResultChanged, modalStateChanged, MODAL_STATE_FINISHED } from "../../actions/modalsActions";
import StyledDatePicker from "../common/StyledDatePicker";
import moment from "moment";
import StyledFormControl from "../common/StyledFormControl";

@connect(
    () => ({}),
    dispatch => ({
        modalResultChanged: (id, p) => dispatch(modalResultChanged(id, p)),
        modalStateChanged: (id, p) => dispatch(modalStateChanged(id, p))
    })
)
export default class PromptModal extends React.Component {
    state = {
        inputErrorBorder: false
    };
    closeModal = _ => {
        this.props.modalResultChanged(this.props.modal.modalId, null);
        this.props.modalStateChanged(this.props.modal.modalId, MODAL_STATE_FINISHED);
    };
    textChanged = ev => {
        this.setState({ inputErrorBorder: false });
        this.props.modalResultChanged(this.props.modal.modalId, ev.target.value);
    };
    dateChanged = ev => {
        this.props.modalResultChanged(this.props.modal.modalId, ev);
    };
    confirm = _ => {
        if (this.props.modal.data.validateContent && this.props.modal.result === "") {
            this.setState({ inputErrorBorder: true });
            return;
        }
        this.props.modalStateChanged(this.props.modal.modalId, MODAL_STATE_FINISHED);
    };
    render() {
        let modalData = this.props.modal.data;
        let control;
        if (this.props.modal.data.fieldType === "date") {
            control = (
                <StyledDatePicker
                    selected={moment(this.props.modal.result)}
                    onChange={this.dateChanged}
                    dateFormat="yyyy-MM-dd"
                />
            );
        } else {
            control = (
                <StyledFormControl
                    errorBorder={this.state.inputErrorBorder}
                    value={this.props.modal.result}
                    onChange={this.textChanged}
                />
            );
        }
        return (
            <Modal show={true} onHide={this.closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalData.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{modalData.message}</p>
                    <FormGroup>
                        <ControlLabel>{modalData.fieldLabel}</ControlLabel>
                        {control}
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeModal}>{modalData.cancelButtonText}</Button>
                    <Button
                        onClick={this.confirm}
                        disabled={
                            modalData.requireContent &&
                            this.props.modal.result != null &&
                            (typeof this.props.modal.result !== "string" || this.props.modal.result.trim()) === ""
                        }
                    >
                        {modalData.okButtonText}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
