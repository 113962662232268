import React, { PropTypes } from "react";
import styled from "styled-components";
import { Button, Col, ControlLabel, FormGroup, Modal, Panel, Row } from "react-bootstrap";
import FormControl from "react-bootstrap/es/FormControl";
import classnames from "classnames";
import ConfirmableButton from "../../components/common/ConfirmableButton";
import StyledIcon from "../../components/common/StyledIcon";
import { debounce } from "../../utils";
import { connect } from "react-redux";
import {
    addOrderToEdited,
    contactPersonEditedOrdersAdd,
    contactPersonModalOpenChanged,
    saveOrders,
    setAll
} from "../actions/contactPersonActions";
import StyledButton from "../../components/common/StyledButton";
import getMainOrderNumber from "../../selectors/getMainOrderNumber";
import { STATE_ERROR, STATE_LOADED, STATE_LOADING } from "../../ExternalsModal/actions/externalActions";

const styleOtherOrderHeader = {
    backgroundColor: "#193f77",
    color: "#fff",
};

const OtherOrderHeaderText = styled.span`
    margin: 4px 4px 0 6px;
`;

const OrderContainerContent = styled.div`
    height: 40px;
`;

const OrderContainerContentText = styled.div`
    margin: 5px;
    overflow: hidden;
`;

const OtherOrderContainer = styled.article`
    border-radius: 0 0 3px 3px;
    font-size: 11px;
    background-color: #fff;
    min-height: 40px;
    overflow: hidden;
    margin: 4px 0 4px 0;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
    &:hover {
        box-shadow: 0 3px 8px 0 rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.08);
    }
`;

@connect(
    ({ contactPersons }) => ({
        ...contactPersons.contactPerson
    }),
    dispatch => ({
        closeContactPersonModal: _ => dispatch(contactPersonModalOpenChanged(false)),
        getOrderNumber: order => getMainOrderNumber(order),
        addOrder: (orderId, employeeId, contactPerson, index) => dispatch(addOrderToEdited(orderId, employeeId, contactPerson, index)),
        saveOrders: _ => dispatch(saveOrders()),
        selectAll: (employeeId) => dispatch(setAll(true, employeeId)),
        deselectAll: (employeeId) => dispatch(setAll(false, employeeId))
    })
)

export default class ContactPersonModal extends React.Component {

    render() {
        let modalBody = null;
        let modalFooter = null;
        if (this.props.state === STATE_LOADING) {
            modalBody = (
                <div>
                    <StyledIcon spin center size="big" />
                </div>
            );
        } else if (this.props.state === STATE_ERROR) {
            modalBody = (
                <div>
                    <Panel header="Error" bsStyle="danger">
                        {this.props.error}
                    </Panel>
                </div>
            );
        } else if (this.props.state === STATE_LOADED) {
            let content = this.props.orders.map((o, index) => {
                return (<Col sm={12} md={4}>
                    <div style={{ padding: "5px" }}>
                        <OtherOrderContainer key={o.orderId}
                            onClick={() => this.props.addOrder(o.orderId, this.props.employeeId, !o.contactPerson, index)}
                            style={o.edited && o.contactPerson
                                ? {} : (
                                    o.edited || o.contactPerson
                                        ? { border: "solid 2px green", height: "60px" } : {}
                                )}>
                            <header style={styleOtherOrderHeader}>
                                <OtherOrderHeaderText>
                                    {this.props.getOrderNumber(o)}
                                </OtherOrderHeaderText>
                            </header>
                            <OrderContainerContent>
                                <OrderContainerContentText>
                                    {o.customerCompanyName ? o.customerCompanyName : (o.locationCompanyName ? o.locationCompanyName : "")}
                                </OrderContainerContentText>
                            </OrderContainerContent>
                        </OtherOrderContainer>
                    </div>
                </Col>);
            });
            modalBody = (
                <Row>
                    {content}
                </Row>
            );
        }

        modalFooter = (
            <div>
                <StyledButton className="pull-left" bsStyle="default" onClick={() => this.props.selectAll(this.props.employeeId)}>Select all</StyledButton>
                <StyledButton className="pull-left" bsStyle="default" onClick={() => this.props.deselectAll(this.props.employeeId)}>Deselect all</StyledButton>
                <ConfirmableButton
                    className="pull-right"
                    bsStyle="primary"
                    onConfirm={this.props.saveOrders}
                >
                    Save
                </ConfirmableButton>
            </div>
        );

        return (
            <Modal show={this.props.contactPersonModalOpen} onHide={this.props.closeContactPersonModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add access to orders for {this.props.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalBody}
                </Modal.Body>
                <Modal.Footer>
                    {modalFooter}
                </Modal.Footer>
            </Modal>
        );
    }
}
