import React from "react";
import Regulations from "./Regulations";

export default class RegulationsPage extends React.Component {

    render() {
        return (
            <Regulations />
        );
    }
}
