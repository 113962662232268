import React from "react";
import MatCheckbox from "../../components/common/Mat/MatCheckbox";
import { connect } from "react-redux";
import { selectedChanged, loadCalculatableTotals } from "../actions/resultsActions";

export default connect()(({ row, dispatch }) => {
    return (
        <MatCheckbox
            checked={row.selected}
            onChange={ev => {
                dispatch(selectedChanged(row._id, ev.target.checked));
                dispatch(loadCalculatableTotals())
            }}
        />
    );
});
