import React from "react";
import { connect } from "react-redux";
import { STATE_LOADED } from "../../actions/customerPanelExternalsActions";
import { TableBody } from "./Styles";

@connect(({ clientPanel }) => clientPanel.clientPanelExternals)
export default class WorkingHours2 extends React.Component {
    render() {
        let hoursSummary = "";
        if (this.props.state === STATE_LOADED) {
            let hoursReportData = this.props.externals.workingHours;

            hoursSummary = (
                <tbody>
                    {!!hoursReportData.sumFlatRate && (
                        <tr>
                            <td>Flat rate: </td>
                            <td> {hoursReportData.sumFlatRate} working hours.</td>
                        </tr>
                    )}
                    {!!hoursReportData.sumWorkingHours && (
                        <tr>
                            <td>Detailed working hour(s): </td>
                            <td> {hoursReportData.sumWorkingHours} includes: </td>
                        </tr>
                    )}
                    {!!hoursReportData.sumWorkingHours && (
                        <tr>
                            <td>Day: </td>
                            <td> {hoursReportData.sumNormalHours}</td>
                        </tr>
                    )}
                    {!!hoursReportData.sumWorkingHours && (
                        <tr>
                            <td>Night: </td>
                            <td> {hoursReportData.sumNightHours}</td>
                        </tr>
                    )}
                    {!!hoursReportData.sumWorkingHours && (
                        <tr>
                            <td>Saturday: </td>
                            <td> {hoursReportData.sumSaturdayHours}</td>
                        </tr>
                    )}
                    {!!hoursReportData.sumWorkingHours && (
                        <tr>
                            <td>Sunday: </td>
                            <td> {hoursReportData.sumSundayHours}</td>
                        </tr>
                    )}
                    {!!hoursReportData.sumWorkingHours && !!hoursReportData.sumFlatRate && (
                        <tr>
                            <td>Summary flat rate + detailed working hour(s): </td>
                            <td>{hoursReportData.sumWorkingHours + hoursReportData.sumFlatRate}</td>
                        </tr>
                    )}
                </tbody>
            );
        }
        return <TableBody responsive={true}>{hoursSummary}</TableBody>;
    }
}
