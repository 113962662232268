import React from "react";
import styled, { css } from "styled-components";

const PositioningWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    ${props =>
        props.slim &&
        css`
            padding-top: 0;
            padding-bottom: 0;
        `}
    ${props =>
        props.quiteSlim &&
        css`
            padding-top: 10px;
            padding-bottom: 10px;
        `}
`;

const SpinWrapper = styled.div`
    position: relative;
    display: inline-block;
    transform: rotate(45deg);
    animation: antRotate 1.2s infinite linear;
    @keyframes antRotate {
        to {
            transform: rotate(405deg);
        }
    }
`;
const SpinDot = styled.div`
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    background-color: rgb(68, 130, 255);
    border-radius: 100%;
    transform: scale(0.75);
    transform-origin: 50% 50%;
    opacity: 0.3;
    animation: antSpinMove 1s infinite linear alternate;

    &:nth-child(1) {
        top: 0;
        left: 0;
    }
    &:nth-child(2) {
        top: 0;
        right: 0;
        animation-delay: 0.4s;
    }
    &:nth-child(3) {
        right: 0;
        bottom: 0;
        animation-delay: 0.8s;
    }
    &:nth-child(4) {
        bottom: 0;
        left: 0;
        animation-delay: 1.2s;
    }
    @keyframes antSpinMove {
        to {
            opacity: 1;
        }
    }
`;

export default function MatSpinner(props) {
    return (
        <PositioningWrapper {...props}>
            <SpinWrapper>
                <SpinDot />
                <SpinDot />
                <SpinDot />
                <SpinDot />
            </SpinWrapper>
        </PositioningWrapper>
    );
}
