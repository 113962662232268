/**
 * When applied to a store saves last 300 actions in an array inside the store.
 */
export default function diagnosticsEnhancer() {
    return createStore => (reducer, ...args) => {
        let store = createStore((state, action) => {

            let stateWithSavedAction = (state && !action._diagnosticsIgnore) ? {
                ...state,
                diagnostics: {
                    ...state.diagnostics,
                    actionBuffer: [
                        ...state.diagnostics.actionBuffer.slice(Math.max(0, state.diagnostics.actionBuffer.length - 300)), // keep only last 300 actions
                        {
                            ...action,
                            _date: new Date().toJSON()
                        }
                    ]
                }
            } : state;

            return reducer(stateWithSavedAction, action);
        }, ...args);
        return store;
    };
}
