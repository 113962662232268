import { makeActionCreator } from "../../utils";
import { makeApiUrl } from "../../api";

export const EMAIL_CHANGED = "PASSWORD_RESET.EMAIL_CHANGED";
export const PASSWORD_RESET_STATE_CHANGED = "PASSWORD_RESET.PASSWORD_RESET_STATE_CHANGED";
export const ERROR_MESSAGE_CHANGED = "PASSWORD_RESET.ERROR_MESSAGE_CHANGED";
export const SHOW_SUCCESS_MODAL_CHANGED = "PASSWORD_RESET.SHOW_SUCCESS_MODAL_CHANGED";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_ERROR = "STATE_ERROR";
export const STATE_LOADING = "STATE_LOADING";

export const emailChanged = makeActionCreator(EMAIL_CHANGED, "email");
export const errorMessageChanged = makeActionCreator(ERROR_MESSAGE_CHANGED, "errorMessage");
export const passwordResetStateChanged = makeActionCreator(PASSWORD_RESET_STATE_CHANGED, "passwordResetState");
export const showSuccessModalChanged = makeActionCreator(SHOW_SUCCESS_MODAL_CHANGED, "showSuccessModal");

export function resetPassword() {
    return async (dispatch, getState) => {
        dispatch(passwordResetStateChanged(STATE_LOADING));
        let passwordResetUrl = makeApiUrl("/api/auth/reset-password");
        let data, response;
        try {
            response = await fetch(passwordResetUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: getState().passwordReset.email
                })
            });
            data = await response.json();
            if (response.ok) {
                dispatch(passwordResetStateChanged(STATE_DEFAULT));
                dispatch(showSuccessModalChanged(true));
            } else {
                throw new Error(data.message || "Unknown error");
            }
        } catch (e) {
            dispatch(passwordResetStateChanged(STATE_ERROR));
            dispatch(emailChanged("")); // clear the email
            dispatch(errorMessageChanged(e.message));

        }


    };
}
