import {
    STATE_DEFAULT,
    DATA_CHANGED,
    NAME_CHANGED,
    DATE_OF_BIRTH_CHANGED,
    STATE_CHANGED,
    DUPLICATE_STATUS_CHANGED,
    DUPLICATE_STATUS_DEFAULT,
    TEMPLATE_ADDED,
    TEMPLATE_REMOVED,
    TEMPLATE_DATA_CHANGED,
    TEMPLATE_STATE_CHANGED,
    TEMPLATE_FIELD_VALUES_CHANGED,
    TEMPLATE_FIELD_VALUE_CHANGED,
    TEMPLATE_FIELD_AUTOCOMPLETE_DATA_CHANGED
} from "../actions/editEmployeeActions";
import { updateRow } from "../../utils";

export const initialState = {
    state: STATE_DEFAULT,
    duplicateStatus: DUPLICATE_STATUS_DEFAULT,
    _id: null,
    name: null,
    dateOfBirth: null,
    templates: []
};
export default function editEmployee(state = initialState, action) {
    switch (action.type) {
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case DATA_CHANGED:
            return {
                ...state,
                ...action.payload
            };
        case NAME_CHANGED:
            return {
                ...state,
                name: action.payload
            };
        case DATE_OF_BIRTH_CHANGED:
            return {
                ...state,
                dateOfBirth: action.payload
            };
        case DUPLICATE_STATUS_CHANGED:
            return {
                ...state,
                duplicateStatus: action.payload
            };
        case TEMPLATE_ADDED:
            return {
                ...state,
                templates: [...state.templates, action.payload]
            };
        case TEMPLATE_REMOVED:
            return {
                ...state,
                templates: state.templates.filter((_, i) => i !== action.templateIndex)
            };
        case TEMPLATE_DATA_CHANGED:
            return {
                ...state,
                templates: updateRow(state.templates, "templateData", action, "templateIndex")
            };
        case TEMPLATE_STATE_CHANGED:
            return {
                ...state,
                templates: updateRow(state.templates, "state", action, "templateIndex")
            };
        case TEMPLATE_FIELD_VALUES_CHANGED:
            return {
                ...state,
                templates: updateRow(state.templates, "fieldValues", action, "templateIndex")
            };
        case TEMPLATE_FIELD_AUTOCOMPLETE_DATA_CHANGED:
            return {
                ...state,
                templates: state.templates.map((t, i) => {
                    if (i !== action.templateIndex) return t;
                    return {
                        ...t,
                        autocompleteData: {
                            ...t.autocompleteData,
                            [action.fieldId]: action.payload
                        }
                    };
                })
            };
        case TEMPLATE_FIELD_VALUE_CHANGED:
            return {
                ...state,
                templates: state.templates.map((t, i) => {
                    if (i !== action.templateIndex) return t;
                    return {
                        ...t,
                        fieldValues: {
                            ...t.fieldValues,
                            [action.fieldId]: action.payload
                        }
                    };
                })
            };
        default:
            return state;
    }
}
