import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import styled from "styled-components";

const Wrapper = styled.div`
    &.hider-enter {
        opacity: 0.01;
        transform: translateY(-50px);
    }
    &.hider-enter-active {
        opacity: 1;
        transition: ${p => p.duration}ms ease-in-out;
        transform: translateY(0);
    }
    &.hider-leave {
        opacity: 1;
        transform: translateY(0);
    }
    &.hider-leave-active {
        opacity: 0.01;
        transition: ${p => p.duration}ms ease-in-out;
        transform: translateY(-50px);
    }
`;

const FirstChild = props => {
    const childrenArray = React.Children.toArray(props.children);
    return childrenArray[0] || null;
};

/**
 * A component which shows and hides its children with an animation.
 */
export default ({ show, children, duration = 200 }) => (
    <ReactCSSTransitionGroup transitionName="hider" transitionEnterTimeout={duration} transitionLeaveTimeout={duration}>
        {show && (
            <Wrapper key="child" duration={duration}>
                {children}
            </Wrapper>
        )}
    </ReactCSSTransitionGroup>
);
