import { makeActionCreator } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import { updateDesktop, desktopsChanged, loadOrders } from "./desktopsActions";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_ERROR = "STATE_ERROR";

export const COMPANY_SEARCH_CONTENT_CHANGED = "MAINS.COMPANIES_LIST.COMPANY_SEARCH_CONTENT_CHANGED";
export const SEARCH_STATE_CHANGED = "MAINS.COMPANIES_LIST.SEARCH_STATE_CHANGED";
export const COMPANY_HEADERS_CHANGED = "MAINS.COMPANIES_LIST.COMPANY_HEADERS_CHANGED"; // list of displayed companies changed
export const ERROR_CHANGED = "MAINS.COMPANIES_LIST.ERROR_CHANGED";

export const companySearchContentChanged = makeActionCreator(COMPANY_SEARCH_CONTENT_CHANGED, "payload");
export const searchStateChanged = makeActionCreator(SEARCH_STATE_CHANGED, "payload");
export const companyHeadersChanged = makeActionCreator(COMPANY_HEADERS_CHANGED, "payload");
export const errorChanged = makeActionCreator(ERROR_CHANGED, "payload");

export function companiesSearched(query) {
    return async dispatch => {
        dispatch(companySearchContentChanged(query));
        // TODO: add debouncing
        await dispatch(loadCompaniesList());
    };
}

export function loadCompaniesList() {
    return async (dispatch, getState) => {
        try {
            let query = getState().desktops.companiesList.companySearchContent;
            dispatch(searchStateChanged(STATE_LOADING));
            let resp = [];
            let data = [];
            if (query !== "") {
                resp = await dispatch(apiFetch(`/api/companies/also-by-order/search?query=${encodeURIComponent(query)}`));
                data = await resp.json();
                if (!Array.isArray(data)) {
                    throw new Error("Data returned by API is not an object!");
                }
            }
            //delete from result current available companies on the desktop
            let desktopCompanies = getState().desktops.desktop.companies
            desktopCompanies.forEach(c => {
                data = data.filter((obj) => {
                    return obj._id !== c.company._id;
                });
            });
            dispatch(searchStateChanged(STATE_DEFAULT));
            dispatch(companyHeadersChanged(data));
        } catch (e) {
            dispatch(searchStateChanged(STATE_ERROR));
            dispatch(errorChanged(e.message || "Unknown error"));
        }
    };
}

export function addCompanyToDesktop(company) {
    return async (dispatch, getState) => {
        try {
            let activeDesktop = getState().desktops.desktop.activeDesktop;
            let companies = getState().desktops.desktop.companies;
            companies.unshift({
                orders: [],
                companyId: company._id,
                company: company
            });
            await dispatch(updateDesktop(activeDesktop, companies));
            //if is a first company on the desktop show with open orders proposition
            dispatch(loadOrders(0));
            dispatch(desktopsChanged(companies));
            dispatch(searchStateChanged(STATE_DEFAULT));
            dispatch(companySearchContentChanged(""));
            dispatch(companyHeadersChanged([]));
        } catch (e) {
            dispatch(searchStateChanged(STATE_ERROR));
            dispatch(errorChanged(e.message || "Unknown error"));
        }
    };
}
