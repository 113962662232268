import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import BaloonCell from "../../components/common/AutoTable/cells/BaloonCell";
import SimpleText from "../../components/common/AutoTable/cells/SimpleText";
import MatSortableTableHeader from "../../components/common/AutoTable/MatSortableTableHeader";
import OrdersListTableRow from "../../OrdersListPage/components/OrdersListTableRow";
import { formatDate } from "../../utils";
import { getDetailsURL, goToOrder } from "./actions/resultsActions";

const DataColumn = styled.div`
    display: inline-flex;
    flex-direction: column;
    min-width: 200px;
    ${p =>
        p.colored &&
        css`
            color: #41b9f1;
        `}
`;

const ClickableTr = styled(OrdersListTableRow)`
    cursor: pointer;
`;

export default {
    header: MatSortableTableHeader,
    // footer: MatSortableTableFooter,
    
    rowsSelector: state => state.clientPanel.customerOrdersList.results.rows,
    useMatTable: true,
    showNoResultsText: true,
    stickyHeader: true,
    sitckyTopMargin: "0",
    customRow: ({ row, children, dispatch }) => (
        <ClickableTr onClick={() => dispatch(goToOrder(row._id))} row={row}>{children}</ClickableTr>
    ),
    columns: [
        {
            key: "orderNumber",
            title: "Order number",
            valueSelector: (r, i) => r.orderNumber,
            cell: connect((state, ownProps) => ({ url: getDetailsURL(ownProps.row._id, state) }))(
                ({ value, row, url }) => (
                    <DataColumn>
                        <Link to={url}>{value}</Link>
                        <div>start: {formatDate(row.startDate)}</div>
                        <div>end: {row.closedAt && formatDate(row.closedAt)}</div>
                    </DataColumn>
                )
            ),
            sortable: true
        },
        {
            key: "officialOrderName",
            title: "Order name",
            valueSelector: (r, i) => r.officialOrderName,
            cell: ({ value, row }) => (
                <DataColumn colored>
                    <div>{value}</div>
                    <div>Customer: {row.customer}</div>
                    <div>Location: {row.location}</div>
                </DataColumn>
            ),
            sortable: true
        },
        {
            key: "partNames",
            title: "Part names",
            valueSelector: (r, i) => r.partNames,
            cell: BaloonCell,
            sortable: true
        },
        {
            key: "partNumbers",
            title: "Part numbers",
            valueSelector: (r, i) => r.partNumbers,
            cell: BaloonCell,
            sortable: true
        },

        {
            key: "customerTotalChecked",
            title: "Checked",
            valueSelector: (r, i) => r.customerTotalChecked.toString(),
            cell: SimpleText,
            sortable: true
        },
        {
            key: "customerNok",
            title: "NOK",
            valueSelector: (r, i) => r.customerNok.toString(),
            cell: SimpleText,
            sortable: true
        },
        {
            key: "customerReworked",
            title: "Reworked",
            valueSelector: (r, i) => r.customerReworked.toString(),
            cell: SimpleText,
            sortable: true
        },
        {
            key: "customerOk",
            title: "OK",
            valueSelector: (r, i) => (r.customerTotalChecked - r.customerNok).toString(),
            cell: SimpleText,
            sortable: true
        },
        {
            key: "customerTotalHours",
            title: "Hours",
            valueSelector: (r, i) => r.customerTotalHours.toFixed(2),
            cell: SimpleText,
            sortable: true
        }
    ]
};
