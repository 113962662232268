const EXTRA_FIELD_LABELS = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "R",
    "S",
    "T",
    "U",
    "W",
    "X",
    "Y",
    "Z"
];

export const makeInitialState = _ => ({
    problemDescriptionContent: "",
    extraFields: [],
    isLoading: false,
    modified: false
});

export default function problemDescriptionHOR({
    PROBLEM_DESCRIPTION_CONTENT_CHANGED,
    EXTRA_FIELD_ADDED,
    EXTRA_FIELD_CHANGED,
    EXTRA_FIELD_REMOVED,
    RESET,
    DATA_CHANGED,
    PROBLEM_DESCRIPTION_CHANGED = Symbol(),
    IS_LOADING_CHANGED = Symbol()
}) {
    return (state = makeInitialState(), action) => {
        switch (action.type) {
            case RESET:
                return makeInitialState();
            case DATA_CHANGED:
                return {
                    modified: false,
                    ...action.payload.problemDescription
                };
            case PROBLEM_DESCRIPTION_CONTENT_CHANGED:
                return {
                    ...state,
                    modified: true,
                    problemDescriptionContent: action.payload
                };
            case EXTRA_FIELD_ADDED: {
                if (state.extraFields.length >= EXTRA_FIELD_LABELS.length) {
                    return state;
                }
                // when there were no fields we want to add a and b
                let amountOfFieldsToAdd = state.extraFields.length === 0 ? 2 : 1;

                let fieldsToAdd = [];
                EXTRA_FIELD_LABELS.some(label => {
                    // skip this label when it was already added
                    if (state.extraFields.some(f => f.label === label)) return false;
                    fieldsToAdd.push({
                        label,
                        value: ""
                    });
                    amountOfFieldsToAdd--;
                    // finished adding
                    if (amountOfFieldsToAdd === 0) {
                        return true;
                    }
                    return false;
                });
                let extraFields = [...state.extraFields, ...fieldsToAdd];
                extraFields.sort((a, b) => {
                    return EXTRA_FIELD_LABELS.indexOf(a.label) - EXTRA_FIELD_LABELS.indexOf(b.label);
                });
                return {
                    ...state,
                    modified: true,
                    extraFields
                };
            }
            case EXTRA_FIELD_REMOVED:
                return {
                    ...state,
                    modified: true,
                    extraFields: state.extraFields.filter((f, i) => i !== action.fieldIndex)
                };
            case EXTRA_FIELD_CHANGED:
                return {
                    ...state,
                    modified: true,
                    extraFields: state.extraFields.map((f, i) => {
                        if (i !== action.fieldIndex) return f;
                        return {
                            label: f.label,
                            value: action.payload
                        };
                    })
                };
            case PROBLEM_DESCRIPTION_CHANGED:
                return {
                    ...state,
                    modified: false,
                    ...action.payload
                };
            case IS_LOADING_CHANGED:
                return {
                    ...state,
                    isLoading: action.payload
                };
            default:
                return state;
        }
    };
}
