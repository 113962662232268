import { stringify as stringifyQueryString } from "querystring";
import React from "react";
import { Link } from "react-router-dom";
import MatSortArrowIcon from "../Mat/MatSortArrowIcon";

export default ({ model, processedColumns, runtimeProps = {} }) => (
    <tfoot>
        <tr>
            {processedColumns.map((c, i) => {
                if (c.customFooterCell) {
                    return <c.customFooterCell column={c} key={i} model={model} />;
                }

                return <th key={i} />;
            })}
        </tr>
    </tfoot>
);
