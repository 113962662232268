import React from "react";
import { connect } from "react-redux";
import { FormGroup, ControlLabel, FormControl, Row, Col } from "react-bootstrap";
import {
    flatRateChanged,
    workingHoursChanged,
    normalHoursChanged,
    nightHoursChanged,
    saturdayHoursChanged,
    sundayHoursChanged
} from "../actions/externalActions";
import { parseFloatWithZeroFallback } from "../../utils";
import styled from "styled-components";
import isExternalDisabled from "../selectors/isExternalDisabled";

const HoursTable = styled.table`
    border: none !important;
    td {
        padding: 10px;
    }
`;
@connect(
    state => ({
        ...state.externals.external,
        disabled: isExternalDisabled(state)
    }),
    dispatch => ({
        flatRateChanged: ev => dispatch(flatRateChanged(ev.target.checked)),
        workingHoursChanged: ev => dispatch(workingHoursChanged(ev.target.value)),
        normalHoursChanged: ev => dispatch(normalHoursChanged(ev.target.value)),
        nightHoursChanged: ev => dispatch(nightHoursChanged(ev.target.value)),
        saturdayHoursChanged: ev => dispatch(saturdayHoursChanged(ev.target.value)),
        sundayHoursChanged: ev => dispatch(sundayHoursChanged(ev.target.value))
    })
)
export default class HoursForm extends React.Component {
    render() {
        let p = this.props;
        let hoursFieldsWithTypes = [];

        if (!p.flatRate) {
            hoursFieldsWithTypes = (
                <HoursTable>
                    <tbody>
                        <tr>
                            <td>
                                <ControlLabel>Normal</ControlLabel>
                            </td>
                            <td>
                                <FormControl
                                    disabled={this.props.disabled}
                                    type="number"
                                    className="input-very-small"
                                    value={p.normalHours}
                                    onChange={p.normalHoursChanged}
                                />
                            </td>
                            <td>
                                <ControlLabel>Night</ControlLabel>
                            </td>
                            <td>
                                <FormControl
                                    disabled={this.props.disabled}
                                    type="number"
                                    className="input-very-small"
                                    value={p.nightHours}
                                    onChange={p.nightHoursChanged}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ControlLabel>Saturday</ControlLabel>
                            </td>
                            <td>
                                <FormControl
                                    disabled={this.props.disabled}
                                    type="number"
                                    className="input-very-small"
                                    value={p.saturdayHours}
                                    onChange={p.saturdayHoursChanged}
                                />
                            </td>
                            <td>
                                <ControlLabel>Sunday</ControlLabel>
                            </td>
                            <td>
                                <FormControl
                                    disabled={this.props.disabled}
                                    type="number"
                                    className="input-very-small"
                                    value={p.sundayHours}
                                    onChange={p.sundayHoursChanged}
                                />
                            </td>
                        </tr>
                    </tbody>
                </HoursTable>
            );
        }

        let workingHours = p.flatRate
            ? p.workingHours
            : parseFloatWithZeroFallback(p.normalHours) +
              parseFloatWithZeroFallback(p.nightHours) +
              parseFloatWithZeroFallback(p.saturdayHours) +
              parseFloatWithZeroFallback(p.sundayHours);
        return (
            <div>
                <Row>
                    <Col md={5}>
                        <FormGroup className="form-inline">
                            <ControlLabel>Working hours</ControlLabel>
                            <FormControl
                                type="number"
                                className="input-very-small"
                                disabled={!p.flatRate || this.props.disabled}
                                value={workingHours}
                                onChange={p.workingHoursChanged}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <input
                            type="checkbox"
                            className="checkbox-big inline"
                            checked={p.flatRate}
                            onChange={p.flatRateChanged}
                            disabled={this.props.disabled}
                        />
                        <ControlLabel>Flat rate</ControlLabel>
                    </Col>
                </Row>
                {hoursFieldsWithTypes}
            </div>
        );
    }
}
