import React from "react";
import { connect } from "react-redux";
import SidebarMenu from "../../components/common/SidebarMenu";

@connect(({ clientPanel, router }) => ({
    showMainMenu: clientPanel.clientPanels.showMainMenu,
    shouldShowDetailedReport:
        router.location.pathname.startsWith("/customer") &&
        !router.location.pathname.startsWith("/customer/orders/list")
}))
export default class CustomerMenu extends React.Component {
    render() {
        return (
            <SidebarMenu
                expanded={this.props.showMainMenu}
                items={[
                    {
                        show: true,
                        key: "customerpanel-externals-view",
                        label: "Dashboard",
                        to: "/customer/orders/list",
                        glyph: "th"
                    },
                    {
                        show: this.props.shouldShowDetailedReport,
                        key: "customerpanel-orders-list",
                        label: "Detailed report",
                        to: "/customer/",
                        glyph: "search"
                    }
                ]}
            />
        );
    }
}
