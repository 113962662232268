import { makeActionCreator } from "../utils";

export const MODAL_TYPE_ALERT = "MODAL_TYPE_ALERT";
export const MODAL_TYPE_CONFIRM = "MODAL_TYPE_CONFIRM";
export const MODAL_TYPE_LINK_LIST = "MODAL_TYPE_LINK_LIST";
export const MODAL_TYPE_PROMPT = "MODAL_TYPE_PROMPT";

export const MODAL_STATE_OPENED = "MODAL_STATE_OPENED";
/**
 * User chose an option and now the decision must be picked up by the subscriber. Not used in alert modal.
 */
export const MODAL_STATE_FINISHED = "MODAL_STATE_FINISHED";

export const MODAL_CREATED = "MODALS.MODAL_CREATED";
export const MODAL_DELETED = "MODALS.MODAL_DELETED";
export const MODAL_STATE_CHANGED = "MODALS.MODAL_STATE_CHANGED";
export const MODAL_RESULT_CHANGED = "MODALS.MODAL_RESULT_CHANGED";

export const modalCreated = makeActionCreator(MODAL_CREATED, "modalId", "data", "defaultResult");
export const modalDeleted = makeActionCreator(MODAL_DELETED, "modalId");
export const modalStateChanged = makeActionCreator(MODAL_STATE_CHANGED, "modalId", "payload");
export const modalResultChanged = makeActionCreator(MODAL_RESULT_CHANGED, "modalId", "payload");

function showBasicModalHOA(type, defaultOptions = {}) {
    return function showConfirmModal(opts) {
        return async (dispatch, getState, { getStore }) => {
            let modalId = Math.floor(Math.random() * 1000000);
            dispatch(
                modalCreated(modalId, {
                    type,
                    ...defaultOptions,
                    ...opts
                })
            );
            return await new Promise(res => {
                let unsubscribe = getStore().subscribe(_ => {
                    if (getState().modals.modals.every(m => m.modalId !== modalId)) {
                        res();
                        unsubscribe();
                    }
                });
            });
        };
    };
}

export const showAlertModal = showBasicModalHOA(MODAL_TYPE_ALERT);
export const showLinkListModal = showBasicModalHOA(MODAL_TYPE_LINK_LIST, { links: [] });

/**
 * Shows a customizable, async confirm modal
 */
export function showConfirmModal({ message = "", title = "", okButtonText = "OK", cancelButtonText = "Cancel" }) {
    return async (dispatch, getState, { getStore }) => {
        let modalId = Math.floor(Math.random() * 1000000);
        dispatch(
            modalCreated(modalId, {
                type: MODAL_TYPE_CONFIRM,
                message,
                title,
                okButtonText,
                cancelButtonText
            })
        );
        return await new Promise(res => {
            let unsubscribe = getStore().subscribe(_ => {
                let modal = null;
                modal = getState().modals.modals.find(m => modalId === m.modalId && m.state === MODAL_STATE_FINISHED);
                if (modal) {
                    res(modal.result);
                    unsubscribe();
                }
            });
        });
    };
}

/**
 * Shows a customizable, async confirm modal
 */
export function showPromptModal({
    message = "",
    title = "",
    okButtonText = "OK",
    cancelButtonText = "Cancel",
    fieldLabel = "",
    requireContent = false,
    fieldType = "text",
    defaultValue = "",
    validateContent = false
}) {
    return async (dispatch, getState, { getStore }) => {
        let modalId = Math.floor(Math.random() * 1000000);
        dispatch(
            modalCreated(
                modalId,
                {
                    type: MODAL_TYPE_PROMPT,
                    message,
                    title,
                    okButtonText,
                    cancelButtonText,
                    fieldLabel,
                    requireContent,
                    fieldType,
                    validateContent
                },
                defaultValue
            )
        );
        return await new Promise(res => {
            let unsubscribe = getStore().subscribe(_ => {
                let modal = null;
                modal = getState().modals.modals.find(m => modalId === m.modalId && m.state === MODAL_STATE_FINISHED);
                if (modal) {
                    res(modal.result);
                    unsubscribe();
                }
            });
        });
    };
}
