import React from "react";
import { connect } from "react-redux";
import TemplateForm from "./form/TemplateForm";
import StyledButton from "../../../components/common/StyledButton";
import { Modal, Button } from "react-bootstrap";

@connect(state => state.hr.editTemplate)
export default class TemplatePreviewModal extends React.Component {
    constructor(p) {
        super(p);
        this.state = {
            modalVisible: false
        };
    }
    showModal = _ => {
        this.setState({
            modalVisible: true
        });
    };
    hideModal = _ => {
        this.setState({
            modalVisible: false
        });
    };
    render() {
        return (
            <div>
                <StyledButton bsStyle="warning" wide onClick={this.showModal} disabled={this.props.disabled}>
                    Preview
                </StyledButton>
                <Modal show={this.state.modalVisible} onHide={this.hideModal} dialogClassName="modal-centered">
                    <Modal.Header closeButton>
                        <Modal.Title>Template preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TemplateForm template={this.props} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.hideModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
