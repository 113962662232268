import {
    NEW_PASSWORD_CHANGED,
    NEW_PASSWORD2_CHANGED,
    OLD_PASSWORD_CHANGED,
    STATE_DEFAULT,
    PASSWORD_ERROR_CHANGED,
    SHOW_COMMUNIQUE_CHANGED
} from "../actions/accountActions";

const initialState = {
    state: STATE_DEFAULT,
    oldPassword: "",
    newPassword: "",
    newPassword2: "",
    errorMessage: null,
    showCommunique: false
};

export default function accounts(state = initialState, action) {
    switch (action.type) {
        case OLD_PASSWORD_CHANGED:
            return {
                ...state,
                oldPassword: action.payload
            };
        case NEW_PASSWORD_CHANGED:
            return {
                ...state,
                newPassword: action.payload
            };
        case NEW_PASSWORD2_CHANGED:
            return {
                ...state,
                newPassword2: action.payload
            };
        case PASSWORD_ERROR_CHANGED:
            return {
                ...state,
                errorMessage: action.payload
            };
        case SHOW_COMMUNIQUE_CHANGED:
            return {
                ...state,
                showCommunique: action.payload
            };
        default:
            return state;
    }
}