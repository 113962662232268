import styled, { css } from "styled-components";

export default styled.tr`
    ${props =>
        props.row.finished &&
        css`
            td {
                background-color: rgba(0, 0, 0, 0.22);
            }
        `}
`;
