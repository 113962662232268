import { STATE_INITIALIZING, STATE_CHANGED, BUFFER_FLUSHED, SESSION_ID_CHANGED } from "../actions/diagnosticsActions";

const initialState = {
    state: STATE_INITIALIZING,
    sessionId: null,
    actionBuffer: [] // will be filled by enhancer
};

export default function diagnostics(state = initialState, action) {
    switch (action.type) {
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case BUFFER_FLUSHED:
            return {
                ...state,
                actionBuffer: []
            };
        case SESSION_ID_CHANGED:
            return {
                ...state,
                sessionId: action.payload
            };
        default:
            return state;
    }
}
