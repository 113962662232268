import {
    EMPLOYEES_CHANGED,
    STATE_CHANGED,
    TOTAL_COUNT_CHANGED,
    EMPLOYEE_DELETED,
    TEMPLATE_COLUMNS_CHANGED
} from "../actions/employeesListActions";

export const initialState = {
    state: null,
    employees: null,
    templateColumns: null,
    totalCount: 0
};

export default function employeesList(state = initialState, action) {
    switch (action.type) {
        case EMPLOYEES_CHANGED:
            return {
                ...state,
                employees: action.payload
            };
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: action.payload
            };
        case EMPLOYEE_DELETED:
            return {
                ...state,
                employees: state.employees.filter(e => e._id !== action.payload)
            };
        case TEMPLATE_COLUMNS_CHANGED:
            return {
                ...state,
                templateColumns: action.payload
            };
        default:
            return state;
    }
}
