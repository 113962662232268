import React from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import AutoRow from "./AutoRow";
import AutoCell from "./AutoCell";
import IconButton from "../IconButton";
import MatTable from "../Mat/MatTable";
import MatSpinner from "../Mat/MatSpinner";

@connect(
    (state, ownProps) => ({
        rows: ownProps.model.rowsSelector(state),
        processedColumns: ownProps.model.processColumns
            ? ownProps.model.processColumns(ownProps.model.columns, state)
            : ownProps.model.columns
    }),
    null,
    null,
    { pure: true }
)
export default class AutoTable extends React.PureComponent {
    firstCellRefs = [];
    handleFirstCellRef = index => ref => {
        this.firstCellRefs[index] = ref;
    };
    render() {
        let header, content;
        if (window.matchMedia("(min-device-width: 960px)").matches) {
            header = (
                <this.props.model.header
                    disabled={this.props.disabled}
                    model={this.props.model}
                    runtimeProps={this.props.runtimeProps}
                    processedColumns={this.props.processedColumns}
                />
            );
            if (this.props.loading) {
                content = (
                    <tr>
                        <td colSpan={this.props.processedColumns.length}>
                            <MatSpinner />
                        </td>
                    </tr>
                );
            } else if (this.props.model.showNoResultsText && this.props.rows.length <= 0) {
                content = (
                    <tr>
                        <td colSpan={this.props.processedColumns.length}>
                            <center>No results for this query.</center>
                        </td>
                    </tr>
                );
            } else {
                // TODO: generate key using model selector
                content = this.props.rows.map((r, i) => {
                    let nextRowRef = this.firstCellRefs[i + 1];
                    let focusNextRowCallback = null;
                    if (nextRowRef && nextRowRef.focus) {
                        focusNextRowCallback = _ => nextRowRef.focus();
                    }
                    return (
                        <AutoRow
                            key={r._id || i}
                            allRows={this.props.rows}
                            model={this.props.model}
                            rowIndex={i}
                            row={r}
                            disabled={this.props.disabled}
                            rowsLength={this.props.rows.length}
                            focusNextRow={focusNextRowCallback}
                            firstCellRef={this.handleFirstCellRef(i)}
                            processedColumns={this.props.processedColumns}
                            dispatch={this.props.dispatch}
                            focusLastRowOnNextUpdate={() => {
                                setTimeout(() => {
                                    this.firstCellRefs[this.firstCellRefs.length - 1].focus();
                                }, 1);
                            }}
                        />
                    );
                });
            }
        } else {
            header = null;
            content = this.props.rows.map((row, rowIndex) =>
                this.props.processedColumns.map((c, columnIndex) => [
                    <tr key={(row._id || rowIndex) + columnIndex + "_H"}>
                        <th>{c.title}</th>
                    </tr>,
                    <tr>
                        <AutoCell
                            key={(row._id || rowIndex) + columnIndex + "_V"}
                            model={this.props.model}
                            row={row}
                            rowIndex={rowIndex}
                            column={c}
                            columnIndex={columnIndex}
                            disabled={this.props.disabled}
                            rowsLength={this.props.rows.length}
                        />
                    </tr>
                ])
            );
        }
        let footer = null;
        if (this.props.model.footer) {
            footer = (
                <this.props.model.footer
                    disabled={this.props.disabled}
                    model={this.props.model}
                    runtimeProps={this.props.runtimeProps}
                    processedColumns={this.props.processedColumns}
                />
            );
        } else if (this.props.model.addRowActionCreator) {
            footer = (
                <tfoot>
                    <tr>
                        <td colSpan={this.props.model.addButtonColSpan || this.props.model.columns.length}>
                            <IconButton
                                disabled={this.props.disabled}
                                wide
                                onClick={_ => this.props.dispatch(this.props.model.addRowActionCreator())}
                            />
                        </td>
                        {this.props.model.footerExtraCells && (
                            <this.props.model.footerExtraCells rows={this.props.rows} model={this.props.model} />
                        )}
                    </tr>
                </tfoot>
            );
        }
        let TableComponent = this.props.model.useMatTable ? MatTable : Table;
        return (
            <TableComponent className={this.props.className}>
                {header}
                <tbody>{content}</tbody>
                {footer}
            </TableComponent>
        );
    }
}
