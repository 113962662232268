import React from "react";
import MatButton from "../../components/common/Mat/MatButton";
import { LinkContainer } from "react-router-bootstrap";
import { encodeParams } from "../../utils";

export default ({ row }) => {
    return row.finished ? null : (
        <LinkContainer to={encodeParams`/dashboard/reports?order=${row._id}`}>
            <MatButton success orderFinishColor slim>
                Report&nbsp;+
            </MatButton>
        </LinkContainer>
    );
};
