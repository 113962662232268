import React from "react";
import CalendarMonthDisplay from "./CalendarMonthDisplay";
import styled from "styled-components";
import { incrementMonth } from "./dateUtils";

const Flex = styled.div`
    display: flex;
    flex-direction: row;
`;

const SecondCalendar = styled(CalendarMonthDisplay)`
    @media (max-width: 999px) {
        display: none;
    }
`;

export default ({ currentMonthView, dayClicked, dateRange }) => {
    return (
        <Flex>
            <CalendarMonthDisplay
                month={currentMonthView.month}
                year={currentMonthView.year}
                dayClicked={dayClicked}
                dateRange={dateRange}
            />
            <SecondCalendar {...incrementMonth(currentMonthView)} dayClicked={dayClicked} dateRange={dateRange} />
        </Flex>
    );
};
