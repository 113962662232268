import moment from "moment";

export const periodSelectOptions = [
    {
        value: "lastWeek",
        label: "last week",
        dateEnd: moment().endOf('day'),
        dateStart: moment().add(-1, 'weeks').add(1, 'days').startOf('day')
    },
    {
        value: "2LastWeeks",
        label: "2 last weeks",
        dateEnd: moment().endOf('day'),
        dateStart: moment().add(-2, 'weeks').add(1, 'days').startOf('day')
    },
    {
        value: "1LastMonth",
        label: "1 last month",
        dateEnd: moment().endOf('day'),
        dateStart: moment().add(-1, 'months').add(1, 'days').startOf('day')
    },
    {
        value: "1LastFullMonth",
        label: "1 last full month",
        dateEnd: moment().add(-1, 'months').endOf('month'),
        dateStart: moment().add(-1, 'months').startOf('month')
    },
    {
        value: "tillNowWith1FullMonth",
        label: "till now with 1 full month",
        dateEnd: moment().endOf('day'),
        dateStart: moment().add(-1, 'months').startOf('month')
    },
    {
        value: "3LastMonths",
        label: "3 last months",
        dateEnd: moment().endOf('day'),
        dateStart: moment().add(-3, 'months').add(1, 'days').startOf('day')
    },
    {
        value: "thisYear",
        label: "this year",
        dateEnd: moment().endOf('day'),
        dateStart: new Date(moment().startOf('year'))
    },
    {
        value: "12LastMonths",
        label: "12 last months",
        dateEnd: moment().endOf('day'),
        dateStart: new Date(moment().add(-12, 'months').add(1, 'days').startOf('day'))
    },
    {
        value: "lastYear",
        label: "last year",
        dateEnd: new Date(moment().add(-1, 'years').endOf('year')),
        dateStart: new Date(moment().add(-1, 'years').startOf('year'))
    }
];

export const conditionSelectOptions = [
    {
        value: "active",
        label: "active"
    },
    {
        value: "notActive",
        label: "not active"
    },
    {
        value: "opened",
        label: "opened"
    },
    {
        value: "created",
        label: "created"
    },
    {
        value: "closed",
        label: "closed"
    }
];
