import { makeActionCreator, encodeParams } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import { toast } from "react-toastify";
import {
    HR_FIELD_TYPE_TRAY_NOTIFICATION,
    HR_FIELD_TYPE_EMAIL_NOTIFICATION,
    HR_FIELD_TYPE_CHECKBOX,
    HR_FIELD_TYPE_TEXT,
    HR_FIELD_TYPE_TIME_FROM,
    populateTemplateDefaults
} from "../../../../common/src/hrTemplates";
import { push } from "react-router-redux";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_LOADED = "STATE_LOADED";

export const DUPLICATE_STATUS_DEFAULT = "DEFAULT";
export const DUPLICATE_STATUS_LOADING = "LOADING";
export const DUPLICATE_STATUS_OK = "OK";
export const DUPLICATE_STATUS_DUPLICATE = "DUPLICATE";

export const STATE_CHANGED = "HR.EDIT_EMPLOYEE.STATE_CHANGED";
export const DATA_CHANGED = "HR.EDIT_EMPLOYEE.DATA_CHANGED";
export const NAME_CHANGED = "HR.EDIT_EMPLOYEE.NAME_CHANGED";
export const DATE_OF_BIRTH_CHANGED = "HR.EDIT_EMPLOYEE.DATE_OF_BIRTH_CHANGED";
export const DUPLICATE_STATUS_CHANGED = "HR.EDIT_EMPLOYEE.DUPLICATE_STATUS_CHANGED";

export const TEMPLATE_ADDED = "HR.EDIT_EMPLOYEE.TEMPLATE_ADDED";
export const TEMPLATE_REMOVED = "HR.EDIT_EMPLOYEE.TEMPLATE_REMOVED";
export const TEMPLATE_STATE_CHANGED = "HR.EDIT_EMPLOYEE.TEMPLATE_STATE_CHANGED";
export const TEMPLATE_FIELD_VALUE_CHANGED = "HR.EDIT_EMPLOYEE.TEMPLATE_FIELD_VALUE_CHANGED";
export const TEMPLATE_FIELD_VALUES_CHANGED = "HR.EDIT_EMPLOYEE.TEMPLATE_FIELD_VALUES_CHANGED";
export const TEMPLATE_DATA_CHANGED = "HR.EDIT_EMPLOYEE.TEMPLATE_DATA_CHANGED";
export const TEMPLATE_FIELD_AUTOCOMPLETE_DATA_CHANGED = "HR.EDIT_EMPLOYEE.TEMPLATE_FIELD_AUTOCOMPLETE_DATA_CHANGED";

export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const dataChanged = makeActionCreator(DATA_CHANGED, "payload");
export const nameChanged = makeActionCreator(NAME_CHANGED, "payload");
export const dateOfBirthChanged = makeActionCreator(DATE_OF_BIRTH_CHANGED, "payload");
export const duplicateStatusChanged = makeActionCreator(DUPLICATE_STATUS_CHANGED, "payload");
export const templateAdded = makeActionCreator(TEMPLATE_ADDED, "payload");
export const templateRemoved = makeActionCreator(TEMPLATE_REMOVED, "templateIndex");
export const templateStateChanged = makeActionCreator(TEMPLATE_STATE_CHANGED, "templateIndex", "payload");
export const templateFieldValueChanged = makeActionCreator(
    TEMPLATE_FIELD_VALUE_CHANGED,
    "templateIndex",
    "fieldId",
    "payload"
);
export const templateFieldValuesChanged = makeActionCreator(TEMPLATE_FIELD_VALUES_CHANGED, "templateIndex", "payload");
export const templateDataChanged = makeActionCreator(TEMPLATE_DATA_CHANGED, "templateIndex", "payload");
export const templateFieldAutocompleteDataChanged = makeActionCreator(
    TEMPLATE_FIELD_AUTOCOMPLETE_DATA_CHANGED,
    "templateIndex",
    "fieldId",
    "payload"
);

export function loadEmployee(id) {
    return async dispatch => {
        try {
            dispatch(stateChanged(STATE_LOADING));
            let resp = await dispatch(apiFetch(encodeParams`/api/employees/${id}`));
            let data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            dispatch(
                dataChanged({
                    ...data,
                    templates: data.templates.map(tpl => ({
                        ...tpl,
                        autocompleteData: {}
                    }))
                })
            );
            for (let i = 0; i < data.templates.length; i++) {
                dispatch(templateStateChanged(i, STATE_LOADING)); // populate the states
                dispatch(loadTemplateData(i));
            }
            dispatch(stateChanged(STATE_LOADED));
            dispatch(checkDuplicateStatus());
        } catch (e) {
            dispatch(stateChanged(STATE_DEFAULT));
            toast("An error has occured while loading employee data " + e.message, { type: "error" });
        }
    };
}

export function checkDuplicateStatus() {
    return async (dispatch, getState) => {
        try {
            dispatch(duplicateStatusChanged(DUPLICATE_STATUS_LOADING));
            let resp = await dispatch(
                apiFetch(
                    encodeParams`/api/employees-management/duplicate?name=${
                        getState().hr.editEmployee.name
                    }&dateOfBirth=${getState().hr.editEmployee.dateOfBirth}&currentId=${getState().hr.editEmployee._id}`
                )
            );
            let data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            dispatch(duplicateStatusChanged(data.duplicate ? DUPLICATE_STATUS_DUPLICATE : DUPLICATE_STATUS_OK));
        } catch (e) {
            dispatch(stateChanged(STATE_DEFAULT));
            toast("An error has occured while loading employee data " + e.message, { type: "error" });
        }
    };
}

export function saveEmployee() {
    return async (dispatch, getState) => {
        try {
            dispatch(stateChanged(STATE_LOADING));
            let resp = await dispatch(
                apiFetch(encodeParams`/api/employees/${getState().hr.editEmployee._id}`, {
                    method: "PATCH",
                    body: JSON.stringify(getState().hr.editEmployee)
                })
            );
            let data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            dispatch(stateChanged(STATE_LOADED));
            dispatch(push("/dashboard/hr"));
        } catch (e) {
            dispatch(stateChanged(STATE_DEFAULT));
            toast("An error has occured while saving employee " + e.message, { type: "error" });
        }
    };
}

export function addTemplateAndLoadData(templateId) {
    return async (dispatch, getState) => {
        let ee = getState().hr.editEmployee;
        if (ee.templates.some(t => t.templateId === templateId)) return; // duplicate template
        let newIndex = ee.templates.length;
        dispatch(
            templateAdded({
                state: STATE_LOADING,
                templateId: templateId,
                templateData: null,
                fieldValues: {},
                autocompleteData: {}
            })
        );
        await dispatch(loadTemplateData(newIndex, true));
    };
}

export function loadTemplateData(templateIndex, populateDefaultValues = false) {
    return async (dispatch, getState) => {
        try {
            const resp = await dispatch(
                apiFetch(encodeParams`/api/templates/${getState().hr.editEmployee.templates[templateIndex].templateId}`)
            );
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            const data = await resp.json();
            dispatch(templateDataChanged(templateIndex, data));
            if (populateDefaultValues) {
                dispatch(templateFieldValuesChanged(templateIndex, populateTemplateDefaults(data)));
            }
            dispatch(templateStateChanged(templateIndex, STATE_LOADED));
        } catch (e) {
            toast("An error has occured while loading template data " + e.message, { type: "error" });
        }
    };
}

export function loadAutocompleteData(templateIndex, field, query) {
    return async (dispatch, getState) => {
        try {
            let template = getState().hr.editEmployee.templates[templateIndex];
            const resp = await dispatch(
                apiFetch(
                    encodeParams`/api/employees-management/template-field-autocomplete?templateId=${
                        template.templateId
                    }&fieldId=${field._id}&query=${query}`
                )
            );
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            const data = await resp.json();
            dispatch(templateFieldAutocompleteDataChanged(templateIndex, field._id, data));
        } catch (e) {
            toast("An error has occured while loading autocomplete data " + e.message, { type: "error" });
        }
    };
}


export function deleteEmployee(id) {
    return async (dispatch, getState) => {
        try {
            dispatch(stateChanged(STATE_LOADING));
            let resp = await dispatch(
                apiFetch(encodeParams`/api/employees/${id}`, {
                    method: "DELETE",
                    body: `{}`
                })
            );
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            let data = await resp.json();
            dispatch(stateChanged(STATE_LOADED));
            toast(`Employee ${data && data.name} deleted successfully,`, {
                type: "success"
            });
            dispatch(push("/dashboard/hr"));
        } catch (e) {
            dispatch(stateChanged(STATE_LOADED));
            toast(e.message, {
                type: "error"
            });
        }
    };
}
