import React from "react";
import ExternalPage from "./ExternalPage";
import styled from "styled-components";

const CustomerPanel = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export default class CustomerPanelPage extends React.Component {
    render() {
        return (
            <CustomerPanel>
                <ExternalPage />
            </CustomerPanel>
        );
    }
}
