import React from "react";
import { Glyphicon, InputGroup, FormControl, FormGroup, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { stringify as stringifyQueryString } from "querystring";
import styled from "styled-components";
import { debounce } from "../../utils";

const FieldContainer = styled(FormGroup)`
    max-width: 300px;
`;

@connect()
export default class FilterHeaderField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldValue: props.query[props.queryKey] || ""
        };
    }
    fieldValueChanged = ev => {
        this.setState({
            fieldValue: ev.target.value
        });
        this.doFilter();
    };
    doFilter = debounce(() => {
        let newQuery = {
            ...this.props.query,
            page: 1
        };
        if (this.props.noPagination) {
            delete newQuery.page;
        }
        if (this.state.fieldValue.trim() !== "") {
            newQuery[this.props.queryKey] = this.state.fieldValue;
        } else {
            delete newQuery[this.props.queryKey];
        }
        this.props.dispatch(push("?" + stringifyQueryString(newQuery)));
    }, 500);
    render() {
        return (
            <FieldContainer>
                <InputGroup>
                    <FormControl
                        type="text"
                        placeholder={this.props.placeholder}
                        onChange={this.fieldValueChanged}
                        onBlur={this.doFilter}
                        value={this.state.fieldValue}
                    />
                    <InputGroup.Addon>
                        <Glyphicon glyph="search" />
                    </InputGroup.Addon>
                </InputGroup>
            </FieldContainer>
        );
    }
}
