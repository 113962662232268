import React from "react";
import { Panel, Table } from "react-bootstrap";
import { formatDateTime, encodeParams } from "../../../utils";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { markNotificationAsRead } from "../../../actions/notificationsActions";
import HeaderWithButton from "./HeaderWithButton";

@connect(
    null,
    (dispatch, { notification }) => ({
        markedAsRead: _ => dispatch(markNotificationAsRead(notification._id))
    })
)
export default class OrderFinishedNotification extends React.Component {
    render() {
        let data = this.props.notification.data;
        let finished = data.finished;
        return (
            <Panel>
                <HeaderWithButton notification={this.props.notification}>
                    Order {finished ? "finished" : "reopened"}
                </HeaderWithButton>
                <Table>
                    <tbody>
                        <tr>
                            <th>Order number</th>
                            <td>
                                <Link to={encodeParams`/dashboard/orders/${data.orderId}`}>{data.orderNumber}</Link>
                            </td>
                        </tr>
                        <tr>
                            <th>{finished ? "Finished" : "Reopened"} on</th>
                            <td>{formatDateTime(data.finishedDate)}</td>
                        </tr>
                        <tr>
                            <th>{finished ? "Finished" : "Reopened"} by</th>
                            <td>{data.userDisplayName}</td>
                        </tr>
                    </tbody>
                </Table>
            </Panel>
        );
    }
}
