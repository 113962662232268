import React from "react";
import { connect } from "react-redux";
import { detectUserHome } from "../../actions/authActions";
import MatSpinner from "./Mat/MatSpinner";

@connect()
export default class UserHomeRedirector extends React.Component {
    componentDidMount() {
        this.props.dispatch(detectUserHome());
    }
    render() {
        return (
            <div>
                <MatSpinner />
            </div>
        );
    }
}
