import { makeActionCreator } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import { toast } from "react-toastify";

export const FILE_ADDED = "ORDERS.FILES.FILE_ADDED";
export const PROGRESS_CHANGED = "ORDERS.FILES.PROGRESS_CHANGED";
export const FILE_ERRORED = "ORDERS.FILES.FILE_ERRORED";
export const REPLACE_FILE_AFTER_UPLOAD = "ORDERS.FILES.REPLACE_FILE_AFTER_UPLOAD";
export const FILE_DELETED = "ORDERS.FILES.FILE_DELETED";

export const fileAdded = makeActionCreator(FILE_ADDED, "payload");
export const progressChanged = makeActionCreator(PROGRESS_CHANGED, "fileId", "payload");
export const fileErrored = makeActionCreator(FILE_ERRORED, "fileId", "payload");
export const replaceFileAfterUpload = makeActionCreator(REPLACE_FILE_AFTER_UPLOAD, "fileId", "payload");
export const fileDeleted = makeActionCreator(FILE_DELETED, "fileId");

export function startFileUpload(input) {
    return async (dispatch, getState) => {
        try {
            if (!input || !input.files[0]) return;
            var data = new FormData();
            data.append("file", input.files[0]);
            const totalSize = input.files[0].size;

            const tempId = Math.random().toString(36);
            dispatch(
                fileAdded({
                    _id: tempId, // assign a temporary id before it is uploaded
                    uploading: true,
                    error: null,
                    originalName: input.files[0].name,
                    progress: 0
                })
            );
            let resp = await dispatch(
                apiFetch("/api/files/upload", {
                    method: "POST",
                    fileUpload: true,
                    body: data
                })
            );
            const jsonData = await resp.json();
            if (!resp.ok) {
                throw new Error(jsonData.message);
            }
            dispatch(
                replaceFileAfterUpload(tempId, {
                    uploading: false,
                    error: null,
                    ...jsonData
                })
            );
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(fileDeleted(tempId));
        }
    };
}



