/**
 * Defines the color sheme of custom styled elements.
 */
export default {
    backgroundHighlightColor: "#f4f4f4",
    secondaryText: "#777",
    secondaryColor: "#dddddd",
    primaryColor: "#193F77",
    customerPrimaryColor: "#193f77",
    finishOrderBrown: "#9A3300"
};
