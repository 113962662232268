import React from "react";
import { connect } from "react-redux";
import MatButton from "../../components/common/Mat/MatButton";
import MatSpinner from "../../components/common/Mat/MatSpinner";
import { loadTotalHoursForOrder } from "../actions/resultsActions";
import { encodeParams } from "../../utils";
import { SELECTED_FILTER_DATE } from "../actions/filtersActions";

export default connect(state => state.ordersList.filters)(
    ({ value, dispatch, row, dateRange, selectedFilter, period }) => {
        if (value.isLoading) {
            return <MatSpinner slim />;
        }

        if (value.data) {
            return (
                <div>
                    <a
                        href={encodeParams`/dashboard/simulations/customer/${
                            row._id
                        }?selectedFilter=${selectedFilter}&dateRange=${JSON.stringify(
                            dateRange
                        )}&period=${period}&disableOrderSelect=true`}
                        target="_blank"
                    >
                        Ext h:
                    </a>
                    {value.data.external} Int h: {value.data.internal}
                </div>
            );
        }
        return (
            <div>
                <MatButton slim primary onClick={() => dispatch(loadTotalHoursForOrder(row._id))}>
                    Count
                </MatButton>
            </div>
        );
    }
);
