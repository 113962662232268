import React from "react";
import Logo from "../../components/common/Logo";
import LoadableButton from "../../components/common/LoadableButton";
import { connect } from "react-redux";
import { pageEntered } from "../actions/passwordResetFinishActions";
import { Panel } from "react-bootstrap";
import { push } from "react-router-redux";
import NarrowContainer from "../../components/common/containers/NarrowContainer";

@connect(({ passwordResetFinish }) => ({
    message: passwordResetFinish.message
}), dispatch => ({
    pageEntered: (userId, token) => dispatch(pageEntered(userId, token)),
    goBackToLogin: _ => dispatch(push("/login"))
}))
export default class PasswordResetFinishPage extends React.Component {
    componentDidMount() {
        this.props.pageEntered(this.props.match.params.userId, this.props.match.params.token);
    }
    render() {
        return (
            <div className="center-vertically">
                <NarrowContainer>
                    <Logo />
                    {this.props.message && <Panel>
                        {this.props.message}
                    </Panel>}
                    <LoadableButton block bsStyle="primary" onClick={this.props.goBackToLogin}
                        isLoading={this.props.isLoading}>
                        Login
                    </LoadableButton>
                </NarrowContainer>
            </div>
        );
    }
}
