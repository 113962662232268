import { combineReducers } from "redux";
import employeeTimeTable from "./employeeTimeTable";
import problemDescription from "./problemDescription";
import productQA from "./productQA";
import remarksDescription from "./remarksDescription";
import report from "./report";
import validation from "./validation";

export default combineReducers({
    problemDescription,
    employeeTimeTable,
    productQA,
    remarksDescription,
    report,
    validation
});
