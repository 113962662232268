import React from "react";
import { connect } from "react-redux";
import { changeDaysRange } from "../../DesktopsPage/actions/desktopsActions";
import StyledIcon from "../../components/common/StyledIcon";
import styled, { css } from "styled-components";

const RangesList = styled.div`
    display: flex;
    flex-direction: row;
`;

const RangeItem = styled.span`
    padding-left: 3px;
    padding-right: 3px;
    cursor: pointer;
    ${p =>
        p.active &&
        css`
            font-weight: bold;
        `} &:after {
        display: inline;
        margin-left: 3px;
        content: "   |";
    }
    &:last-child {
        &:after {
            content: "";
        }
    }
`;

@connect(
    ({ desktops }) => ({
        currentDaysRange: desktops.desktop.daysRange
    }),
    dispatch => ({
        daysRange: days => dispatch(changeDaysRange(days))
    })
)
export default class DaysFilter extends React.Component {
    render() {
        const dayButtonsValue = [1, 2, 3, 7, 21];
        let content = dayButtonsValue.map(value => (
            <RangeItem
                key={value}
                active={this.props.currentDaysRange === value}
                onClick={() => this.props.daysRange(value)}
            >
                {value}d
            </RangeItem>
        ));
        return (
            <div>
                <RangesList
                    className="list-inline"
                    title="Choose from how many days you want to display reports on your desktop"
                >
                    <span>
                        <StyledIcon glyph="time" /> Range:
                    </span>
                    {content}
                </RangesList>
            </div>
        );
    }
}
