import { makeActionCreator, encodeParams } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import { toast } from "react-toastify";

// states used when donwloading company details from API

/**
 * The select box is empty, this customer was freshly added.
 * id = null and details = null.
 */
export const COMPANY_STATE_NOT_SELECTED = "COMPANY_STATE_NOT_SELECTED";

/**
 * User selected a company, now waiting for API to give extra details.
 * id = number, details = null.
 */
export const COMPANY_STATE_LOADING = "COMPANY_STATE_LOADING";

/**
 * Company details loaded from API, can be displayed.
 * id = number, details = object
 */
export const COMPANY_STATE_LOADED = "COMPANY_STATE_LOADED";

/**
 * Loading details from api failed, show error message.
 * id = number, details = null.
 */
export const COMPANY_STATE_ERROR = "COMPANY_STATE_ERROR";

export const CUSTOMER_ADDED = "ORDERS.CUSTOMERS.CUSTOMER_ADDED";
export const CUSTOMER_REMOVED = "ORDERS.CUSTOMERS.CUSTOMER_REMOVED";
export const CUSTOMER_COMPANY_ID_CHANGED = "ORDERS.CUSTOMERS.CUSTOMER_COMPANY_ID_CHANGED";
export const CUSTOMER_COMPANY_DETAILS_CHANGED = "ORDERS.CUSTOMERS.CUSTOMER_COMPANY_DETAILS_CHANGED";
export const CUSTOMER_COMPANY_EMPLOYEES_CHANGED = "ORDERS.CUSTOMERS.CUSTOMER_COMPANY_EMPLOYEES_CHANGED";
export const CUSTOMER_COMPANY_STATE_CHANGED = "ORDERS.CUSTOMERS.CUSTOMER_COMPANY_STATE_CHANGED";
export const COMPANY_SEARCH_CACHE_UPDATED = "ORDERS.CUSTOMERS.COMPANY_SEARCH_CACHE_UPDATED";
export const CUSTOMER_EMPLOYEE_CONTACT_PERSON_CHANGED = "ORDERS.CUSTOMERS.CUSTOMER_EMPLOYEE_CONTACT_PERSON_CHANGED";
export const CUSTOMER_EMPLOYEE_EMAIL_NOTIFICATION_CHANGED = "ORDERS.CUSTOMERS.CUSTOMER_EMPLOYEE_EMAIL_NOTIFICATION_CHANGED";

export const customerAdded = makeActionCreator(CUSTOMER_ADDED);
export const customerRemoved = makeActionCreator(CUSTOMER_REMOVED, "customerIndex");
export const customerCompanyIdChanged = makeActionCreator(CUSTOMER_COMPANY_ID_CHANGED, "customerIndex", "payload");
export const customerCompanyDetailsChanged = makeActionCreator(
    CUSTOMER_COMPANY_DETAILS_CHANGED,
    "customerIndex",
    "payload"
);
export const customerCompanyStateChanged = makeActionCreator(
    CUSTOMER_COMPANY_STATE_CHANGED,
    "customerIndex",
    "payload"
);
export const customerCompanyEmployeesChanged = makeActionCreator(
    CUSTOMER_COMPANY_EMPLOYEES_CHANGED,
    "customerIndex",
    "payload"
);
export const customerEmployeeContactPersonChanged = makeActionCreator(
    CUSTOMER_EMPLOYEE_CONTACT_PERSON_CHANGED,
    "customerIndex",
    "employeeId",
    "payload"
);
export const customerEmployeeEmailNotificationChanged = makeActionCreator(
    CUSTOMER_EMPLOYEE_EMAIL_NOTIFICATION_CHANGED,
    "customerIndex",
    "employeeId",
    "payload"
);
export const companySearchCacheUpdated = makeActionCreator(COMPANY_SEARCH_CACHE_UPDATED, "cacheKey", "payload");

/**
 * Dispatched when something is entered in the company select box.
 * @param {String} searchQuery
 */
export function companiesSearched(searchQuery) {
    return async dispatch => {
        try {
            let resp = await dispatch(apiFetch(encodeParams`/api/companies/search?query=${searchQuery}`));
            let data = await resp.json();
            return data;
        } catch (e) {
            toast(e.message, { type: "error" });
            
            return [];
        }
    };
}

export function companySelected(customerIndex, companyId) {
    return async dispatch => {
        dispatch(customerCompanyIdChanged(customerIndex, companyId));
        if (companyId === null) {
            dispatch(customerCompanyStateChanged(customerIndex, COMPANY_STATE_NOT_SELECTED));
            return;
        }
        await dispatch(loadCustomerDetails(customerIndex));
    };
}

export function loadCustomerDetails(customerIndex) {
    return async (dispatch, getState) => {
        let customer = getState().orders.customers.customers[customerIndex];
        if(!customer || !customer.companyId) {
            return;
        }
        let companyId = customer.companyId;
        dispatch(customerCompanyStateChanged(customerIndex, COMPANY_STATE_LOADING));
        try {
            {
                let resp = await dispatch(apiFetch(`/api/companies/${encodeURIComponent(companyId)}`));
                let company = await resp.json();
                if (resp.status !== 200) {
                    throw new Error("API returned an error!");
                }
                if (company && typeof company === "object") {
                    dispatch(customerCompanyDetailsChanged(customerIndex, company));
                    dispatch(customerCompanyEmployeesChanged(customerIndex, company.contacts));
                } else {
                    throw new Error("API didn't return an object.");
                }
            }
            dispatch(customerCompanyStateChanged(customerIndex, COMPANY_STATE_LOADED));
        } catch (e) {
            
            toast(e.message, { type: "error" });
            dispatch(customerCompanyStateChanged(customerIndex, COMPANY_STATE_ERROR));
        }
    };
}
