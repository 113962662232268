import { userHasPermissionSelector } from "../../selectors/authSelectors";
import { PERMISSION_CREATE_EXTERNALS, PERMISSION_EDIT_EXTERNALS } from "../../../../common/src/permissions";

export default function isExternalDisabled(state) {
    if (state.externals.external.id) {
        return !userHasPermissionSelector(state, PERMISSION_EDIT_EXTERNALS);
    } else {
        return !userHasPermissionSelector(state, PERMISSION_CREATE_EXTERNALS);
    }
}
