import React from "react";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";

export default ({ field, value, onChange, autocompleteDataSelector }) => {
    let datalistId = "tpl_ac_id_" + field._id;
    let autocompleteData = autocompleteDataSelector(field);
    return (
        <FormGroup>
            {autocompleteData && (
                <datalist id={datalistId}>
                    {autocompleteData.map((opt, i) => (
                        <option key={i}>{opt}</option>
                    ))}
                </datalist>
            )}
            <FormControl
                value={value}
                onChange={ev => onChange(ev.target.value)}
                placeholder={field.comments}
                list={datalistId}
            />
        </FormGroup>
    );
};
