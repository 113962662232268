import React, {useState} from "react";
import OpenReport from "../DesktopsPage/components/DesktopPage/Order/Day/Reports/OpenReport";
import styled from "styled-components";
import ReportContentContainer from "../DesktopsPage/components/DesktopPage/Order/Day/Reports/ReportContentContainer";
import Balloon from "../DesktopsPage/components/DesktopPage/Order/Day/Balloon";
import useHover from "../hooks/useHover";

const SingleReport = styled.div`
    border: 1px solid #99d9ea;
    background-color: ${props => (props.type ? "#99d9ea" : "#fff")};
    color: ${props => (props.type ? "#fff" : "#000")};;
    height: 25px;
    border-radius: 3px;
    margin-right:-3px;
`;
export const SingleReportContainer = styled.div`
    margin: 4px 8px 3px 8px;    
    font-size: 14px;
    color: #99d9ea;
    display: block;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16);
    z-index: 2;
`;
export default function ReportContainer(props) {
    const [hoverRef, isHovered] = useHover();
    const [leftSide, setLeftSide] = useState(false);
    let report = props.report;
    document.addEventListener('mousemove', calculateBalloonPosition);
    function calculateBalloonPosition(e) {
        window.innerWidth * 0.5 > e.clientX ? setLeftSide(false) : setLeftSide(true);
    }
    return (
        <div
            ref={hoverRef}
            onClick={() => { window.open("reports/" + report.id, "_blank")}}
        >
            <SingleReportContainer>
                <SingleReport type={report.type}>
                    {isHovered && <Balloon data={report} marginLeft={leftSide ? "0px" : "270px"} printButton={false}/>}
                    <ReportContentContainer report={report} />
                </SingleReport>
            </SingleReportContainer>
        </div>
    );
}