import moment from "moment";

export const periodSelectOptions = [
    {
        value: "today",
        label: "today",
        dateEnd: moment().endOf('day'),
        dateStart: moment().startOf('day')
    },
    {
        value: "3LastDays",
        label: "3 last days",
        dateEnd: moment().endOf('day'),
        dateStart: moment().add(-2, 'days').startOf('day')
    },
    {
        value: "7LastDays",
        label: "7 last days",
        dateEnd: moment().endOf('day'),
        dateStart: moment().add(-6, 'days').startOf('day')
    },
    {
        value: "1LastMonth",
        label: "1 last month",
        dateEnd: moment().endOf('day'),
        dateStart: moment().add(-1, 'months').add(1, 'days').startOf('day')
    },
    {
        value: "3LastMonths",
        label: "3 last months",
        dateEnd: moment().endOf('day'),
        dateStart: moment().add(-3, 'months').add(1, 'days').startOf('day')
    },
    {
        value: "lastYear",
        label: "last year",
        dateEnd: new Date(moment().add(-1, 'years').endOf('year')),
        dateStart: new Date(moment().add(-1, 'years').startOf('year'))
    }
];