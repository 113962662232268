import { OLD_EXTERNALS_LOADED } from "../actions/oldExternalsActions";

export const initialState = {
    oldExternals: []
};

export default function oldExternals(state = initialState, action) {
    switch (action.type) {
        case OLD_EXTERNALS_LOADED: {
            return {
                ...state,
                oldExternals: action.payload
            };
        }
        default:
            return state;
    }
}
