import React from "react";
import { connect } from "react-redux";
import { nameChanged } from "../../actions/editTemplateActions";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";

@connect(
    state => state.hr.editTemplate,
    dispatch => ({
        nameChanged: ev => dispatch(nameChanged(ev.target.value))
    })
)
export default class TemplateNameInput extends React.Component {
    render() {
        return (
            <FormGroup>
                <ControlLabel>Template name</ControlLabel>
                <FormControl value={this.props.name} onChange={this.props.nameChanged} className="input-contact-name" />
            </FormGroup>
        );
    }
}
