import React, { useState } from "react";
import styled, { css } from "styled-components";
import MatDropdown from "./MatDropdown";
import OutsideClickHandler from "react-outside-click-handler";

const SelectWrapper = styled.div`
    display: inline-flex;
    position: relative;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(233, 233, 233);
    border-image: initial;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    height: 35px;
    cursor: pointer;

    flex-direction: row;
    flex-wrap: nowrap;
    ${props =>
        props.isOpen &&
        css`
            box-shadow: rgba(68, 130, 255, 0.2) 0px 0px 0px 2px;
            border-color: rgb(68, 130, 255);
            outline: 0px;
        `}
    ${props =>
        props.wide &&
        css`
            min-width: 300px;
        `}

    ${props =>
        props.simple &&
        css`
            background-color: transparent;
            border-radius: 0;
            border-top: none;
            border-left: none;
            border-right: none;
            border-color: rgb(150, 150, 150);
        `}
    width: 100%;
    ${props =>
        props.disabled &&
        css`
            background: #f2f2f2;
        `}
`;
const CurrentValue = styled.div`
    font-size: 14px;
    /* min-width: 140px; */
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 33px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    flex-grow: 999;
`;
const Placeholder = styled(CurrentValue)`
    opacity: 0.7;
`;
const Spacer = styled.div`
    height: 1px;
`;

const DropdownArrow = styled.div`
    position: relative;
    width: 14px;
    height: 12px;
    align-self: center;
    margin-right: 10px;
    transform: rotate(${props => (props.dropup ? "180deg" : "0deg")});
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    ${props =>
        props.isOpen &&
        css`
            transform: rotate(${props => (props.dropup ? "0deg" : "180deg")});
        `}
    &:before,
    &:after {
        position: absolute;
        top: 5px;
        left: 0;
        width: 10px;
        height: 0px;
        content: " ";

        border: 0.7px solid #d3d3d3;
    }
    &:before {
        transform: rotate(55deg);
    }
    &:after {
        transform: rotate(-55deg);
        left: 4.5px;
    }
`;

const SelectOption = styled.div`
    position: relative;
    display: block;
    padding: 5px 12px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: background 0.3s ease;
    &:hover {
        background-color: #e6f7ff;
    }
    &:first-child {
        border-radius: 4px 4px 0 0;
    }
    &:last-child {
        border-radius: 0 0 4px 4px;
    }
    ${({ selected }) =>
        selected &&
        css`
            font-weight: bold;
        `}
`;

export default function MatSelect({
    options = [],
    value,
    onChange,
    disabled,
    dropup,
    onClick,
    expandWhenDisabled,
    stopPropagation,
    placeholderText,
    wide,
    simple
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [uncontrolledValue, setUncontrolledValue] = useState(null);
    const isUncontrolled = value === undefined && !onChange;
    let effectiveValue, effectiveOnChange;
    if (isUncontrolled) {
        effectiveValue = uncontrolledValue;
        effectiveOnChange = opt => setUncontrolledValue(opt.value);
    } else {
        effectiveValue = value;
        effectiveOnChange = onChange;
    }
    const selectedOpt = options.find(opt => opt.value === effectiveValue) || null;
    return (
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
            <SelectWrapper
                onClick={ev => {
                    if (stopPropagation) {
                        ev.stopPropagation();
                    }
                    if (!disabled || expandWhenDisabled) {
                        setIsOpen(!isOpen);
                    }
                    if (onClick) {
                        onClick(ev);
                    }
                }}
                isOpen={isOpen}
                wide={wide}
                simple={simple}
                disabled={disabled}
                className="mat-select"
            >
                {selectedOpt && <CurrentValue>{selectedOpt.label || selectedOpt.value}</CurrentValue>}
                {!selectedOpt && <Placeholder>{placeholderText || "select..."}</Placeholder>}
                <Spacer />
                <DropdownArrow isOpen={isOpen} dropup={dropup} />
                <MatDropdown select isOpen={isOpen} dropup={dropup}>
                    {options.map(opt => (
                        <SelectOption
                            key={opt.value}
                            onClick={() => effectiveOnChange(opt)}
                            selected={opt.value === effectiveValue}
                        >
                            {opt.label || opt.value}
                        </SelectOption>
                    ))}
                </MatDropdown>
            </SelectWrapper>
        </OutsideClickHandler>
    );
}
