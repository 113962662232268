import { makeActionCreator, encodeParams } from "../../utils";
import { apiFetch } from "../../actions/apiActions";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_ERROR = "STATE_ERROR";

export const STATE_CHANGED = "EMPLOYEES.UNAPPROVED_REPORTS_LIST.STATE_CHANGED";
export const ERROR_CHANGED = "EMPLOYEES.UNAPPROVED_REPORTS_LIST.ERROR_CHANGED";
export const REPORTS_CHANGED = "EMPLOYEES.UNAPPROVED_REPORTS_LIST.REPORTS_CHANGED";

export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const errorChanged = makeActionCreator(ERROR_CHANGED, "payload");
export const reportsChanged = makeActionCreator(REPORTS_CHANGED, "payload");

export function loadRequested(filterlocationId = null) {
    return async dispatch => {
        try {
            dispatch(stateChanged(STATE_LOADING));
            let url;
            if (filterlocationId) {
                url = encodeParams`/api/reports/unapproved?locationId=${filterlocationId}`;
            } else {
                url = encodeParams`/api/reports/unapproved`;
            }
            let resp = await dispatch(apiFetch(url));
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message);
            }
            dispatch(reportsChanged(data));
            dispatch(stateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}
