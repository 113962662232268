import { makeActionCreator, encodeParams } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import { toast } from "react-toastify";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_ERROR = "STATE_ERROR";

export const STATE_CHANGED = "USERS.USERS_LIST.STATE_CHANGED";
export const ERROR_CHANGED = "USERS.USERS_LIST.ERROR_CHANGED";
export const USERS_CHANGED = "USERS.USERS_LIST.USERS_CHANGED";
export const GROUP_FILTER_CHANGED = "USERS.USERS_LIST.GROUP_FILTER_CHANGED";
export const TEXT_SEARCH_CHANGED = "USERS.USERS_LIST.TEXT_SEARCH_CHANGED";
export const REPLACE_USER = "USERS.USERS_LIST.REPLACE_USER";
export const USER_DELETED = "USERS.USERS_LIST.USER_DELETED";

export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const errorChanged = makeActionCreator(ERROR_CHANGED, "payload");
export const usersChanged = makeActionCreator(USERS_CHANGED, "payload");
export const groupFilterChanged = makeActionCreator(GROUP_FILTER_CHANGED, "payload");
export const textSearchChanged = makeActionCreator(TEXT_SEARCH_CHANGED, "payload");
export const replaceUser = makeActionCreator(REPLACE_USER, "payload");
export const userDeleted = makeActionCreator(USER_DELETED, "payload");

export function loadUsersList(query, extraOptions) {
    return async (dispatch, getState) => {
        if (query && query.kind) {
            dispatch(groupFilterChanged(null));
        }
        dispatch(stateChanged(STATE_LOADING));
        try {
            let resp = await dispatch(
                apiFetch(
                    "/api/users/search",
                    {},
                    {
                        query: getState().users.usersList.textSearch,
                        group: getState().users.usersList.groupFilter,
                        ...query
                    }
                )
            );
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            dispatch(usersChanged(await resp.json()));
            dispatch(stateChanged(STATE_LOADED));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}
export function deleteUser(id) {
    return async dispatch => {
        dispatch(stateChanged(STATE_LOADING));
        try {
            let resp = await dispatch(
                apiFetch(encodeParams`/api/users/${id}`, {
                    method: "DELETE"
                })
            );
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            dispatch(stateChanged(STATE_LOADED));
            dispatch(userDeleted(id));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}
