import React from "react";
import MatGrid from "../../../../components/common/Mat/MatGrid";
import MatHintText from "../../../../components/common/Mat/MatHintText";
import MatInput from "../../../../components/common/Mat/MatInput";
import MatLabel from "../../../../components/common/Mat/MatLabel";
import { Container } from "../../../CustomerPanelPage/components/ExternalPage/Styles";
import styled from "styled-components";
import MatRadio from "../../../../components/common/Mat/MatRadio";
import MatSelect from "../../../../components/common/Mat/MatSelect";
import MatDateRangeSelector from "../../../../components/common/Mat/MatDateField/MatDateRangeSelector";
import { connect } from "react-redux";
import {
    customerViewFiltersSelector,
    updateFilters,
    SELECTED_FILTER_PERIOD,
    SELECTED_FILTER_DATE,
    loadExternalsForOrder,
    generateCustomerReport
} from "../../actions/customerPanelExternalsActions";
import { periodSelectOptions } from "../../../../OrdersListPage/filtersModel";
import moment from "moment";
import OrderSearchSelect from "./OrderSearchSelect";
import { withRouter } from "react-router";
import { parseRawQueryString } from "../../../../urlUtils";
import MatButton from "../../../../components/common/Mat/MatButton";

const KeywordSearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-left: 7px;
    @media (max-width: 999px) {
        margin-left: 0;
    }
    ${MatLabel} {
        margin-top: 15px;
        margin-right: 10px;
    }
`;

const PeriodRadio = styled(MatRadio)`
    margin-top: 15px;
`;

const ControlsRow = styled(MatGrid.Row)`
    margin-top: 15px;
`;

const StyledDateRangeSelector = styled(MatDateRangeSelector)`
    margin-top: -5px !important;
`;

const OrderNameLabel = styled(MatLabel)`
    color: #555;
    max-width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

@withRouter
@connect(
    state => ({
        ...customerViewFiltersSelector(state),
        orderName: state.clientPanel.clientPanelExternals.orderInformation
            ? state.clientPanel.clientPanelExternals.orderInformation.orderName
            : ""
    }),
    dispatch => ({
        updateFilters: nf => dispatch(updateFilters(nf)),
        loadExternalsForOrder: () => dispatch(loadExternalsForOrder()),
        generateCustomerReport: _ => dispatch(generateCustomerReport())
    })
)
export default class Searchbar extends React.Component {
    orderChanged = ev => {
        this.props.updateFilters({ order: ev ? ev.value : null });
    };
    selectedFilterChanged = ev => {
        if (ev.target) {
            this.props.updateFilters({ selectedFilter: ev.target.value });
        } else {
            this.props.updateFilters({ selectedFilter: ev });
        }
    };

    periodChanged = ev => {
        this.props.updateFilters({
            period: ev ? ev.value : null,
            dateRange: ev ? { start: new Date(ev.dateStart), end: new Date(ev.dateEnd) } : null
        });
    };

    dateRangeChanged = ev => {
        let endDate = moment(ev.end)
            .endOf("day")
            .toString();
        const dateRange = ev ? { start: ev.start, end: new Date(endDate) } : null;
        this.props.updateFilters({
            period: null,
            dateRange
        });
    };

    componentDidMount() {
        this.props.updateFilters({});
        setTimeout(() => this.props.loadExternalsForOrder({}), 10);
    }

    render() {
        const disableOrderSelect = !!parseRawQueryString(this.props.location.search).disableOrderSelect;
        return (
            <div>
                <Container alignWithMatComponents noFlex>
                    <MatGrid.Row>
                        <MatGrid.Col>
                            <MatHintText>Quick orders searching</MatHintText>
                        </MatGrid.Col>
                    </MatGrid.Row>
                    <ControlsRow wrapMobile>
                        <MatGrid.Col marginRight>
                            <KeywordSearchWrapper>
                                <MatLabel>order</MatLabel>
                                <OrderSearchSelect
                                    clearable={disableOrderSelect}
                                    style={{ width: "130px" }}
                                    value={this.props.order}
                                    onChange={this.orderChanged}
                                    disabled={disableOrderSelect}
                                />
                            </KeywordSearchWrapper>
                        </MatGrid.Col>
                        <MatGrid.Col marginRight>
                            <KeywordSearchWrapper>
                                <MatLabel>order</MatLabel>
                                <OrderNameLabel title={this.props.orderName}>{this.props.orderName}</OrderNameLabel>
                                {/* <MatInput
                                    type="text"
                                    readonly
                                    noBottomMargin
                                    value=
                                    onChange={() => null}
                                /> */}
                            </KeywordSearchWrapper>
                        </MatGrid.Col>
                        {/* <MatGrid.Col width="15px" /> */}
                        <MatGrid.Col width="350px">
                            <MatGrid.Row alignCenter wrapMobile>
                                <MatGrid.Col width="40%" mobileWidth="100%">
                                    <PeriodRadio
                                        checked={this.props.selectedFilter === SELECTED_FILTER_PERIOD}
                                        label="period"
                                        name="filterradio"
                                        onChange={this.selectedFilterChanged}
                                        value={SELECTED_FILTER_PERIOD}
                                    />
                                </MatGrid.Col>

                                <MatGrid.Col width="60%" marginRight mobileWidth="100%">
                                    <MatSelect
                                        expandWhenDisabled
                                        disabled={this.props.selectedFilter !== SELECTED_FILTER_PERIOD}
                                        value={this.props.period}
                                        onChange={this.periodChanged}
                                        options={periodSelectOptions}
                                        onClick={() => this.selectedFilterChanged(SELECTED_FILTER_PERIOD)}
                                    />
                                </MatGrid.Col>
                            </MatGrid.Row>
                        </MatGrid.Col>
                        <MatGrid.Col width="400px">
                            <MatGrid.Row alignCenter wrapMobile>
                                <MatGrid.Col width="16%" mobileWidth="100%">
                                    <PeriodRadio
                                        checked={this.props.selectedFilter === SELECTED_FILTER_DATE}
                                        label="date"
                                        name="filterradio"
                                        onChange={this.selectedFilterChanged}
                                        value={SELECTED_FILTER_DATE}
                                    />
                                </MatGrid.Col>

                                <MatGrid.Col width="74%" marginRight mobileWidth="100%">
                                    <StyledDateRangeSelector
                                        expandWhenDisabled
                                        disabled={this.props.selectedFilter !== SELECTED_FILTER_DATE}
                                        value={this.props.dateRange}
                                        onChange={this.dateRangeChanged}
                                        onClick={() => this.selectedFilterChanged(SELECTED_FILTER_DATE)}
                                    />
                                </MatGrid.Col>
                            </MatGrid.Row>
                        </MatGrid.Col>
                        <MatGrid.Spacer />
                        <MatGrid.Col width="150px" style={{ paddingTop: "10px" }}>
                            <MatButton onClick={this.props.generateCustomerReport} primary>
                                Generate excel
                            </MatButton>
                        </MatGrid.Col>
                    </ControlsRow>
                </Container>
            </div>
        );
    }
}
