import React from "react";
import MatGrid from "../../../components/common/Mat/MatGrid";
import MatHintText from "../../../components/common/Mat/MatHintText";
import MatInput from "../../../components/common/Mat/MatInput";
import MatLabel from "../../../components/common/Mat/MatLabel";
import { Container } from "../../CustomerPanelPage/components/ExternalPage/Styles";
import styled from "styled-components";
import MatRadio from "../../../components/common/Mat/MatRadio";
import MatSelect from "../../../components/common/Mat/MatSelect";
import MatDateRangeSelector from "../../../components/common/Mat/MatDateField/MatDateRangeSelector";
import { connect } from "react-redux";
import {
    ordersListFiltersSelector,
    updateFilters,
    SELECTED_FILTER_PERIOD,
    SELECTED_FILTER_DATE
} from "../actions/filtersActions";
import { periodSelectOptions } from "../../../OrdersListPage/filtersModel";
import moment from "moment";

const KeywordSearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-left: 7px;
    @media (max-width: 999px) {
        margin-left: 0;
    }
    ${MatLabel} {
        margin-top: 15px;
        margin-right: 10px;
    }
`;

const PeriodRadio = styled(MatRadio)`
    margin-top: 15px;
`;

const ControlsRow = styled(MatGrid.Row)`
    margin-top: 15px;
`;

const StyledDateRangeSelector = styled(MatDateRangeSelector)`
    margin-top: -5px !important;
`;

@connect(
    ordersListFiltersSelector,
    dispatch => ({
        updateFilters: nf => dispatch(updateFilters(nf))
    })
)
export default class FiltersForm extends React.Component {
    searchContentChanged = ev => {
        this.props.updateFilters({ searchContent: ev.target.value });
    };
    selectedFilterChanged = ev => {
        if (ev.target) {
            this.props.updateFilters({ selectedFilter: ev.target.value });
        } else {
            this.props.updateFilters({ selectedFilter: ev });
        }
    };

    periodChanged = ev => {
        this.props.updateFilters({
            period: ev ? ev.value : null,
            dateRange: ev ? { start: new Date(ev.dateStart), end: new Date(ev.dateEnd) } : null
        });
    };

    dateRangeChanged = ev => {
        let endDate = moment(ev.end)
            .endOf("day")
            .toString();
        const dateRange = ev ? { start: ev.start, end: new Date(endDate) } : null;
        console.log("dr", dateRange);
        this.props.updateFilters({
            period: null,
            dateRange
        });
    };

    render() {
        return (
            <div>
                <Container alignWithMatComponents>
                    <div>
                        <MatGrid.Row>
                            <MatGrid.Col>
                                <MatHintText>Quick orders searching</MatHintText>
                            </MatGrid.Col>
                        </MatGrid.Row>
                        <ControlsRow>
                            <MatGrid.Col marginRight>
                                <KeywordSearchWrapper>
                                    <MatLabel>keyword</MatLabel>
                                    <MatInput
                                        label={"search"}
                                        type="text"
                                        required
                                        noBottomMargin
                                        value={this.props.searchContent}
                                        onChange={this.searchContentChanged}
                                    />
                                </KeywordSearchWrapper>
                            </MatGrid.Col>
                            <MatGrid.Col width="120px"></MatGrid.Col>
                            <MatGrid.Col width="400px">
                                <MatGrid.Row alignCenter wrapMobile>
                                    <MatGrid.Col width="30%" mobileWidth="100%">
                                        <PeriodRadio
                                            checked={this.props.selectedFilter === SELECTED_FILTER_PERIOD}
                                            label="period"
                                            name="filterradio"
                                            onChange={this.selectedFilterChanged}
                                            value={SELECTED_FILTER_PERIOD}
                                        />
                                    </MatGrid.Col>

                                    <MatGrid.Col width="60%" marginRight mobileWidth="100%">
                                        <MatSelect
                                            expandWhenDisabled
                                            disabled={this.props.selectedFilter !== SELECTED_FILTER_PERIOD}
                                            value={this.props.period}
                                            onChange={this.periodChanged}
                                            options={periodSelectOptions}
                                            onClick={() => this.selectedFilterChanged(SELECTED_FILTER_PERIOD)}
                                        />
                                    </MatGrid.Col>
                                </MatGrid.Row>
                            </MatGrid.Col>
                            <MatGrid.Col width="120px"></MatGrid.Col>
                            <MatGrid.Col width="400px">
                                <MatGrid.Row alignCenter wrapMobile>
                                    <MatGrid.Col width="30%" mobileWidth="100%">
                                        <PeriodRadio
                                            checked={this.props.selectedFilter === SELECTED_FILTER_DATE}
                                            label="date"
                                            name="filterradio"
                                            onChange={this.selectedFilterChanged}
                                            value={SELECTED_FILTER_DATE}
                                        />
                                    </MatGrid.Col>

                                    <MatGrid.Col width="60%" marginRight mobileWidth="100%">
                                        <StyledDateRangeSelector
                                            expandWhenDisabled
                                            disabled={this.props.selectedFilter !== SELECTED_FILTER_DATE}
                                            value={this.props.dateRange}
                                            onChange={this.dateRangeChanged}
                                            onClick={() => this.selectedFilterChanged(SELECTED_FILTER_DATE)}
                                        />
                                    </MatGrid.Col>
                                </MatGrid.Row>
                            </MatGrid.Col>
                        </ControlsRow>
                    </div>
                </Container>
            </div>
        );
    }
}
