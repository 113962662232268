import { push } from "react-router-redux";

import { parseRawQueryString } from "../../urlUtils";
import { parseIntWithZeroFallback } from "../../utils";
import { stringify as stringifyQueryString } from "querystring";
import { loadRowsDebounced } from "./resultsActions";

export function companiesFiltersSelector(state) {
    const query = parseRawQueryString(state.router.location.search);
    return {
        searchContent: query.searchContent || "",
        page: parseIntWithZeroFallback(query.page),
        rowsPerPage: parseIntWithZeroFallback(query.rowsPerPage) || 5,
        sortBy: query.sortBy || "name"
    };
}

export function updateFilters(newValues = {}) {
    return (dispatch, getState) => {
        const newQuery = {
            ...companiesFiltersSelector(getState()),
            ...newValues
        };
        if (Object.keys(newValues).some(k => ["searchContent", "sortBy"].includes(k))) {
            newQuery.page = 0;
        }

        dispatch(
            push({
                search: "?" + stringifyQueryString(newQuery)
            })
        );
        if (Object.keys(newValues).length > 0) {
            dispatch(loadRowsDebounced());
        }
    };
}
