import { makeActionCreator, encodeParams } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import { toast } from "react-toastify";

export const ORDER_NUMBER_CHANGED = "ORDERS.ORDER_NUMBER.ORDER_NUMBER_CHANGED";
export const STANDING_ORDER_NUMBER_CHANGED = "ORDERS.ORDER_NUMBER.STANDING_ORDER_NUMBER_CHANGED";
export const INTERNAL_ORDER_NUMBER_CHANGED = "ORDERS.ORDER_NUMBER.INTERNAL_ORDER_NUMBER_CHANGED";
export const IS_STANDING_ORDER_CHANGED = "ORDERS.ORDER_NUMBER.IS_STANDING_ORDER_CHANGED";
export const IS_INTERNAL_ORDER_CHANGED = "ORDERS.ORDER_NUMBER.IS_INTERNAL_ORDER_CHANGED";
export const ORDER_RECIEVED_DATE_CHANGED = "ORDERS.ORDER_NUMBER.ORDER_RECIEVED_DATE_CHANGED";
export const INTERNAL_NUMBER_AVAILABILTY_STATE_CHANGED =
    "ORDERS.ORDER_NUMBER.INTERNAL_NUMBER_AVAILABILTY_STATE_CHANGED";
// internal order number availability states
export const INA_STATE_DEFAULT = "INA_STATE_DEFAULT";
export const INA_STATE_LOADING = "INA_STATE_LOADING";
export const INA_STATE_AVAILABLE = "INA_STATE_AVAILABLE";
export const INA_STATE_TAKEN = "INA_STATE_TAKEN";
export const INA_STATE_ERROR = "INA_STATE_ERROR";

export const orderNumberChanged = makeActionCreator(ORDER_NUMBER_CHANGED, "payload");
export const standingOrderNumberChanged = makeActionCreator(STANDING_ORDER_NUMBER_CHANGED, "payload");
export const internalOrderNumberChanged = makeActionCreator(INTERNAL_ORDER_NUMBER_CHANGED, "payload");
export const isStandingOrderChanged = makeActionCreator(IS_STANDING_ORDER_CHANGED, "payload");
export const isInternalOrderChanged = makeActionCreator(IS_INTERNAL_ORDER_CHANGED, "payload");
export const orderRecievedDateChanged = makeActionCreator(ORDER_RECIEVED_DATE_CHANGED, "payload");
export const internalNumberAvailabiltyStateChanged = makeActionCreator(
    INTERNAL_NUMBER_AVAILABILTY_STATE_CHANGED,
    "payload"
);

export function checkInternalOrderNumberAvailability() {
    return async (dispatch, getState) => {
        dispatch(internalNumberAvailabiltyStateChanged(INA_STATE_LOADING));
        try {
            let resp = await dispatch(
                apiFetch(
                    encodeParams`/api/orders/internal-number-available?number=${
                        getState().orders.orderNumbers.internalOrderNumber
                    }`
                )
            );
            let data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message || "Unknown error!");
            }
            if (data.available) {
                dispatch(internalNumberAvailabiltyStateChanged(INA_STATE_AVAILABLE));
            } else {
                dispatch(internalNumberAvailabiltyStateChanged(INA_STATE_TAKEN));
            }
        } catch (e) {
            toast(e.message, { type: "error" });
            console.error(e);
            dispatch(internalNumberAvailabiltyStateChanged(INA_STATE_ERROR));
        }
    };
}
