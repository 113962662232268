import styled from "styled-components";
import warningTriangleUrl from "../../resources/warningTriangle.png";

export default styled.img.attrs({
    src: warningTriangleUrl,
    alt: "Warning triangle"
}) `
    height: 30px;
    width: 30px;
    display: inline-block;
    margin-right: 10px;
`;
