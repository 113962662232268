import { encodeParams, makeActionCreator } from "../../utils";
import { apiFetch } from "../../actions/apiActions";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_ERROR = "STATE_ERROR";

export const STATE_CHANGED = "INVITATIONS.INVITATION.STATE_CHANGED";
export const ERROR_CHANGED = "INVITATIONS.INVITATION.ERROR_CHANGED";
export const CONTACT_PERSON_MODAL_OPEN_CHANGED = "CONTACT_PERSONS.CONTACT_PERSON.CONTACT_PERSON_MODAL_OPEN_CHANGED";
export const CONTACT_PERSON_MODAL_NAME_CHANGED = "CONTACT_PERSONS.CONTACT_PERSON.CONTACT_PERSON_MODAL_NAME_CHANGED";
export const CONTACT_PERSON_MODAL_EMPLOYEE_ID_CHANGED = "CONTACT_PERSONS.CONTACT_PERSON.CONTACT_PERSON_MODAL_EMPLOYEE_ID_CHANGED";
export const CONTACT_PERSON_MODAL_ORDERS_CHANGED = "CONTACT_PERSONS.CONTACT_PERSON.CONTACT_PERSON_MODAL_ORDERS_CHANGED";
export const CONTACT_PERSON_MODAL_ORDERS_EDITED_CHANGED = "CONTACT_PERSONS.CONTACT_PERSON.CONTACT_PERSON_MODAL_ORDERS_EDITED_CHANGED";
export const CONTACT_PERSON_MODAL_EMAIL_CHANGED = "CONTACT_PERSONS.CONTACT_PERSON.CONTACT_PERSON_MODAL_EMAIL_CHANGED";
export const CONTACT_PERSON_MODAL_EDITED_ORDERS_ADD = "CONTACT_PERSONS.CONTACT_PERSON.CONTACT_PERSON_MODAL_EDITED_ORDERS_ADD";
export const CONTACT_PERSON_MODAL_EDITED_ORDERS_REMOVE = "CONTACT_PERSONS.CONTACT_PERSON.CONTACT_PERSON_MODAL_EDITED_ORDERS_REMOVE";
export const CONTACT_PERSON_MODAL_EDITED_ORDERS_REMOVE_ALL = "CONTACT_PERSONS.CONTACT_PERSON.CONTACT_PERSON_MODAL_EDITED_ORDERS_REMOVE_ALL";

export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const errorChanged = makeActionCreator(ERROR_CHANGED, "payload");

export const contactPersonModalOpenChanged = makeActionCreator(CONTACT_PERSON_MODAL_OPEN_CHANGED, "payload");
export const contactPersonNameChanged = makeActionCreator(CONTACT_PERSON_MODAL_NAME_CHANGED, "payload");
export const contactPersonEmployeeIdChanged = makeActionCreator(CONTACT_PERSON_MODAL_EMPLOYEE_ID_CHANGED, "payload");
export const contactPersonOrdersChanged = makeActionCreator(CONTACT_PERSON_MODAL_ORDERS_CHANGED, "payload");
export const contactPersonOrdersEditedChanged = makeActionCreator(CONTACT_PERSON_MODAL_ORDERS_EDITED_CHANGED, "payload");
export const contactPersonEditedOrdersAdd = makeActionCreator(CONTACT_PERSON_MODAL_EDITED_ORDERS_ADD, "payload");
export const contactPersonEditedOrdersRemove = makeActionCreator(CONTACT_PERSON_MODAL_EDITED_ORDERS_REMOVE, "payload");
export const contactPersonEditedOrdersRemoveAll = makeActionCreator(CONTACT_PERSON_MODAL_EDITED_ORDERS_REMOVE_ALL);

export function openContactPersonModal(name, employeeId, email) {
    return async dispatch => {
        try {
            dispatch(contactPersonEditedOrdersRemoveAll());
            dispatch(stateChanged(STATE_LOADING));
            let resp = await dispatch(apiFetch(encodeParams`/api/orders/contact-person/search?query=${email}`));
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            dispatch(contactPersonOrdersChanged(await resp.json()));
            dispatch(contactPersonNameChanged(name));
            dispatch(contactPersonEmployeeIdChanged(employeeId));
            dispatch(contactPersonModalOpenChanged(true));
            dispatch(stateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

export function addOrderToEdited(orderId, employeeId, contactPerson, index) {
    return async (dispatch, getState) => {
        try {
            let currentOrders = getState().contactPersons.contactPerson.editedOrders;
            if(currentOrders.find(o => o.index === index) === undefined) {
                dispatch(contactPersonEditedOrdersAdd({orderId: orderId, employeeId: employeeId, contactPerson: contactPerson, index: index}));
                dispatch(contactPersonOrdersEditedChanged({index: index, edited: true}));
            } else {
                dispatch(contactPersonEditedOrdersRemove({index: currentOrders.findIndex(o => o.index === index)}));
                dispatch(contactPersonOrdersEditedChanged({index: index, edited: false}));

            }
        } catch (e) {
            dispatch(stateChanged(STATE_ERROR));
            dispatch(errorChanged(e.message));
        }
    };
}

export function saveOrders() {
    return async (dispatch, getState) => {
        try {
            dispatch(stateChanged(STATE_LOADING));
            let editedOrders = getState().contactPersons.contactPerson.editedOrders;
            await Promise.all(
                editedOrders.map(async o => {
                    await dispatch(
                        apiFetch(encodeParams`/api/orders/${o.orderId}/contact-person/${o.employeeId}`, {
                            method: "PATCH",
                            body: JSON.stringify({
                                contactPerson: o.contactPerson
                            })
                        })
                    );
                })
            );
            dispatch(contactPersonEditedOrdersRemoveAll());
            dispatch(contactPersonModalOpenChanged(false));
            dispatch(stateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(stateChanged(STATE_ERROR));
            dispatch(errorChanged(e.message));
            return;
        }
    };
}

export function setAll(value, employeeId) {
    return async (dispatch, getState) => {
        try {
            dispatch(stateChanged(STATE_LOADING));
            dispatch(contactPersonEditedOrdersRemoveAll());
            let orders = getState().contactPersons.contactPerson.orders;
            orders.forEach((o, index) => {
                if(o.contactPerson !== value) {
                    dispatch(contactPersonOrdersEditedChanged({index: index, edited: true}));
                    dispatch(contactPersonEditedOrdersAdd({orderId: o.orderId, employeeId: employeeId, contactPerson: !o.contactPerson, index: index}));
                }
                if(o.contactPerson === value && o.edited === true) {
                    dispatch(contactPersonOrdersEditedChanged({index: index, edited: false}));
                }
            });
            dispatch(stateChanged(STATE_LOADED));

        } catch (e) {
            dispatch(stateChanged(STATE_ERROR));
            dispatch(errorChanged(e.message));
            return;
        }
    };
}
