import { makeActionCreator, encodeParams } from "../../utils";
import { toast } from "react-toastify";
import { push } from "react-router-redux";
import { apiFetch } from "../../actions/apiActions";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_NEW_TEMPLATE = "STATE_NEW_TEMPLATE";

export const STATE_CHANGED = "HR.EDIT_TEMPLATE.STATE_CHANGED";
export const NAME_CHANGED = "HR.EDIT_TEMPLATE.NAME_CHANGED";
export const RESET = "HR.EDIT_TEMPLATE.RESET";
export const DATA_CHANGED = "HR.EDIT_TEMPLATE.DATA_CHANGED";
export const FIELD_ADDED = "HR.EDIT_TEMPLATE.FIELD_ADDED";
export const FIELD_REMOVED = "HR.EDIT_TEMPLATE.FIELD_REMOVED";
export const FIELD_TYPE_CHANGED = "HR.EDIT_TEMPLATE.FIELD_TYPE_CHANGED";
export const FIELD_COPIED = "HR.EDIT_TEMPLATE.FIELD_COPIED";
export const FIELD_COMMENTS_CHANGED = "HR.EDIT_TEMPLATE.FIELD_COMMENTS_CHANGED";
export const FIELD_DAYS_EARLIER_CHANGED = "HR.EDIT_TEMPLATE.FIELD_DAYS_EARLIER_CHANGED";
export const FIELD_ADD_COLUMN_CHANGED = "HR.EDIT_TEMPLATE.FIELD_ADD_COLUMN_CHANGED";
export const ADD_TEMPLATE_AS_COLUMN_CHANGED = "HR.EDIT_TEMPLATE.ADD_TEMPLATE_AS_COLUMN_CHANGED";
export const ADD_TO_ALL_EMPLOYEES_CHANGED = "HR.EDIT_TEMPLATE.ADD_TO_ALL_EMPLOYEES_CHANGED"

export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const nameChanged = makeActionCreator(NAME_CHANGED, "payload");
export const reset = makeActionCreator(RESET);
export const dataChanged = makeActionCreator(DATA_CHANGED, "payload");
export const fieldAdded = makeActionCreator(FIELD_ADDED);
export const fieldRemoved = makeActionCreator(FIELD_REMOVED, "fieldIndex");
export const fieldTypeChanged = makeActionCreator(FIELD_TYPE_CHANGED, "fieldIndex", "payload");
export const fieldCopied = makeActionCreator(FIELD_COPIED, "fieldIndex");
export const fieldCommentsChanged = makeActionCreator(FIELD_COMMENTS_CHANGED, "fieldIndex", "payload");
export const fieldDaysEarlierChanged = makeActionCreator(FIELD_DAYS_EARLIER_CHANGED, "fieldIndex", "payload");
export const fieldAddColumnChanged = makeActionCreator(FIELD_ADD_COLUMN_CHANGED, "fieldIndex", "payload");
export const addTemplateAsColumnChanged = makeActionCreator(ADD_TEMPLATE_AS_COLUMN_CHANGED, "payload");
export const addToAllEmployeesChanged = makeActionCreator(ADD_TO_ALL_EMPLOYEES_CHANGED, "payload")

export function saveTemplate() {
    return async (dispatch, getState) => {
        try {
            let prevState = getState().hr.editTemplate.state;
            dispatch(stateChanged(STATE_LOADING));
            let fetchAction;
            if (prevState === STATE_NEW_TEMPLATE) {
                fetchAction = apiFetch(encodeParams`/api/templates`, {
                    method: "POST",
                    body: JSON.stringify(getState().hr.editTemplate)
                });
            } else if (prevState === STATE_LOADED) {
                fetchAction = apiFetch(encodeParams`/api/templates/${getState().hr.editTemplate._id}`, {
                    method: "PATCH",
                    body: JSON.stringify(getState().hr.editTemplate)
                });
            }

            let resp = await dispatch(fetchAction);
            let data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            dispatch(stateChanged(STATE_NEW_TEMPLATE));
            toast("Template saved!", { type: "success" });
            dispatch(push("/dashboard/hr/templates"));
        } catch (e) {
            dispatch(stateChanged(STATE_DEFAULT));
            console.error(e);
            toast("An error has occured while saving template " + e.message, { type: "error" });
        }
    };
}

export function loadTemplate(id) {
    return async (dispatch, getState) => {
        try {
            dispatch(stateChanged(STATE_LOADING));
            let resp = await dispatch(apiFetch(encodeParams`/api/templates/${id}`));
            let data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            dispatch(dataChanged(data));
            dispatch(stateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(stateChanged(STATE_DEFAULT));
            toast("An error has occured while loading template " + e.message, { type: "error" });
        }
    };
}