import styled from "styled-components";

export const ENTITY_STATUS_UNCHANGED = 0;
export const ENTITY_STATUS_NEW = 1;
export const ENTITY_STATUS_CHANGED = 2;
export const ENTITY_STATUS_DELETED = 3;

/**
 * Adds zero to number of string if it is shorter than 2 characters.
 * @param {String|Number} data
 */
export function leftPadWithZero(data) {
    return data.toString().length < 2 ? "0" + data.toString() : data.toString();
}

/**
 * Converts minutes to HH:MM.
 * @param {Number} minutes
 */
export function minutesToTimeString(minutes) {
    return leftPadWithZero(Math.floor(minutes / 60)) + ":" + leftPadWithZero(minutes % 60);
}

/**
 * Converts HH:MM to minutes.
 * @param {String} clockTime
 */
export function clockTimeToMinutes(clockTime) {
    let segments = clockTime.split(":");
    return parseInt(segments[0]) * 60 + parseInt(segments[1]);
}

export function validateClockTime(clockTime) {
    if (!clockTime) return false;
    let segments = clockTime.split(":").map(v => parseInt(v));
    if (segments.length !== 2) return false;
    if (isNaN(segments[0]) || segments[0] > 24) {
        return false;
    }
    if (isNaN(segments[1]) || segments[1] > 60) {
        return false;
    }
    return true;
}

/**
 * Creates a yyyy-MM-dd date string from
 */
export function dateInputFormat(now = new Date()) {
    var month = now.getMonth() + 1;
    var day = now.getDate();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    var today = now.getFullYear() + "-" + month + "-" + day;
    return today;
}

/**
 * Get starting hours of a 8-hour shift for the current moment.
 */
export function getCurrentStartShift() {
    var d = new Date();
    var now = d.getHours();
    if (now < 6) {
        return "22:00";
    } else if (now < 14) {
        return "06:00";
    } else if (now < 22) {
        return "14:00";
    } else {
        return "22:00";
    }
}

/**
 * Get ending hours of a 8-hour shift for the current moment.
 */
export function getCurrentEndShift() {
    var d = new Date();
    var now = d.getHours();
    if (now < 6) {
        return "06:00";
    } else if (now < 14) {
        return "14:00";
    } else if (now < 22) {
        return "22:00";
    } else {
        return "06:00";
    }
}

/**
 * Gets the current week number since Jan 1.
 * (Assymes that weeks start with mondays)
 */
export function getCurrentWeekNumber() {
    let d = new Date();
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    let weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNo;
}

/**
 * Creates a redux action creator.
 * @param {String} type
 * @param {String} argNames
 */
export function makeActionCreator(type, ...argNames) {
    return function(...actionArgs) {
        let action = {};
        argNames.forEach((an, i) => {
            action[an] = actionArgs[i];
        });
        return {
            ...action,
            type
        };
    };
}

/**
 * Parses an integer from string and defaults to zero if failed.
 * @param {String} str
 */
export function parseIntWithZeroFallback(str) {
    let int = parseInt(str, 10); // radix 10
    if (isNaN(int)) {
        return 0;
    }
    return int;
}

/**
 * Parses a float from string and defaults to zero if failed.
 * @param {String} str
 */
export function parseFloatWithZeroFallback(str) {
    let f = parseFloat(str);
    if (isNaN(f)) {
        return 0;
    }
    return f;
}

/**
 * Validates emails. Returns true if email is valid.
 * @param {String} email
 */
export function validateEmail(email) {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

/**
 * Utitlity function to update the property denoted by key of a row indicated by action[rowIndexKey].
 * @param {Array} rows
 * @param {String} key
 * @param {Object} action
 * @param {string} rowIndexKey optional row index key
 * @param {Boolean} setStatus set status to changed
 */
export function updateRow(rows, key, action, rowIndexKey = "rowIndex") {
    return rows.map((r, i) => {
        if (i !== action[rowIndexKey]) return r;
        return {
            ...r,
            [key]: action.payload
        };
    });
}

function romanize(num) {
    let lookup = { X: 10, IX: 9, V: 5, IV: 4, I: 1 };
    let roman = "";
    for (let i in lookup) {
        while (num >= lookup[i]) {
            roman += i;
            num -= lookup[i];
        }
    }
    return roman;
}

export function formatDate(d, type = "default") {
    if (!(d instanceof Date)) {
        d = new Date(d);
    }

    let day = d
        .getDate()
        .toString()
        .padStart(2, "0");
    let month = (d.getMonth() + 1).toString().padStart(2, "0");
    let year = d.getFullYear().toString();
    if (type === "default") {
        return `${day}/${month}/${year}`;
    } else {
        return {
            day: `${day}`,
            month: romanize(month)
        };
    }
}

export function formatDateTime(d) {
    if (!(d instanceof Date)) {
        d = new Date(d);
    }

    let hours = d
        .getHours()
        .toString()
        .padStart(2, "0");
    let minutes = d
        .getMinutes()
        .toString()
        .padStart(2, "0");
    let seconds = d
        .getSeconds()
        .toString()
        .padStart(2, "0");

    return `${formatDate(d)} ${hours}:${minutes}:${seconds}`;
}

export function selectObjectKeys(obj, keys) {
    let newObj = {};
    for (let key of keys) {
        if (obj.hasOwnProperty(key)) {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 */
export function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this,
            args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

/**
 * A function for tagged template literals that encodes params for URLS.
 */
export function encodeParams(template, ...expressions) {
    return template.reduce((accumulator, part, i) => {
        return accumulator + encodeURIComponent(expressions[i - 1]) + part;
    });
}

export const BreakingRow = styled.tr`
    @media all and (max-width: 768px) {
        th,
        td {
            display: inline-block;
        }
    }
`;

export const noopSelectFilter = options => {
    // Do no filtering, just return all options
    return options;
};

export function formatTimeWithoutZeros(time, deleteFirstZero = false) {
    if (deleteFirstZero && time) {
        return time[0] === "0" ? time.slice(1) : time;
    }
    let result =
        time && (parseInt(clockTimeToMinutes(time)) / 60) % 1 === 0 ? parseInt(clockTimeToMinutes(time)) / 60 : time;
    return result !== "NaN:NaN" ? result : "";
}

export function roundNumberToThousands(number) {
    let result = number;
    if (number >= 500) {
        let thousands = ~~(number / 1000);
        number % 1000 >= 500 ? (thousands += 1) : "";
        result = thousands + "k";
    }
    return result;
}

export function humanFileSize(bytes, si = false) {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + " B";
    }
    var units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + " " + units[u];
}

export function adjustForTimezone(date) {
    date = new Date(date);
    let timeOffsetInMS = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timeOffsetInMS);
    return date;
}


export function getSumWorkingHours(rows) {
    let sumWorkingMinutes = 0;

    for (let row of rows) {
        let fromTimeInMinutes = clockTimeToMinutes(row.fromTime);
        let toTimeInMinutes = clockTimeToMinutes(row.toTime);
        if (toTimeInMinutes > fromTimeInMinutes) {
            sumWorkingMinutes += toTimeInMinutes - fromTimeInMinutes;
        } else if (toTimeInMinutes === fromTimeInMinutes) {
            sumWorkingMinutes += 24 * 60;
        } else {
            sumWorkingMinutes += toTimeInMinutes;
            sumWorkingMinutes += 24 * 60 - fromTimeInMinutes;
        }
    }
    let result = Math.floor(sumWorkingMinutes / 60);
    isNaN(result) ? (result = "") : null;
    let minutes = sumWorkingMinutes % 60;
    isNaN(minutes) ? (minutes = 0) : null;
    minutes !== 0 ? (minutes > 0 && minutes < 10 ? (result += `:0${minutes}`) : (result += `:${minutes}`)) : null;
    return result;
}
