import React from "react";
import BaloonCell from "../components/common/AutoTable/cells/BaloonCell";
import SimpleText from "../components/common/AutoTable/cells/SimpleText";
import MatSortableTableHeader from "../components/common/AutoTable/MatSortableTableHeader";
import { encodeParams, formatDate } from "../utils";
import SelectAllCheckboxCell from "./components/SelectAllCheckboxCell";
import SelectItemCheckboxCell from "./components/SelectItemCheckboxCell";
import StatusCell from "./components/StatusCell";
import { userHasPermissionSelector } from "../selectors/authSelectors";
import { PERMISSION_MASS_REPORTS_APPROVAL } from "../../../common/src/permissions";
import MassOperationsBar from "./components/MassOperationsBar";

export default {
    header: MatSortableTableHeader,
    // footer: MatSortableTableFooter,
    rowsSelector: state => state.reportsList.results.rows,
    useMatTable: true,
    showNoResultsText: true,
    stickyHeader: true,
    footer: MassOperationsBar,
    processColumns: (columns, state) => {
        const columnsMap = {};
        for (const col of state.reportsList.results.enabledColumns) {
            columnsMap[col] = true;
        }
       
        return columns
            .filter(col => !col.customizerSwitch || columnsMap[col.key])
            .filter(col => col.key !== "select" || userHasPermissionSelector(state, PERMISSION_MASS_REPORTS_APPROVAL));
    },
    columns: [
        {
            key: "select",
            title: "Select",
            valueSelector: () => null,
            customTopHeader: SelectAllCheckboxCell,
            cell: SelectItemCheckboxCell
        },
        {
            key: "status",
            title: "Status",
            valueSelector: (r, i) => r.status,
            cell: StatusCell,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: true
        },
        {
            key: "controlDate",
            title: "Control date",
            valueSelector: (r, i) => formatDate(r.controlDate),
            cell: BaloonCell,
            to: row => encodeParams`/dashboard/reports/${row._id}`,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: true
        },

        {
            key: "connectedOrder",
            title: "Order number",
            valueSelector: (r, i) => r.connectedOrder,
            cell: BaloonCell,
            to: row => encodeParams`/dashboard/orders/${row.connectedOrderId}`,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: true
        },
        {
            key: "unofficialOrderName",
            title: "Unofficial order name",
            valueSelector: (r, i) => r.unofficialOrderName,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: true
        },
        {
            key: "officialOrderName",
            title: "Official order name",
            valueSelector: (r, i) => r.officialOrderName,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: false
        },
        {
            key: "customer",
            title: "Customer",
            valueSelector: (r, i) => r.customer,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: true
        },
        {
            key: "location",
            title: "Location",
            valueSelector: (r, i) => r.location,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: true
        },
        {
            key: "formularNumber",
            title: "Form no.",
            valueSelector: (r, i) => r.formularNumber,
            cell: SimpleText,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: false
        },
        {
            key: "managed",
            title: "Managed",
            valueSelector: (r, i) => r.managed,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "open",
            title: "Open",
            valueSelector: (r, i) => r.open,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "inProgress",
            title: "In progress",
            valueSelector: (r, i) => r.inProgress,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "approved",
            title: "Approved",
            valueSelector: (r, i) => r.approved,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "hidden",
            title: "Hidden",
            valueSelector: (r, i) => r.hidden,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "external",
            title: "External",
            valueSelector: (r, i) => r.external,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "deleted",
            title: "Deleted",
            valueSelector: (r, i) => r.deleted,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "partNames",
            title: "Part name",
            valueSelector: (r, i) => r.partNames,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "partNumbers",
            title: "Part number",
            valueSelector: (r, i) => r.partNumbers,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "operators",
            title: "Operators",
            valueSelector: (r, i) =>
                r.operators.map(op => (
                    <>
                        {op.split(" ").map(p => (
                            <>{p}&nbsp;</>
                        ))}
                        <br />
                    </>
                )),
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: true
        },
        {
            key: "time",
            title: "Time",
            valueSelector: (r, i) => r.time,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "sortRate",
            title: "Sort rate",
            valueSelector: (r, i) => r.sortRate,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "totalHours",
            title: "Total hours",
            valueSelector: (r, i) => r.totalHours.split(" ").map(th => <>{th}&nbsp;</>),
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: true
        },
        {
            key: "totalChecked",
            title: "Total Checked",
            valueSelector: (r, i) => r.totalChecked,
            cell: SimpleText,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: true
        },
        {
            key: "ok",
            title: "OK",
            valueSelector: (r, i) => r.ok,
            cell: SimpleText,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "reworked",
            title: "Reworked",
            valueSelector: (r, i) => r.reworked,
            cell: SimpleText,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "nok",
            title: "NOK",
            valueSelector: (r, i) => r.nok,
            cell: SimpleText,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: true
        },
        {
            key: "nokDetails",
            title: "NOK details",
            valueSelector: (r, i) => r.nokDetails,
            cell: SimpleText,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "totalOk",
            title: "Total OK",
            valueSelector: (r, i) => r.totalOk,
            cell: SimpleText,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: true
        },
        {
            key: "remarks",
            title: "Remarks",
            valueSelector: (r, i) => r.remarks,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "problemDescription",
            title: "Problem description",
            valueSelector: (r, i) => r.problemDescription,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        }
    ]
};
