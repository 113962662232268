import React from "react";
import { NavDropdown, Glyphicon, Label } from "react-bootstrap";
import StyledIcon from "../../components/common/StyledIcon";
import styled from "styled-components";
import { connect } from "react-redux";
import {
    NOTIFICATION_KIND_NEW_EMPLOYEE_CREATED,
    NOTIFICATION_KIND_CUSTOM_ANNOUNCEMENT,
    NOTIFICATION_KIND_NEW_USER_CREATED,
    NOTIFICATION_KIND_TEMPLATES_CUSTOM,
    NOTIFICATION_KIND_ORDER_FINISHED
} from "../../../../common/src/notificationKinds";
import NewEmployeeNotification from "./notifications/NewEmployeeNotification";
import CustomAnnouncementNotification from "./notifications/CustomAnnouncementNotification";
import NewUserNotification from "./notifications/NewUserNotification";
import TemplatesCustomNotification from "./notifications/TemplatesCustomNotification";
import { NavbarButton, ButtonIcon, NavbarItemsRight, NavbarDropdownContent } from "../../components/navbarStyles";
import AnimatedHider from "../../components/common/AnimatedHider";
import OutsideClickHandler from "react-outside-click-handler";
import OrderFinishedNotification from "./notifications/OrderFinishedNotification";

const ContentWithOffset = styled.div`
    position: relative;
    left: -200px;
    @media (max-width: 400px) {
        left: -60vw;
    }
`;

const notificationComponents = {
    [NOTIFICATION_KIND_NEW_EMPLOYEE_CREATED]: NewEmployeeNotification,
    [NOTIFICATION_KIND_CUSTOM_ANNOUNCEMENT]: CustomAnnouncementNotification,
    [NOTIFICATION_KIND_NEW_USER_CREATED]: NewUserNotification,
    [NOTIFICATION_KIND_TEMPLATES_CUSTOM]: TemplatesCustomNotification,
    [NOTIFICATION_KIND_ORDER_FINISHED]: OrderFinishedNotification
};

@connect(({ notifications }) => ({
    notifications: notifications.notifications.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn)),
    unreadCount: notifications.notifications.reduce((p, n) => (n.read ? p : p + 1), 0)
}))
export default class NotificationsDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showNotificationsDropdown: false };
    }
    showNotificationsDropdown = () => {
        this.setState({ showNotificationsDropdown: true });
    };

    toggleNotificationsDropdown = () => {
        this.setState({ showNotificationsDropdown: !this.state.showNotificationsDropdown });
    };

    hideNotificationsDropdown = () => {
        if (!this.state.showNotificationsDropdown) {
            return;
        }
        setTimeout(() => {
            this.setState({ showNotificationsDropdown: false });
        }, 1);
    };
    render() {
        return (
            <div>
                <NavbarButton onClick={this.showNotificationsDropdown}>
                    <ButtonIcon>
                        <Glyphicon glyph="bell" />
                    </ButtonIcon>
                    <div>{!!this.props.unreadCount && <Label bsStyle="danger">{this.props.unreadCount}</Label>}</div>
                </NavbarButton>
                <OutsideClickHandler onOutsideClick={this.hideNotificationsDropdown}>
                    <AnimatedHider show={this.state.showNotificationsDropdown}>
                        <ContentWithOffset>
                            <NavbarDropdownContent withPadding wide withOverflow>
                                {this.props.notifications.map(n => {
                                    let Cmp = notificationComponents[n.kind];
                                    if (!Cmp) return <div />;
                                    return <Cmp key={n._id} notification={n} />;
                                })}
                            </NavbarDropdownContent>
                        </ContentWithOffset>
                    </AnimatedHider>
                </OutsideClickHandler>
            </div>
        );
    }
}
