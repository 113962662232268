import React from "react";
import { connect } from "react-redux";
import TemplateNameInput from "./TemplateNameInput";
import FieldsList from "./FieldsList";
import StyledButton from "../../../components/common/StyledButton";
import StyledIcon from "../../../components/common/StyledIcon";
import {
    fieldAdded,
    saveTemplate,
    STATE_LOADING,
    STATE_LOADED,
    STATE_NEW_TEMPLATE,
    reset,
    dataChanged,
    addTemplateAsColumnChanged,
    loadTemplate,
    addToAllEmployeesChanged
} from "../../actions/editTemplateActions";
import TemplatePreviewModal from "./TemplatePreviewModal";
import LoaderGuard from "../../../components/common/LoaderGuard";
import AlignedFlex from "../../../components/common/AlignedFlex";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

@connect(
    state => state.hr.editTemplate,
    dispatch => ({
        fieldAdded: _ => dispatch(fieldAdded()),
        saveTemplate: _ => dispatch(saveTemplate()),
        reset: _ => dispatch(reset()),
        addTemplateAsColumnChanged: p => dispatch(addTemplateAsColumnChanged(p)),
        addToAllEmployeesChanged: p => dispatch(addToAllEmployeesChanged(p)),
        loadTemplate: id => dispatch(loadTemplate(id))
    })
)
export default class EditTemplatePage extends React.Component {
    componentDidMount() {
        this.loadData();
    }
    componentDidUpdate(oldProps) {
        if (this.props.match.params.id !== oldProps.match.params.id) {
            this.loadData();
        }
    }
    loadData() {
        if (this.props.match.params.id === "new") {
            this.props.reset();
        } else {
            // load existsing template
            this.props.loadTemplate(this.props.match.params.id);
        }
    }
    render() {
        let templateValid = this.props.name !== "" && this.props.fields.every(f => !!f.type);
        return (
            <LoaderGuard
                isLoading={this.props.state === STATE_LOADING}
                showContents={this.props.state === STATE_LOADED || this.props.state === STATE_NEW_TEMPLATE}
            >
                <div>
                    <h3>
                        <Link to="/dashboard/hr/templates" exact>
                            <StyledIcon glyph="arrow-left" />
                        </Link>
                        &nbsp;Create template
                    </h3>

                    <TemplateNameInput />
                    <FieldsList />
                    <br />
                    <StyledButton
                        className="pull-right"
                        wide
                        bsStyle="primary"
                        onClick={this.props.saveTemplate}
                        disabled={!templateValid}
                    >
                        Save
                    </StyledButton>
                    <div className="flex-row">
                        <input
                            type="checkbox"
                            className="checkbox-big"
                            checked={this.props.addTemplateAsColumn}
                            onChange={ev => this.props.addTemplateAsColumnChanged(ev.target.checked)}
                        />
                        <div className="inline-label">Add template as column in employees list</div>
                    </div>
                    <div className="flex-row">
                        <input
                            type="checkbox"
                            className="checkbox-big"
                            checked={this.props.addToAllEmployees}
                            onChange={ev => this.props.addToAllEmployeesChanged(ev.target.checked)}
                        />
                        <div className="inline-label">After save template apply to all employees</div>
                    </div>

                    <div className="clearfix" />
                </div>
            </LoaderGuard>
        );
    }
}
