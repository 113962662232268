import {
    STATE_DEFAULT,
    COMPANY_SEARCH_CONTENT_CHANGED,
    SEARCH_STATE_CHANGED,
    COMPANY_HEADERS_CHANGED,
    ERROR_CHANGED
} from "../actions/companiesListActions";
import companiesListHOR from "./../../CompaniesPage/higherOrder/reducers/companiesListHOR";

export default companiesListHOR ({
    STATE_DEFAULT,
    COMPANY_SEARCH_CONTENT_CHANGED,
    SEARCH_STATE_CHANGED,
    COMPANY_HEADERS_CHANGED,
    ERROR_CHANGED
});