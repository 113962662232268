import styled from "styled-components";

export default styled.div`
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(233, 233, 233);
    border-image: initial;
    margin: 0px 0px 30px;
`;
