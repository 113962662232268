import React from "react";
import IconButton from "../../../components/common/IconButton";
import { connect } from "react-redux";
import { markNotificationAsRead } from "../../../actions/notificationsActions";
import { Panel } from "react-bootstrap";

@connect(
    null,
    (dispatch, { notification }) => ({
        markedAsRead: _ => dispatch(markNotificationAsRead(notification._id))
    })
)
export default class HeaderWithButton extends React.Component {
    render() {
        return (
            <Panel.Heading>
                {this.props.children}
                {!this.props.notification.read && (
                    <IconButton glyph="check" className="pull-right" onClick={this.props.markedAsRead} />
                )}
                <div className="clearfix" />
            </Panel.Heading>
        );
    }
}
