
// unprefix IndexedDB
//;window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
//window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction || {READ_WRITE: "readwrite"};
//window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;

/**
 * Fixes incompatibility of Modal with React 16.
 * REMOVE THIS FILE ON NPM UPDATE
 */

const { Modal } = require("react-overlays");

const focus = () => { };

const cDU = Modal.prototype.componentDidUpdate;

Modal.prototype.componentDidUpdate = function (prevProps) {
    if (this.focus !== focus) {
        this.focus = focus;
    }
    cDU.call(this, prevProps);
};
