import validateDateOfBirth from "./validateDateOfBirth";
import {
    validationErrors,
    VERROR_EMPLOYEE_TT_INVALID_DATE_OF_BIRTH,
    VERROR_EMPLOYEE_TT_TIME_CONFLICT,
    VERROR_NO_REPORT_NAME,
    VERROR_INVALID_CONTROL_DATE,
    VERROR_NO_CONNECTED_ORDER,
    VERROR_QA_REWORKED_AND_NOK_GREATER_THAN_TOTAL_CHECKED,
    VERROR_NO_REMARKS_WHEN_TOTAL_IS_ZERO,
    VERROR_EMPLOYEE_TT_NOT_SELECTED,
    VERROR_EMPLOYEE_TT_DUPLICATE_ROWS
} from "../validationErrors";
import { CS_CONFLICT, CS_LOADING } from "../actions/employeeTimeTableActions";
import validateQAReworkedAndNOK from "./validateQAReworkedAndNOK";
import validateQATotalRemarks from "./validateQATotalRemarks";
import { getWorkingHoursRange } from "../../../../src/helpers/reportsHelpers";

/**
 * Returns the validation errors for the currenlty edited report.
 */
export default function getValidationErrors(state) {
    let errors = [];


    if (!state.reports.report.controlDate) {
        errors.push(validationErrors[VERROR_INVALID_CONTROL_DATE]());
    }

    if (!state.reports.report.connectedOrderId) {
        errors.push(validationErrors[VERROR_NO_CONNECTED_ORDER]());
    }

    const reportName = state.reports.report.reportName;
    if (!reportName || !reportName.trim()) {
        errors.push(validationErrors[VERROR_NO_REPORT_NAME]());
    }
    let employeesMap = {};
    state.reports.employeeTimeTable.rows.forEach((row, index) => {
        if (!validateDateOfBirth(row)) {
            errors.push(validationErrors[VERROR_EMPLOYEE_TT_INVALID_DATE_OF_BIRTH](index));
        }
        if (!row.conflictStatus === CS_CONFLICT || row.conflictStatus === CS_LOADING) {
            errors.push(validationErrors[VERROR_EMPLOYEE_TT_TIME_CONFLICT](index));
        }
        // check for duplicate rows in emploee time table
        if (!row.employeeData) {
            errors.push(validationErrors[VERROR_EMPLOYEE_TT_NOT_SELECTED](index));
            return;
        }
        if (!row.employeeData._id) {
            // new employee cannot be duplicated
            return; //everything is okay
        }
        if (!employeesMap[row.employeeData._id]) {
            employeesMap[row.employeeData._id] = [];
        }
        let myRange = getWorkingHoursRange(row, state.reports.report.controlDate);
        if (!myRange) {
            return;
        }
        for (let range of employeesMap[row.employeeData._id]) {
            if (range.intersect(myRange)) {
                errors.push(validationErrors[VERROR_EMPLOYEE_TT_DUPLICATE_ROWS](index));
            }
        }
        employeesMap[row.employeeData._id].push(myRange);
    });

 

    state.reports.productQA.rows.forEach((r, index) => {
        if (!validateQAReworkedAndNOK(r)) {
            errors.push(validationErrors[VERROR_QA_REWORKED_AND_NOK_GREATER_THAN_TOTAL_CHECKED](index));
        }
    });

    if (!validateQATotalRemarks(state)) {
        errors.push(validationErrors[VERROR_NO_REMARKS_WHEN_TOTAL_IS_ZERO]());
    }

    return errors;
}
