/**
 * For every order it returns it's main number (normal, internal or standing), it's type and id
 */
export default function ordersHeaderSelector(orders) {
    return orders.map(o => {
        let mainNumber, type;
        if (o.orderNumbers.isStandingOrder) {
            mainNumber = o.orderNumbers.standingOrderNumber;
            type = "Standing";
        } else if (o.orderNumbers.isInternalOrder) {
            mainNumber = o.orderNumbers.internalOrderNumber;
            type = "Internal";
        } else {
            mainNumber = o.orderNumbers.orderNumber;
            type = "Normal";
        }
        return {
            ...o,
            _id: o._id,
            mainNumber,
            type
        };
    });
}
