import React, { useState } from "react";
import { push } from "react-router-redux";
import styled, { css } from "styled-components";
import MatSortableTableHeader from "../components/common/AutoTable/MatSortableTableHeader";
import MatSelect from "../components/common/Mat/MatSelect";
import { encodeParams } from "../utils";

const DataColumn = styled.div`
    display: inline-flex;
    flex-direction: column;
    min-width: 200px;
    ${p =>
        p.colored &&
        css`
            color: #41b9f1;
        `}
`;

const ClickableTr = styled.tr`
    cursor: pointer;
`;

const ContactsCellWrapper = styled.div`
    color: #41b9f1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    /* justify-content: center; */
`;

const ContactsCellRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    min-width: 400px;
    div {
        width: 50%;
        margin-right: 5px;
    }
`;

const ContactsCell = ({ value, row }) => {
    const [expanded, setExpanded] = useState(false);
    let rows = [];
    let contactsList = row.contacts;
    if (!expanded) {
        contactsList = contactsList.slice(0, 6);
    }
    contactsList.forEach((c, i) => {
        if (i % 2 === 0) {
            rows.push([]);
        }

        rows[rows.length - 1].push(<div key={c._id}>{c.name}</div>);
    });
    return (
        <ContactsCellWrapper onClick={ev => ev.stopPropagation()}>
            <div>
                {rows.map((r, i) => (
                    <ContactsCellRow key={i}>{r}</ContactsCellRow>
                ))}
            </div>
            {row.contacts.length > 6 && <DropdownArrow isOpen={expanded} onClick={() => setExpanded(!expanded)} />}
        </ContactsCellWrapper>
    );
};

const DropdownArrow = styled.div`
    position: relative;
    width: 14px;
    height: 12px;
    align-self: center;
    margin-right: 10px;
    transform: rotate(${props => (props.dropup ? "180deg" : "0deg")});
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    ${props =>
        props.isOpen &&
        css`
            transform: rotate(${props => (props.dropup ? "0deg" : "180deg")});
        `}
    &:before,
    &:after {
        position: absolute;
        top: 5px;
        left: 0;
        width: 10px;
        height: 0px;
        content: " ";

        border: 0.7px solid #a3a3a3;
    }
    &:before {
        transform: rotate(55deg);
    }
    &:after {
        transform: rotate(-55deg);
        left: 4.5px;
    }
`;

export default {
    rowsSelector: state => state.companiesList.results.rows,
    header: MatSortableTableHeader,
    useMatTable: true,
    showNoResultsText: true,
    stickyHeader: true,
    sitckyTopMargin: "0",
    customRow: ({ row, children, dispatch }) => (
        <ClickableTr onClick={() => dispatch(push(row._id))}>{children}</ClickableTr>
    ),
    columns: [
        {
            key: "unofficialName",
            title: "Unofficial name (short)",
            valueSelector: (r, i) => r.unofficialName,
            cell: ({ value, row }) => (
                <DataColumn colored>
                    <div>{value}</div>
                </DataColumn>
            ),
            sortable: true
        },
        {
            key: "name",
            title: "Name",
            valueSelector: (r, i) => r.name,
            cell: ({ value, row }) => (
                <DataColumn colored>
                    <div>{value}</div>
                </DataColumn>
            ),
            sortable: true
        },
        {
            key: "address",
            title: "Address",
            valueSelector: (r, i) => "",
            cell: ({ value, row }) => (
                <DataColumn colored>
                    <div>{row.address2}</div>
                    <div>{row.address1}</div>
                </DataColumn>
            )
            // sortable: true
        },
        {
            key: "contactPersons",
            title: "Contact persons",
            cell: ContactsCell
            // sortable: true
        },

        {
            key: "actions",
            title: "Actions",
            valueSelector: (r, i) => r._id,
            cell: ({ dispatch, value, row }) => {
                return (
                    <MatSelect
                        stopPropagation
                        value={null}
                        placeholderText="Add order"
                        wide
                        simple
                        options={[
                            { label: "Add order", value: "ADD_ORDER" },
                            { label: "Add order for the supplier", value: "ADD_ORDER_LOCATION" },
                            { label: "Add order in another location", value: "ADD_ORDER_CUSTOMER" }
                        ]}
                        onChange={ev => {
                            if (!ev) return;
                            switch (ev.value) {
                                case "ADD_ORDER":
                                    dispatch(push(encodeParams`/dashboard/orders?company=${value}&location=${value}`));
                                    break;
                                case "ADD_ORDER_LOCATION":
                                    dispatch(push(encodeParams`/dashboard/orders?location=${value}`));
                                    break;
                                case "ADD_ORDER_CUSTOMER":
                                    dispatch(push(encodeParams`/dashboard/orders?company=${value}`));
                                    break;
                            }
                        }}
                    />
                );
            }
        }
    ]
};
