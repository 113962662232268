import {
    STATE_DEFAULT,
    STATE_CHANGED,
    ERROR_CHANGED,
    USERS_CHANGED,
    GROUP_FILTER_CHANGED,
    TEXT_SEARCH_CHANGED,
    REPLACE_USER,
    USER_DELETED
} from "../actions/usersListActions";

export const initialState = {
    users: [],
    error: null,
    state: STATE_DEFAULT,
    groupFilter: null,
    textSearch: ""
};

export default function usersListReducer(state = initialState, action) {
    switch (action.type) {
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case ERROR_CHANGED:
            return {
                ...state,
                error: action.payload
            };
        case USERS_CHANGED:
            return {
                ...state,
                users: action.payload
            };
        case GROUP_FILTER_CHANGED:
            return {
                ...state,
                groupFilter: action.payload
            };
        case TEXT_SEARCH_CHANGED:
            return {
                ...state,
                textSearch: action.payload
            };
        case REPLACE_USER:
            return {
                ...state,
                users: state.users.map(u => (u._id === action.payload._id ? { ...u, ...action.payload } : u))
            };
        case USER_DELETED:
            return {
                ...state,
                users: state.users.filter(u => u._id !== action.payload)
            };
        default:
            return state;
    }
}
