import { makeActionCreator } from "../../utils";

export const EXTRA_FIELD_ADDED = "ORDERS.PROBLEM_DESCRIPTION.EXTRA_FIELD_ADDED";
export const EXTRA_FIELD_CHANGED = "ORDERS.PROBLEM_DESCRIPTION.EXTRA_FIELD_CHANGED";
export const EXTRA_FIELD_REMOVED = "ORDERS.PROBLEM_DESCRIPTION.EXTRA_FIELD_REMOVED";
export const PROBLEM_DESCRIPTION_CONTENT_CHANGED = "ORDERS.PROBLEM_DESCRIPTION.CONTENT_CHANGED";

export const extraFieldAdded = makeActionCreator(EXTRA_FIELD_ADDED),
    extraFieldChanged = makeActionCreator(EXTRA_FIELD_CHANGED, "fieldIndex", "payload"),
    extraFieldRemoved = makeActionCreator(EXTRA_FIELD_REMOVED, "fieldIndex"),
    problemDescriptionContentChanged = makeActionCreator(PROBLEM_DESCRIPTION_CONTENT_CHANGED, "payload");
