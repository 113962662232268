import React from "react";
import styled, { css } from "styled-components";
import StatusIndicator from "./StatusIndicator";
import StyledButton from "../../components/common/StyledButton";
import { connect } from "react-redux";
import {
    approveSelectedReports,
    deleteSelectedReports,
    createExternalsForSelectedReports
} from "../actions/resultsActions";
import MatSpinner from "../../components/common/Mat/MatSpinner";
import { userHasPermissionSelector } from "../../selectors/authSelectors";
import { PERMISSION_MASS_REPORTS_APPROVAL } from "../../../../common/src/permissions";

const BarWrapper = styled.div`
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 20px 20px;
    background: white;
    border-top: 1.5px solid rgba(224, 224, 224, 1);
`;

const ActionsRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    height: 100%;
    padding-bottom: 6px;
    > * {
        margin-right: 20px;
    }
`;

const ActionsColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 7px;
    height: 100%;
`;

const StickyTh = styled.th`
    border-bottom: none !important;
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 99;
    border-top: 1.5px solid rgba(224, 224, 224, 1);
    height: 109px;
    ${p =>
        p.lessPadding &&
        css`
            padding-top: 0 !important;
            padding-right: 0 !important;
        `}
`;

const BarTfoot = styled.tfoot``;

@connect(
    state => ({
        ...state.reportsList.results,
        shouldShowMassOperationsBarForInProgress:
            userHasPermissionSelector(state, PERMISSION_MASS_REPORTS_APPROVAL) &&
            !!state.reportsList.results.rows.reduce((prev, row) => {
                if (prev === false) return false;
                if (!row.selected) return prev;
                if (row.status !== "inProgress") return false;
                if (prev !== "" && row.connectedOrderId !== prev) return false;
                return row.connectedOrderId;
                // if(row.order)
            }, ""),
        shouldShowMassOperationsBarForApproved:
            userHasPermissionSelector(state, PERMISSION_MASS_REPORTS_APPROVAL) &&
            !!state.reportsList.results.rows.reduce((prev, row) => {
                if (prev === false) return false;
                if (!row.selected) return prev;
                if (row.status !== "approved") return false;
                if (prev !== "" && row.connectedOrderId !== prev) return false;
                return row.connectedOrderId;
                // if(row.order)
            }, "")
    }),
    dispatch => ({
        approveSelectedReports: () => dispatch(approveSelectedReports()),
        deleteSelectedReports: () => dispatch(deleteSelectedReports()),
        createHiddenExternals: () => dispatch(createExternalsForSelectedReports(true)),
        createShownExternals: () => dispatch(createExternalsForSelectedReports(false))
    })
)
export default class MassOperationsBar extends React.Component {
    render() {
        if (this.props.shouldShowMassOperationsBarForInProgress) {
            return (
                <BarTfoot>
                    {this.props.massOperationLoading && (
                        <tr>
                            <StickyTh colSpan={this.props.processedColumns.length}>
                                <MatSpinner slim />
                            </StickyTh>
                        </tr>
                    )}

                    {!this.props.massOperationLoading && (
                        <tr>
                            <StickyTh lessPadding>
                                <ActionsColumn>
                                    <h5>Mass&nbsp;approval</h5>
                                    <StatusIndicator status="inProgress" superWide />
                                </ActionsColumn>
                            </StickyTh>
                            <StickyTh colSpan={this.props.processedColumns.length - 1}>
                                <ActionsRow>
                                    <StyledButton bsStyle="primary" onClick={this.props.approveSelectedReports}>
                                        Approve all
                                    </StyledButton>
                                    <StyledButton bsStyle="danger" onClick={this.props.deleteSelectedReports}>
                                        Delete all
                                    </StyledButton>
                                </ActionsRow>
                            </StickyTh>
                        </tr>
                    )}
                </BarTfoot>
            );
        }

        if (this.props.shouldShowMassOperationsBarForApproved) {
            return (
                <BarTfoot>
                    {this.props.massOperationLoading && (
                        <tr>
                            <StickyTh colSpan={this.props.processedColumns.length}>
                                <MatSpinner quiteSlim slim />
                            </StickyTh>
                        </tr>
                    )}

                    {!this.props.massOperationLoading && (
                        <tr>
                            <StickyTh lessPadding>
                                <ActionsColumn>
                                    <h5>Mass&nbsp;approval</h5>
                                    <StatusIndicator status="approved" superWide />
                                </ActionsColumn>
                            </StickyTh>
                            <StickyTh colSpan={this.props.processedColumns.length - 1}>
                                <ActionsRow>
                                    <StyledButton bsStyle="primary" onClick={this.props.createShownExternals}>
                                        Visible to client all
                                    </StyledButton>
                                    <StyledButton
                                        dark
                                        bsStyle="__ddd"
                                        customBackgroundColor="#bfb5b6"
                                        onClick={this.props.createHiddenExternals}
                                    >
                                        Hide all
                                    </StyledButton>
                                    <StyledButton bsStyle="danger" onClick={this.props.deleteSelectedReports}>
                                        Delete all
                                    </StyledButton>
                                </ActionsRow>
                            </StickyTh>
                        </tr>
                    )}
                </BarTfoot>
            );
        }

        return <tfoot />;
    }
}
