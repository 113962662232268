import React from "react";
import { connect } from "react-redux";
import MatCheckbox from "../../components/common/Mat/MatCheckbox";
import { selectDeselectAll } from "../actions/resultsActions";
import styled, { css } from "styled-components";
import StyledTh from "../../components/common/AutoTable/StyledTh";

export default connect(state => state.reportsList.results)(({ rows, dispatch, model }) => {
    const selectedCount = rows.reduce((prev, r) => (r.selected ? prev + 1 : prev), 0);

    return (
        <StyledTh model={model} style={{ minWidth: "121px" }}>
            <MatCheckbox
                checked={selectedCount > 0}
                onChange={ev => {
                    dispatch(selectDeselectAll(ev.target.checked));
                }}
            />
        </StyledTh>
    );
});
