import React, { PropTypes } from "react";
import styled from "styled-components";
import { ControlLabel, FormGroup, Modal, Panel } from "react-bootstrap";
import { connect } from "react-redux";
import RichTextEditor from "react-rte";
import {
    invitationContentChanged,
    invitationModalOpenChanged,
    sendInvitation,
    STATE_ERROR,
    STATE_LOADED,
    STATE_LOADING
} from "../actions/invitationActions";
import FormControl from "react-bootstrap/es/FormControl";
import classnames from "classnames";
import ConfirmableButton from "../../components/common/ConfirmableButton";
import StyledIcon from "../../components/common/StyledIcon";
import { debounce } from "../../utils";
import {
    questionnaireByEmailChanged,
    questionnaireF2fContactChanged,
    questionnaireNotSettledChanged,
    questionnaireViaPhoneChanged,
    questionnaireClearedChanged
} from "../actions/questionnaireAction";

const StyledModal = styled(Modal)`
    .control-label {
        transform: translateY(1px);
    }
    .modal-content {
        max-width: 100vw;
    }
`;

const InformationContainer = styled.div`
    border: solid 1px red;
    margin: 30px;
    padding: 20px;
`;

const CommunicatContainer = styled.div`
    font-size: 22px;
    text-align: center;
    padding: 0 20px 0 20px;
`;

const MarksContainer = styled.p`
    float: left;
    margin-left: 30px;
`;

const appName = process.env.EMAIL_APP_NAME;

@connect(
    ({ invitations }) => ({
        ...invitations.invitation,
        ...invitations.questionnaire
    }),
    dispatch => ({
        closeInvitationModal: _ => {
            dispatch(invitationModalOpenChanged(false));
            dispatch(questionnaireClearedChanged());
        },
        sendInvitation: _ => {
            dispatch(sendInvitation());
            dispatch(questionnaireClearedChanged());
        },
        setContent: val => dispatch(invitationContentChanged(val)),
        questionnaireF2fContactChanged: val => dispatch(questionnaireF2fContactChanged(val)),
        questionnaireViaPhoneChanged: val => dispatch(questionnaireViaPhoneChanged(val)),
        questionnaireByEmailChanged: val => dispatch(questionnaireByEmailChanged(val)),
        questionnaireNotSettledChanged: val => dispatch(questionnaireNotSettledChanged(val))
    })
)
export default class InvitationModal extends React.Component {
    state = {
        value: RichTextEditor.createValueFromString(
            `Hello,<br><br>

You are invited by [inviter] to <b>${appName}</b> (ORS). You can get access to ORS by click the link below.<br>
Clicking the link is tantamount to consenting to the processing of personal data in accordance with the ORS 
<a href="https://www.resglobalgroup.com/en/ors-policy" >security policy.</a><br><br>

(PL) Kliknięcie linku jest równoznaczne z wyrażeniem zgody na przetwarzanie danych osobowych zgodnie z 
<a href="https://www.resglobalgroup.com/polityka-prywatnosci-ors-policy">polityką bezpieczeństwa ORS.</a><br><br>

<a href="https://www.system.resglobalgroup.com/login">Click here to login.</a><br><br>

Your login: [login]<br><br>

Temporary access password: [pass]<br><br>

You should change your password to your account after first login!<br><br>

You have access to simple version of the Customer Panel. Collected data effort to present, show and prepare really complicated reports with diagrams etc., but this functionalities / tools will be permanently added.<br><br>

<b>ORS</b> is giving you possibility to check RES activity results 24/7.<br><br>

This access is connected to the company you represent and given only for the period you represent your employer - let us know if you change your position.<br><br>
            `,
            "html"
        )
    };
    //set email content
    componentDidMount() {
        this.props.setContent(`<p>${this.state.value._cache.html}</p>`);
    }

    onChange = value => {
        this.setState({ value });
        this.debounceUpdateContent(value);

        if (this.props.onChange) {
            this.props.onChange(value.toString("html"));
        }
    };

    debounceUpdateContent = debounce(value => {
        this.props.setContent(value.toString("html"));
    }, 500);

    render() {
        let modalBody = null;
        let modalFooter = null;
        if (this.props.state === STATE_LOADING) {
            modalBody = (
                <div>
                    <StyledIcon spin center size="big" />
                </div>
            );
        } else if (this.props.state === STATE_ERROR) {
            modalBody = (
                <div>
                    <Panel header="Error" bsStyle="danger">
                        <Panel.Body>{this.props.error}</Panel.Body>
                    </Panel>
                </div>
            );
        } else if (this.props.state === STATE_LOADED) {
            modalBody = (
                <div>
                    <RichTextEditor value={this.state.value} onChange={this.onChange} />
                </div>
            );
            modalFooter = (
                <div>
                    <MarksContainer>Marks: [inviter], [login], [pass].</MarksContainer>
                    <ConfirmableButton
                        className="pull-right"
                        bsStyle="primary"
                        disabled={
                            this.props.notSettled ||
                            !(this.props.f2fContact || this.props.viaPhone || this.props.byEmail)
                        }
                        onConfirm={this.props.sendInvitation}
                    >
                        Send
                    </ConfirmableButton>
                </div>
            );
        }

        return (
            <StyledModal show={this.props.invitationModalOpen} onHide={this.props.closeInvitationModal} bsSize="large">
                <Modal.Header closeButton>
                    <Modal.Title>Would you like to invite {this.props.name} to ORS?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <ControlLabel>How have you settled customer's access?</ControlLabel>
                        <div className="flex-row">
                            <input
                                type="checkbox"
                                className="checkbox-big"
                                checked={this.props.f2fContact}
                                onChange={ev => this.props.questionnaireF2fContactChanged(ev.target.checked)}
                            />
                            <div className="inline-label">f2f contact</div>
                        </div>
                        <div className="flex-row">
                            <input
                                type="checkbox"
                                className="checkbox-big"
                                checked={this.props.viaPhone}
                                onChange={ev => this.props.questionnaireViaPhoneChanged(ev.target.checked)}
                            />
                            <div className="inline-label">via phone</div>
                        </div>
                        <div className="flex-row">
                            <input
                                type="checkbox"
                                className="checkbox-big"
                                checked={this.props.byEmail}
                                onChange={ev => this.props.questionnaireByEmailChanged(ev.target.checked)}
                            />
                            <div className="inline-label">by e-mail</div>
                        </div>
                        <div className="flex-row">
                            <input
                                type="checkbox"
                                className="checkbox-big"
                                checked={this.props.notSettled}
                                onChange={ev => this.props.questionnaireNotSettledChanged(ev.target.checked)}
                            />
                            <div className="inline-label">I didn't settle it</div>
                        </div>
                    </FormGroup>
                    {this.props.notSettled && (
                        <InformationContainer>
                            <CommunicatContainer>
                                You can not send invitation if you did not settle it/talk about it with customer. You
                                have to do it and try again.
                            </CommunicatContainer>
                        </InformationContainer>
                    )}
                    <FormGroup>
                        <ControlLabel>Send to: </ControlLabel>
                        <FormControl
                            placeholder="E-mail"
                            type="email"
                            value={this.props.email}
                            className={classnames()}
                            disabled={true}
                        />
                    </FormGroup>
                    {modalBody}
                </Modal.Body>
                <Modal.Footer>{modalFooter}</Modal.Footer>
            </StyledModal>
        );
    }
}
