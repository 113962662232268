import {
    COMPANY_SEARCH_CACHE_UPDATED,
    CUSTOMER_COMPANY_DETAILS_CHANGED,
    CUSTOMER_COMPANY_EMPLOYEES_CHANGED,
    CUSTOMER_ADDED,
    CUSTOMER_COMPANY_ID_CHANGED,
    CUSTOMER_COMPANY_STATE_CHANGED,
    COMPANY_STATE_NOT_SELECTED,
    CUSTOMER_REMOVED,
    CUSTOMER_EMPLOYEE_CONTACT_PERSON_CHANGED,
    CUSTOMER_EMPLOYEE_EMAIL_NOTIFICATION_CHANGED
} from "../actions/customersActions";
import { updateRow } from "../../utils";
import { ORDER_DATA_CHANGED, ORDER_RESET } from "../actions/orderActions";

const makeInitialState = _ => ({
    customers: [makeNewCustomer()],
    companySearchCache: []
});

let makeNewCustomer = _ => ({
    companyDetails: null,
    companyEmployees: null,
    companyId: null,
    companyState: COMPANY_STATE_NOT_SELECTED,
    contacts: []
});

export default function customers(state = makeInitialState(), action) {
    function updateContactProperty(propertyName) {
        let contactRecord = state.customers[action.customerIndex].contacts.find(
            c => c.employeeId === action.employeeId
        );
        if (!contactRecord) {
            contactRecord = {
                employeeId: action.employeeId,
                contactPerson: false,
                emailNotification: false
            };
        }
        contactRecord = {
            ...contactRecord,
            [propertyName]: action.payload
        };
        if(propertyName === "contactPerson" && action.payload === false) {
            contactRecord.emailNotification = false;
        }
        return {
            ...state,
            customers: state.customers.map((customer, i) => {
                if (i !== action.customerIndex) return customer;
                return {
                    ...customer,
                    contacts: [
                        ...customer.contacts.filter(contact => contact.employeeId !== action.employeeId),
                        contactRecord
                    ]
                };
            })
        };
    }
    switch (action.type) {
        case ORDER_RESET:
            return makeInitialState();
        case ORDER_DATA_CHANGED: {
            let customers = [];
            if (action.payload.customers.customer) {
                customers.push({
                    contacts: [],
                    ...action.payload.customers.customer
                });
            } else {
                customers.push(makeNewCustomer());
            }
            if (action.payload.customers.location) {
                customers.push({
                    contacts: [],
                    ...action.payload.customers.location
                });
            }
            return {
                ...state,
                customers
            };
        }
        case COMPANY_SEARCH_CACHE_UPDATED:
            return {
                ...state,
                companySearchCache: {
                    ...state.companySearchCache,
                    [action.cacheKey]: action.payload
                }
            };
        case CUSTOMER_ADDED:
            return {
                ...state,
                customers: [...state.customers, makeNewCustomer()]
            };
        case CUSTOMER_REMOVED:
            return {
                ...state,
                customers: state.customers.filter((_, i) => i !== action.customerIndex)
            };
        case CUSTOMER_COMPANY_DETAILS_CHANGED:
            return {
                ...state,
                customers: updateRow(state.customers, "companyDetails", action, "customerIndex")
            };
        case CUSTOMER_COMPANY_EMPLOYEES_CHANGED:
            return {
                ...state,
                customers: updateRow(state.customers, "companyEmployees", action, "customerIndex")
            };
        case CUSTOMER_COMPANY_ID_CHANGED:
            return {
                ...state,
                customers: updateRow(state.customers, "companyId", action, "customerIndex")
            };
        case CUSTOMER_COMPANY_STATE_CHANGED:
            return {
                ...state,
                customers: updateRow(state.customers, "companyState", action, "customerIndex")
            };
        case CUSTOMER_EMPLOYEE_CONTACT_PERSON_CHANGED: {
            return updateContactProperty("contactPerson");
        }
        case CUSTOMER_EMPLOYEE_EMAIL_NOTIFICATION_CHANGED: {
            return updateContactProperty("emailNotification");
        }
        default:
            return state;
    }
}
