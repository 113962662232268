import React from "react";
import StyledTh from "./StyledTh";

//const columnToTh = (col, i) => <th>{col.header}</th>

export default class AutoTableHeader extends React.Component {
    render() {
        return (
            <thead>
                <tr>
                    {this.props.model.columns.map((c, i) => (
                        <StyledTh key={i} model={this.props.model}>
                            {c.title}
                        </StyledTh>
                    ))}
                </tr>
            </thead>
        );
    }
}
