import React from "react";
import PolicyContent from "./PolicyContent";

export default class Regulations extends React.Component {
    render() {
        return (
            <PolicyContent />
        );
    }
}
