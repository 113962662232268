import React from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import ExternalQATableRow from "./ExternalQATableRow";
import ExternalQATableHeader from "./ExternalQATableHeader";
import ExternalQATableTotalsRow from "./ExternalQATableTotalsRow";
import styled from "styled-components";

const NarrowTable = styled(Table)`
   width: auto;
`;
@connect(({ externals }) => ({ ...externals.external }))
export default class ExternalQATable extends React.Component {
    render() {
        let tableBody = this.props.productQA.map((r, i) => {
            return <ExternalQATableRow externalMode row={r} key={r._id || i} rowIndex={i} />;
        });

        return (
            <NarrowTable responsive={true}>
                <ExternalQATableHeader />
                <tbody>{tableBody}</tbody>
                <tfoot>
                    <ExternalQATableTotalsRow />
                </tfoot>
            </NarrowTable>
        );
    }
}
