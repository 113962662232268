import download from "downloadjs";
import moment from "moment";
import { stringify as stringifyQueryString } from "querystring";
import { push } from "react-router-redux";
import { toast } from "react-toastify";
import { apiFetch } from "../../../actions/apiActions";
import { periodSelectOptions } from "../../../OrdersListPage/filtersModel";
import { parseRawQueryString } from "../../../urlUtils";
import { encodeParams, makeActionCreator } from "../../../utils";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_ERROR = "STATE_ERROR";

export const STATE_CHANGED = "CLIENT_PANEL.CLIENT_PANEL_EXTERNALS.STATE_CHANGED";
export const EXTERNALS_CHANGED = "CLIENT_PANEL.CLIENT_PANEL_EXTERNALS.EXTERNALS_CHANGED";
export const ORDER_INFORMATION_CHANGED = "CLIENT_PANEL.CLIENT_PANEL_EXTERNALS.ORDER_INFORMATION_CHANGED";
export const LAST_FILTERS_UPDATE_CHANGED = "CLIENT_PANEL.CLIENT_PANEL_EXTERNALS.LAST_FILTERS_UPDATE_CHANGED";

export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const externalsChanged = makeActionCreator(EXTERNALS_CHANGED, "payload");
export const orderInformationChanged = makeActionCreator(ORDER_INFORMATION_CHANGED, "payload");
export const lastFiltersUpdateChanged = makeActionCreator(LAST_FILTERS_UPDATE_CHANGED, "payload");

export const SELECTED_FILTER_PERIOD = "PERIOD";
export const SELECTED_FILTER_DATE = "DATE";

export function customerViewFiltersSelector(state) {
    const query = parseRawQueryString(state.router.location.search);
    let parsedDateRange = null;
    let selectedFilter = query.selectedFilter;
    if (selectedFilter !== SELECTED_FILTER_PERIOD && selectedFilter !== SELECTED_FILTER_DATE) {
        selectedFilter = SELECTED_FILTER_PERIOD;
    }
    const periodOption = periodSelectOptions.find(o => o.value === query.period) || periodSelectOptions[0];
    if (selectedFilter === SELECTED_FILTER_DATE) {
        try {
            let data = JSON.parse(query.dateRange);
            parsedDateRange = {
                start: data.start ? new Date(data.start) : null,
                end: data.end ? new Date(data.end) : null
            };
        } catch (e) {
            console.error(e);
        }
    }
    if (!parsedDateRange) {
        parsedDateRange = {
            start: new Date(periodOption.dateStart.toString()),
            end: new Date(periodOption.dateEnd.toString())
        };
    }
    let order;
    if (state.router.location.pathname.indexOf("/dashboard/simulations/customer/") === -1) {
        order = state.router.location.pathname.split("/")[2];
    } else {
        order = state.router.location.pathname.split("/")[4];
    }
    if (order === "null") {
        order = null;
    }
    return {
        selectedFilter,
        period: periodOption.value,
        searchContent: query.searchContent || "",
        dateRange: parsedDateRange,
        order: order || null
    };
}

export function updateFilters(newValues = {}) {
    return (dispatch, getState) => {
        const oldQueryString = getState().router.location.search;

        const newOrder = newValues.order;
        delete newValues.order;
        const newQuery = {
            ...customerViewFiltersSelector(getState()),
            ...newValues
        };
        if (typeof newQuery.dateRange === "object") {
            newQuery.dateRange = JSON.stringify(newQuery.dateRange);
        }

        let pathname;
        if (newOrder || newOrder === null) {
            if (getState().router.location.pathname.indexOf("/dashboard/simulations/customer/") === -1) {
                // check if we are in simulations
                pathname = encodeParams`/customer/${newOrder}`;
            } else {
                pathname = encodeParams`/dashboard/simulations/customer/${newOrder}`;
            }
        }

        const newQueryString = "?" + stringifyQueryString(newQuery);

        dispatch(
            push({
                pathname,
                search: newQueryString
            })
        );
        if (Object.keys(newValues).length > 0 || newOrder) {
            if (newOrder || newQueryString !== oldQueryString) {
                dispatch(loadExternalsForOrderDebounced());
            }
        }
    };
}

export function loadExternalsForOrderDebounced() {
    return async (dispatch, getState) => {
        const old = new Date().toString();
        dispatch(lastFiltersUpdateChanged(old));
        setTimeout(() => {
            if (getState().clientPanel.clientPanelExternals.lastFiltersUpdate === old) {
                dispatch(loadExternalsForOrder());
            }
        }, 400);
    };
}

export function loadExternalsForOrder() {
    return async (dispatch, getState) => {
        dispatch(stateChanged(STATE_LOADING));
        try {
            let data;
            const filters = customerViewFiltersSelector(getState());
            const order = filters.order;
            const dateStart = filters.dateRange && filters.dateRange.start;
            const dateEnd = filters.dateRange && filters.dateRange.end;
            if (!order) {
                dispatch(stateChanged(STATE_DEFAULT));
                return;
            }
            let orderInformation = await dispatch(apiFetch(encodeParams`/api/customer-panel/orders/${order}/info`));
            let orderInformationData = await orderInformation.json();
            if (!orderInformation.ok) {
                throw new Error(orderInformationData.message);
            }
            dispatch(orderInformationChanged(orderInformationData));

            //new general summary
            let generalSummaryPerPart = await dispatch(
                apiFetch(
                    encodeParams`/api/customer-panel/externals/part-report?order=${order}&dateStart=${dateStart}&dateEnd=${dateEnd}`
                )
            );
            let generalSummaryPerPartData = await generalSummaryPerPart.json();

            if (!generalSummaryPerPart.ok) {
                throw new Error(generalSummaryPerPartData.message);
            }

            //new working hours
            let workingHours = await dispatch(
                apiFetch(
                    encodeParams`/api/customer-panel/externals/hour-report?order=${order}&dateStart=${dateStart}&dateEnd=${dateEnd}`
                )
            );
            let workingHoursData = await workingHours.json();
            if (!workingHours.ok) {
                throw new Error(workingHoursData.message);
            }

            //new details part report
            let detailedPartReport = await dispatch(
                apiFetch(
                    encodeParams`/api/customer-panel/externals/detail-part-report?order=${order}&dateStart=${dateStart}&dateEnd=${dateEnd}`
                )
            );
            let detailedPartReportData = await detailedPartReport.json();
            if (!detailedPartReport.ok) {
                throw new Error(detailedPartReportData.message);
            }

            //new daily part report
            let dailyPartReport = await dispatch(
                apiFetch(
                    encodeParams`/api/customer-panel/externals/daily-part-report?order=${order}&dateStart=${dateStart}&dateEnd=${dateEnd}`
                )
            );
            let dailyPartReportData = await dailyPartReport.json();
            if (!detailedPartReport.ok) {
                throw new Error(dailyPartReportData.message);
            }

            let letterColumnsDescription = await dispatch(
                apiFetch(encodeParams`/api/customer-panel/orders/${order}/letter-columns-desc`)
            );
            let letterColumnsDescriptionData = await letterColumnsDescription.json();
            if (!letterColumnsDescription.ok) {
                throw new Error(letterColumnsDescriptionData.message);
            }

            data = {
                generalSummaryPerPart: generalSummaryPerPartData,
                workingHours: workingHoursData,
                detailedPartReport: detailedPartReportData,
                dailyPartReport: dailyPartReportData,
                letterColumnsDescription: letterColumnsDescriptionData
            };
            dispatch(externalsChanged(data));
            dispatch(stateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(stateChanged(STATE_ERROR));
            toast("An error has occured while loading customer panel data: " + e.message, { type: "error" });
            console.error(e);
        }
    };
}

export function generateCustomerReport() {
    return async (dispatch, getState) => {
        try {
            const filters = customerViewFiltersSelector(getState());
            const order = filters.order;
            const dateStart = filters.dateRange && filters.dateRange.start;
            const dateEnd = filters.dateRange && filters.dateRange.end;
            let resp = await dispatch(
                apiFetch("/api/customer-panel/externals/generate-excel", undefined, {
                    dateStart: moment(dateStart).format("YYYY-MM-DD"),
                    dateEnd: moment(dateEnd).format("YYYY-MM-DD"),
                    order
                })
            );
            if (resp.status !== 200) {
                throw new Error((await resp.json()).message);
            }

            let data = await resp.blob();
            download(data);
        } catch (e) {
            toast(e.message, { type: "error" });
        }
    };
}
