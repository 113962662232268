import React from "react";
import styled from "styled-components";

export default styled.table`
    border: none !important;
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    td,
    th {
        display: table-cell;
        padding: 22px;
        text-align: left;
        font-weight: 400;
        line-height: 1.43;
        border-bottom: 1.5px solid rgba(224, 224, 224, 1);
        letter-spacing: 0.01071em;
        vertical-align: inherit;
        transition: background-color 300ms ease-in-out;
    }
    th {
        font-weight: 600;
        line-height: 1.5rem;
        vertical-align: top;
    }
    tr {
        &:hover {
            td {
                background-color: rgba(0, 0, 0, 0.07);
            }
        }
    }
`;
