import { makeActionCreator } from "../utils";
import { makeApiUrl } from "../api";
import UnauthorizedError from "../errors/UnauthorizedError";

export const TOKEN_CHANGED = "API.TOKEN_CHANGED";
export const SHOW_PLEASE_LOGIN_MODAL_CHANGED = "API.SHOW_PLEASE_LOGIN_MODAL_CHANGED";

/**
 * Updates the token in memory. DO NOT USE WHEN RECIEVING NEW TOKEN FROM SERVER, use tokenUpdated.
 */
export const tokenChanged = makeActionCreator(TOKEN_CHANGED, "payload");

export const showPleaseLoginModalChanged = makeActionCreator(SHOW_PLEASE_LOGIN_MODAL_CHANGED, "payload");

/**
 * Updates the token in memory and saves it to localStorage.
 * @param {String} newToken
 */
export function tokenUpdated(newToken) {
    return dispatch => {
        dispatch(tokenChanged(newToken));
        localStorage.setItem("token", newToken);
    };
}

export function loadToken() {
    return dispatch => {
        dispatch(tokenChanged(localStorage.getItem("token")));
    };
}

export function apiFetch(endpoint, options = {}, getParams) {
    if (typeof endpoint !== "string") {
        throw new Error(`Endpoint should be a string, ${typeof endpoint} given.`);
    }
    return async (dispatch, getState) => {
        let token = getState().api.token;
        let authSuccessful = true;
        let extraHeaders = {};
        if (options.method !== "GET" && !options.fileUpload) {
            extraHeaders["Content-Type"] = "application/json";
        }
        if (!token) {
            authSuccessful = false;
        } else {
            let resp;
            try {
                resp = await fetch(makeApiUrl(endpoint, getParams), {
                    headers: {
                        ...extraHeaders,
                        Authorization: `Bearer ${token}`,
                        ...options.headers
                    },
                    ...options
                });
            } catch (e) {
                if (e.message === "Failed to fetch") {
                    throw new Error(
                        "Error! You probably lost your internet connection. Check it and reload page."
                    );
                } else {
                    throw e;
                }
            }
            if (resp.status === 401) {
                authSuccessful = false;
            } else {
                return resp;
            }
        }
        if (!authSuccessful) {
            dispatch(showPleaseLoginModalChanged(true));
            throw new UnauthorizedError();
        }
    };
}

if (process.env.NODE_ENV !== "production") {
    console.log("Exposing apiFetch at window.apiFetchE");
    window.apiFetchE = apiFetch;
}
