import React from "react";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { modalResultChanged, modalStateChanged, MODAL_STATE_FINISHED } from "../../actions/modalsActions";

@connect(
    () => ({}),
    dispatch => ({
        modalResultChanged: (id, p) => dispatch(modalResultChanged(id, p)),
        modalStateChanged: (id, p) => dispatch(modalStateChanged(id, p))
    })
)
export default class ConfirmModal extends React.Component {
    closeModal = _ => {
        this.props.modalResultChanged(this.props.modal.modalId, false);
        this.props.modalStateChanged(this.props.modal.modalId, MODAL_STATE_FINISHED);
    };
    confirm = _ => {
        this.props.modalResultChanged(this.props.modal.modalId, true);
        this.props.modalStateChanged(this.props.modal.modalId, MODAL_STATE_FINISHED);
    };
    render() {
        let modalData = this.props.modal.data;
        return (
            <Modal show={true} onHide={this.closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {modalData.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{modalData.message}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeModal}>{modalData.cancelButtonText}</Button>
                    <Button onClick={this.confirm}>{modalData.okButtonText}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
