import React from "react";
import { connect } from "react-redux";
import { FormControl } from "react-bootstrap";
import totalsSelector from "../selectors/totalsSelector";
import styled from "styled-components";
import QAField from "./styled/QAField";

const TotalTd = styled.td`
    padding-top: 10px !important;
`;

export class DumbExternalQATableTotalsRow extends React.Component {
    render() {
        return (
            <tr>
                <td />
                <TotalTd className="total">Total:</TotalTd>
                <td>
                    <QAField type="text" disabled value={this.props.totalChecked} />
                </td>
                <td>
                    <QAField type="text" disabled value={this.props.fromThisOk} />
                </td>
                <td>
                    <QAField type="text" disabled value={this.props.reworked} />
                </td>
                <td>
                    <QAField type="text" disabled value={this.props.nok} />
                </td>
                <td>
                    <QAField
                        type="text"
                        disabled
                        className="input-total"
                        value={this.props.totalOk}
                    />
                </td>
                <td />
                <td />
            </tr>
        );
    }
}

export default connect(totalsSelector)(DumbExternalQATableTotalsRow);
