import React, { useState } from "react";
import MatDropdown from "../MatDropdown";
import DualCalendarSelector from "./DualCalendarSelector";
import OutsideClickHandler from "react-outside-click-handler";
import styled, { css } from "styled-components";
import { decrementMonth, incrementMonth } from "./dateUtils";
import { formatDate } from "../../../../utils";

const DirectionButton = styled.button`
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575;
    position: absolute;
    top: 18px;
    line-height: 0.78;
    border-radius: 3px;
    padding: 6px 9px;
    outline: none;
    &:hover {
        border: 1px solid #c4c4c4;
    }
    ${props =>
        props.right &&
        css`
            right: 25px;
            ${ArrowSvg} {
                transform: rotate(180deg);
            }
        `}
`;
/**
 * Arrow icon in the dropdown Next/prev month button.
 */
const ArrowSvg = styled.svg`
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block;
`;

const ArrowIcon = (
    <ArrowSvg focusable="false" viewBox="0 0 1000 1000">
        <path d="M336 275L126 485h806c13 0 23 10 23 23s-10 23-23 23H126l210 210c11 11 11 21 0 32-5 5-10 7-16 7s-11-2-16-7L55 524c-11-11-11-21 0-32l249-249c21-22 53 10 32 32z" />
    </ArrowSvg>
);

const DateRangeInputBox = styled.div`
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 35px;
    ${props =>
        props.disabled &&
        css`
            background: #f2f2f2;
        `}
`;

const DatePart = styled.div`
    cursor: text;
    padding: 11px;
    font-weight: lighter;
    font-size: 14px;
    line-height: 24px;
    color: #484848;

    width: 100%;
    padding: 4px 11px 2px;
    border: 0;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    span {
        opacity: 0.8;
    }
    ${props =>
        props.active &&
        css`
            font-weight: 400;
            border-color: rgb(68, 130, 255);
        `}
`;

const InputDividerArrowSvg = styled.svg`
    align-self: center;
    vertical-align: middle;
    fill: #484848;
    height: 35px;
    width: 55px;
`;

const InputDividerArrow = (
    <InputDividerArrowSvg focusable="false" viewBox="0 0 1000 1000">
        <path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z" />
    </InputDividerArrowSvg>
);

const EDITED_PART_START = "START";
const EDITED_PART_END = "END";

export default ({ value, onChange, disabled, onClick, expandWhenDisabled, ...props }) => {
    const [editedPart, setEditedPart] = useState(null);
    const [currentMonthView, setCurrentMonthView] = useState({
        month: new Date().getMonth(),
        year: new Date().getFullYear()
    });
    const [uncontrolledValue, setUncontrolledValue] = useState(null);
    const isUncontrolled = value === undefined && !onChange;
    let effectiveValue, effectiveOnChange;
    if (isUncontrolled) {
        effectiveValue = uncontrolledValue;
        effectiveOnChange = v => setUncontrolledValue(v);
    } else {
        effectiveValue = value;
        effectiveOnChange = onChange;
    }
    let fallbackValue = effectiveValue || { start: null, end: null };
    const dayClicked = day => {
        if (editedPart === EDITED_PART_START) {
            let end = fallbackValue.end;
            if (end && day.getTime() > end.getTime()) {
                end = null;
            }
            effectiveOnChange({
                ...fallbackValue,
                end,
                start: day
            });
            setEditedPart(EDITED_PART_END);
        } else if (editedPart === EDITED_PART_END) {
            let start = fallbackValue.start;
            if (start && day.getTime() < start.getTime()) {
                start = null;
                setEditedPart(EDITED_PART_START);
            }
            effectiveOnChange({
                ...fallbackValue,
                start,
                end: day
            });
        }
    };
    return (
        <div {...props}>
            <DateRangeInputBox disabled={disabled} onClick={onClick}>
                <DatePart
                    onClick={() => (!disabled || expandWhenDisabled) && setEditedPart(EDITED_PART_START)}
                    active={editedPart === EDITED_PART_START}
                >
                    {fallbackValue.start ? formatDate(fallbackValue.start) : <span>start&nbsp;date</span>}
                </DatePart>
                {InputDividerArrow}
                <DatePart
                    onClick={() => (!disabled || expandWhenDisabled) && setEditedPart(EDITED_PART_END)}
                    active={editedPart === EDITED_PART_END}
                >
                    {fallbackValue.end ? formatDate(fallbackValue.end) : <span>end&nbsp;date</span>}
                </DatePart>
            </DateRangeInputBox>
            <OutsideClickHandler onOutsideClick={() => setEditedPart(null)}>
                <MatDropdown calendar isOpen={!!editedPart}>
                    <DirectionButton onClick={() => setCurrentMonthView(decrementMonth(currentMonthView))}>
                        {ArrowIcon}
                    </DirectionButton>
                    <DirectionButton right onClick={() => setCurrentMonthView(incrementMonth(currentMonthView))}>
                        {ArrowIcon}
                    </DirectionButton>
                    <DualCalendarSelector
                        currentMonthView={currentMonthView}
                        dayClicked={dayClicked}
                        dateRange={fallbackValue}
                    />
                </MatDropdown>
            </OutsideClickHandler>
        </div>
    );
};
