import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import styled from "styled-components";
import { hrFieldTypesInfo } from "../../../../../common/src/hrTemplates";
import ConfirmableButton from "../../../components/common/ConfirmableButton";
import StyledButton from "../../../components/common/StyledButton";
import StyledIcon from "../../../components/common/StyledIcon";
import { fieldTypeChanged, fieldRemoved, fieldCopied, fieldAddColumnChanged } from "../../actions/editTemplateActions";
import FieldParamCells from "./FieldParamCells";

const typeOptions = Object.keys(hrFieldTypesInfo).map(k => ({
    value: k,
    label: hrFieldTypesInfo[k].name
}));

const Row = styled.tr`
    td {
        padding-bottom: 20px !important;
    }
`;

const TypeSelectCell = styled.td`
    width: 200px;
    padding-right: 10px;
`;

const CheckboxCell = styled.td`
    width: 40px;
`;

const ButtonCell = styled.td`
    width: 40px;
    padding-left: 15px;
`;

@connect(
    state => state.hr.editTemplate,
    (dispatch, props) => ({
        fieldTypeChanged: ev => dispatch(fieldTypeChanged(props.fieldIndex, !!ev ? ev.value : null)),
        fieldRemoved: _ => dispatch(fieldRemoved(props.fieldIndex)),
        fieldCopied: _ => dispatch(fieldCopied(props.fieldIndex)),
        fieldAddColumnChanged: ev => dispatch(fieldAddColumnChanged(props.fieldIndex, ev.target.checked))
    })
)
export default class FieldEditRow extends React.Component {
    render() {
        return (
            <Row>
                <CheckboxCell>
                    {this.props.addTemplateAsColumn && (
                        <input
                            type="checkbox"
                            className="checkbox-big"
                            checked={this.props.field.addColumn}
                            onChange={this.props.fieldAddColumnChanged}
                        />
                    )}
                </CheckboxCell>
                <TypeSelectCell>
                    <Select
                        options={typeOptions}
                        value={this.props.field.type}
                        onChange={this.props.fieldTypeChanged}
                    />
                </TypeSelectCell>
                <FieldParamCells field={this.props.field} fieldIndex={this.props.fieldIndex} />
                <ButtonCell>
                    <StyledButton onClick={this.props.fieldCopied}>
                        <StyledIcon glyph="plus" />
                    </StyledButton>
                </ButtonCell>
                <ButtonCell>
                    <ConfirmableButton onConfirm={this.props.fieldRemoved} />
                </ButtonCell>
            </Row>
        );
    }
}
