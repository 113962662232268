import React from "react";
import styled, { css } from "styled-components";
import useHover from "../../../../hooks/useHover";
import { Link } from "react-router-dom";

const BalloonContainer = styled.div`
    background-color: #fff;
    border: solid 1px black;
    border-radius: 3px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    width: auto;
    min-width: 200px;
    max-width: 510px;
    padding: 5px;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    display: block;
    color: #000;
`;

const CellContainer = styled.div`
    position: relative;
`;
const OverflowCss = css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
`;

const Overflow = styled.div`
    ${OverflowCss}
`;

const OverflowLink = styled(Link)`
    ${OverflowCss}
`;

/**
 * Baloon cell limits the amount of text in the cell to 3 lines and shows the rest in a tooltip.
 */
export default ({ value, column, row }) => {
    const [ref, hovering] = useHover();

    return (
        <CellContainer ref={ref}>
            {!column.to && <Overflow>{value}</Overflow>}
            {column.to && <OverflowLink to={column.to(row)}>{value}</OverflowLink>}
            {hovering && <BalloonContainer>{value}</BalloonContainer>}
        </CellContainer>
    );
};
