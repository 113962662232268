import { UNOFFICIAL_NAME_CHANGED } from "../actions/unofficialNameActions";
import { ORDER_DATA_CHANGED, ORDER_RESET } from "../actions/orderActions";

const makeInitialState = _ => ({
    unofficialName: ""
});

export default function unofficialName(state = makeInitialState(), action) {
    switch (action.type) {
        case ORDER_DATA_CHANGED:
            return action.payload.unofficialName;
        case ORDER_RESET:
            return makeInitialState();
        case UNOFFICIAL_NAME_CHANGED:
            return {
                ...state,
                unofficialName: action.payload
            };
        default:
            return state;
    }
}
