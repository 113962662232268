import React from "react";
import styled from "styled-components";
import MatLabel from "./MatLabel";

const CheckboxLabel = styled(MatLabel)`
    position: relative;
    height: 20px;
    &:hover > ${CheckboxInput} {
        opacity: 1;
    }
`;

const LabelSpan = styled.span`
    display: inline-block;
    width: 100%;
    cursor: pointer;
    &:before {
        content: "";
        display: inline-block;
        box-sizing: border-box;
        margin: 3px 11px 3px 1px;
        border: solid 2px; /* Safari */
        border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
        border-radius: 2px;
        width: 18px;
        height: 18px;
        vertical-align: top;
        transition: border-color 0.2s, background-color 0.2s;
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        left: 1px;
        width: 12px;
        height: 7px;
        border: solid 2px transparent;
        border-right: none;
        border-top: none;
        transform: translate(3px, 4px) rotate(-45deg);
    }
`;

const CheckboxInput = styled.input`
    appearance: none;
    z-index: -1;
    position: absolute;
    left: -10px;
    top: -8px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;
    &:checked {
        background-color: rgb(33, 150, 243);
    }
    &:checked + ${LabelSpan}:before {
        border-color: rgb(68, 130, 255);
        background-color: rgb(68, 130, 255);
    }
    &:checked + ${LabelSpan}:after {
        border-color: rgb(255, 255, 255);
    }
`;

export default ({ label, className, ...rest }) => {
    return (
        <CheckboxLabel className={className}>
            <CheckboxInput type="checkbox" {...rest} />
            <LabelSpan>{label}</LabelSpan>
        </CheckboxLabel>
    );
};
