import React from "react";
import { connect } from "react-redux";
import { Row, Button, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { fromTimeChanged, toTimeChanged } from "../actions/externalActions";
import styled from "styled-components";
import { clockTimeToMinutes } from "../../../../src/helpers/reportsHelpers";
import IndicatorButton from "../../components/common/IndicatorButton";

export const LinearContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    .form-group,
    .indicator-wrapper {
        margin-left: 10px;
    }
    .indicator-wrapper {
        margin-bottom: 15px;
    }
`;

export const HoursInput = styled(FormControl)`
    width: 65px !important;
`;
export class DumbExternalReportHeader extends React.Component {
    render() {
        let toTimeMinutes = clockTimeToMinutes(this.props.toTime);
        let fromTimeMinutes = clockTimeToMinutes(this.props.fromTime);
        let dayIndicator = null;
        if ((fromTimeMinutes > toTimeMinutes && toTimeMinutes !== 0) || fromTimeMinutes < 6 * 60) {
            dayIndicator = <IndicatorButton disabled>+1d</IndicatorButton>;
        }
        return (
            <LinearContainer>
                <FormGroup className="form-inline">
                    <ControlLabel>Form. number</ControlLabel>
                    <FormControl disabled value={this.props.formularNumber} className="input-very-small" />
                </FormGroup>

                <FormGroup className="form-inline">
                    <ControlLabel>From</ControlLabel>
                    <HoursInput
                        value={this.props.fromTime}
                        onChange={this.props.fromTimeChanged}
                        disabled
                    />
                </FormGroup>

                <FormGroup className="form-inline">
                    <ControlLabel>To</ControlLabel>
                    <HoursInput
                        value={this.props.toTime}
                        onChange={this.props.toTimeChanged}
                        disabled
                    />
                </FormGroup>
                <div className="indicator-wrapper">{dayIndicator}</div>
            </LinearContainer>
        );
    }
}

export default connect(
    ({ externals }) => ({ ...externals.external }),
    dispatch => ({
        fromTimeChanged: ev => dispatch(fromTimeChanged(ev.target.value)),
        toTimeChanged: ev => dispatch(toTimeChanged(ev.target.value))
    })
)(DumbExternalReportHeader);
