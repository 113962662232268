import { makeActionCreator, encodeParams } from "../../utils";
import { apiFetch } from "../../actions/apiActions";

export const OLD_EXTERNALS_LOADED = "EXTERNALS.OLD_EXTERNALS.OLD_EXTERNALS_LOADED";

export const oldExternalsLoaded = makeActionCreator(OLD_EXTERNALS_LOADED, "payload");

export function loadOldExternals(orderId, controlDate) {
    return async (dispatch, getState) => {
        let resp = await dispatch(apiFetch(encodeParams`/api/externals/search?order=${orderId}&date=${controlDate}`));
        if (!resp.ok) {
            throw new Error((await resp.json()).message);
        }
        let data = await resp.json();
        dispatch(oldExternalsLoaded(data));
    };
}
