import {
    EMAIL_CHANGED,
    PASSWORD_CHANGED,
    ERROR_MESSAGE_CHANGED,
    LOGIN_STATE_CHANGED,
    STATE_DEFAULT
} from "../actions/loginActions";

const initialState = {
    email: "",
    password: "",
    errorMessage: "",
    loginState: STATE_DEFAULT
};

export default function login(state = initialState, action) {
    switch (action.type) {
        case EMAIL_CHANGED:
            return {
                ...state,
                email: action.payload
            };
        case PASSWORD_CHANGED:
            return {
                ...state,
                password: action.payload
            };
        case ERROR_MESSAGE_CHANGED:
            return {
                ...state,
                errorMessage: action.payload
            };
        case LOGIN_STATE_CHANGED:
            return {
                ...state,
                loginState: action.payload
            };
        default:
            return state;
    }
}
