import {
    REMARKS_DESCRIPTION_CHANGED,
} from "../actions/remarksDescriptionActions";
import { REPORT_RESET, REPORT_DATA_CHANGED } from "../actions/reportActions";

export const makeInitialState = _ => ({
    remarksDescriptionContent: ""
});

export default function remarksDescription(state = makeInitialState(), action) {
    switch (action.type) {
        case REPORT_RESET:
            return makeInitialState();
        case REPORT_DATA_CHANGED:
            return action.payload.remarksDescription;
        case REMARKS_DESCRIPTION_CHANGED:
            return {
                ...state,
                remarksDescriptionContent: action.payload
            };
        default:
            return state;
    }
}
