import React from "react";
import { connect } from "react-redux";
import StyledButton from "../../components/common/StyledButton";
import ExternalReportHeader from "./ExternalReportHeader";
import ExternalQATable from "./ExternalQATable";
import CurrentRemarks from "./CurrentRemarks";
import CurrentReportSortRate from "./CurrentReportSortRate";
import { Row, Col, ControlLabel } from "react-bootstrap";
import HoursForm from "./HoursForm";
import { saveExternal, hiddenFromClientChanged, externalModalOpenChanged } from "../actions/externalActions";
import styled from "styled-components";
import theme from "../../theme";
import isExternalDisabled from "../selectors/isExternalDisabled";

const CurrentReportContainer = styled.div`
    border-radius: 5px;
    border: 1px solid ${theme.primaryColor};
    padding: 10px;
`;

export const InlineCheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
    padding-top: 5px;
    div {
        /* TODO: fix ugly hack */
        margin-top: -3px;
    }
`;

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @media only screen and (max-width: 418px) {
        flex-direction: column;
        button {
            margin-top: 10px;
            align-self: center;
        }
    }
`;

@connect(
    state => ({
        ...state.externals.external,
        disabled: isExternalDisabled(state)
    }),
    dispatch => ({
        hiddenFromClientChanged: ev => {
            dispatch(hiddenFromClientChanged(ev.target.checked));
            if (ev.target.checked) {
                dispatch(saveExternal(true));
            }
        },
        saveExternal: _ => dispatch(saveExternal())
    })
)
export default class CurrentReportForm extends React.Component {
    render() {
        return (
            <CurrentReportContainer>
                <ExternalReportHeader />
                <ExternalQATable />
                <Row>
                    <Col md={4}>
                        <CurrentReportSortRate />
                        <CurrentRemarks />
                    </Col>
                    <Col md={8}>
                        <HoursForm />
                    </Col>
                </Row>
                <ActionsContainer>
                    <InlineCheckboxContainer>
                        <div>
                            <input
                                type="checkbox"
                                className="checkbox-big inline"
                                checked={this.props.hiddenFromClient}
                                onChange={this.props.hiddenFromClientChanged}
                                disabled={this.props.disabled}
                            />
                        </div>
                        <ControlLabel>Don't show this report to client</ControlLabel>
                    </InlineCheckboxContainer>
                    <StyledButton
                        bsStyle="__custom"
                        onClick={this.props.saveExternal}
                        customBackgroundColor={this.props.hiddenFromClient ? "#3498db" : "#193F77"}
                        dark
                        disabled={this.props.disabled}
                    >
                        {this.props.hiddenFromClient ? "Save" : "Visible to client"}
                    </StyledButton>
                </ActionsContainer>
                <div className="clearfix" />
            </CurrentReportContainer>
        );
    }
}
