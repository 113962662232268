import { OFFICIAL_NAME_CHANGED } from "../actions/officialNameActions";
import { ORDER_DATA_CHANGED, ORDER_RESET } from "../actions/orderActions";

const makeInitialState = _ => ({
    officialName: ""
});

export default function officialName(state = makeInitialState(), action) {
    switch (action.type) {
        case ORDER_DATA_CHANGED:
            return action.payload.officialName || { officialName: "" };
        case ORDER_RESET:
            return makeInitialState();
        case OFFICIAL_NAME_CHANGED:
            return {
                ...state,
                officialName: action.payload
            };
        default:
            return state;
    }
}
