import { combineReducers } from "redux";
import employeesList from "./employeesList";
import editEmployee from "./editEmployee";
import editTemplate from "./editTemplate";
import templatesList from "./templatesList";
import templateFiltersModal from "./templateFiltersModal";

export default combineReducers({
    employeesList,
    editEmployee,
    editTemplate,
    templatesList,
    templateFiltersModal
});
