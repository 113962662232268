import { apiFetch } from "../../../actions/apiActions";
import { encodeParams } from "../../../utils";

export function ordersSearched(query, { location, customer } = {}) {
    return async (dispatch, getState) => {
        try {
            let isSimulation = null;
            getState().router.location.pathname.indexOf("simulations") === -1
                ? (isSimulation = false)
                : (isSimulation = true);
            let u = "";
            //search in all orders if it is simulation
            isSimulation
                ? (u = encodeParams`/api/orders/search?query=${query}`)
                : (u = encodeParams`/api/customer-panel/orders/search?query=${query}`);

            if (location) {
                u += `&location=${location}`;
            }
            if (customer) {
                u += `&customer=${customer}`;
            }
            let resp = await dispatch(apiFetch(u));
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message || "Unknown error");
            }
            return data;
        } catch (e) {
            // TODO: Add ui
            // eslint-disable-next-line no-console
            console.error("[NEEDSUI] Error while searching orders", e);
            return [];
        }
    };
}
