import React from "react";
import styled from "styled-components";

const MatLabel = styled.label`
    display: block;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    padding: 0;
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
`;

export default MatLabel;
