import React from "react";
import {
    HR_FIELD_TYPE_TEXT,
    HR_FIELD_TYPE_CHECKBOX,
    HR_FIELD_TYPE_TIME_FROM,
    HR_FIELD_TYPE_TIME_TO,
    HR_FIELD_TYPE_EMAIL_NOTIFICATION,
    HR_FIELD_TYPE_TRAY_NOTIFICATION
} from "../../../../../../common/src/hrTemplates";
import TextField from "./fields/TextField";
import CheckboxField from "./fields/CheckboxField";
import DateField from "./fields/DateField";
import NotificationField from "./fields/NotificationField";

const fieldTypeComponentMap = {
    [HR_FIELD_TYPE_TEXT]: TextField,
    [HR_FIELD_TYPE_CHECKBOX]: CheckboxField,
    [HR_FIELD_TYPE_TIME_FROM]: DateField,
    [HR_FIELD_TYPE_TIME_TO]: DateField,
    [HR_FIELD_TYPE_EMAIL_NOTIFICATION]: NotificationField,
    [HR_FIELD_TYPE_TRAY_NOTIFICATION]: NotificationField
};

export default class FormFieldsList extends React.Component {
    render() {
        return this.props.fields.map((f, i) => {
            let Comp = fieldTypeComponentMap[f.type];
            if (!Comp) return null;
            return (
                <Comp
                    key={f._id || i}
                    field={f}
                    fieldIndex={i}
                    value={this.props.valueSelector(f, i)}
                    onChange={this.props.onChangeSelector(f, i)}
                    autocompleteDataSelector={this.props.autocompleteDataSelector}
                />
            );
        });
    }
}
