import React, { useEffect } from "react";
import OrderContainer from "./OrderContainer";
import { connect } from "react-redux";
import getActions from "./actions/orderActions";
import styled from "styled-components";

const Company = styled.div`
    border: 2px solid #193f77;
    margin: 5px 0 0 10px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16);
    border-radius: 3px;
    margin-top: 10px;
`;

const CompanyHeader = styled.div`
    background-color: #193f77;
    color: #fff;
    font-size: 16px;
    padding-left: 10px;
`;

function CompanyContainer(props) {
    useEffect(() => {
        props.getCompanies();
    }, []);
    const { companies } = props;
    return (
        <>
            {
                companies.map(company => {
                    return (
                        <Company>
                            <CompanyHeader>{company.companyName}</CompanyHeader>
                            <OrderContainer orders={company.orders} />
                        </Company>
                    );
                })
            }
        </>
    );
}

function mapStoreStateToProps({ reporterDesktop }) {
    return {
        ...reporterDesktop
    };
}

function mapActionsToProps(dispatch) {
    return getActions(dispatch);
}

export default connect(mapStoreStateToProps, mapActionsToProps)(CompanyContainer);