import React from "react";
import styled, { css } from "styled-components";

const ArrowSvg = (
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
        <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
    </svg>
);

export default styled.button.attrs(props => ({
    children: (
        <>
            <span>{props.label}</span>
            {ArrowSvg}
        </>
    )
}))`
    padding: 0;
    margin-top: -15px;
    display: inline-block;
    appearance: none;
    background: transparent;
    border: none;
    outline: none;

    svg {
        margin-left: 10px;
        vertical-align: bottom;

        width: 24px;
        height: 24px;
        fill: #757575;
        opacity: 0;
        transition: 300ms ease-in-out;
        transform: translateY(3px) rotate(180deg);
        ${props =>
            props.isOtherSortedDesc &&
            css`
                transform: translateY(3px) rotate(0deg);
            `}
    }
    ${props =>
        props.isSortedAsc &&
        css`
            svg {
                opacity: 1;
                transform: translateY(3px) rotate(180deg);
            }
        `}
    ${props =>
        props.isSortedDesc &&
        css`
            svg {
                opacity: 1;
                transform: translateY(3px) rotate(0deg);
            }
        `}
    &:hover {
        svg {
            opacity: 1;
        }
    }
`;
