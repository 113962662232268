import React from "react";
import { connect } from "react-redux";
import { showSuccessModalChanged } from "../actions/passwordResetActions";
import { Modal, Button } from "react-bootstrap";
import WarningTriangle from "../../components/common/WarningTriangle";

@connect(state => ({
    showSuccessModal: state.passwordReset.showSuccessModal
}), dispatch => ({
    successModalClosed: _ => dispatch(showSuccessModalChanged(false))
}))
export default class SuccessModal extends React.Component {
    render() {
        return (
            <Modal
                show={this.props.showSuccessModal}
                onHide={this.props.successModalClosed}
                dialogClassName="modal-centered">
                <Modal.Header closeButton>

                    <Modal.Title>
                        <WarningTriangle />
                        Sent email message
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Please confirm password reset and click on the link in the email.
                    </p>
                </Modal.Body>
                <Modal.Footer className="reset-password-modal-footer">
                    <Button onClick={this.props.successModalClosed}>OK</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
