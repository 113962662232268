import React from "react";
import { Button, ControlLabel, FormControl, FormGroup, Panel } from "react-bootstrap";
import styled from "styled-components";
import { connect } from "react-redux";
import {
    changePassword,
    newPassword2Changed,
    newPasswordChanged,
    oldPasswordChanged,
    showCommuniqueChanged
} from "../actions/accountActions";
import classnames from "classnames";
import { logoutRequested } from "../../../actions/authActions";
import StyledFormControl from "../../../components/common/StyledFormControl";
import StyledButton from "../../../components/common/StyledButton";

const Content = styled.div`
    padding-left: 30px;
    padding-right: 30px;
`;

const Container = styled.div`
    padding: 10px;
    max-width: 500px;
    margin: 0 auto;
`;

const InformationContainer = styled.div`
    font-size: 11px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    padding: 3px;
    margin-top: 3px;
`;

const ComunicatContainer = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(52, 52, 52, 0.8);
    z-index: 10;
`;

const CommuniqueContent = styled.div`
    position: relative;
    top: 50%;
    left: 50%;
    width: 70%;
    max-width: 660px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 1px;
    padding: 10px 20px 40px 20px;
`;

@connect(
    state => ({
        ...state.clientPanel.accounts,
        showCommunique: state.clientPanel.accounts.showCommunique
    }),
    dispatch => ({
        oldPasswordChanged: ev => dispatch(oldPasswordChanged(ev.target.value)),
        newPasswordChanged: ev => dispatch(newPasswordChanged(ev.target.value)),
        newPassword2Changed: ev => dispatch(newPassword2Changed(ev.target.value)),
        saveClicked: _ => dispatch(changePassword()),
        logoutRequested: _ => {
            dispatch(logoutRequested());
            dispatch(showCommuniqueChanged(false));
        }
    })
)
export default class AccountsPage extends React.Component {
    state = {
        showErrorBorders: false
    };

    showErrorBorders = () => {
        this.setState({ showErrorBorders: true });
    };
    render() {
        let content;
        content = (
            <div>
                {this.props.showCommunique && (
                    <ComunicatContainer>
                        <CommuniqueContent>
                            <div>
                                <h4>Your password has been changed. Please re-login with new credentials.</h4>
                                <Button onClick={this.props.logoutRequested} bsStyle="success" className="pull-right">
                                    Ok
                                </Button>
                            </div>
                        </CommuniqueContent>
                    </ComunicatContainer>
                )}
            </div>
        );
        let errorPanel;
        if (this.props.errorMessage) {
            errorPanel = (
                <Panel header="Error" bsStyle="danger">
                    {this.props.errorMessage}
                </Panel>
            );
        }

        const oldPasswordInvalid = this.props.oldPassword.trim() === "";
        //password must have 8-30 chars with one lower case, one upper case and one number
        const newPasswordInvalid =
            this.props.newPassword.trim() === "" ||
            this.props.newPassword.trim().length < 8 ||
            this.props.newPassword.trim().length > 30 ||
            this.props.newPassword.match(/[0-9]/gi) === null ||
            this.props.newPassword.match(/[a-z]/gi) === null ||
            this.props.newPassword.match(/[A-Z]/gi) === null;
        const newPassword2Invalid =
            this.props.newPassword2.trim() === "" || this.props.newPassword2 !== this.props.newPassword;
        return (
            <Content>
                {content}
                <h2>Manage your account</h2>
                <Container>
                    <strong>Change password</strong>
                    <br />
                    <br />
                    {errorPanel}
                    <FormGroup>
                        <StyledFormControl
                            type="password"
                            placeholder="Old password"
                            value={this.props.oldPassword}
                            onChange={this.props.oldPasswordChanged}
                            errorBorder={this.state.showErrorBorders && oldPasswordInvalid}
                            alignLeft
                            fatter
                        />
                    </FormGroup>
                    <FormGroup>
                        <StyledFormControl
                            type="password"
                            placeholder="New password"
                            value={this.props.newPassword}
                            onChange={this.props.newPasswordChanged}
                            errorBorder={this.state.showErrorBorders && newPasswordInvalid}
                            alignLeft
                            fatter
                        />
                        {this.state.showErrorBorders && newPasswordInvalid && (
                            <InformationContainer>
                                Password must:
                                <ul>
                                    <li> be between 8 and 30 characters.</li>
                                    <li> contain at least one uppercase, or capital, letter</li>
                                    <li> contain at least one lowercase letter</li>
                                    <li> contain at least one number digit </li>
                                </ul>
                            </InformationContainer>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <StyledFormControl
                            type="password"
                            placeholder="Confirm new password"
                            value={this.props.newPassword2}
                            onChange={this.props.newPassword2Changed}
                            errorBorder={this.state.showErrorBorders && newPassword2Invalid}
                            alignLeft
                            fatter
                        />
                    </FormGroup>
                    <StyledButton
                        onClick={
                            newPasswordInvalid || newPassword2Invalid || oldPasswordInvalid
                                ? this.showErrorBorders
                                : this.props.saveClicked
                        }
                        bsStyle="primary"
                        paddedFullWidth
                    >
                        Save
                    </StyledButton>
                </Container>
            </Content>
        );
    }
}
