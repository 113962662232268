import { updateRow } from "../../utils";
import {
    DATA_CHANGED,
    FIELD_ADDED,
    FIELD_ADD_COLUMN_CHANGED,
    FIELD_COMMENTS_CHANGED,
    FIELD_COPIED,
    FIELD_DAYS_EARLIER_CHANGED,
    FIELD_REMOVED,
    FIELD_TYPE_CHANGED,
    NAME_CHANGED,
    RESET,
    STATE_CHANGED,
    STATE_NEW_TEMPLATE,
    ADD_TEMPLATE_AS_COLUMN_CHANGED,
    ADD_TO_ALL_EMPLOYEES_CHANGED
} from "../actions/editTemplateActions";

const initialState = {
    state: STATE_NEW_TEMPLATE,
    name: "",
    newFieldLabelCounter: 2,
    addTemplateAsColumn: false,
    addToAllEmployees: false,
    fields: [
        {
            type: null,
            label: "New field 1",
            daysEarlier: 7,
            comments: "",
            addColumn: false
        }
    ]
};

export default function editTemplate(state = initialState, action) {
    switch (action.type) {
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case RESET:
            return initialState;
        case DATA_CHANGED:
            return {
                ...state,
                ...action.payload
            };
        case NAME_CHANGED:
            return {
                ...state,
                name: action.payload
            };
        case FIELD_ADDED:
            return {
                ...state,
                newFieldLabelCounter: state.newFieldLabelCounter + 1,
                fields: [
                    ...state.fields,
                    {
                        type: null,
                        label: `New field ${state.newFieldLabelCounter}`
                    }
                ]
            };
        case FIELD_REMOVED:
            return {
                ...state,
                fields: state.fields.filter((_, i) => i !== action.fieldIndex)
            };
        case FIELD_TYPE_CHANGED:
            return {
                ...state,
                fields: state.fields.map((f, i) => {
                    if (action.fieldIndex !== i) return f;
                    return {
                        ...f,
                        type: action.payload
                    };
                })
            };
        case FIELD_COPIED:
            let newFields = [];
            state.fields.forEach((f, i) => {
                newFields.push(f);
                if (i === action.fieldIndex) {
                    newFields.push({
                        ...f,
                        comments: "",
                        daysEarlier: 0,
                        addColumn: false,
                        _id: undefined // copy and remove _id
                    });
                }
            });
            return {
                ...state,
                fields: newFields
            };
        case FIELD_COMMENTS_CHANGED:
            return {
                ...state,
                fields: updateRow(state.fields, "comments", action, "fieldIndex")
            };
        case FIELD_DAYS_EARLIER_CHANGED:
            return {
                ...state,
                fields: updateRow(state.fields, "daysEarlier", action, "fieldIndex")
            };
        case FIELD_ADD_COLUMN_CHANGED:
            return {
                ...state,
                fields: updateRow(state.fields, "addColumn", action, "fieldIndex")
            };
        case ADD_TEMPLATE_AS_COLUMN_CHANGED:
            return {
                ...state,
                fields: state.fields.map(f => ({
                    ...f,
                    addColumn: true
                })),
                addTemplateAsColumn: action.payload
            };
        case ADD_TO_ALL_EMPLOYEES_CHANGED:
            return {
                ...state,
                addToAllEmployees: action.payload
            };
        default:
            return state;
    }
}
