import "./fixes";
import { diagnosticsInit, globalErrorHandler } from "./actions/diagnosticsActions";
import store from "./store";
import ReactDOM from "react-dom";
import React from "react";
import { Provider } from "react-redux";
import AppComponent from "./components/AppComponent";
import { ConnectedRouter } from "react-router-redux";
import history from "./history";
import { loadToken } from "./actions/apiActions";
import { Workbox } from "workbox-window";

// css imports
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap-theme.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-select/dist/react-select.css";
import "./styles/entry.less";

if (process.env.NODE_ENV !== "production") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(React);
}

async function runApp() {
    if (typeof window.fetch !== "function") {
        // load fetch polyfill only when needed
        await import("whatwg-fetch");
    }
    if (
        process.env.NODE_ENV === "production" &&
        location.hostname !== "localhost" &&
        !/(^127\.)|(^10\.)|(^172\.1[6-9]\.)|(^172\.2[0-9]\.)|(^172\.3[0-1]\.)|(^192\.168\.)/.test(location.hostname) // match local ips for mobile testing
    ) {
        if (location.protocol !== "https:") location.protocol = "https:";
    }
    window.onerror = globalErrorHandler;
    store.dispatch(diagnosticsInit()); // initialize diagnostics

    store.dispatch(loadToken()); // try to load the token from localStorage to memory (redux)

    if ("serviceWorker" in navigator) {
        const wb = new Workbox("/service-worker.js");

        wb.register();
    }
    const appElement = document.createElement("div"); // create the root element for the app
    document.body.appendChild(appElement); // add the element to DOM

    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <AppComponent />
            </ConnectedRouter>
        </Provider>,
        appElement
    );
}

runApp();
