import { makeActionCreator, encodeParams } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import { toast } from "react-toastify";
import { showPromptModal, showConfirmModal } from "../../actions/modalsActions";
import { permissionsInfo } from "../../../../common/src/permissions";
import { authCheckRequested } from "../../actions/authActions";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_ERROR = "STATE_ERROR";

export const STATE_CHANGED = "USERS.GROUPS.STATE_CHANGED";
export const GROUPS_CHANGED = "USERS.GROUPS.GROUPS_CHANGED";
export const RESET_CHANGED_PERMISSIONS = "USERS.GROUPS.RESET_CHANGED_PERMISSIONS";
export const GROUP_PERMISSION_CHANGED = "USERS.GROUPS.GROUP_PERMISSION_CHANGED";
export const GROUP_ADDED = "USERS.GROUPS.GROUP_ADDED";
export const GROUP_NAME_CHANGED = "USERS.GROUPS.GROUP_NAME_CHANGED";
export const GROUP_EDITABLE_GROUPS_CHANGED = "USERS.GROUPS.GROUP_EDITABLE_GROUPS_CHANGED";

export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const groupsChanged = makeActionCreator(GROUPS_CHANGED, "payload");
export const groupAdded = makeActionCreator(GROUP_ADDED, "payload");
export const groupPermissionChanged = makeActionCreator(GROUP_PERMISSION_CHANGED, "groupId", "permission", "payload");
export const groupNameChanged = makeActionCreator(GROUP_NAME_CHANGED, "groupId", "payload");
export const resetChangedPermissions = makeActionCreator(RESET_CHANGED_PERMISSIONS);
export const groupEditableGroupsChanged = makeActionCreator(
    GROUP_EDITABLE_GROUPS_CHANGED,
    "groupId",
    "groupToEdit",
    "payload"
);

export function groupPermissionChangedThunk(groupId, permission, payload) {
    return async dispatch => {
        if (payload && permissionsInfo[permission].solo) {
            if (
                !(await dispatch(
                    showConfirmModal({
                        title: "Are you sure?",
                        message: `Selecting the "${
                            permissionsInfo[permission].name
                        }" will erease all other permissions for this group and potentially undo unsaved changes made on this screen.`
                    })
                ))
            ) {
                return; // abort
            }
            // solo requirements
        }
        dispatch(groupPermissionChanged(groupId, permission, payload));
    };
}

export function loadGroups() {
    return async dispatch => {
        dispatch(stateChanged(STATE_LOADING));
        try {
            let resp = await dispatch(apiFetch("/api/groups"));
            let data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            dispatch(groupsChanged(data));
            dispatch(resetChangedPermissions());
            dispatch(stateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(stateChanged(STATE_ERROR));
            console.error(e);
            toast("Error while loading groups: " + e.message, {
                type: "error"
            });
        }
    };
}

export function saveGroups() {
    return async (dispatch, getState) => {
        dispatch(stateChanged(STATE_LOADING));
        try {
            let resp = await dispatch(
                apiFetch("/api/groups", {
                    method: "POST",
                    body: JSON.stringify(getState().users.groups.groups)
                })
            );
            let data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            toast("Groups saved!", { type: "success" });
            await dispatch(loadGroups());
            await dispatch(authCheckRequested())

        } catch (e) {
            dispatch(stateChanged(STATE_ERROR));
            console.error(e);
            toast("Error while saving groups: " + e.message, {
                type: "error"
            });
        }
    };
}

export function addGroup() {
    return async dispatch => {
        let name = await dispatch(
            showPromptModal({ title: "Add new group", fieldLabel: "Group name", okButtonText: "Add" })
        );
        if (name == null) {
            return;
        }
        dispatch(
            groupAdded({
                _id: null,
                name,
                permissions: [],
                editableGroups: []
            })
        );
    };
}
