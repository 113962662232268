import {
    SELECTED_FILTER_CHANGED,
    DATE_RANGE_CHANGED,
    PERIOD_CHANGED,
    CONDITION_CHANGED,
    SEARCH_CONTENT_CHANGED,
    SELECTED_FILTER_PERIOD,
    CUSTOMIZER_COLUMN_ENABLED_CHANGED,
    CUSTOMIZER_DIRTY_CLEARED,
    FILTER_RESET,
    PAGE_CHANGED,
    PAGE_INCREMENTED,
    ROWS_PER_PAGE_CHANGED,
    PAGE_DECREMENTED,
    SORT_BY_CHANGED,
    EXTENDED_SEARCH_CHANGED,
    CUSTOMIZATIONS_LOADED
} from "../actions/filtersActions";
import ordersTableModel from "../ordersTableModel";
import { periodSelectOptions, conditionSelectOptions } from "./../filtersModel";

const makeInitialState = _ => ({
    selectedFilter: SELECTED_FILTER_PERIOD,
    dateRange: {
        start: new Date(periodSelectOptions[0].dateStart.toString()),
        end: new Date(periodSelectOptions[0].dateEnd.toString())
    },
    period: periodSelectOptions[0].value,
    condition: conditionSelectOptions[0].label,
    searchContent: "",
    customizerEnabledColumns: [],
    customizerDirty: false,
    lastUpdate: null, // last update of the filters so that we can debounce the server requests,
    page: 0,
    sortBy: "-orderNumber",
    rowsPerPage: 5,
    extendedSearch: false
});

export default function filters(state = makeInitialState(), action) {
    switch (action.type) {
        case FILTER_RESET:
            return { ...makeInitialState(), ...action.payload };
        case SELECTED_FILTER_CHANGED:
            return {
                ...state,
                customizerDirty: true,
                selectedFilter: action.payload,
                lastUpdate: action.lastUpdate
            };
        case DATE_RANGE_CHANGED:
            return {
                ...state,
                customizerDirty: true,
                dateRange: action.payload,
                lastUpdate: action.lastUpdate
            };
        case PERIOD_CHANGED:
            return {
                ...state,
                customizerDirty: true,
                period: action.payload,
                lastUpdate: action.lastUpdate
            };
        case CONDITION_CHANGED:
            return {
                ...state,
                condition: action.payload,
                lastUpdate: action.lastUpdate
            };
        case SEARCH_CONTENT_CHANGED:
            return {
                ...state,
                searchContent: action.payload,
                lastUpdate: action.lastUpdate
            };
        case CUSTOMIZATIONS_LOADED:
            return {
                ...state,
                customizerDirty: false,
                ...action.payload
            };
        case CUSTOMIZER_COLUMN_ENABLED_CHANGED: {
            const customizerEnabledColumns = [
                ...state.customizerEnabledColumns.filter(col => col !== action.columnKey),
                ...(action.payload ? [action.columnKey] : []) // re-add the column key if payload is true (doing this like that ensures that there will never be any duplicates)
            ];
            let sortBy = state.sortBy;
            if (!action.payload && (sortBy === action.columnKey || sortBy === "-" + action.columnKey)) {
                let newSortKey = "-orderNumber";
                customizerEnabledColumns.find(enabledColumnKey => {
                    let definition = ordersTableModel.columns.find(c => c.key === enabledColumnKey);
                    if (!definition) return false;
                    if (definition.sortable) {
                        newSortKey = definition.backendKey || enabledColumnKey;
                        return true;
                    }

                    return false;
                });
                sortBy = newSortKey;
            }
            return {
                ...state,
                customizerDirty: true,
                lastUpdate: action.lastUpdate,
                customizerEnabledColumns,
                sortBy
            };
        }

        case CUSTOMIZER_DIRTY_CLEARED:
            return {
                ...state,
                customizerDirty: false
            };
        case ROWS_PER_PAGE_CHANGED:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
                rowsPerPage: action.payload,
                customizerDirty: true,
                page: 0 // reset the page
            };
        case PAGE_CHANGED:
            return {
                ...state,
                page: action.payload
            };
        case PAGE_INCREMENTED:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
                page: state.page + 1
            };
        case PAGE_DECREMENTED:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
                page: state.page - 1
            };

        case SORT_BY_CHANGED:
            return {
                ...state,
                page: 0,
                lastUpdate: action.lastUpdate,
                sortBy: action.payload
            };

        case EXTENDED_SEARCH_CHANGED:
            return {
                ...state,
                extendedSearch: action.payload
            };

        default:
            return state;
    }
}
