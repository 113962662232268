import { makeActionCreator, encodeParams } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import { toast } from "react-toastify";

export const ROW_ADDED = "REPORTS.PRODUCT_QA.ROW_ADDED";
export const ROW_REMOVED = "REPORTS.PRODUCT_QA.ROW_REMOVED";
export const PART_NUMBER_CHANGED = "REPORTS.PRODUCT_QA.PART_NUMBER_CHANGED";
export const PART_NUMBER_AUTOCOMPLETE_STATE_CHANGED = "REPORTS.PRODUCT_QA.PART_NUMBER_AUTOCOMPLETE_STATE_CHANGED";
export const PART_NUMBER_AUTOCOMPLETE_DATA_CHANGED = "REPORTS.PRODUCT_QA.PART_NUMBER_AUTOCOMPLETE_DATA_CHANGED";
export const DELIVERY_CHANGED = "REPORTS.PRODUCT_QA.DELIVERY_CHANGED";
export const PRODUCTION_DATE_CHANGED = "REPORTS.PRODUCT_QA.DELIVERY_CHANGED";
export const TOTAL_CHECKED_CHANGED = "REPORTS.PRODUCT_QA.TOTAL_CHECKED_CHANGED";
export const OK_CHANGED = "REPORTS.PRODUCT_QA.OK_CHANGED";
export const REWORKED_CHANGED = "REPORTS.PRODUCT_QA.REWORKED_CHANGED";
export const NOK_CHANGED = "REPORTS.PRODUCT_QA.NOK_CHANGED";
export const TOTAL_OK_CHANGED = "REPORTS.PRODUCT_QA.TOTAL_OK_CHANGED";
export const REMARKS_CHANGED = "REPORTS.PRODUCT_QA.REMARKS_CHANGED";
export const LETTER_COLUMN_ADDED = "REPORTS.PRODUCT_QA.LETTER_COLUMN_ADDED";
export const LETTER_COLUMN_REMOVED = "REPORTS.PRODUCT_QA.LETTER_COLUMN_REMOVED";
export const LETTER_COLUMN_CHANGED = "REPORTS.PRODUCT_QA.LETTER_COLUMN_CHANGED";
export const EMPTY_COLUMN_ADDED = "REPORTS.PRODUCT_QA.EMPTY_COLUMN_ADDED";
export const EMPTY_COLUMN_REMOVED = "REPORTS.PRODUCT_QA.EMPTY_COLUMN_REMOVED";
export const EMPTY_COLUMN_CHANGED = "REPORTS.PRODUCT_QA.EMPTY_COLUMN_CHANGED";
export const EMPTY_COLUMN_LABEL_CHANGED = "REPORTS.PRODUCT_QA.EMPTY_COLUMN_LABEL_CHANGED";
export const EMPTY_COLUMNS_LOADED = "REPORTS.PRODUCT_QA.EMPTY_COLUMNS_LOADED";
export const CLEAN_UP_EMPTY_ROWS = "REPORTS.PRODUCT_QA.CLEAN_UP_EMPTY_ROWS";

export const AUTOCOMPLETE_STATE_DEFAULT = "AUTOCOMPLETE_STATE_DEFAULT";
export const AUTOCOMPLETE_STATE_LOADING = "AUTOCOMPLETE_STATE_LOADING";
export const AUTOCOMPLETE_STATE_LOADED = "AUTOCOMPLETE_STATE_LOADED";

export const rowAdded = makeActionCreator(ROW_ADDED),
    rowRemoved = makeActionCreator(ROW_REMOVED, "rowIndex"),
    partNumberChanged = makeActionCreator(PART_NUMBER_CHANGED, "rowIndex", "payload"),
    partNumberAutocompleteStateChanged = makeActionCreator(
        PART_NUMBER_AUTOCOMPLETE_STATE_CHANGED,
        "rowIndex",
        "payload"
    ),
    partNumberAutocompleteDataChanged = makeActionCreator(PART_NUMBER_AUTOCOMPLETE_DATA_CHANGED, "rowIndex", "payload"),
    deliveryChanged = makeActionCreator(DELIVERY_CHANGED, "rowIndex", "payload"),
    productionDateChanged = makeActionCreator(PRODUCTION_DATE_CHANGED, "rowIndex", "payload"),
    totalCheckedChanged = makeActionCreator(TOTAL_CHECKED_CHANGED, "rowIndex", "payload"),
    okChanged = makeActionCreator(OK_CHANGED, "rowIndex", "payload"),
    reworkedChanged = makeActionCreator(REWORKED_CHANGED, "rowIndex", "payload"),
    nokChanged = makeActionCreator(NOK_CHANGED, "rowIndex", "payload"),
    totalOKChanged = makeActionCreator(TOTAL_OK_CHANGED, "rowIndex", "payload"),
    remarksChanged = makeActionCreator(REMARKS_CHANGED, "rowIndex", "payload"),
    letterColumnAdded = makeActionCreator(LETTER_COLUMN_ADDED),
    letterColumnRemoved = makeActionCreator(LETTER_COLUMN_REMOVED, "columnIndex"),
    letterColumnChanged = makeActionCreator(LETTER_COLUMN_CHANGED, "columnIndex", "rowIndex", "payload"),
    emptyColumnAdded = makeActionCreator(EMPTY_COLUMN_ADDED),
    emptyColumnRemoved = makeActionCreator(EMPTY_COLUMN_REMOVED, "columnIndex"),
    emptyColumnChanged = makeActionCreator(EMPTY_COLUMN_CHANGED, "columnIndex", "rowIndex", "payload"),
    emptyColumnLabelChanged = makeActionCreator(EMPTY_COLUMN_LABEL_CHANGED, "columnIndex", "payload"),
    emptyColumnsLoaded = makeActionCreator(EMPTY_COLUMNS_LOADED, "payload"),
    cleanUpEmptyRows = makeActionCreator(CLEAN_UP_EMPTY_ROWS);

export function loadAutocomplete(rowIndex) {
    return async (dispatch, getState) => {
        try {
            if (!getState().reports.report.connectedOrderId) {
                return;
            }
            dispatch(partNumberAutocompleteStateChanged(rowIndex, AUTOCOMPLETE_STATE_LOADING));
            let resp = await dispatch(
                apiFetch(
                    encodeParams`/api/orders/${getState().reports.report.connectedOrderId}/parts/search?query=${
                        getState().reports.productQA.rows[rowIndex].partNumber
                    }`
                )
            );
            let data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }

            dispatch(partNumberAutocompleteDataChanged(rowIndex, data));
            dispatch(partNumberAutocompleteStateChanged(rowIndex, AUTOCOMPLETE_STATE_LOADED));
        } catch (e) {
            dispatch(partNumberAutocompleteStateChanged(rowIndex, AUTOCOMPLETE_STATE_DEFAULT));

            // eslint-disable-next-line no-console
            console.error("Error while downloading autocomplete data", e);

            toast("Error while downloading autocomplete data: " + e.message, {
                type: "error"
            });
        }
    };
}

export function loadEmptyColumnsFromPreviousReport() {
    return async (dispatch, getState) => {
        if (!getState().reports.report.connectedOrderId) {
            return;
        }
        try {
            let resp = await dispatch(
                apiFetch(`/api/reports/last-empty-columns/${getState().reports.report.connectedOrderId}`)
            );
            let data = await resp.json();
            if (!resp.ok) {
                if (resp.status === 404) {
                    // ignore 404 errors on new orders
                    return;
                }
                throw new Error(data.message);
            }
            dispatch(emptyColumnsLoaded(data));
        } catch (e) {
            toast("Error while columns data from previous report: " + e.message, {
                type: "error"
            });
        }
    };
}
