import { routerReducer as router } from "react-router-redux";
import { combineReducers } from "redux";
import companies from "../CompaniesPage/reducers/companies";
import contactPersons from "../ContactPersonModal/reducers/contactPersons";
import clientPanel from "../CustomerPanel/reducers/customerPanel";
import desktops from "../DesktopsPage/reducers/desktops";
import externals from "../ExternalsModal/reducers/externals";
import hr from "../HRPage/reducers/hr";
import invitations from "../InvitationModal/reducers/invitations";
import login from "../LoginPage/reducers/login";
import office from "../OfficePage/reducers/office";
import ordersList from "../OrdersListPage/reducers/ordersList";
import orders from "../OrdersPage/reducers/orders";
import passwordResetFinish from "../PasswordResetFinishPage/reducers/passwordResetFinish";
import passwordReset from "../PasswordResetPage/reducers/passwordReset";
import reporterDesktop from "../ReporterDesktopPage/reducers/reporterDesktop";
import reportsList from "../ReportsListPage/reducers/reportsList";
import reports from "../ReportsPage/reducers/reports";
import users from "../UsersPage/reducers/users";
import api from "./api";
import auth from "./auth";
import dashboardUI from "./dashboardUI";
import diagnostics from "./diagnostics";
import modals from "./modals";
import notifications from "./notifications";
import companiesList from "../CompaniesListPage/reducers/companiesList";
import changePasswordReducer from '../DashboardPage/reducers/changePasswordReducer';

export default combineReducers({
    reports,
    router,
    passwordReset,
    login,
    companies,
    passwordResetFinish,
    orders,
    api,
    auth,
    office,
    diagnostics,
    modals,
    notifications,
    users,
    externals,
    clientPanel,
    desktops,
    invitations,
    hr,
    contactPersons,
    dashboardUI,
    ordersList,
    reporterDesktop,
    reportsList,
    companiesList,
    changePasswordReducer
});
