import { push } from "react-router-redux";
import { periodSelectOptions } from "../../../OrdersListPage/filtersModel";
import { parseRawQueryString } from "../../../urlUtils";
import { parseIntWithZeroFallback } from "../../../utils";
import { stringify as stringifyQueryString } from "querystring";
import { loadRowsDebounced } from "./resultsActions";

export const SELECTED_FILTER_PERIOD = "PERIOD";
export const SELECTED_FILTER_DATE = "DATE";

export function ordersListFiltersSelector(state) {
    const query = parseRawQueryString(state.router.location.search);
    let parsedDateRange = null;
    let selectedFilter = query.selectedFilter;
    if (selectedFilter !== SELECTED_FILTER_PERIOD && selectedFilter !== SELECTED_FILTER_DATE) {
        selectedFilter = SELECTED_FILTER_PERIOD;
    }
    const periodOption = periodSelectOptions.find(o => o.value === query.period) || periodSelectOptions[0];
    if (selectedFilter === SELECTED_FILTER_DATE) {
        try {
            let data = JSON.parse(query.dateRange);
            parsedDateRange = {
                start: data.start ? new Date(data.start) : null,
                end: data.end ? new Date(data.end) : null
            };
        } catch (e) {
            console.error(e);
        }
    }
    if (!parsedDateRange) {
        parsedDateRange = {
            start: new Date(periodOption.dateStart.toString()),
            end: new Date(periodOption.dateEnd.toString())
        };
    }
    return {
        selectedFilter,
        period: periodOption.value,
        searchContent: query.searchContent || "",
        page: parseIntWithZeroFallback(query.page),
        rowsPerPage: parseIntWithZeroFallback(query.rowsPerPage) || 5,
        sortBy: query.sortBy || "orderNumber",
        dateRange: parsedDateRange
    };
}

export function updateFilters(newValues = {}) {
    return (dispatch, getState) => {
        const newQuery = {
            ...ordersListFiltersSelector(getState()),
            ...newValues
        };
        if (typeof newQuery.dateRange === "object") {
            newQuery.dateRange = JSON.stringify(newQuery.dateRange);
        }
        if (Object.keys(newValues).some(k => ["period", "searchContent", "dateRange", "selectedFilter"].includes(k))) {
            newQuery.page = 0;
        }

        dispatch(
            push({
                search: "?" + stringifyQueryString(newQuery)
            })
        );
        if (Object.keys(newValues).length > 0) {
            dispatch(loadRowsDebounced());
        }
    };
}
