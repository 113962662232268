import { makeActionCreator } from "../../utils";

export const ORDER_PART_ADDED = "ORDERS.ORDER_PARTS.ORDER_PART_ADDED";
export const ORDER_PART_REMOVED = "ORDERS.ORDER_PARTS.ORDER_PART_REMOVED";
export const ORDER_PART_NAME_CHANGED = "ORDERS.ORDER_PARTS.ORDER_PART_NAME_CHANGED";
export const ORDER_PART_NUMBER_ADDED = "ORDERS.ORDER_PARTS.ORDER_PART_NUMBER_ADDED";
export const ORDER_PART_NUMBER_REMOVED = "ORDERS.ORDER_PARTS.ORDER_PART_NUMBER_REMOVED";
export const ORDER_PART_NUMBER_CHANGED = "ORDERS.ORDER_PARTS.ORDER_PART_NUMBER_CHANGED";

export const orderPartAdded = makeActionCreator(ORDER_PART_ADDED);
export const orderPartRemoved = makeActionCreator(ORDER_PART_REMOVED, "partIndex");
export const orderPartNameChanged = makeActionCreator(ORDER_PART_NAME_CHANGED, "partIndex", "payload");
export const orderPartNumberAdded = makeActionCreator(ORDER_PART_NUMBER_ADDED, "partIndex");
export const orderPartNumberRemoved = makeActionCreator(ORDER_PART_NUMBER_REMOVED, "partIndex", "partNumberIndex");
export const orderPartNumberChanged = makeActionCreator(ORDER_PART_NUMBER_CHANGED, "partIndex", "partNumberIndex", "payload");

