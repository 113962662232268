import { makeActionCreator, encodeParams } from "../../utils";
import { toast } from "react-toastify";
import { apiFetch } from "../../actions/apiActions";
import { push } from "react-router-redux";
import { encode } from "punycode";
import { FIELD_SORTING_PRIORITY } from "../../../../common/src/hrTemplates";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_ERROR = "STATE_ERROR";

export const STATE_CHANGED = "HR.EMPLOYEES_LIST.STATE_CHANGED";
export const EMPLOYEES_CHANGED = "HR.EMPLOYEES_LIST.EMPLOYEES_CHANGED";
export const TEMPLATE_COLUMNS_CHANGED = "HR.EMPLOYEES_LIST.TEMPLATE_COLUMNS_CHANGED";
export const TOTAL_COUNT_CHANGED = "HR.EMPLOYEES.TOTAL_COUNT_CHANGED";
export const EMPLOYEE_DELETED = "HR.EMPLOYEES.EMPLOYEE_DELETED";

export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const employeesChanged = makeActionCreator(EMPLOYEES_CHANGED, "payload");
export const totalCountChanged = makeActionCreator(TOTAL_COUNT_CHANGED, "payload");
export const employeeDeleted = makeActionCreator(EMPLOYEE_DELETED, "payload");
export const templateColumnsChanged = makeActionCreator(TEMPLATE_COLUMNS_CHANGED, "payload");

export function loadEmployees(query) {
    return async (dispatch, getState) => {
        try {
            dispatch(stateChanged(STATE_LOADING));
            if (true /*!getState().hr.employeesList.totalCount*/) {
                let resp = await dispatch(apiFetch(encodeParams`/api/employees-management/total-count`, {}, query));
                if (!resp.ok) {
                    throw new Error((await resp.json()).message);
                }
                dispatch(totalCountChanged(await resp.json()));
            }

            let resp = await dispatch(apiFetch(encodeParams`/api/employees-management`, {}, query));
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            let data = await resp.json();
            let templateColumnsResp = await dispatch(apiFetch(encodeParams`/api/templates/template-columns`));
            if (!templateColumnsResp.ok) {
                throw new Error((await resp.json()).message);
            }
            let templateColumns = await templateColumnsResp.json();
            dispatch(
                templateColumnsChanged(
                    templateColumns.map(tc => {
                        let fields = [];
                        FIELD_SORTING_PRIORITY.forEach(fp => fields.push(...tc.fields.filter(f => f.type === fp)));
                        return {
                            ...tc,
                            fields
                        };
                    })
                )
            );
            dispatch(employeesChanged(data));
            dispatch(stateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(stateChanged(STATE_ERROR));
            toast(e.message, {
                type: "error"
            });
        }
    };
}

