import {
    STATE_NEW,
    STATE_CHANGED,
    ERROR_CHANGED,
    REPORT_VERSIONS_CHANGED,
    VIEWED_VERSION_CHANGED,
    SHOW_VERSION_MESSAGE_MODAL_CHANGED,
    MODAL_VERSION_MESSAGE_CHANGED,
    REPORT_RESET,
    REPORT_DATA_CHANGED,
    CONTROL_DATE_CHANGED,
    REPORT_NAME_CHANGED,
    FORMULAR_NUMBER_CHANGED,
    CONNECTED_ORDER_ID_CHANGED,
    EXTERNAL_STATUS_CHANGED,
    OFFICIAL_ORDER_NAME_CHANGED
} from "../actions/reportActions";
import moment from "moment";
import { dateInputFormat } from "../../utils";

const makeInitialState = _ => ({
    state: STATE_NEW,
    error: null,
    reportId: null,
    versions: [],
    viewedVersion: null,
    showVersionMessageModal: false,
    modalVersionMessage: "",
    approved: null,
    controlDate: dateInputFormat(
        moment()
            .subtract(6, "hours")
            .startOf("day")
            .toDate()
    ),
    reportName: "",
    officialOrderName: "",
    formularNumber: "",
    connectedOrderId: null,
    previousReport: null,
    nextReport: null,
    externalStatus: null
});

export default function report(state = makeInitialState(), action) {
    switch (action.type) {
        case REPORT_RESET:
            return makeInitialState();
        case REPORT_DATA_CHANGED:
            return {
                ...state,
                reportName: action.payload.reportName,
                officialOrderName: action.payload.officialOrderName,
                connectedOrderId: action.payload.connectedOrder,
                controlDate: action.payload.controlDate,
                reportId: action.payload._id,
                approved: !!action.payload.approved,
                formularNumber: action.payload.formularNumber,
                previousReport: action.payload.previousReport,
                nextReport: action.payload.nextReport
            };
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case ERROR_CHANGED:
            return {
                ...state,
                error: action.payload
            };
        case REPORT_VERSIONS_CHANGED:
            return {
                ...state,
                versions: action.payload
            };
        case VIEWED_VERSION_CHANGED:
            return {
                ...state,
                viewedVersion: action.payload
            };
        case SHOW_VERSION_MESSAGE_MODAL_CHANGED:
            return {
                ...state,
                showVersionMessageModal: action.payload
            };
        case MODAL_VERSION_MESSAGE_CHANGED:
            return {
                ...state,
                modalVersionMessage: action.payload
            };
        case CONTROL_DATE_CHANGED:
            return {
                ...state,
                controlDate: action.payload
            };
        case REPORT_NAME_CHANGED:
            return {
                ...state,
                reportName: action.payload
            };
        case OFFICIAL_ORDER_NAME_CHANGED:
            return {
                ...state,
                officialOrderName: action.payload
            };
        case FORMULAR_NUMBER_CHANGED:
            return {
                ...state,
                formularNumber: action.payload
            };
        case CONNECTED_ORDER_ID_CHANGED:
            return {
                ...state,
                connectedOrderId: action.payload
            };
        case EXTERNAL_STATUS_CHANGED:
            return {
                ...state,
                externalStatus: action.payload
            };
        default:
            return state;
    }
}
