import React from "react";
import DayContainer from "./DayContainer";
import styled from "styled-components";

const styleCompanyContainerBasic = {
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.16)",
    borderRadius: "3px",
    margin: "10px 0 10px 5px",
    width: "300px",
    position: "relative",
    display: "inline-block",
    border: "solid 1px #dee2e9"
};

const styleCompanyContainerHeader = {
    backgroundColor: "#193f77",
    color: "#fff",
    fontSize: "11px",
    paddingLeft: "4px"
};

const SingleDayContainer = styled.div`
    border-top: ${props =>(props.index === 0 ? "none" : "solid 1px #000")};
    margin-top: ${props =>(props.index === 0 ? "0" : "15px")};
    margin-bottom: 20px;
    border-spacing: 15px;
    padding-top:10px;
    margin-left: 6px;
    margin-right: 6px;
`;

export default function OrderContainer(props) {
    const { orders } = props;
    return (
        <>
            {
                orders.map(order => {
                    return (
                        <div style={styleCompanyContainerBasic} key={order.number}>
                            <header style={styleCompanyContainerHeader}>
                                {order.number}
                            </header>
                            {
                                order.days.map((day, index) => {
                                    return (
                                        <SingleDayContainer index={index}>
                                            <DayContainer key={day.id} day={day}/>
                                        </SingleDayContainer>
                                    )
                                })
                            }
                        </div>
                    );
                })
            }
        </>
    );
}
