import { makeActionCreator } from "../utils";
import store from "../store";
import { makeApiUrl } from "../api";
import { toast } from "react-toastify";
import { showPromptModal } from "./modalsActions";

export const STATE_INITIALIZING = "STATE_INITIALIZING";
export const STATE_ERROR = "STATE_ERROR";
export const STATE_READY = "STATE_READY";

export const STATE_CHANGED = "DIAGNOSTICS.STATE_CHANGED";
export const SESSION_ID_CHANGED = "DIAGNOSTICS.SESSION_ID_CHANGED";
export const BUFFER_FLUSHED = "DIAGNOSTICS.BUFFER_FLUSHED";

export const bufferFlushed = makeActionCreator(BUFFER_FLUSHED);
export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const sessionIdChanged = makeActionCreator(SESSION_ID_CHANGED, "payload");

export function diagnosticsInit() {
    return async dispatch => {
        dispatch(
            sessionIdChanged(
                Math.random()
                    .toString(36)
                    .substring(2)
            )
        );
        dispatch(stateChanged(STATE_READY));
    };
}

export function submitUserError() {
    return async dispatch => {
        const description = await dispatch(
            showPromptModal({
                title: "Diagnostic report",
                message: "Please provide as much information about the error as possible.",
                fieldLabel: "Error description"
            })
        );
        if (description == null) {
            return;
        }
        dispatch(
            submitErrorData({
                trigger: "USER",
                message: description
            })
        );
        toast("Diagnostic report for this error has been submitted!", { type: "success" });
    };
}

export function submitErrorData(error) {
    return async _ => {
        try {
            let resp = await fetch(makeApiUrl("/api/diagnostics/diagnostic-report"), {
                method: "POST",
                body: JSON.stringify({
                    error,
                    state: store.getState(),
                    navigator: {
                        userAgent: navigator.userAgent,
                        language: navigator.language,
                        onLine: navigator.onLine,
                        cookieEnabled: navigator.cookieEnabled,
                        vendor: navigator.vendor
                    },
                    date: new Date(),
                    version: process.env.RES_VERSION
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (resp.status !== 200) {
                throw new Error((await resp.json()).message);
            }
            // eslint-disable-next-line no-console
            console.info("Diagnostic report for error has been submitted!");
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Error while submiting diagnostic report", e);
        }
    };
}

export function globalErrorHandler(message, scriptUrl, line, column, error) {
    let serializedError = {
        trigger: "GLOBAL_HANDLER",
        message,
        scriptUrl,
        line,
        column,
        stack: error && error.stack,
        errorMessage: error && error.message
    };
    // eslint-disable-next-line no-console
    console.log("[DIAGNOSTICS] Submiting error", serializedError);
    store.dispatch(submitErrorData(serializedError));
    //@todo to fix problem with d&d in dekstop
    // toast("Your error report has been submitted.", { type: "warning" });
}
