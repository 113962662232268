import React from "react";
import StyledIcon from "./StyledIcon";
import StyledButton from "./StyledButton";

export default function IconButton({ glyph = "plus", ...props }) {
    return (
        <StyledButton {...props}>
            <StyledIcon glyph={glyph} />
        </StyledButton>
    );
}
