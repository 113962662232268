import React from "react";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import StyledDatePicker from "../../../../../components/common/StyledDatePicker";
import { HR_FIELD_TYPE_TIME_FROM } from "../../../../../../../common/src/hrTemplates";
import styled from "styled-components";
import moment from "moment";

export default ({ field, value, onChange }) => (
    <FormGroup>
        <div className="flex-row">
            <span className="inline-label">{field.type === HR_FIELD_TYPE_TIME_FROM ? "Date from:" : "Date to:"}</span>
            <StyledDatePicker
                dateFormat="yyyy-MM-dd"
                todayButton={"Today"}
                showWeekNumbers
                placeholderText="Click to select a date"
                wide
                selected={value ? new Date(value) : null}
                onChange={ev =>
                    onChange(
                        moment(ev)
                            .toDate()
                            .toJSON()
                    )
                }
            />
        </div>
    </FormGroup>
);
