import { combineReducers } from "redux";
import companiesList from "./companiesList";
import company from "./company";
import validation from "./validation";

export default combineReducers({
    companiesList,
    company,
    validation
});
