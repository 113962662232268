import { makeActionCreator } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import {
    companyEmployeeAccessToPanelChanged,
    companySaved,
    userCheck
} from "../../CompaniesPage/actions/companyActions";
import { ERROR_NO_CLIENT_PANEL_GROUP } from "../../../../common/src/errorKinds";
import { showAlertModal } from "../../actions/modalsActions";
import { push } from "react-router-redux";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_ERROR = "STATE_ERROR";

export const STATE_CHANGED = "INVITATIONS.INVITATION.STATE_CHANGED";
export const ERROR_CHANGED = "INVITATIONS.INVITATION.ERROR_CHANGED";
export const INVITATION_MODAL_OPEN_CHANGED = "INVITATIONS.INVITATION.INVITATION_MODAL_OPEN_CHANGED";
export const INVITATION_EMPLOYEE_ID_CHANGED = "INVITATIONS.INVITATION.INVITATION_EMPLOYEE_ID_CHANGED";
export const INVITATION_INDEX_CHANGED = "INVITATIONS.INVITATION.INVITATION_INDEX_CHANGED";
export const INVITATION_NAME_CHANGED = "INVITATIONS.INVITATION.INVITATION_NAME_CHANGED";
export const INVITATION_EMAIL_CHANGED = "INVITATIONS.INVITATION.INVITATION_EMAIL_CHANGED";
export const INVITATION_CONTENT_CHANGED = "INVITATIONS.INVITATION.INVITATION_CONTENT_CHANGED";

export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const errorChanged = makeActionCreator(ERROR_CHANGED, "payload");
export const invitationModalOpenChanged = makeActionCreator(INVITATION_MODAL_OPEN_CHANGED, "payload");
export const invitationEmployeeIdChanged = makeActionCreator(INVITATION_EMPLOYEE_ID_CHANGED, "payload");
export const invitationIndexChanged = makeActionCreator(INVITATION_INDEX_CHANGED, "payload");
export const invitationNameChanged = makeActionCreator(INVITATION_NAME_CHANGED, "payload");
export const invitationEmailChanged = makeActionCreator(INVITATION_EMAIL_CHANGED, "payload");
export const invitationContentChanged = makeActionCreator(INVITATION_CONTENT_CHANGED, "payload");

export function loadInvitationContent(employeeId, name, email, index) {
    return async dispatch => {
        try {
            dispatch(stateChanged(STATE_LOADING));
            dispatch(invitationEmployeeIdChanged(employeeId));
            dispatch(invitationIndexChanged(index));
            dispatch(invitationNameChanged(name));
            dispatch(invitationEmailChanged(email));
            dispatch(invitationModalOpenChanged(true));
            dispatch(stateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

export function sendInvitation() {
    return async (dispatch, getState) => {
        try {
            dispatch(stateChanged(STATE_LOADING));
            let questionnaireValue = "";
            if (getState().invitations.questionnaire.f2fContact) {
                questionnaireValue = "f2fContact";
            } else if (getState().invitations.questionnaire.viaPhone) {
                questionnaireValue = "viaPhone";
            } else {
                questionnaireValue = "byEmail";
            }
            let resp = await dispatch(
                apiFetch("/api/users/invite-user", {
                    method: "POST",
                    body: JSON.stringify({
                        employeeId: getState().invitations.invitation.employeeId,
                        name: getState().invitations.invitation.name,
                        email: getState().invitations.invitation.email,
                        content: getState().invitations.invitation.content,
                        questionnaireValue: questionnaireValue
                    })
                })
            );
            let data = await resp.json();
            if (resp.status !== 200) {
                if (data.kind === ERROR_NO_CLIENT_PANEL_GROUP) {
                    dispatch(invitationModalOpenChanged(false));
                    await dispatch(
                        showAlertModal({
                            title: "Error while inviting user",
                            message: data.message
                        })
                    );
                    dispatch(push(`/dashboard/users/groups`));
                    return;
                }
                throw new Error(data.message || "Unknown error");
            }
            dispatch(invitationModalOpenChanged(false));
            dispatch(companyEmployeeAccessToPanelChanged(getState().invitations.invitation.index, true));
            dispatch(companySaved());
            let employees = getState().companies.company.employees;
            employees.forEach((e, i) => {
                dispatch(userCheck(i));
            });
        } catch (e) {
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

export function deleteAccess(employeeId, index = null) {
    return async (dispatch, getState) => {
        try {
            let resp = await dispatch(
                apiFetch("/api/users/access", {
                    method: "DELETE",
                    body: JSON.stringify({
                        employeeId: employeeId
                    })
                })
            );
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message || "Unknown error");
            }
            if (index !== null) {
                let employees = getState().companies.company.employees;
                employees.forEach((e, i) => {
                    dispatch(userCheck(i));
                });
                dispatch(companyEmployeeAccessToPanelChanged(index, false));
            }
            dispatch(companySaved());
        } catch (e) {
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

export function addAccess(employeeId, name, email, index) {
    return async (dispatch, getState) => {
        try {
            let resp = await dispatch(
                apiFetch("/api/users/access", {
                    method: "PATCH",
                    body: JSON.stringify({
                        name: name,
                        email: email,
                        employeeId: employeeId
                    })
                })
            );
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message || "Unknown error");
            }
            let employees = getState().companies.company.employees;
            employees.forEach((e, i) => {
                dispatch(userCheck(i));
            });
            dispatch(companyEmployeeAccessToPanelChanged(index, true));
            dispatch(companySaved());
        } catch (e) {
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}
