import { makeActionCreator, parseIntWithZeroFallback, encodeParams, selectObjectKeys } from "../../utils";
import { toast } from "react-toastify";
import { apiFetch } from "../../actions/apiActions";
import reportsTableModel from "../reportsTableModel";
import { push } from "react-router-redux";
import { stringify as stringifyQueryString } from "querystring";
import { parseRawQueryString } from "../../urlUtils";
import { periodSelectOptions } from "../filtersModel";
import { loadRows, isLoadingChanged } from "./resultsActions";
import download from "downloadjs";

export const SELECTED_FILTER_PERIOD = "PERIOD";
export const SELECTED_FILTER_DATE = "DATE";

export const FILTER_RESET = "REPORTS_LIST.FILTERS.FILTER_RESET";
export const SELECTED_FILTER_CHANGED = "REPORTS_LIST.FILTERS.SELECTED_FILTER_CHANGED";
export const DATE_RANGE_CHANGED = "REPORTS_LIST.FILTERS.DATE_RANGE_CHANGED";
export const PERIOD_CHANGED = "REPORTS_LIST.FILTERS.PERIOD_CHANGED";
export const MANAGED_BY_CHANGED = "REPORTS_LIST.FILTERS.MANAGED_BY_CHANGED";
export const COMPANY_CHANGED = "REPORTS_LIST.FILTERS.COMPANY_CHANGED";
export const CUSTOMER_CHANGED = "REPORTS_LIST.FILTERS.CUSTOMER_CHANGED";
export const LOCATION_CHANGED = "REPORTS_LIST.FILTERS.LOCATION_CHANGED";
export const GROUPS_CHANGED = "REPORTS_LIST.FILTERS.GROUPS_CHANGED";
export const OPERATOR_CHANGED = "REPORTS_LIST.FILTERS.OPERATOR_CHANGED";
export const PARTS_CHANGED = "REPORTS_LIST.FILTERS.PARTS_CHANGED";

export const CUSTOMIZER_COLUMN_ENABLED_CHANGED = "REPORTS_LIST.FILTERS.CUSTOMIZER_COLUMN_ENABLED_CHANGED";
export const CUSTOMIZER_DIRTY_CLEARED = "REPORTS_LIST.FILTERS.CUSTOMIZER_DIRTY_CLEARED";
export const PAGE_CHANGED = "REPORTS_LIST.FILTERS.PAGE_CHANGED";
export const SORT_BY_CHANGED = "REPORTS_LIST.FILTERS.SORT_BY_CHANGED";
export const PAGE_INCREMENTED = "REPORTS_LIST.FILTERS.PAGE_INCREMENTED";
export const PAGE_DECREMENTED = "REPORTS_LIST.FILTERS.PAGE_DECREMENTED";
export const ROWS_PER_PAGE_CHANGED = "REPORTS_LIST.FILTERS.ROWS_PER_PAGE_CHANGED";

export const CUSTOMIZATIONS_LOADED = "REPORTS_LIST.FILTERS.CUSTOMIZATIONS_LOADED";

const DEBOUNCE_FAST = "__DEBOUNCE_FAST";

const PAGE_RESET_ACTIONS = [
    SELECTED_FILTER_CHANGED,
    DATE_RANGE_CHANGED,
    PERIOD_CHANGED,

    MANAGED_BY_CHANGED,
    COMPANY_CHANGED,
    CUSTOMER_CHANGED,
    LOCATION_CHANGED,
    GROUPS_CHANGED,
    OPERATOR_CHANGED,
    PARTS_CHANGED
];

/**
 * Creates an action creator which will make a request for the search results after a debounce.
 * @param  {...any} args
 */
function makeActionCreatorWithServerRequest(...args) {
    let debounceTime = 500;
    if (args[0] === DEBOUNCE_FAST) {
        args = args.slice(1);
        debounceTime = 100;
    }
    const actionCreator = makeActionCreator(...args);
    return (...actionArgs) => {
        const action = actionCreator(...actionArgs);
        const lastUpdate = new Date().getTime();

        return (dispatch, getState) => {
            dispatch({
                ...action,
                lastUpdate
            });
            setTimeout(() => {
                if (getState().reportsList.filters.lastUpdate === lastUpdate) {
                    if (PAGE_RESET_ACTIONS.includes(action.type)) {
                        dispatch(pageChanged(0));
                    }
                    dispatch(loadResultsAndUpdateCustomizations());
                }
            }, debounceTime);
        };
    };
}

export const filterReset = makeActionCreator(FILTER_RESET, "payload");
export const customizerDirtyCleared = makeActionCreator(CUSTOMIZER_DIRTY_CLEARED);
export const pageChanged = makeActionCreator(PAGE_CHANGED, "payload");

export const selectedFilterChanged = makeActionCreatorWithServerRequest(SELECTED_FILTER_CHANGED, "payload");
export const dateRangeChanged = makeActionCreatorWithServerRequest(DATE_RANGE_CHANGED, "payload");
export const periodChanged = makeActionCreatorWithServerRequest(PERIOD_CHANGED, "payload");
export const managedByChanged = makeActionCreatorWithServerRequest(MANAGED_BY_CHANGED, "payload");
export const companyChanged = makeActionCreatorWithServerRequest(COMPANY_CHANGED, "payload");
export const customerChanged = makeActionCreatorWithServerRequest(CUSTOMER_CHANGED, "payload");
export const locationChanged = makeActionCreatorWithServerRequest(LOCATION_CHANGED, "payload");
export const groupsChanged = makeActionCreatorWithServerRequest(GROUPS_CHANGED, "payload");
export const operatorChanged = makeActionCreatorWithServerRequest(OPERATOR_CHANGED, "payload");
export const partsChanged = makeActionCreatorWithServerRequest(PARTS_CHANGED, "payload");

export const sortByChanged = makeActionCreatorWithServerRequest(SORT_BY_CHANGED, "payload");
export const pageIncremented = makeActionCreatorWithServerRequest(DEBOUNCE_FAST, PAGE_INCREMENTED);
export const pageDecremented = makeActionCreatorWithServerRequest(DEBOUNCE_FAST, PAGE_DECREMENTED);
export const rowsPerPageChanged = makeActionCreatorWithServerRequest(ROWS_PER_PAGE_CHANGED, "payload");
export const customizerColumnEnabledChanged = makeActionCreatorWithServerRequest(
    CUSTOMIZER_COLUMN_ENABLED_CHANGED,
    "columnKey",
    "payload"
);

export const customizationsLoaded = makeActionCreator(CUSTOMIZATIONS_LOADED, "payload");

export function loadResultsAndUpdateCustomizations() {
    return async (dispatch, getState) => {
        dispatch(isLoadingChanged(true));
        const newQuery = dispatch(serializeFiltersToQueryObject());
        dispatch(push({ search: "?" + stringifyQueryString(newQuery) }));
        let customizationsUpdatePromise = Promise.resolve();
        if (getState().reportsList.filters.customizerDirty) {
            customizationsUpdatePromise = (async () => {
                try {
                    const resp = await dispatch(
                        apiFetch(`/api/reports-list/customizations`, {
                            method: "PUT",
                            body: JSON.stringify({
                                enabledColumns: getState().reportsList.filters.customizerEnabledColumns,
                                ...selectObjectKeys(getState().reportsList.filters, [
                                    "rowsPerPage",
                                    "selectedFilter",
                                    "dateRange",
                                    "period"
                                ])
                            })
                        })
                    );
                    if (!resp.ok) {
                        throw new Error((await resp.json()).message);
                    }
                    await resp.json();
                    dispatch(customizerDirtyCleared());
                } catch (e) {
                    console.error(e);
                    toast("Failed to save customizations: " + e.message, {
                        type: "error"
                    });
                }
            })();
        }
        await Promise.all([customizationsUpdatePromise, dispatch(loadRows())]);
    };
}

export function resetFiltersAndLoadInitialData(force = true) {
    return async (dispatch, getState) => {
        if (!dispatch(setFiltersFromQuery()) && !force) {
            return;
        }
        dispatch(isLoadingChanged(true));
        let customizerEnabledColumns = reportsTableModel.columns
            .filter(col => col.enabledByDefault)
            .map(col => col.key);
        let rowsPerPage = 5;
        let period = periodSelectOptions[0].value;
        let selectedFilter = SELECTED_FILTER_PERIOD;
        let dateRange = {
            start: new Date(periodSelectOptions[0].dateStart.toString()),
            end: new Date(periodSelectOptions[0].dateEnd.toString())
        };
        try {
            const resp = await dispatch(apiFetch(`/api/reports-list/customizations`));
            if (resp.status === 404) {
            } else if (!resp.ok) {
                throw new Error((await resp.json()).message);
            } else {
                const data = await resp.json();
                customizerEnabledColumns = data.enabledColumns;
                rowsPerPage = data.rowsPerPage || 5; // backwards compatibility
                if (data.period) {
                    period = data.period;
                }
                if (data.selectedFilter) {
                    selectedFilter = data.selectedFilter;
                }
                if (data.dateRange) {
                    dateRange = {
                        start: new Date(data.dateRange.start),
                        end: new Date(data.dateRange.end)
                    };
                }
            }
        } catch (e) {
            console.error(e);
            toast("Failed to get customizations: " + e.message, {
                type: "error"
            });
        }

        dispatch(
            customizationsLoaded({
                customizerEnabledColumns,
                rowsPerPage,
                period,
                selectedFilter,
                dateRange
            })
        );

        dispatch(loadRows());
    };
}

export function serializeFiltersToQueryObject(includeEnabledColumns = false) {
    return (dispatch, getState) => {
        const filters = getState().reportsList.filters;
        let serialized = {};
        serialized.selectedFilter = filters.selectedFilter;
        serialized.dateRange = JSON.stringify(filters.dateRange);

        if (includeEnabledColumns) {
            serialized.enabledColumns = filters.customizerEnabledColumns
                .map(c => {
                    const definition = reportsTableModel.columns.find(def => def.key === c);
                    if (!definition) {
                        return c;
                    }
                    if (definition.backendKey === null) {
                        return null;
                    }
                    return definition.backendKey || c;
                })
                .filter(c => !!c);
        }

        serialized.managedBy = filters.managedBy;
        serialized.company = filters.company;
        serialized.customer = filters.customer;
        serialized.location = filters.location;
        serialized.groups = filters.groups;
        serialized.operator = filters.operator;
        serialized.parts = filters.parts;

        serialized.page = filters.page;

        serialized.page = filters.page;
        serialized.sortBy = filters.sortBy;
        serialized.rowsPerPage = filters.rowsPerPage;
        return serialized;
    };
}

export function setFiltersFromQuery() {
    return (dispatch, getState) => {
        if (
            getState().router.location.search ===
            "?" + stringifyQueryString(dispatch(serializeFiltersToQueryObject()))
        ) {
            // no need to update stuff it is our own update
            return false;
        }
        const query = parseRawQueryString(getState().router.location.search);
        const filtersData = {};
        if ([SELECTED_FILTER_PERIOD, SELECTED_FILTER_DATE].includes(query.selectedFilter)) {
            filtersData.selectedFilter = query.selectedFilter;
        }
        if (query.selectedFilter === SELECTED_FILTER_DATE) {
            filtersData.dateRange = null;
            try {
                let data = JSON.parse(query.dateRange);
                if (data.start && data.end) {
                    filtersData.dateRange = {
                        start: new Date(data.start),
                        end: new Date(data.end)
                    };
                }
            } catch (e) {}
        }
        if (
            query.selectedFilter === SELECTED_FILTER_PERIOD &&
            periodSelectOptions.map(p => p.value).includes(query.period)
        ) {
            filtersData.period = query.period;
        }

        filtersData.managedBy = query.managedBy || "";
        filtersData.company = query.company || "";
        filtersData.customer = query.customer || "";
        filtersData.location = query.location || "";
        if (query.groups) {
            filtersData.groups = Array.isArray(query.groups) ? query.groups : [query.groups];
        } else {
            filtersData.groups = ["open", "inProgress", "approved", "hidden", "visibleToCustomer", "deleted"];
        }
        filtersData.operator = query.operator || "";
        filtersData.parts = query.parts || "";

        filtersData.page = parseIntWithZeroFallback(query.page);
        filtersData.rowsPerPage = parseIntWithZeroFallback(query.rowsPerPage) || 5;
        if (query.sortBy) {
            filtersData.sortBy = query.sortBy;
        }
        dispatch(filterReset(filtersData));
        return true;
    };
}
