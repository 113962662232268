import { combineReducers } from "redux";
import clientPanels from "./customerPanels";
import clientPanelExternals from "../CustomerPanelPage/reducers/customerPanelExternals";
import accounts from "../AccountsPage/reducers/accounts";
import customerOrdersList from "../CustomerOrdersListPage/reducers/customerOrdersList";

export default combineReducers({
    clientPanels,
    clientPanelExternals,
    accounts,
    customerOrdersList
});
