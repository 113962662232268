import { combineReducers } from "redux";
import external from "./external";
import totals from "./totals";
import oldExternals from "./oldExternals";

export default combineReducers({
    external,
    totals,
    oldExternals
});
