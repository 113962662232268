import {
    COMPANY_CHANGED,
    CUSTOMER_CHANGED,
    CUSTOMIZER_COLUMN_ENABLED_CHANGED,
    CUSTOMIZER_DIRTY_CLEARED,
    DATE_RANGE_CHANGED,
    FILTER_RESET,
    GROUPS_CHANGED,
    LOCATION_CHANGED,
    MANAGED_BY_CHANGED,
    OPERATOR_CHANGED,
    PAGE_CHANGED,
    PAGE_DECREMENTED,
    PAGE_INCREMENTED,
    PERIOD_CHANGED,
    ROWS_PER_PAGE_CHANGED,
    SELECTED_FILTER_CHANGED,
    SELECTED_FILTER_PERIOD,
    SORT_BY_CHANGED,
    PARTS_CHANGED,
    CUSTOMIZATIONS_LOADED
} from "../actions/filtersActions";
import ordersTableModel from "../reportsTableModel";
import { periodSelectOptions } from "./../filtersModel";

const makeInitialState = _ => ({
    selectedFilter: SELECTED_FILTER_PERIOD,
    dateRange: {
        start: new Date(periodSelectOptions[0].dateStart.toString()),
        end: new Date(periodSelectOptions[0].dateEnd.toString())
    },
    period: periodSelectOptions[0].value,
    managedBy: "",
    company: "",
    customer: "",
    location: "",
    groups: ["open", "inProgress", "approved", "hidden", "visibleToCustomer", "deleted"],
    operator: "",
    parts: "",
    customizerEnabledColumns: [],
    customizerDirty: false,
    lastUpdate: null, // last update of the filters so that we can debounce the server requests,
    page: 0,
    sortBy: "-controlDate",
    rowsPerPage: 5,
    extendedSearch: false
});

export default function filters(state = makeInitialState(), action) {
    switch (action.type) {
        case FILTER_RESET:
            return { ...makeInitialState(), ...action.payload };
        case SELECTED_FILTER_CHANGED:
            return {
                ...state,
                customizerDirty: true,
                selectedFilter: action.payload,
                lastUpdate: action.lastUpdate
            };
        case DATE_RANGE_CHANGED:
            return {
                ...state,
                customizerDirty: true,
                dateRange: action.payload,
                lastUpdate: action.lastUpdate
            };
        case PERIOD_CHANGED:
            return {
                ...state,
                customizerDirty: true,
                period: action.payload,
                lastUpdate: action.lastUpdate
            };
        case MANAGED_BY_CHANGED:
            return {
                ...state,
                managedBy: action.payload,
                lastUpdate: action.lastUpdate
            };
        case COMPANY_CHANGED:
            return {
                ...state,
                company: action.payload,
                lastUpdate: action.lastUpdate
            };
        case CUSTOMER_CHANGED:
            return {
                ...state,
                customer: action.payload,
                lastUpdate: action.lastUpdate
            };
        case LOCATION_CHANGED:
            return {
                ...state,
                location: action.payload,
                lastUpdate: action.lastUpdate
            };
        case GROUPS_CHANGED:
            return {
                ...state,
                groups: action.payload,
                lastUpdate: action.lastUpdate
            };
        case OPERATOR_CHANGED:
            return {
                ...state,
                operator: action.payload,
                lastUpdate: action.lastUpdate
            };
        case CUSTOMIZATIONS_LOADED:
            return {
                ...state,
                customizerDirty: false,
                ...action.payload
            };
        case CUSTOMIZER_COLUMN_ENABLED_CHANGED: {
            const customizerEnabledColumns = [
                ...state.customizerEnabledColumns.filter(col => col !== action.columnKey),
                ...(action.payload ? [action.columnKey] : []) // re-add the column key if payload is true (doing this like that ensures that there will never be any duplicates)
            ];
            let sortBy = state.sortBy;
            if (!action.payload && (sortBy === action.columnKey || sortBy === "-" + action.columnKey)) {
                let newSortKey = "-controlDate";
                customizerEnabledColumns.find(enabledColumnKey => {
                    let definition = ordersTableModel.columns.find(c => c.key === enabledColumnKey);
                    if (!definition) return false;
                    if (definition.sortable) {
                        newSortKey = definition.backendKey || enabledColumnKey;
                        return true;
                    }

                    return false;
                });
                sortBy = newSortKey;
            }
            return {
                ...state,
                customizerDirty: true,
                lastUpdate: action.lastUpdate,
                customizerEnabledColumns,
                sortBy
            };
        }

        case CUSTOMIZER_DIRTY_CLEARED:
            return {
                ...state,
                customizerDirty: false
            };
        case ROWS_PER_PAGE_CHANGED:
            return {
                ...state,
                customizerDirty: true,
                lastUpdate: action.lastUpdate,
                rowsPerPage: action.payload,
                page: 0 // reset the page
            };
        case PAGE_CHANGED:
            return {
                ...state,
                page: action.payload
            };
        case PAGE_INCREMENTED:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
                page: state.page + 1
            };
        case PAGE_DECREMENTED:
            return {
                ...state,
                lastUpdate: action.lastUpdate,
                page: state.page - 1
            };

        case SORT_BY_CHANGED:
            return {
                ...state,
                page: 0,
                lastUpdate: action.lastUpdate,
                sortBy: action.payload
            };
        case PARTS_CHANGED:
            return {
                ...state,
                parts: action.payload,
                lastUpdate: action.lastUpdate
            };

        default:
            return state;
    }
}
