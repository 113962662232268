import React from "react";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import styled from "styled-components";
import { parseIntWithZeroFallback } from "../../../../../utils";
import { HR_FIELD_TYPE_EMAIL_NOTIFICATION } from "../../../../../../../common/src/hrTemplates";

const DaysEarlierField = styled(FormControl)`
    width: 210px;
    margin-bottom: 10px;
`;

const Label = styled.span`
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 5px;
    font-weight: bold;
    display: inline-block;
    min-width: 40px;
`;

export default ({ field, value, onChange }) => {
    value = value || { comments: "", daysEarlier: 0 };
    return (
        <div className="flex-row">
            <Label>{field.type === HR_FIELD_TYPE_EMAIL_NOTIFICATION ? "Email" : "Tray"}</Label>
            <FormControl
                value={value.comments}
                onChange={ev =>
                    onChange({
                        ...value,
                        comments: ev.target.value
                    })
                }
                placeholder={field.comments}
            />
            &nbsp;
            <DaysEarlierField
                type="number"
                value={value.daysEarlier === 0 ? "" : value.daysEarlier}
                onChange={ev =>
                    onChange({
                        ...value,
                        daysEarlier: parseIntWithZeroFallback(ev.target.value)
                    })
                }
                placeholder="Days earlier"
            />
        </div>
    );
};
