import React from "react";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import styled from "styled-components";

const InformationHeader = styled.h4`
    font-weight: bold; 
    font-size: 14px;
`;

const Container = styled.div`
    margin-bottom: 15px;
`;

@connect(({ clientPanel }) => clientPanel.clientPanelExternals)
export default class OrderInformation extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Col md={6}>
                    <InformationHeader>Problem description: </InformationHeader>
                    <Container>{this.props.orderInformation.problemDescription}</Container>
                </Col>
                <Col md={3}>
                    <InformationHeader>Customer: </InformationHeader>
                    <Container>{this.props.orderInformation.customerCompanyName}</Container>
                </Col>
                {this.props.orderInformation.locationCompanyName && <Col md={3}>
                    <InformationHeader>Location: </InformationHeader>
                    <Container>{this.props.orderInformation.locationCompanyName}</Container>
                </Col>}
            </React.Fragment>
        );
    }
}