import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import MatDirectionButton from "../../components/common/Mat/MatDirectionButton";
import MatSelect from "../../components/common/Mat/MatSelect";
import { companiesFiltersSelector, updateFilters } from "../actions/filtersActions";

const PaginatorFlex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 10px;
    padding-right: 10px;
    & > div {
        margin-right: 10px;
    }
`;

const Spacer = styled.div`
    width: 20px;
`;

const rowsPerPageOptions = [5, 10, 15, 25, 50, 100].map(v => ({ value: v, label: v.toString() }));

export default connect(state => ({
    filters: companiesFiltersSelector(state),
    results: state.clientPanel.customerOrdersList.results
}))(({ filters, results, dispatch }) => {
    const { rowsPerPage, page } = filters;
    return (
        <PaginatorFlex>
            <div>Rows per page</div>
            <MatSelect
                dropup
                value={5}
                options={rowsPerPageOptions}
                value={rowsPerPage}
                onChange={ev => dispatch(updateFilters({ rowsPerPage: ev ? ev.value : null, page: 0 }))}
            />
            <Spacer />
            <div>
                {page * rowsPerPage + 1}-{Math.min(page * rowsPerPage + rowsPerPage, results.totalRows)} of{" "}
                {results.totalRows}
            </div>
            <Spacer />
            <MatDirectionButton left onClick={() => dispatch(updateFilters({ page: page - 1 }))} disabled={page <= 0} />
            <Spacer />
            <MatDirectionButton
                onClick={() => dispatch(updateFilters({ page: page + 1 }))}
                disabled={page >= Math.floor(results.totalRows / rowsPerPage)}
            />
        </PaginatorFlex>
    );
});
