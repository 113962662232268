import React from "react";
import { remarksChanged, showRemarksChanged } from "../actions/externalActions";
import { connect } from "react-redux";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import styled from "styled-components";
import { InlineCheckboxContainer } from "./CurrentReportForm";
import isExternalDisabled from "../selectors/isExternalDisabled";

export const TextareaVerticalResize = styled(FormControl)`
    resize: vertical;
    max-width: 100%;
`;

const FromGroupWithMargin = styled(FormGroup)`
    margin-right: 10px;
`;
@connect(
    state => ({
        ...state.externals.external,
        disabled: isExternalDisabled(state)
    }),
    dispatch => ({
        remarksChanged: ev => dispatch(remarksChanged(ev.target.value)),
        showRemarksChanged: ev => dispatch(showRemarksChanged(ev.target.checked))
    })
)
export default class CurrentRemarks extends React.Component {
    render() {
        return (
            <div>
                <InlineCheckboxContainer>
                    <div>
                        <input
                            type="checkbox"
                            className="checkbox-big inline"
                            checked={this.props.showRemarks}
                            onChange={this.props.showRemarksChanged}
                            disabled={this.props.disabled}
                        />
                    </div>
                    <ControlLabel>Show remarks to the customer</ControlLabel>
                </InlineCheckboxContainer>
                <FromGroupWithMargin>
                    <TextareaVerticalResize
                        componentClass="textarea"
                        value={this.props.remarks}
                        onChange={this.props.remarksChanged}
                        disabled={!this.props.showRemarks || this.props.disabled}
                    />
                </FromGroupWithMargin>
            </div>
        );
    }
}
