import React from "react";
import StatusIndicator from "./StatusIndicator";
import styled from "styled-components";
import StyledIcon from "../../components/common/StyledIcon";
import { loadExternalFromReportId } from "../../ExternalsModal/actions/externalActions";
import { connect } from "react-redux";
import { userHasPermissionSelector } from "../../selectors/authSelectors";
import { PERMISSION_APPROVE_REPORTS, PERMISSION_CREATE_EXTERNALS } from "../../../../common/src/permissions";
import { apiFetch } from "../../actions/apiActions";
import { encodeParams } from "../../utils";
import { toast } from "react-toastify";
import { requestCache, statusChanged } from "../actions/resultsActions";

const DoubleRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;

@connect(state => ({
    showCreateExternal: userHasPermissionSelector(state, PERMISSION_CREATE_EXTERNALS),
    showApprove: userHasPermissionSelector(state, PERMISSION_APPROVE_REPORTS)
}))
export default class StatusCell extends React.Component {
    state = {
        loading: false
    };
    performApproval = async () => {
        this.setState({ loading: true });
        try {
            const getResp = await this.props.dispatch(apiFetch(encodeParams`/api/reports/${this.props.row._id}`));
            const data = await getResp.json();
            if (!getResp.ok) {
                throw new Error(data.message);
            }
            const approveResp = await this.props.dispatch(
                apiFetch(encodeParams`/api/reports/${this.props.row._id}/approval`, {
                    method: "PATCH",
                    body: JSON.stringify({
                        ...data,
                        versionMetadata: {
                            message: "Approved"
                        },
                        approval: true
                    })
                })
            );
            if (!approveResp.ok) {
                throw new Error((await approveResp.json()).message);
            }
            for (let i in requestCache) {
                delete requestCache[i];
            }
            this.props.dispatch(statusChanged(this.props.row._id, "approved"));
        } catch (e) {
            console.error(e);
            toast("Failed to approve report:" + e.message, { type: "error" });
        } finally {
            this.setState({ loading: false });
        }
    };
    render() {
        if (this.props.value === "inProgress" && this.props.showApprove) {
            return (
                <DoubleRow>
                    <StatusIndicator partial status={this.props.value} wide />
                    <StatusIndicator
                        partial
                        status={"approved"}
                        clickable
                        checked={!this.state.loading}
                        onClick={this.performApproval}
                    >
                        {this.state.loading && <StyledIcon spin color="white" center />}
                    </StatusIndicator>
                </DoubleRow>
            );
        }
        if (this.props.value === "approved" && this.props.showCreateExternal) {
            return (
                <DoubleRow>
                    <StatusIndicator partial status={this.props.value} wide />
                    <StatusIndicator
                        partial
                        status={"visibleToCustomer"}
                        clickable
                        checked
                        onClick={() => this.props.dispatch(loadExternalFromReportId(this.props.row._id))}
                    />
                </DoubleRow>
            );
        }
        return <StatusIndicator status={this.props.value} wide />;
    }
}
