import React from "react";
import ExternalsList from "./ExternalsList";
import Searchbar from "./Searchbar";
import styled from "styled-components";

const ExternalContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Line = styled.div`
    border-top: 1px solid rgba(0, 0, 0, 0.55);
    margin-left: 16px;
    margin-right: 16px;
`;

export function setTitle(d, dictionary) {
    let result = "";
    const foundRow = dictionary.find(l => l.label === d);
    if (foundRow) {
        result = foundRow.value;
    }
    return result || "n/a";
}

export default class External extends React.Component {
    render() {
        return (
            <ExternalContainer>
                <Searchbar />
                <Line />
                <ExternalsList />
            </ExternalContainer>
        );
    }
}
