import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AutoTable from "../../components/common/AutoTable";
import MatBox from "../../components/common/Mat/MatBox";
import { companiesFiltersSelector, resetFiltersAndLoadInitialData, updateFilters } from "../actions/filtersActions";
import companiesTableModel from "../companiesTableModel";
import FiltersForm from "./FiltersForm";
import Paginator from "./Paginator";
import { loadRows } from "../actions/resultsActions";
const ResultsBox = styled(MatBox)`
    padding: 0;
    padding-top: 25px;

    strong {
        margin: 15px;

        font-size: 17px;
    }
`;

const HrNoMargin = styled.hr`
    margin-top: 0;
    margin-bottom: 0;
`;

@connect(state => ({
    search: state.router.location.search,
    // sortBy: state.ordersList.filters.sortBy,
    isLoading: state.companiesList.results.isLoading,
    filters: companiesFiltersSelector(state)
}))
export default class CompaniesListPage extends React.PureComponent {
    componentDidMount() {
        this.props.dispatch(loadRows());
    }
    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            // this.props.dispatch(resetFiltersAndLoadInitialData(false));
        }
    }
    render() {
        return (
            <div>
                <style>
                    {`body {
                        background: #f1f2f7;
                    }`}
                </style>
                <FiltersForm />

                <ResultsBox>
                    <strong>Companies</strong>
                    <AutoTable
                        model={companiesTableModel}
                        runtimeProps={{
                            sortBy: this.props.filters.sortBy,
                            sortByChanged: ev => this.props.dispatch(updateFilters({ sortBy: ev }))
                        }}
                        loading={this.props.isLoading}
                    />
                    {/* <Paginator /> */}
                </ResultsBox>
            </div>
        );
    }
}
