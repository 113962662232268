import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AutoTable from "../../../components/common/AutoTable";
import MatBox from "../../../components/common/Mat/MatBox";
import { ordersListFiltersSelector, updateFilters } from "../actions/filtersActions";
import { loadRows } from "../actions/resultsActions";
import customerOrdersListTableModel from "../customerOrdersListTableModel";
import FiltersForm from "./FiltersForm";
import Paginator from "./Paginator";

const ResultsBox = styled(MatBox)`
    padding: 0;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 25px;
    max-width: calc(100% - 40px);
   
`;

@connect(
    state => ({
        ...state.clientPanel.customerOrdersList.results,
        ...ordersListFiltersSelector(state)
    }),
    dispatch => ({
        updateFilters: nf => dispatch(updateFilters(nf)),
        loadRows: () => dispatch(loadRows())
    })
)
export default class CustomerOrdersListPage extends React.Component {
    componentDidMount() {
        this.props.updateFilters({});
        setTimeout(() => this.props.loadRows({}), 10);
    }

    render() {
        return (
            <div>
                <FiltersForm />
                <div>
                    <ResultsBox>
                        <AutoTable
                            model={customerOrdersListTableModel}
                            runtimeProps={{
                                sortBy: this.props.sortBy,
                                sortByChanged: ev => this.props.updateFilters({ sortBy: ev })
                            }}
                            loading={this.props.isLoading}
                        />
                        <Paginator />
                    </ResultsBox>
                </div>

                <div style={{ height: "500px" }} />
            </div>
        );
    }
}
