import {
    STATE_DEFAULT,
    STATE_CHANGED,
    ERROR_CHANGED,
    INVITATION_MODAL_OPEN_CHANGED,
    INVITATION_EMPLOYEE_ID_CHANGED,
    INVITATION_NAME_CHANGED,
    INVITATION_EMAIL_CHANGED,
    INVITATION_CONTENT_CHANGED,
    INVITATION_INDEX_CHANGED
} from "../actions/invitationActions";

export const initialState = {
    invitationModalOpen: false,
    state: STATE_DEFAULT,
    error: null,
    employeeId: null,
    index: null,
    name: null,
    email: null,
    content: null
};

export default function invitation(state = initialState, action) {
    switch (action.type) {
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case ERROR_CHANGED:
            return {
                ...state,
                error: action.payload
            };
        case INVITATION_MODAL_OPEN_CHANGED:
            return {
                ...state,
                invitationModalOpen: action.payload
            };
        case INVITATION_EMPLOYEE_ID_CHANGED:
            return {
                ...state,
                employeeId: action.payload
            };
        case INVITATION_NAME_CHANGED:
            return {
                ...state,
                name: action.payload
            };
        case INVITATION_INDEX_CHANGED:
            return {
                ...state,
                index: action.payload
            };
        case INVITATION_EMAIL_CHANGED:
            return {
                ...state,
                email: action.payload
            };
        case INVITATION_CONTENT_CHANGED:
            return {
                ...state,
                content: action.payload
            };
        default:
            return state;
    }
}
