import React from "react";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { modalDeleted } from "../../actions/modalsActions";

@connect(
    () => ({

    }),
    dispatch => ({
        modalDeleted: modalId => dispatch(modalDeleted(modalId))
    })
)
export default class AlertModal extends React.Component {
    closeModal = _ => {
        this.props.modalDeleted(this.props.modal.modalId);
    };
    render() {
        let modalData = this.props.modal.data;
        return (
            <Modal show={true} onHide={this.closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {modalData.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{modalData.message}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeModal}>{modalData.buttonText || "OK"}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
