import React from "react";
import { connect } from "react-redux";
import MatSpinner from "../../components/common/Mat/MatSpinner";

export default connect((state, { column }) => ({ field: state.ordersList.results.calculatableTotals[column.key] }))(
    ({ column, model, field }) => {
        if (field.isLoading) {
            return (
                <td>
                    <MatSpinner slim />
                </td>
            );
        }
        if (field.data) {
            return <td>{column.calculatableFormat(field.data)}</td>;
        }
        return <td />;
    }
);
