import { makeActionCreator, encodeParams } from "../../utils";
import { apiFetch } from "../../actions/apiActions";
import { toast } from "react-toastify";
import { push } from "react-router-redux";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_ERROR = "STATE_ERROR";
export const STATE_NEW = "STATE_NEW";

export const STATE_CHANGED = "USERS.EDIT_USER.STATE_CHANGED";
export const ERROR_CHANGED = "USERS.EDIT_USER.ERROR_CHANGED";
export const RESET_FOR_NEW_USER = "USERS.EDIT_USER.RESET_FOR_NEW_USER";
export const DATA_LOADED = "USERS.EDIT_USER.DATA_LOADED";
export const DISPLAY_NAME_CHANGED = "USERS.EDIT_USER.DISPLAY_NAME_CHANGED";
export const EMAIL_CHANGED = "USERS.EDIT_USER.EMAIL_CHANGED";
export const PASSWORD_CHANGED = "USERS.EDIT_USER.PASSWORD_CHANGED";
export const PASSWORD2_CHANGED = "USERS.EDIT_USER.PASSWORD2_CHANGED";
export const GROUP_CHANGED = "USERS.EDIT_USER.GROUP_CHANGED";
export const CONNECTED_EMPLOYEE_CHANGED = "USERS.EDIT_USER.CONNECTED_EMPLOYEE_CHANGED";
export const BLOCKED_CHANGED = "USERS.EDIT_USER.BLOCKED_CHANGED";
export const GROUP_DATA_CHANGED = "USERS.EDIT_USER.GROUP_DATA_CHANGED";
export const GROUP_DATA_STATE_CHANGED = "USERS.EDIT_USER.GROUP_DATA_STATE_CHANGED";
export const OVERRIDE_CHANGED = "USERS.EDIT_USER.OVERRIDE_CHANGED";
export const OVERRIDES_CLEARED = "USERS.EDIT_USER.OVERRIDES_CLEARED";

export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const errorChanged = makeActionCreator(ERROR_CHANGED, "payload");
export const resetForNewUser = makeActionCreator(RESET_FOR_NEW_USER);
export const dataLoaded = makeActionCreator(DATA_LOADED, "payload");
export const displayNameChanged = makeActionCreator(DISPLAY_NAME_CHANGED, "payload");
export const emailChanged = makeActionCreator(EMAIL_CHANGED, "payload");
export const passwordChanged = makeActionCreator(PASSWORD_CHANGED, "payload");
export const password2Changed = makeActionCreator(PASSWORD2_CHANGED, "payload");
export const groupChanged = makeActionCreator(GROUP_CHANGED, "payload");
export const connectedEmployeeChanged = makeActionCreator(CONNECTED_EMPLOYEE_CHANGED, "payload");
export const blockedChanged = makeActionCreator(BLOCKED_CHANGED, "payload");
export const groupDataChanged = makeActionCreator(GROUP_DATA_CHANGED, "payload");
export const groupDataStateChanged = makeActionCreator(GROUP_DATA_STATE_CHANGED, "payload");
export const overrideChanged = makeActionCreator(OVERRIDE_CHANGED, "permission", "payload");
export const overridesCleared = makeActionCreator(OVERRIDES_CLEARED);

export function groupsSearched(query) {
    return async dispatch => {
        try {
            let resp = await dispatch(apiFetch("/api/groups/search", {}, { query }));
            let data = await resp.json();
            if (!resp.ok) {
                throw new Error("API error", data.message);
            }
            return data;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Failed to search for employees", e);
            return [];
        }
    };
}

export function createUser() {
    return async (dispatch, getState) => {
        dispatch(stateChanged(STATE_LOADING));
        try {
            let resp = await dispatch(
                apiFetch("/api/auth/users", {
                    method: "POST",
                    body: JSON.stringify({
                        ...getState().users.editUser,
                        state: undefined,
                        error: undefined,
                        password2: undefined
                    })
                })
            );
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            dispatch(stateChanged(STATE_NEW));
            dispatch(resetForNewUser());
            dispatch(push("/dashboard/users/list"));
            toast("User created successfully!", {
                type: "success"
            });
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(stateChanged(STATE_ERROR));
            dispatch(errorChanged(e.message));
            return;
        }
    };
}

export function loadUser(id) {
    return async dispatch => {
        dispatch(stateChanged(STATE_LOADING));
        try {
            let resp = await dispatch(apiFetch(encodeParams`/api/users/${id}`));
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            dispatch(dataLoaded(await resp.json()));
            await dispatch(loadGroupData());
            dispatch(stateChanged(STATE_LOADED));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(stateChanged(STATE_ERROR));
            dispatch(errorChanged(e.message));
            return;
        }
    };
}

export function saveUser(id) {
    return async (dispatch, getState) => {
        dispatch(stateChanged(STATE_LOADING));
        try {
            let userState = getState().users.editUser;
            let resp = await dispatch(
                apiFetch(encodeParams`/api/users/${id}`, {
                    method: "PATCH",
                    body: JSON.stringify({
                        displayName: userState.displayName,
                        email: userState.email,
                        group: userState.group,
                        connectedEmployee: userState.connectedEmployee,
                        blocked: userState.blocked,
                        permissionOverrides: userState.permissionOverrides
                    })
                })
            );
            if (!resp.ok) {
                throw new Error((await resp.json()).message);
            }
            if (userState.password.trim() !== "") {
                // we need to change the password
                let resp = await dispatch(
                    apiFetch(encodeParams`/api/users/${id}/changePassword`, {
                        method: "PATCH",
                        body: JSON.stringify({
                            password: userState.password
                        })
                    })
                );
                if (!resp.ok) {
                    throw new Error((await resp.json()).message);
                }
            }
            dispatch(stateChanged(STATE_NEW));
            dispatch(resetForNewUser());
            dispatch(push("/dashboard/users/list"));
            toast("User updated successfully!", {
                type: "success"
            });
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(stateChanged(STATE_ERROR));
            dispatch(errorChanged(e.message));
            return;
        }
    };
}

export function loadGroupData() {
    return async (dispatch, getState) => {
        try {
            let groupId = getState().users.editUser.group;
            if (!groupId) {
                dispatch(groupDataStateChanged(STATE_DEFAULT));
                return;
            }
            dispatch(groupDataStateChanged(STATE_LOADING));
            let resp = await dispatch(apiFetch(encodeParams`/api/groups/${groupId}`));
            let data = await resp.json();
            if (!resp.ok) {
                throw new Error(data.message);
            }
            dispatch(groupDataChanged(data));
            dispatch(groupDataStateChanged(STATE_LOADED));
        } catch (e) {
            dispatch(groupDataStateChanged(STATE_ERROR));
            toast("Error while loading group data: " + e.message, {
                type: "error"
            });
            console.error(e);
        }
    };
}
