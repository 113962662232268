import {
    MODAL_CREATED,
    MODAL_DELETED,
    MODAL_STATE_CHANGED,
    MODAL_STATE_OPENED,
    MODAL_RESULT_CHANGED
} from "../actions/modalsActions";

const initialState = {
    modals: []
};

export default function modals(state = initialState, action) {
    switch (action.type) {
        case MODAL_CREATED:
            return {
                ...state,
                modals: [
                    ...state.modals,
                    {
                        data: action.data,
                        modalId: action.modalId,
                        state: MODAL_STATE_OPENED,
                        result: action.defaultResult == null ? null : action.defaultResult
                    }
                ]
            };
        case MODAL_DELETED:
            return {
                ...state,
                modals: state.modals.filter(m => m.modalId !== action.modalId)
            };
        case MODAL_STATE_CHANGED:
            return {
                ...state,
                modals: state.modals.map(m => (m.modalId === action.modalId ? { ...m, state: action.payload } : m))
            };
        case MODAL_RESULT_CHANGED:
            return {
                ...state,
                modals: state.modals.map(m => (m.modalId === action.modalId ? { ...m, result: action.payload } : m))
            };
        default:
            return state;
    }
}
