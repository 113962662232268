import React from "react";
import MatButton from "../../components/common/Mat/MatButton";
import { connect } from "react-redux";
import { finishOrder } from "../actions/resultsActions";

export default connect()(({ row, dispatch }) => {
    if (row.finished) return null;
    return (
        <MatButton slim orderFinishColor onClick={() => dispatch(finishOrder(row._id))}>
            Finish
        </MatButton>
    );
});
