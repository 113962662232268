import React from "react";
import { formatDate, formatTimeWithoutZeros, roundNumberToThousands } from "../../../../../../utils";
import styled from "styled-components";

const DateContainer = styled.div`
    width: 25px;
    height 25px;
    background-color: #3875ab;
    font-size: 9px;
    border-radius: 3px 0 0 3px;
    float: left;
    display: inline-block;
    margin: -1px 0 0 -5px;
    color: #fff;
`;

const DateContainerText = styled.div`
    margin: 1px 0 0 8px;
`;

const TimeText = styled.div`
    margin: 1px 0 0 25px;
    display: inline-block;
    position: absolute;    
    right: 170px;
`;

const TotalText = styled.div`
    margin: 1px 0 0 10px;
    display: inline-block;
    position: absolute;
    right: 135px;
`;

const WorkingHours = styled.div`
    margin: 1px 5px 0 10px;
    display: inline-block;
    position: absolute;
    right: 60px;
`;

const NOKText = styled.div`
    margin: 1px 5px 0 10px;
    display: inline-block;
    position: absolute;
    right: 100px;
    color: red;
`;

const styleSmallText = {
    fontSize: "11px",
    marginTop: "4px"
};

export default class ReportContentContainer extends React.Component {

    render() {
        function useSmallText(report) {
            if(formatTimeWithoutZeros(report.startWork)
                && formatTimeWithoutZeros(report.endWork)
                && (formatTimeWithoutZeros(report.startWork).length
                    + formatTimeWithoutZeros(report.endWork).length) > 9) {
                return true;
            }
            return false;
        }

        function useSmallTextInWH(report) {
            if(report.sumWorkingHours && report.sumWorkingHours.length > 4) {
                return true;
            }
            return false;
        }

        return (
            <div>
                <DateContainer>
                    <DateContainerText>
                        {formatDate(this.props.report.controlDate, "custom").day} <br/>
                        {formatDate(this.props.report.controlDate, "custom").month}
                    </DateContainerText>
                </DateContainer>
                <TimeText
                    style={ useSmallText(this.props.report)
                        ? styleSmallText : {}}>
                    {formatTimeWithoutZeros(this.props.report.startWork)}-{formatTimeWithoutZeros(this.props.report.endWork)}
                </TimeText>
                <TotalText
                    style={roundNumberToThousands(this.props.report.total)
                    && roundNumberToThousands(this.props.report.total).length > 4
                        ? styleSmallText : {}}>
                    {roundNumberToThousands(this.props.report.total)}
                </TotalText>
                <WorkingHours style={useSmallTextInWH(this.props.report)? styleSmallText: {}}>
                    {(this.props.report.sumWorkingHours || this.props.report.sumWorkingHours === 0)
                        ? this.props.report.sumWorkingHours + "h" : ""}
                </WorkingHours>
                <NOKText
                    style={(roundNumberToThousands(this.props.report.nok)
                        && (roundNumberToThousands(this.props.report.nok).length > 3)) ? styleSmallText : {}}>
                    {roundNumberToThousands(this.props.report.nok)}
                </NOKText>
            </div>
        );
    }
}