import React from "react";
import StyledIcon from "./StyledIcon";
import { Panel } from "react-bootstrap";

export default class PageSpinner extends React.Component {
    render() {
        if (this.props.error) {
            return (
                <div>
                    <Panel header="Error" bsStyle="danger">
                        An error has occured while loading this page. Error Info: {this.props.error.message}
                    </Panel>
                </div>
            );
        } else if (this.props.pastDelay) {
            return (
                <div>
                    <StyledIcon spin center size="big" />
                </div>
            );
        } else {
            return null;
        }
    }
}
