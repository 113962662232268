import React from "react";
import { FormControl, FormGroup, Panel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Logo from "../../components/common/Logo";
import NarrowContainer from "../../components/common/containers/NarrowContainer";
import { emailChanged, passwordChanged, login, STATE_LOADING, STATE_ERROR } from "../actions/loginActions";
import LoadableButton from "../../components/common/LoadableButton";
import styled from "styled-components";

const ResetPasswordWrapper = styled.div`
    margin-top: 5px;
    text-align: right;
`;

@connect(
    state => ({
        ...state.login,
        isLoading: state.login.loginState === STATE_LOADING,
        isError: state.login.loginState === STATE_ERROR
    }),
    dispatch => ({
        emailChanged: ev => dispatch(emailChanged(ev.target.value)),
        passwordChanged: ev => dispatch(passwordChanged(ev.target.value)),
        onLoginClicked: _ => dispatch(login())
    })
)
export default class LoginPage extends React.Component {
    handleKeyDownOnInput = ev => {
        if (ev.keyCode === 13) {
            // enter pressed, submit login data
            this.props.onLoginClicked();
        }
    };
    render() {
        let errorPanel;
        if (this.props.isError) {
            errorPanel = (
                <Panel header="Error" bsStyle="danger">
                    {this.props.errorMessage}
                </Panel>
            );
        }

        return (
            <div className="center-vertically">
                <NarrowContainer>
                    <Logo />
                    {errorPanel}
                    <FormGroup>
                        <FormControl
                            type="email"
                            placeholder="Enter email"
                            value={this.props.email}
                            onChange={this.props.emailChanged}
                            onKeyDown={this.handleKeyDownOnInput}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControl
                            type="password"
                            placeholder="Enter password"
                            value={this.props.password}
                            onChange={this.props.passwordChanged}
                            onKeyDown={this.handleKeyDownOnInput}
                        />
                    </FormGroup>

                    <LoadableButton
                        block
                        bsStyle="primary"
                        onClick={this.props.onLoginClicked}
                        isLoading={this.props.isLoading}
                    >
                        Login
                    </LoadableButton>
                    <ResetPasswordWrapper>
                        <Link to="/login/password-reset">Reset password</Link>
                    </ResetPasswordWrapper>
                </NarrowContainer>
            </div>
        );
    }
}
