import { makeActionCreator } from "../../utils";

export const STATE_DEFAULT = "STATE_DEFAULT";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_ERROR = "STATE_ERROR";

export const STATE_CHANGED = "INVITATIONS.QUESTIONNAIRE.STATE_CHANGED";
export const ERROR_CHANGED = "INVITATIONS.QUESTIONNAIRE.ERROR_CHANGED";
export const QUESTIONNAIRE_F2F_CONTACT_CHANGED = "INVITATIONS.QUESTIONNAIRE.QUESTIONNAIRE_F2F_CONTACT_CHANGED";
export const QUESTIONNAIRE_VIA_PHONE_CHANGED = "INVITATIONS.QUESTIONNAIRE.QUESTIONNAIRE_VIA_PHONE_CHANGED";
export const QUESTIONNAIRE_BY_EMAIL_CHANGED = "INVITATIONS.QUESTIONNAIRE.QUESTIONNAIRE_BY_EMAIL_CHANGED";
export const QUESTIONNAIRE_NOT_SETTLED_CHANGED = "INVITATIONS.QUESTIONNAIRE.QUESTIONNAIRE_NOT_SETTLED_CHANGED";
export const QUESTIONNAIRE_CLEARED_CHANGED = "INVITATIONS.QUESTIONNAIRE.QUESTIONNAIRE_CLEARED_CHANGED";

export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const errorChanged = makeActionCreator(ERROR_CHANGED, "payload");
export const questionnaireF2fContactChanged = makeActionCreator(QUESTIONNAIRE_F2F_CONTACT_CHANGED, "payload");
export const questionnaireViaPhoneChanged = makeActionCreator(QUESTIONNAIRE_VIA_PHONE_CHANGED, "payload");
export const questionnaireByEmailChanged = makeActionCreator(QUESTIONNAIRE_BY_EMAIL_CHANGED, "payload");
export const questionnaireNotSettledChanged = makeActionCreator(QUESTIONNAIRE_NOT_SETTLED_CHANGED, "payload");
export const questionnaireClearedChanged = makeActionCreator(QUESTIONNAIRE_CLEARED_CHANGED);
